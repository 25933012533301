import { useTranslation } from 'react-i18next'

import { Role } from 'src/travelsuit'

export function useClassRolesOptions() {
	const { t } = useTranslation()

	return [
		{ value: null, label: t('flight-policy-settings.business-class-roles.no-one', 'No One') },
		{
			value: Role.Employee,
			label: t('flight-policy-settings.business-class-roles.all-employees', 'All Employees'),
		},
		{
			value: Role.Manager,
			label: t('flight-policy-settings.business-class-roles.managers-and-above', 'Managers and above'),
		},
		{
			value: Role.Executive,
			label: t('flight-policy-settings.business-class-roles.executives-only', 'Executives only'),
		},
	]
}
