import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { E2E } from 'src/lib/e2e-utils'
import { currency } from 'src/lib/number-utils'
import { BRAND_SPECIFIC } from 'src/travelsuit'
import { Currency } from 'src/types/common'

import { PolicySettingSummaryValue } from './PolicySettings.components'

interface FlightPriceCapProps extends E2E {
	smartPrice: number | null
	maxPrice: number | null
	currencyCode?: Currency
}

export function FlightPriceCap({ smartPrice, maxPrice, currencyCode, e2e }: FlightPriceCapProps) {
	const { t } = useTranslation()

	const flightPriceCap = currency(maxPrice ?? 0, {
		removeZeroes: true,
		currency: currencyCode,
	})

	return (
		<PolicySettingSummaryValue e2e={e2e}>
			{smartPrice !== null && (
				<Trans i18nKey="flight-policy-settings.dynamic-price-cap.smartprice-summary">
					{{ smartPricePolicy: BRAND_SPECIFIC.SMART_PRICE_POLICY }} price plus{' '}
					<span data-test={`${e2e}.PercentileDeviation`}>{{ percentage: smartPrice }}%</span>
					{', '}
				</Trans>
			)}
			{maxPrice ? (
				<Trans i18nKey="flight-policy-settings.dynamic-price-cap.max-price-summary">
					Max price per ticket <span data-test={`${e2e}.MaxPrice`}>{{ flightPriceCap }}</span>
				</Trans>
			) : (
				t('flight-policy-settings.unlimited-price-cap', 'Unlimited')
			)}
		</PolicySettingSummaryValue>
	)
}
