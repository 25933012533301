import moment from 'moment'

import { entityGenerator, TimeFormatsLocaleUnsafe } from 'src/lib/utils'
import { NationalId, TravelerDocumentType } from 'src/travelsuit/index'

export const emptyNationalId = entityGenerator<NationalId>({
	id: null,
	type: TravelerDocumentType.National_ID,
	number: '',
	expiration_date: null,
	country: { code: '', name: '' },
})

export function prepareNationalIdJson(nationalId: NationalId) {
	const copy: Partial<NationalId> = { ...nationalId }
	delete copy.id
	copy.expiration_date = moment(copy.expiration_date!).format(TimeFormatsLocaleUnsafe.DateOnly)
	return copy
}
