import React from 'react'

import { GridProps } from 'src/css-helpers/flex'
import { Trans, useTranslation } from 'src/lib/i18n/i18n'
import { mapSelectOptions } from 'src/lib/utils'
import LogicalGroupTextRepr from 'src/molecules/LogicalGroupTextRepr/LogicalGroupTextRepr'
import UserChipList from 'src/molecules/UserChipList/UserChipList'
import { fontBlack, fontSecondary, mainBlack } from 'src/refactor/colors'
import styled from 'src/styles'
import {
	ApprovalPolicy,
	CompanyApprovalSetting,
	DefaultProps,
	getApproverLabelByGroupTypeTranslations,
	GroupTypes,
	SelectOption,
	User,
} from 'src/travelsuit'
import { TravelPolicy, TravelPolicyMinimal } from 'src/travelsuit/policy'
import { fullName } from 'src/travelsuit/users'

import {
	PolicySettingSummary,
	PolicySettingSummaryColumn,
	PolicySettingSummaryItem,
	PolicySettingSummaryTitle,
	PolicySettingSummaryValue,
} from './PolicySettings.components'
import { isTravelPolicyLoaded } from './utils'

interface IProps extends DefaultProps {
	policy: TravelPolicy | TravelPolicyMinimal
	groupType?: GroupTypes
	columns?: GridProps['columns']
	actualApprovers?: User[][]
	ignoreApprovals?: boolean
}

const GeneralPolicySettingsSummaryContainer = styled(PolicySettingSummary)`
	display: block;
`
const GeneralPolicySettingSummaryValue = styled(PolicySettingSummaryValue)`
	color: ${fontSecondary};
	& b {
		color: ${fontBlack};
	}
`

const approvalPolicyOptions: SelectOption<ApprovalPolicy>[] = [
	{
		value: ApprovalPolicy.Never,
		label: <Trans i18nKey="general-policy-settings.approval-options.never">Never</Trans>,
	},
	{
		value: ApprovalPolicy.Always,
		label: <Trans i18nKey="general-policy-settings.approval-options.always">Always</Trans>,
	},
	{
		value: ApprovalPolicy.WhenOutOfPolicy,
		label: <Trans i18nKey="general-policy-settings.approval-options.when-out-of-policy">Only When Out of Policy</Trans>,
	},
]

const approverOptions: SelectOption<CompanyApprovalSetting>[] = [
	{
		value: CompanyApprovalSetting.Main,
		label: <Trans i18nKey="general-policy-settings.approval-options.company-approver">Company Approver</Trans>,
	},
	{
		value: CompanyApprovalSetting.OfficeAdmin,
		label: <Trans i18nKey="general-policy-settings.approval-options.office-admin">Office Approver</Trans>,
	},
	{
		value: CompanyApprovalSetting.GroupApprover,
		label: <Trans i18nKey="general-policy-settings.approval-options.department-manager">Department Approver</Trans>,
	},
]

const GeneralPolicySettingsSummary: React.FunctionComponent<IProps> = (props) => {
	const { className, policy, groupType, actualApprovers, ignoreApprovals, columns } = props
	const { t } = useTranslation()

	if (!isTravelPolicyLoaded(policy)) {
		return null
	}

	const { users, approval_policy: approvalPolicy } = policy

	const currentApprovalOption: SelectOption<ApprovalPolicy> =
		approvalPolicyOptions.find((o) => o.value === approvalPolicy) || approvalPolicyOptions[0]

	const flow = policy.approval_flow ?? { conditions: [] }
	const { conditions = [] } = flow
	const flowSelections = conditions.map(
		(cur) =>
			[
				cur.require_company_approver && CompanyApprovalSetting.Main,
				cur.require_department_approver && CompanyApprovalSetting.GroupApprover,
				cur.require_office_approver && CompanyApprovalSetting.OfficeAdmin,
			].filter(Boolean) as CompanyApprovalSetting[],
	)
	const disabled = Boolean(currentApprovalOption.value === ApprovalPolicy.Never || ignoreApprovals)
	const isCustomPolicy = groupType === GroupTypes.PolicyGroup
	const isOfficePolicy = groupType === GroupTypes.Office
	const approvers = isOfficePolicy ? [policy.group?.approver].filter(Boolean) : policy.approvers
	const _shouldBreakActualApprovers = (arr: any[], i: number) =>
		arr?.length > 1 &&
		(arr[i].length > 1 || (i > 0 && arr[i - 1].length > 1) || (i < arr.length - 1 && arr[i + 1].length > 1))

	return (
		<GeneralPolicySettingsSummaryContainer className={className} columns={columns}>
			<PolicySettingSummaryColumn>
				{!ignoreApprovals && (
					<PolicySettingSummaryItem>
						<PolicySettingSummaryTitle e2e={'PolicySettings.ApprovalIsNeededTitle'}>
							<Trans i18nKey="general-policy-settings.approval-needed">Approval Is Needed</Trans>
						</PolicySettingSummaryTitle>
						<PolicySettingSummaryValue e2e={'PolicySettings.ApprovalIsNeededStatus'}>
							{currentApprovalOption.label}
						</PolicySettingSummaryValue>
					</PolicySettingSummaryItem>
				)}
				{!disabled && !isCustomPolicy ? (
					<PolicySettingSummaryItem>
						<PolicySettingSummaryTitle e2e={'PolicySettings.RequiredApproversTitle'}>
							<Trans i18nKey="general-policy-settings.required-approvers">Required Approvers</Trans>
						</PolicySettingSummaryTitle>
						<PolicySettingSummaryValue e2e={'PolicySettings.RequiredApproversStatus'}>
							{flowSelections.length && flowSelections.some((s) => s.length) ? (
								<LogicalGroupTextRepr
									options={approverOptions}
									selections={flowSelections}
									color={mainBlack}
									data-test="PolicySettings.RequiredApproversStatus.Info"
								/>
							) : (
								<Trans i18nKey="general-policy-settings.no-selections">No Selections</Trans>
							)}
						</PolicySettingSummaryValue>
					</PolicySettingSummaryItem>
				) : null}
				{isCustomPolicy ? (
					<PolicySettingSummaryItem>
						<PolicySettingSummaryTitle>
							<Trans i18nKey="general-policy-settings.traveler" count={users?.length ?? 0}>
								Traveler
							</Trans>
						</PolicySettingSummaryTitle>
						<PolicySettingSummaryValue>
							{users.length ? (
								<UserChipList users={users} limit={6} />
							) : (
								<Trans i18nKey="general-policy-settings.none">None</Trans>
							)}
						</PolicySettingSummaryValue>
					</PolicySettingSummaryItem>
				) : null}
				{ignoreApprovals ? (
					<>
						<PolicySettingSummaryTitle>
							{t('general-policy-settings.ignore-approvals-title', 'Approvals ignored for user')}
						</PolicySettingSummaryTitle>
						<PolicySettingSummaryValue>
							{t(
								'general-policy-settings.ignore-approvals-text',
								'This user is under the "Travelers exempt from approval" policy group, which means no approval is necessary.',
							)}
						</PolicySettingSummaryValue>
					</>
				) : null}
				{!disabled ? (
					<PolicySettingSummaryItem>
						{!actualApprovers?.length ? (
							<>
								<PolicySettingSummaryTitle e2e={'PolicySettings.CompanyApproversTitle'}>
									{getApproverLabelByGroupTypeTranslations(t, groupType!, { count: approvers.length })}
								</PolicySettingSummaryTitle>
								<PolicySettingSummaryValue e2e={'PolicySettings.CompanyApproversList'}>
									{approvers.length ? (
										<UserChipList data-test="PolicySettings.CompanyApproversList" users={approvers} limit={4} />
									) : (
										<Trans i18nKey="general-policy-settings.none">None</Trans>
									)}
								</PolicySettingSummaryValue>
							</>
						) : (
							<>
								<PolicySettingSummaryTitle>
									{t('general-policy-settings.trip-approvers', {
										count: actualApprovers.reduce((count, list) => list.length + count, 0),
									})}
								</PolicySettingSummaryTitle>
								<GeneralPolicySettingSummaryValue>
									<LogicalGroupTextRepr
										data-test="PolicySettings.TripApprovers.Info"
										options={actualApprovers.reduce<SelectOption<User>[]>(
											(all, l) => [...all, ...mapSelectOptions<React.ReactNode, User>(l, { mapLabel: fullName })],
											[],
										)}
										selections={actualApprovers}
										color={mainBlack}
										renderGroup={(child, _group, _origGroup, _sep, i) =>
											_shouldBreakActualApprovers(actualApprovers, i) ? <div>{child}</div> : child
										}
									/>
								</GeneralPolicySettingSummaryValue>
							</>
						)}
					</PolicySettingSummaryItem>
				) : null}
			</PolicySettingSummaryColumn>
		</GeneralPolicySettingsSummaryContainer>
	)
}

export default GeneralPolicySettingsSummary
