import React from 'react'

import { Coords } from 'src/lib/utils'
import Details from 'src/refactor/assets/actions/details.svg'
import { backgroundGray, brightRed, linkBlue, successGreen, transparent, white, yellow } from 'src/refactor/colors'
import { font, FontProps } from 'src/refactor/fonts'
import styled, { padMulti, padPx } from 'src/styles'
import { DefaultProps } from 'src/travelsuit'

import { Grid } from '../GenericComponents/GenericComponents'
import { IProps as IconProps, SvgIcon } from '../SvgIcon/SvgIcon'

interface IProps extends DefaultProps {
	title?: React.ReactNode
	level: 'info' | 'warning' | 'error' | 'success'
	icon?: string
	iconSize?: IconProps['size']
	iconOffset?: Partial<Coords>
	variant?: 'normal' | 'disclaimer' | 'mini'
	fontSize?: FontProps['size']
	backgroundColor?: string
}

type StyleProps = Pick<IProps, 'level' | 'variant' | 'fontSize' | 'iconSize' | 'backgroundColor'>

const LevelColorMap: Record<IProps['level'], string> = {
	info: linkBlue,
	warning: yellow,
	error: brightRed,
	success: successGreen,
}

export const InfoBoxContainer = styled(Grid)<StyleProps>`
	grid-template-columns: ${(props) => padMulti([props.iconSize! + (props.variant === 'disclaimer' ? 6 : 28), 'auto'])};
	border-radius: 5px;
	overflow: hidden;

	& > * {
		padding: ${(props) => padPx(props.variant === 'disclaimer' ? 0 : 15)};
	}

	& b,
	& strong {
		font-weight: bold;
	}
	& i,
	& em {
		font-style: italic;
	}
`

export const IconColumn = styled.div<StyleProps>`
	color: ${(props) => (props.variant === 'disclaimer' ? LevelColorMap[props.level] : white)};
	background: ${(props) => (props.variant === 'disclaimer' ? transparent : LevelColorMap[props.level])};
`

const InfoTitle = styled.h1`
	margin-bottom: 5px;
`

const InfoIcon = styled(SvgIcon)<{ offset?: Partial<Coords> }>`
	${(props) =>
		props.offset
			? `
		${props.offset.x ? `margin-left: ${padPx(props.offset.x)};` : ''}
		${props.offset.y ? `margin-top: ${padPx(props.offset.y)};` : ''}
	`
			: ''}
`

export const ContentColumn = styled.div<StyleProps>`
	background: ${(props) =>
		props.backgroundColor ??
		(props.variant === 'disclaimer' ? transparent : props.variant === 'mini' ? white : backgroundGray)};
	${(props) => font({ size: props.fontSize ?? (props.variant === 'disclaimer' ? 12 : 16) })}

	& ${InfoTitle} {
		color: ${(props) => LevelColorMap[props.level]};
	}
`

const InfoBox: React.FunctionComponent<IProps> = ({
	className,
	children,
	title,
	level,
	icon,
	variant,
	fontSize,
	iconOffset,
	iconSize,
	backgroundColor,
	...rest
}) => {
	const styleProps = { level, variant, fontSize, iconSize, backgroundColor }
	return (
		<InfoBoxContainer className={className} {...styleProps} {...rest}>
			<IconColumn {...styleProps}>
				<InfoIcon offset={iconOffset} src={icon} size={iconSize} />
			</IconColumn>
			<ContentColumn {...styleProps}>
				{title ? <InfoTitle>{title}</InfoTitle> : null}
				{children}
			</ContentColumn>
		</InfoBoxContainer>
	)
}

InfoBox.defaultProps = {
	icon: Details,
	variant: 'normal',
	iconSize: SvgIcon!.defaultProps!.size!,
}

export default InfoBox
