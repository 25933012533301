import { AxiosError } from 'axios'

import { CarResult, VoidCallback } from 'src/travelsuit'
import { CarRate } from 'src/travelsuit/cars.type'

import { IAction } from './action-helpers'
import { CarRatesTypes as Types } from './actions.types'

export const Actions = {
	getCarRates(
		car: CarResult,
		additionalEquip?: string[],
		callback?: VoidCallback<CarRate>,
		errCallback?: VoidCallback<AxiosError>,
	): IAction {
		return {
			type: Types.GetCarRates.REQUEST,
			payload: car,
			body: { car_search_result: car, additional_equip: additionalEquip },
			callback,
			errCallback,
		}
	},
}
