import { z } from 'zod'

export enum JobStatus {
	Sent = 'sent',
	Failed = 'failed',
	Completed = 'completed',
	InProgress = 'in progress',
}

export const JobStatusZ = z.nativeEnum(JobStatus)

export const CreateJobResponseZ = z.object({
	job_id: z.string(),
	status: JobStatusZ,
})

export type CreateJobResponse = z.infer<typeof CreateJobResponseZ>

// Separate type because inferring the `ReturnType` from `getJobResultZ<T>` turned out to be problematic
export type JobResult<T = unknown> =
	| {
			status: JobStatus.Sent
	  }
	| {
			status: JobStatus.InProgress
	  }
	| {
			status: JobStatus.Completed
			result: T
	  }
	| {
			status: JobStatus.Failed
			errors: string[] | null | undefined
	  }

export type JobGroupResult<T = unknown> =
	| {
			status: JobStatus.Sent
	  }
	| {
			status: JobStatus.InProgress
	  }
	| {
			status: JobStatus.Completed
			result: (T | { errors: string[] })[]
	  }
	| {
			status: JobStatus.Failed
			result: { errors: string[] }[]
	  }

export function getJobResultZ<T extends z.ZodTypeAny>(resultSchema: T) {
	return z.discriminatedUnion('status', [
		z.object({
			status: z.literal(JobStatus.Sent),
		}),
		z.object({
			status: z.literal(JobStatus.InProgress),
		}),
		z.object({
			status: z.literal(JobStatus.Completed),
			result: resultSchema,
		}),
		z.object({
			status: z.literal(JobStatus.Failed),
			errors: z.array(z.string()).nullish(),
		}),
	])
}

export function getJobGroupResultZ<T extends z.ZodTypeAny>(resultSchema: T) {
	return z.discriminatedUnion('status', [
		z.object({
			status: z.literal(JobStatus.Sent),
		}),
		z.object({
			status: z.literal(JobStatus.InProgress),
		}),
		z.object({
			status: z.literal(JobStatus.Completed),
			result: z.array(z.object({ errors: z.array(z.string()) }).or(resultSchema)),
		}),
		z.object({
			status: z.literal(JobStatus.Failed),
			result: z.array(z.object({ errors: z.array(z.string()) })),
		}),
	])
}
