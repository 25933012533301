import styled from 'src/styles'

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 45px !important;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`

export const Header = styled.h3`
	color: #02bbaa;
	font-weight: bold;
	font-size: 2em;
	margin-bottom: 15px;
`

export const Subheading = styled.h4`
	color: #02bbaa;
	font-size: 1.4em;
	margin-bottom: calc(40px / 2);
`
export const TextBlock = styled.div`
	max-width: 435px;
	font-size: 1.3em;
	line-height: 1.5;
	color: #737373;
	text-align: center;
	margin-bottom: calc(15px * 2);
`

export const CheckboxText = styled.span`
	line-height: 1.5;
	color: #574872;
	font-size: 1.3em;
	position: relative;
	top: -2px;
`

export const TermsSection = styled.div`
	display: grid;
	grid-template-columns: 0fr 3fr;
	align-items: center;
	margin-bottom: calc(15px * 2);
`

export const ButtonContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 15px;

	justify-content: center;
	margin-top: calc(10px * 2);
`
