import { TFunction } from 'i18next'

export function getDeclinedPaymentErrorTextParts(t: TFunction) {
	return [
		t(
			'full-itinerary.status-box.not-booked.declined-payment',
			'This transaction was declined by your bank or the card issuing company.',
		),
		t(
			'full-itinerary.status-box.not-booked.verify-payment-information',
			'Please verify the information you entered is correct and try again or use another credit card.',
		),
		t(
			'full-itinerary.status-box.not-booked.contact-issuing-company',
			'If the problem persists, please contact your bank or card issuing company.',
		),
	]
}
