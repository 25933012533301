import { Dispatch, MiddlewareAPI } from 'redux'

import { trackEvent } from 'src/travelsuit/analytics'

import { IAction } from '../actions/action-helpers'

function _isRequestResponse(type: string): boolean {
	const suffixes = ['UPDATE', 'SUCCESS']
	return suffixes.some((s) => type.endsWith(`_${s}`))
}

function _filtered(type: string): boolean {
	return _isRequestResponse(type)
}

export default function analyticsMiddleware(_store: MiddlewareAPI) {
	return (next: Dispatch) => (action: IAction) => {
		const { type, payload } = action
		const { tripId, userId } = payload ?? {}

		if (_filtered(type)) {
			return next(action)
		}
		const isFailure = type.includes('_FAIL')

		trackEvent({
			action: !isFailure ? 'API Request' : 'API Request FAIL',
			type: isFailure ? type : type.substring(0, type.lastIndexOf('_')),
			IS_API_REQUEST: true,
			tripId,
			userId,
		})
		return next(action)
	}
}
