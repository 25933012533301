import { size } from 'src/css-helpers/positioning'
import { borderGray, fontSecondary } from 'src/refactor/colors'
import { font } from 'src/refactor/fonts'
import styled from 'src/styles'

export const Title = styled.div`
	${font({ size: 14, weight: 400 })}
`

export const Label = styled.div`
	color: ${fontSecondary};
	${font({ size: 12 })}
`

export const Flag = styled.div<{ src: string; size?: number }>`
	${(p) => size(p.size ?? 24)}
	flex-shrink: 0;
	border-radius: 50%;
	border: 1px solid ${borderGray};
	background-image: url('${({ src }) => src}');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	overflow: hidden;
`
