import moment from 'moment'

import { entityGenerator, TimeFormatsLocaleUnsafe } from 'src/lib/utils'
import { Passport, TravelerDocumentType } from 'src/travelsuit/index'

export const emptyPassport = entityGenerator<Passport>({
	id: null,
	type: TravelerDocumentType.Passport,
	passport_number: '',
	citizenship: { code: '', name: '' },
	expiration_date: null,
	issue_date: null,
	issuing_country: { code: '', name: '' },
})

export function preparePassportJson(passport: Passport) {
	const copy: Partial<Passport> = { ...passport }
	delete copy.id
	copy.issue_date = moment(copy.issue_date!).format(TimeFormatsLocaleUnsafe.DateOnly)
	copy.expiration_date = moment(copy.expiration_date!).format(TimeFormatsLocaleUnsafe.DateOnly)
	return copy
}
