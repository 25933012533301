import { z } from 'zod'

export const RailPassengerIdZ = z.string().brand<'RailPassengerId'>()

export type RailPassengerId = z.infer<typeof RailPassengerIdZ>

export const RailSectionIdZ = z.string().brand<'RailSectionIdZ'>()

export type RailSectionId = z.infer<typeof RailSectionIdZ>

export const RailSeatPreferenceIdZ = z.string().brand<'RailSeatPreferenceId'>()

export type RailSeatPreferenceId = z.infer<typeof RailSeatPreferenceIdZ>

export enum RailTransportType {
	Train = 'train',
}

export const RailTransportTypeZ = z.nativeEnum(RailTransportType)
