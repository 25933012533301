import React, { forwardRef } from 'react'

import { accentPrimary, bgMaterial, boxShadowFloat } from 'src/_vars'
import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import styled, { size } from 'src/styles'
import { DefaultProps } from 'src/travelsuit'

import { brightRed, mainBlack } from '../../refactor/colors'

interface Disabled {
	disabled: boolean
}
interface Checked {
	checked: boolean
}
interface Size {
	size: number
	fillSize: number
	offset: { x: number; y: number }
}

type RadioInputProps = DefaultProps &
	React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
	Partial<Size & Disabled & Checked>

export interface IProps extends RadioInputProps {
	label?: React.ReactNode
	e2eInput?: string
	onToggle?(value: any, e: React.ChangeEvent<HTMLInputElement>): void
	errorMessage?: string
}

const DEFAULT_CHECK_SIZE = 16
const DEFAULT_CHECK_FILL_SIZE = DEFAULT_CHECK_SIZE / 2
const DEFAULT_OFFSET_X = 5
const DEFAULT_OFFSET_Y = 0

const InputContainer = styled.div.attrs(addE2EAttrs)<Partial<Disabled> & E2E>`
	position: relative;
	line-height: 2;
	${(props) =>
		props.disabled
			? `
		& > * {
			opacity: 0.5;
			pointer-events: none;
		}
	`
			: ''}
`

const Input = styled.input.attrs(addE2EAttrs)<{ type: 'radio' }>`
	visibility: hidden;
	pointer-events: none;
	position: absolute;
`

export const Label = styled.label.attrs(addE2EAttrs)<Partial<Checked & Size & E2E>>`
	padding-left: calc(${(props) => props.size!}px + 0.5em);
	display: inline-block;
	cursor: pointer;

	&:before {
		${(props) => size(props.size!)}
		display: block;
		position: absolute;
		content: '';
		border-radius: 50%;
		top: 1em;
		left: 0;
		transform: translateY(-50%);
		border: 1px solid ${(props) => (props.checked ? accentPrimary : mainBlack)};
		background: ${bgMaterial};
		transition: all 150ms ease-in-out;
	}

	&:after {
		${(props) => size(props.fillSize!)}
		display: block;
		position: absolute;
		content: '';
		border-radius: 50%;
		left: ${(props) => props.offset!.x}px;
		top: calc(1em + ${(props) => props.offset!.y}px);
		transform: translateY(-50%);
		${(props) => (props.checked ? `background: ${accentPrimary};` : '')}
		transition: all 150ms ease-in-out;
	}

	&:hover:before {
		box-shadow: ${boxShadowFloat};
	}
`

export const LabelText = styled.span`
	font-size: 0.95em;
`

const ErrorText = styled.div`
	color: ${brightRed};
	font-size: 0.75rem;
`

const RadioInput = forwardRef<HTMLInputElement, IProps>(function RadioInput(props, ref) {
	const {
		className,
		checked,
		label,
		onToggle,
		disabled,
		size: circleSize,
		fillSize,
		e2e,
		offset = { x: DEFAULT_OFFSET_X, y: DEFAULT_OFFSET_Y },
		errorMessage,
		...others
	} = props
	//TODO: consider wrapping to useMemo
	const id = 'radio-' + Math.floor(Math.random() * 10000).toString()
	offset.x = offset.x || DEFAULT_OFFSET_X
	offset.y = offset.y || DEFAULT_OFFSET_Y

	return (
		<InputContainer className={className} disabled={disabled} e2e={e2e}>
			<Input
				ref={ref}
				id={id}
				type="radio"
				checked={checked}
				disabled={disabled}
				e2e={props.e2eInput || ''}
				onChange={(e) => onToggle?.(e.currentTarget.value, e)}
				{...(others as any)}
			/>
			<Label htmlFor={id} size={circleSize} fillSize={fillSize} offset={offset} checked={checked}>
				<LabelText>{label}</LabelText>
			</Label>
			{errorMessage ? <ErrorText>{errorMessage}</ErrorText> : null}
		</InputContainer>
	)
})

RadioInput.defaultProps = {
	color: 'primary',
	size: DEFAULT_CHECK_SIZE,
	fillSize: DEFAULT_CHECK_FILL_SIZE,
}

export default RadioInput
