import React, { useCallback, useMemo, useRef, useState } from 'react'
import { RouteProps, withRouter } from 'react-router'

import { removeEntityById } from 'src/lib/entity/removeEntityById'

import { ModalContext, ModalsContainer } from './ModalApi.components'
import { ModalAction, ModalProps } from './ModalApi.types'

export function ModalApiProvider({ children, location }: React.PropsWithChildren<Pick<RouteProps, 'location'>>) {
	const [modalActions, setModalActions] = useState<ModalAction[]>([])
	// KILLME: BCDTDRCT-1400 Drawer backdrop zIndex is 10000
	// KILLME: Sidebar zIndex is 100000
	const zIndexRef = useRef(100500)

	const currentRouteCleanupsRef = useRef<(() => void)[]>([])
	React.useEffect(() => {
		const cleanups = currentRouteCleanupsRef.current
		return () => {
			cleanups.forEach((cleanup) => cleanup())
			currentRouteCleanupsRef.current = []
		}
	}, [location?.pathname])

	const show = useCallback(
		<TResult,>(render: (modalProps: ModalProps<TResult>) => React.Component, shouldBindToCurrentRoute = false) => {
			const zIndex = zIndexRef.current
			zIndexRef.current = zIndex + 1

			const id = `${new Date().getTime()}_${zIndex}`

			const onHide = () => {
				setModalActions((prevModalActions) => removeEntityById(prevModalActions, id))
			}

			if (shouldBindToCurrentRoute) {
				currentRouteCleanupsRef.current.push(onHide)
			}

			return new Promise<TResult>((resolve, reject) => {
				const modalProps: ModalProps<TResult> = {
					success: (result: TResult) => {
						onHide()
						resolve(result)
					},
					close: () => {
						onHide()
						reject()
					},
					zIndex,
					key: 0,
				}
				const modalAction: ModalAction = {
					component: render(modalProps),
					props: modalProps,
					id,
				}

				setModalActions((prevActions) => prevActions.concat(modalAction))
			})
		},
		[],
	)

	const contextValue = useMemo(() => ({ show }), [show])

	return (
		<ModalContext.Provider value={contextValue}>
			{children}
			<ModalsContainer modalActions={modalActions} />
		</ModalContext.Provider>
	)
}

export const RouteAwareModalApiProvider = withRouter(ModalApiProvider)
