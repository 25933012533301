import { entityGenerator } from 'src/lib/utils'

import { Role, TravelProfile, TravelProfileKeys } from './index'

export const emptyTraveler = entityGenerator<TravelProfile>({
	id: 0,
	employee_id: null,
	user_id: -1,
	gender: null,
	date_of_birth: null,
	home_location: null,
	home_location_id: null,
	office: null,
	office_id: null,
	role: Role.Employee,
	emergency_contact_name: '',
	emergency_contact_phone_number: '',
	emergency_contact_email: '',
	flight_properties: {
		aisle_seat: false,
		center_seat: false,
		glat_kosher_meal: false,
		kosher_meal: false,
		vegetarian_meal: false,
		window_seat: false,
	},
	has_medical_condition: false,
	dial_code_country: { code: '' },
	phone_number: '',
	frequent_flyer_cards: [],
	hotel_loyalty_programs: [],
	rail_cards: [],
	passports: [],
	title: null,
	group: null,
	group_id: null,
	credit_cards: [],
	cost_center: null,
	accounting_unit: null,
	internal_account: null,
	purchase_order_number: null,
	custom_field_1: null,
	custom_field_2: null,
	custom_field_3: null,
	ca_number: null,
	kt_number: null,
	redress_number: null,
})

export const travelerMandatoryFields: TravelProfileKeys[] = ['gender', 'phone_number']
export const notNulllableFields: TravelProfileKeys[] = ['home_location_id']

export function isValidTraveler(traveler: TravelProfile) {
	const emptyCount = travelerMandatoryFields.reduce((c, k) => {
		if (!traveler[k]) {
			return ++c
		}
		return c
	}, 0)

	return emptyCount! < 1
}
