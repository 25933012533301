import { Location } from 'history'

import {
	ApprovalPolicy,
	ApproverSelectionType,
	CabinClassName,
	DistanceUnits,
	Group,
	HotelPriceCapRoute,
	Role,
	User,
} from 'src/travelsuit'
import { AccommodationTypeRule } from 'src/travelsuit/hotels'
import { Currency } from 'src/types/common'

interface BaseRuleArgs {}
interface AllowedOnOffRuleArgs extends BaseRuleArgs {
	allow: boolean
}
interface NumberOfConnectionsFlightRuleArgs extends BaseRuleArgs {
	max_number_of_stops: number
}
type OnOffRuleArgs = AllowedOnOffRuleArgs
interface UserRuleArgs extends AllowedOnOffRuleArgs {
	user_ids: number[]
}
export interface RoleRuleArgs extends UserRuleArgs {
	role: Role
}

export interface BusinessForLongFlightsRuleArgs extends AllowedOnOffRuleArgs {
	min_number_of_hours: number
}

export interface NonFlexibleRuleArgs extends OnOffRuleArgs {
	permit_up_to: number
}

interface MinMaxStarsRuleArgs extends OnOffRuleArgs {
	stars: number
	max_stars_by_role: Record<Role, number>
}
interface DistanceRuleArgs extends OnOffRuleArgs {
	distance: number
	units: DistanceUnits
	readonly distance_km: number
}
interface HotelMaxRateRuleArgs extends OnOffRuleArgs {
	/** @deprecated The traveler policy's currency should be used instead */
	currency: Currency
	default_max_rate: number | null
	rates: HotelPriceCapRoute[]
	max_rates_by_role: Record<Role, number>
}

export type FlightsPolicyLocationTypes = 'country_iso' | 'iata' | 'city_code'

export interface FlightPriceCapRoute {
	departure_type: FlightsPolicyLocationTypes
	departure_code: string
	arrival_type: FlightsPolicyLocationTypes
	arrival_code: string
	num_of_flights: number
	cabin_class: CabinClassName
	max_price: number
	percentile_deviation: number | null
}
interface FlightMaxPriceRuleArgs {
	short_flight_percentile_deviation: number | null
	long_flight_percentile_deviation: number | null
	short_flight_default_max_price: number | null
	long_flight_default_max_price: number | null
	default_max_price: number | null
	routes: FlightPriceCapRoute[]
}
interface CarMaxPriceRuleArgs {
	max_price: number | null
}

interface RoleCarRentRuleArgs {
	user_ids: number[]
	allow: boolean
	role: Role | null
}

interface FlightSeatMaxPriceRuleArgs {
	default_max_price: number | null
	allow: boolean
}

export interface FlightClassRules {
	role: RoleRuleArgs
	for_long_flights: BusinessForLongFlightsRuleArgs
}

export interface FlightPolicyRules {
	connections_rule: NumberOfConnectionsFlightRuleArgs
	non_flexible_flight_rule: NonFlexibleRuleArgs

	business_class_rule: FlightClassRules
	first_class_rule: FlightClassRules
	premium_economy_class_rule: FlightClassRules

	upgrade_travelers_when_manager_flies: RoleRuleArgs
	flight_max_price_rule: FlightMaxPriceRuleArgs
	flight_seat_max_price_rule: FlightSeatMaxPriceRuleArgs
}

export interface CarPolicyRules {
	non_flexible_car_rule: NonFlexibleRuleArgs
	car_max_price_rule: CarMaxPriceRuleArgs
	role_car_rent: RoleCarRentRuleArgs
}

export type HotelPolicyRules = {
	/** Should be a double `m`, but life is not that simple */
	accomodation_type: AccommodationTypeRule
	min_hotel_rating: MinMaxStarsRuleArgs
	max_hotel_rating: MinMaxStarsRuleArgs
	distance_from_destination: DistanceRuleArgs
	hotel_max_rate__rule: HotelMaxRateRuleArgs
	non_flexible_hotel_rule: NonFlexibleRuleArgs
}

export interface PreTripPolicyData {
	conditions: ApprovalCondition[]
	enabled: boolean
	roles: Role[]
	pre_trip_custom_approvers?: User[]
	pre_trip_fallback_approvers?: User[]
}

export interface ApprovalCondition {
	require_company_approver: boolean
	require_office_approver: boolean
	require_department_approver: boolean
}

export interface ApprovalFlow {
	conditions: ApprovalCondition[]
}

export interface TravelPolicy {
	flight_policy_data: FlightPolicyRules
	hotel_policy_data: HotelPolicyRules
	car_policy_data: CarPolicyRules
	approval_flow: ApprovalFlow
	approval_policy: ApprovalPolicy
	approvers: User[]
	expense_approvers: User[]
	approver_selection_type: ApproverSelectionType
	group: Group
	group_id: number
	users: User[]
	rules_users: User[]
	currency_code: Currency
	pre_trip_policy_data: PreTripPolicyData
	company_approvers: User[]
	pre_trip_custom_approvers: User[]
	pre_trip_fallback_approvers: User[]
}

export interface TripTravelPolicy {
	policy: TravelPolicy
	ignore_approval: boolean
	actual_approvers: User[][]
	pre_trip_actual_approvers: User[][]
	user: User
	user_role: Role
}

export type TravelPolicyMinimal = Pick<TravelPolicy, 'group_id' | 'group'>
export type PartialPolicyWithID = Pick<TravelPolicy, 'group_id'> & Partial<TravelPolicy>

export interface UpdatePolicyParams
	extends Omit<
		PartialPolicyWithID,
		'approvers' | 'expense_approvers' | 'users' | 'rule_users' | 'pre_trip_policy_data'
	> {
	approver_ids?: number[]
	expense_approver_ids?: number[]
	user_ids?: number[]
	pre_trip_custom_approver_ids?: number[]
	pre_trip_fallback_approver_ids?: number[]
	pre_trip_policy_data?: Omit<PreTripPolicyData, 'pre_trip_custom_approvers' | 'pre_trip_fallback_approver'>
}

export enum PolicyPaidSeat {
	Never = 'never',
	UpToPrice = 'up_to_price',
}

export enum PolicyRefundOptions {
	Never = 'never',
	Always = 'always',
	UpToHours = 'up_to_hrs',
}

export type GeneralPolicyChanges = Pick<
	TravelPolicy,
	'approval_flow' | 'approval_policy' | 'approvers' | 'approver_selection_type' | 'users'
>

export function parsePolicyUrl(location: Location): Record<(typeof pathNames)[number], string> {
	const pathNames = ['type', 'id', 'section'] as const

	return location.pathname
		.split('/')
		.slice(2)
		.reduce(
			(paths, path, i) => ({
				...paths,
				[pathNames[i]]: path,
			}),
			{},
		) as Record<(typeof pathNames)[number], string>
}

export function parseExpensePolicyUrl(location: Location): Record<(typeof pathNames)[number], string> {
	const pathNames = ['type', 'subtype'] as const

	return location.pathname
		.split('/')
		.slice(2)
		.reduce(
			(routes, route, i) => ({
				...routes,
				[pathNames[i]]: route,
			}),
			{},
		) as Record<(typeof pathNames)[number], string>
}
