import React from 'react'

import Login from './Login'

const META_DESCRIPTION_CONTENT =
	'Welcome to the GetGoing login page. Here, you can sign in to your GetGoing account. Lost your GetGoing password ? Click on Forgot Password to reset it.'

export function LoginWithSeo(props: React.ComponentProps<typeof Login>) {
	React.useLayoutEffect(() => {
		const initialTitle = document.title
		document.title = 'Log in to your GetGoing account here'

		const initialMetaDescriptionElement = document.head.querySelector('meta[name="description"]')

		if (initialMetaDescriptionElement) {
			const initialMetaDescriptionContent = initialMetaDescriptionElement.getAttribute('content')
			initialMetaDescriptionElement.setAttribute('content', META_DESCRIPTION_CONTENT)

			return () => {
				document.title = initialTitle
				initialMetaDescriptionElement.setAttribute('content', initialMetaDescriptionContent ?? '')
			}
		}

		const metaDescriptionElement = document.createElement('meta')
		metaDescriptionElement.setAttribute('name', 'description')
		metaDescriptionElement.setAttribute('content', META_DESCRIPTION_CONTENT)
		document.head.appendChild(metaDescriptionElement)

		return () => {
			document.title = initialTitle
			document.head.removeChild(metaDescriptionElement)
		}
	}, [])

	return <Login {...props} />
}
