import { objDiff } from 'src/lib/utils'
import { getStoreState } from 'src/redux/stores'
import { Callback, Expense, Trip } from 'src/travelsuit'
import { notNulllableFields } from 'src/travelsuit/users'

import { IAction } from './action-helpers'
import { ExpensesTypes as Types } from './actions.types'

export const Actions = {
	addExpense(expense: Partial<Expense>, callback?: (e: Expense) => void): IAction {
		return {
			type: Types.AddExpense.REQUEST,
			payload: expense,
			callback,
		}
	},
	updateExpense(expense: Expense, callback?: (e: Expense) => void): IAction {
		const itinerary = getStoreState().itinerary.get(expense.trip_id)?.itinerary
		const existingExpense = (itinerary?.expenses ?? []).find((ex) => ex.id === expense.id)

		const changes = objDiff(existingExpense, expense)
		notNulllableFields.forEach((k) => {
			// @ts-expect-error todo if you see this please remove this comment and fix the type error, thank you :)
			if (changes[k] === null) {
				// @ts-expect-error todo if you see this please remove this comment and fix the type error, thank you :)
				delete changes[k]
			}
		})

		return {
			type: Types.UpdateExpense.REQUEST,
			payload: expense,
			body: changes,
			callback,
		}
	},
	removeExpense(expense: Expense, callback?: Callback, errCallback?: Callback): IAction {
		return {
			type: Types.RemoveExpense.REQUEST,
			payload: expense,
			callback,
			errCallback,
		}
	},
	downloadExpenses(trip: Trip, callback?: Callback, errCallback?: Callback): IAction {
		return {
			type: Types.DownloadExpenses.REQUEST,
			payload: trip,
			callback,
			errCallback,
		}
	},
}
