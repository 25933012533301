import { IAction } from 'src/redux/actions/action-helpers'
import { TaxAndVatRateTypes } from 'src/redux/actions/actions.types'
import { denormalize } from 'src/redux/reducers/tax-and-vat-rates.reducer'
import { Callback } from 'src/travelsuit'
import { TaxAndVatRate } from 'src/types/expenses'

export const Actions = {
	getRates(callback?: Callback<void, TaxAndVatRate[]>): IAction {
		return {
			type: TaxAndVatRateTypes.GetRates.REQUEST,
			callback,
		}
	},
	updateRate(taxAndVatRate: TaxAndVatRate, callback?: Callback): IAction {
		return {
			type: TaxAndVatRateTypes.UpdateRate.REQUEST,
			payload: { id: +taxAndVatRate.id },
			body: denormalize(taxAndVatRate),
			callback,
		}
	},
	createRate(taxAndVatRate: TaxAndVatRate, callback?: Callback): IAction {
		return {
			type: TaxAndVatRateTypes.CreateRate.REQUEST,
			body: denormalize(taxAndVatRate),
			callback,
		}
	},
	deleteRate(taxAndVatRate: TaxAndVatRate, callback?: Callback): IAction {
		return {
			type: TaxAndVatRateTypes.DeleteRate.REQUEST,
			payload: { id: +taxAndVatRate.id },
			callback,
		}
	},
}
