import { VoidCallback } from 'src/travelsuit'

import { IAction } from './action-helpers'
import { TrainTicketDiscountTypes } from './actions.types'

export const Actions = {
	getAllTrainTicketDiscountCards(
		params: Record<string, string | number | boolean>,
		callback?: VoidCallback<string[]>,
		errCallback?: VoidCallback,
	): IAction {
		return {
			type: TrainTicketDiscountTypes.getTicketDiscounts.REQUEST,
			params,
			callback,
			errCallback,
		}
	},
}
