import React from 'react'

import Logo from 'src/assets/logo/Logo.svg'
import LogoSmall from 'src/assets/logo/Logo_Small.svg'
import { useBreakPoints } from 'src/lib/react-hooks/useBreakPoints'

const TravelSuitLogo: React.FunctionComponent<{}> = () => {
	const { isWideDesktop } = useBreakPoints()
	const imgProps = !isWideDesktop
		? {
				src: LogoSmall,
				width: 50,
				height: 50,
			}
		: {
				src: Logo,
				width: 150,
				height: 50,
			}

	return <img {...imgProps} alt="GetGoing" />
}

export default TravelSuitLogo
