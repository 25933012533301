import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import LogoTagline from 'src/assets/logo/Logo_Tagline.svg'
import Button from 'src/atoms/Button/Button'
import { Checkbox } from 'src/atoms/Checkbox'
import Loader from 'src/atoms/Loader/Loader'
import TextField from 'src/atoms/TextField/TextField'
import { providerLogin, providerPwdReset } from 'src/lib/auth'
import { isIE } from 'src/lib/browser'
import { Trans, withTranslation, WithTranslation } from 'src/lib/i18n/i18n'
import { getCurrentLocale } from 'src/lib/locale-utils'
import { openExternalUrl } from 'src/lib/openExternalUrl'
import { Routes } from 'src/lib/route-utils'
import { eventStopper, isBoolPredicate, parseQueryString } from 'src/lib/utils'
import TermsOfService from 'src/molecules/TermsOfService/TermsOfService'
import {
	BackToLogin,
	Copyright,
	FormTitle,
	Header,
	Image,
	LoginBox,
	LoginContainer,
	LoginForm,
	LoginImage,
	LoginImagesBox,
	LoginPage,
	LoginPageContent,
	LogoImage,
	RecoveryText,
	ResponseText,
	StyledCarousel,
} from 'src/pages/Login/Login.components'
import { CompanyTypeModalActions } from 'src/redux/actions'
import { ApplicationState } from 'src/redux/stores'
import { brightTurquoise, darkBlue, yellow } from 'src/refactor/colors'
import { AuthUser, LoginStatus, PageProps, User } from 'src/travelsuit'
import { AsyncTrackResult, track } from 'src/travelsuit/analytics'
import { Locales } from 'src/types/locale'

import css from './Login.module.css'
import LoginBlueImage from './login/login_blue.svg?raw'
import LoginGreenImage from './login/login_green.svg?raw'
import LoginYellowImage from './login/login_yellow.svg?raw'

interface OwnProps extends PageProps {
	//
}

interface StateProps {
	termsAccepted: boolean | null
	user: AuthUser | null
}

interface DispatchProps {
	showModalWindow(): void
}

type IProps = OwnProps & StateProps & DispatchProps & WithTranslation

interface IState {
	email: string
	password: string
	shouldUseRefreshToken: boolean
	loading: boolean
	resend: boolean
	forgotPwd: boolean
	response: string
	pwdResponse: string
	showTerms: boolean
	activeImgIdx: number
}

const CHROME_DOWNLOAD_URL = 'https://www.google.com/chrome/'

@track({})
// TASK migrate to React.FunctionComponent OR remove this if not possible
class Login extends React.Component<IProps, IState> {
	public state = {
		email: this.props.location.search ? parseQueryString(this.props.location.search).email : '',
		password: '',
		shouldUseRefreshToken: false,
		loading: false,
		resend: false,
		forgotPwd: this.props.location.search
			? isBoolPredicate(parseQueryString(this.props.location.search).forgotPwd)
			: false,
		response: '',
		pwdResponse: '',
		showTerms: false,
		activeImgIdx: 0,
	}

	public render() {
		const { className, termsAccepted, user, t } = this.props
		const { loading, email, password, forgotPwd, response, showTerms, pwdResponse, resend } = this.state
		const shouldShowTerms = showTerms || (user && termsAccepted === false)
		const year = new Date().getFullYear()

		return (
			<LoginPage
				className={className}
				contentProps={shouldShowTerms ? {} : { as: LoginPageContent }}
				offsetTop={0}
				width="100%"
			>
				{!shouldShowTerms ? (
					<LoginContainer>
						<LoginBox e2e="LoginForm">
							<Header>
								<h2>{t('login.welcome', 'Welcome to')}</h2>
								<LogoImage src={LogoTagline} alt="GetGoing" />
							</Header>
							{!isIE ? (
								<LoginForm onSubmit={(e) => this.onSubmit(e)}>
									{response ? <ResponseText variant="alert">{response}</ResponseText> : null}

									<FormTitle>
										{forgotPwd && !pwdResponse
											? t('login.dont-worry', "Don't worry :)")
											: pwdResponse
												? t('login.check-email', 'Check your email...')
												: t('login.login-to-account', 'Login to your account')}
									</FormTitle>

									{forgotPwd && !pwdResponse ? (
										<RecoveryText>
											{t(
												'login.enter-email-for-reset-link',
												'Enter your email address below to receive a password reset link',
											)}
										</RecoveryText>
									) : null}

									{!pwdResponse ? (
										<TextField
											id="login.email"
											label={t('login.email', 'Email')}
											name="username"
											autoComplete="username"
											e2e={'LoginPage.Username'}
											value={email}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ email: e.target.value })}
										/>
									) : null}

									{!forgotPwd ? (
										<TextField
											id="login.password"
											label={t('login.password', 'Password')}
											name="password"
											autoComplete="current-password"
											value={password}
											type="password"
											e2e={'LoginPage.Password'}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ password: e.target.value })}
										/>
									) : null}

									{!pwdResponse && !forgotPwd && (
										<Checkbox
											label={t('login.remember-me', 'Remember me')}
											checked={this.state.shouldUseRefreshToken}
											e2e="LoginPage.RememberMe"
											onToggle={(value) => this.setState({ shouldUseRefreshToken: value })}
										/>
									)}

									{pwdResponse ? (
										<div>
											<ResponseText>{pwdResponse}</ResponseText>
											<div className={css.info}>
												<Trans i18nKey="login.click-to-resend">
													<a
														href={document.location.href}
														onClick={eventStopper.preventDefault(() => this.sendPwdReset(true))}
													>
														Click here to resend email
													</a>
													in the case you did not receive one within 5 minutes.
												</Trans>
											</div>
											{resend ? <ResponseText>{t('login.done', 'Done.')}</ResponseText> : null}
										</div>
									) : null}

									{!pwdResponse ? (
										<Button
											color="bluePrimary"
											disabled={loading}
											e2e={forgotPwd ? 'LoginPage.ResetPassword' : 'LoginPage.Login'}
										>
											{loading ? (
												<Loader size={20} color="contrast" style={{ display: 'inline-block', margin: -5 }} />
											) : forgotPwd ? (
												t('login.reset-password', 'Reset Password')
											) : (
												t('login.login', 'Login')
											)}
										</Button>
									) : null}

									{!forgotPwd ? (
										<div>
											<Trans i18nKey="login.forgot-password">
												Forgot your password? No worries... click
												<a
													style={{ textDecoration: 'none' }}
													href={document.location.href}
													onClick={eventStopper.preventDefault(
														() => (this.setState({ response: '' }), this.resetPwdForm()),
													)}
												>
													here
												</a>
											</Trans>
										</div>
									) : (
										<BackToLogin
											href={document.location.href}
											onClick={eventStopper.preventDefault(() => this.loginForm())}
										>
											{t('login.back-to-login', '‹ Back to Log in')}
										</BackToLogin>
									)}
								</LoginForm>
							) : (
								<div className={css.ieSection}>
									<div className={css.ieError}>
										{t(
											'login.ie-error.title',
											'You are trying to access GetGoing from an older version of Internet Explorer that is no longer supported.',
										)}
									</div>
									<div>{t('login.ie-error.subtitle', 'GetGoing is best experienced on a Google Chrome browser')}</div>
									<div className={css.chromeLink} onClick={() => openExternalUrl(CHROME_DOWNLOAD_URL, '_blank')}>
										{t('login.ie-error.chrome-link', 'Download the Google Chrome browser')}
									</div>
								</div>
							)}
						</LoginBox>

						<LoginImagesBox>
							<StyledCarousel
								showArrows={false}
								useKeyboardArrows={true}
								showStatus={false}
								showThumbs={false}
								dynamicHeight={true}
								infiniteLoop={true}
								autoPlay={true}
								stopOnHover={true}
								selectedItem={this.state.activeImgIdx}
								onChange={(idx: number) => this.setState({ activeImgIdx: idx })}
								onClickItem={(idx: number) => this.setState({ activeImgIdx: idx })}
							>
								{this.imagesCarousel.map((item, i) => (
									<LoginImage key={i} backgroundColor={item.backgroundColor}>
										<h2>{item.text}</h2>
										<Image dangerouslySetInnerHTML={{ __html: item.img }} />
									</LoginImage>
								))}
							</StyledCarousel>
						</LoginImagesBox>

						<Copyright>
							{t(
								'login.copyright',
								'© 2021-{{year}} GetGoing, a commercial brand of BCD Travel. All rights reserved.',
								{ year },
							)}
						</Copyright>
					</LoginContainer>
				) : (
					<TermsOfService {...this.props} />
				)}
			</LoginPage>
		)
	}

	private get returnTo() {
		const { returnTo } = parseQueryString<'returnTo'>(this.props.location.search)
		if (returnTo?.startsWith(Routes.Login)) {
			return Routes.Home
		}
		return returnTo ?? Routes.Home
	}

	private async onSubmit(e: React.FormEvent<HTMLFormElement>) {
		try {
			e.preventDefault()

			if (this.state.forgotPwd) {
				this.sendPwdReset()
				return
			}

			this.login()
		} catch (e) {
			this.setState({ loading: false })
		}
	}

	@track((_p, _s, _a, [user]: AsyncTrackResult<InstanceType<typeof Login>['login']>) => ({
		action: user ? 'LogIn' : 'LogInError',
	}))
	private async login() {
		const { t } = this.props
		const { email, password, shouldUseRefreshToken } = this.state
		const localeBeforeLogin: Locales | null = getCurrentLocale()

		return new Promise<User | undefined>((res) => {
			this.setState({ loading: true }, async () => {
				try {
					const details = await providerLogin({ username: email, password, shouldUseRefreshToken })

					if (details.login_status === LoginStatus.UserExists) {
						this.props.history.replace(this.returnTo)
					} else if (details.login_status === LoginStatus.NotAcceptedTos) {
						this.setState({ showTerms: true })
					}

					if (details.user.language_code !== null && details.user.language_code !== localeBeforeLogin) {
						window.localStorage.setItem('defaultLocale', details.user.language_code)
						window.location.reload()
					}

					this.props.showModalWindow()

					return res(details.user)
				} catch (e) {
					const err =
						e.description ?? e.message
							? t('login.login-error', 'Wrong email or password.')
							: t(
									'login.server-error',
									'There was an internal problem with the server. If this persists, please contact support.',
								)
					this.setState({ loading: false, response: err })
					return res(undefined)
				}
			})
		}).then((user) => {
			if (localeBeforeLogin !== getCurrentLocale()) {
				window.location.reload()
			}
			return user
		})
	}

	private async sendPwdReset(resend = false) {
		this.setState({ pwdResponse: '', loading: true, resend: false }, async () => {
			try {
				const resp = providerPwdReset(this.state.email)
				this.setState({ pwdResponse: await resp, loading: false, resend })
			} catch (e) {
				this.setState({ pwdResponse: e.message || e, loading: false })
			}
		})
	}

	private resetPwdForm() {
		this.setState({ forgotPwd: true })
	}

	private loginForm() {
		this.setState({ forgotPwd: false, pwdResponse: '', resend: false, loading: false })
	}

	private get imagesCarousel() {
		const { t } = this.props
		return [
			{
				text: t('login.travel-needs', 'All your business travel needs in one place'),
				img: LoginGreenImage,
				backgroundColor: brightTurquoise,
			},
			{
				text: t('login.commute-with-peace', 'Commute with Peace of Mind, 24/7 world wide real-time live support'),
				img: LoginBlueImage,
				backgroundColor: darkBlue,
			},
			{
				text: t('login.consumer-grade', 'Consumer grade travel experience for business'),
				img: LoginYellowImage,
				backgroundColor: yellow,
			},
		]
	}
}

const mapStateToProps = ({ auth }: ApplicationState): StateProps => {
	return {
		termsAccepted: auth.get('termsAccepted'),
		user: auth.get('user'),
	}
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
	return {
		showModalWindow: () => {
			dispatch(CompanyTypeModalActions.showMyCompanyModal())
		},
	}
}

export default withTranslation()(
	connect<StateProps, DispatchProps, OwnProps, ApplicationState>(mapStateToProps, mapDispatchToProps)(Login),
)
