/** @deprecated use data-test instead */
export interface E2E {
	/** @deprecated use data-test instead */
	e2e?: string
}

export type TestId = {
	'data-test': string
}

/** @deprecated use data-test instead */
export const addE2EAttrs = ({ e2e }: E2E) => (e2e && typeof e2e === 'string' ? { ['data-test']: e2e } : {})

export function getTestId(props: TestId, suffix?: string) {
	const testId = props['data-test']
	return suffix ? `${testId}.${suffix}` : testId
}

/**
 * use this utility in following cases:
 * * component has multiple usages with both e2e and data-test props (will be removed once all usages are updated)
 * * component need to create derived testId (e.g. `Target` and `Body` for dropdown)
 * @example
 * <OverflowIconContainer data-test={passTestId(props, 'Target')} />
 * <SomeNotYetUpdatedComponent e2e={passTestId(this.props, 'Target')} />
 */
export function passTestId(props: { e2e?: string; 'data-test'?: string }, suffix?: string) {
	// temp fallback to e2e prop while we have dprecated prop usages
	const testId = props['data-test'] ?? props['e2e']
	if (!testId) {
		return undefined
	}
	return suffix ? `${testId}.${suffix}` : testId
}
