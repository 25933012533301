import { useTheme } from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import React from 'react'

import { AdaptiveValue } from '../System/styledUtils'

export interface PictureProps {
	width?: React.CSSProperties['width']
	srcSets?: AdaptiveValue<string>
	defaultSrc: string
}

export function Picture({ width, srcSets = {}, defaultSrc }: PictureProps) {
	const theme = useTheme()

	return (
		<picture>
			{Object.keys(srcSets).map((key: Breakpoint) => {
				// @ts-expect-error todo if you see this please remove this comment and fix the type error, thank you :)
				return <source srcSet={srcSets[key]} key={key} media={theme.breakpoints.down(key).replace('@media ', '')} />
			})}
			<source srcSet={defaultSrc} />
			<img src={defaultSrc} alt="" width={width} />
		</picture>
	)
}
