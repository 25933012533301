import React from 'react'

import SvgIcon from 'src/atoms/SvgIcon/SvgIcon'
import Tooltip from 'src/atoms/Tooltip/Tooltip'

import { DataWithIcons } from './FeeCard.components'

interface FeeDetailProps {
	tooltip?: string
	content: React.ReactNode
	iconSrc: string
	e2e?: string
}

export function FeeDetail({ tooltip, content, iconSrc, e2e }: FeeDetailProps) {
	return (
		<Tooltip title={tooltip}>
			<DataWithIcons data-test={e2e}>
				<SvgIcon src={iconSrc} />
				{content}
			</DataWithIcons>
		</Tooltip>
	)
}
