import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { redirectTo, Routes } from 'src/lib/route-utils'
import { TravelProfilePageTitle } from 'src/organisms/EditTraveler/TravelProfilePageTitle'
import { EditTraveler } from 'src/pages/App/lazyRoutes'
import { User } from 'src/travelsuit'

export type CreateTravelerProfileRouteProps = RouteComponentProps

export function CreateTravelerProfileRoute(props: CreateTravelerProfileRouteProps) {
	const { location } = props

	return (
		<>
			<TravelProfilePageTitle />
			<EditTraveler
				{...props}
				onSave={(u: User) =>
					redirectTo(Routes.EditTravelerProfile, { id: u.id }, { queryString: location.search.slice(1), replace: true })
				}
			/>
		</>
	)
}
