import { IAction } from './action-helpers'
import { CompanyTypeModal as Types } from './actions.types'

export const Actions = {
	showMyCompanyModal(): IAction {
		return {
			type: Types.ShowMyCompanyModal,
			payload: { showModal: true },
		}
	},
	hideMyCompanyModal(): IAction {
		return {
			type: Types.HideMyCompanyModal,
			payload: { showModal: false },
		}
	},
}
