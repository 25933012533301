import React from 'react'

import { DefaultElProps } from 'src/travelsuit'

export interface AffixConfig {
	prefix?: React.ReactNode
	suffix?: React.ReactNode
	/** When this is enabled, the prefix and suffix would only be applied when children's length > 1 */
	wrapItem?: (
		child: React.ReactNode,
		originalItem: React.ReactNode,
		originalGroup: any[],
		separator: React.ReactNode,
		index: number,
	) => React.ReactNode
	onlyAffixMultiple?: boolean
}

interface IProps extends Omit<DefaultElProps<'div'>, 'className'> {
	children: React.ReactNode[]
	separator: React.ReactNode | ((item: any, idx: number) => React.ReactNode)
	removeEmpty?: boolean
	/** Prepend or append nodes to each item */
	affix?: AffixConfig
}

const JoinNodes: React.FunctionComponent<IProps> = (props) => {
	const { children, removeEmpty, separator, affix } = props
	const filtered = removeEmpty ? children.filter(Boolean) : [...children]
	const separatorValue = typeof separator === 'function' ? separator : () => separator

	return (
		<React.Fragment>
			{affix?.prefix && (!affix.onlyAffixMultiple || filtered.length > 1) ? affix.prefix : null}
			{filtered.map((item, i) => {
				const sep = i < filtered.length - 1 ? separatorValue(item, i) : null
				const child = (
					<React.Fragment key={i}>
						{item}
						{sep}
					</React.Fragment>
				)

				return affix?.wrapItem ? affix.wrapItem(child, item, children, sep, i) : child
			})}
			{affix?.suffix && (!affix.onlyAffixMultiple || filtered.length > 1) ? affix.suffix : null}
		</React.Fragment>
	)
}

JoinNodes.defaultProps = {
	removeEmpty: true,
}

export default JoinNodes
