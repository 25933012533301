import React from 'react'

import { animations } from 'src/css-helpers/animated'
import { backgroundGray, fontBlack } from 'src/refactor/colors'
import { font } from 'src/refactor/fonts'
import styled from 'src/styles'

import CollapseBase, { IProps } from './CollapseBase'

const WizardCollapseContainer = styled(CollapseBase)``

export const Title = styled.div<{ open: boolean }>`
	${font({ size: 14, weight: 'bold' })}

	color: ${fontBlack};
	height: 90px;
	border-bottom: 1px solid ${(props) => (!props.open ? backgroundGray : 'transparent')};
	cursor: pointer;
`
const Content = styled.div<{ open: boolean }>`
	padding: 0 0 35px 5px;
	border-bottom: 1px solid ${backgroundGray};
	${animations.slideInTop.animate(300)}

	${font({ size: 14 })}

	& p {
		margin: 0;
		padding: 0 0 10px;

		&:last-child {
			padding-bottom: 0;
		}
	}

	& b {
		font-weight: bold;
	}
`

const WizardCollapse: React.FunctionComponent<IProps> = (props) => {
	const { className, title, children, ...rest } = props

	return (
		<WizardCollapseContainer
			className={className}
			title={title}
			flexTitle
			titleComponent={Title}
			contentComponent={Content}
			{...rest}
		>
			{children}
		</WizardCollapseContainer>
	)
}

export default WizardCollapse
