export const USER_FORM_PERSISTANCE_KEY = 'user-form'

export function getLocalStorageValue<T>(key: string, initialValue?: T): T | undefined {
	const json = window.localStorage.getItem(key)

	if (!json) {
		return initialValue
	}
	return JSON.parse(json)
}

export function setLocalStorageValue<T>(key: string, value: T) {
	window.localStorage.setItem(key, JSON.stringify(value))
}

export function clearLocalStorageValue(key: string) {
	window.localStorage.removeItem(key)
}
