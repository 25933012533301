import { addDays } from 'date-fns/addDays'
import { max } from 'date-fns/max'
import moment from 'moment'
import React from 'react'
import { Trans } from 'react-i18next'

import { appConfig } from 'src/app-config/appConfig'
import { setUserForErrorTracking } from 'src/error-tracking'
import { filterEmptyValues } from 'src/lib/array-utils'
import { downloadFileByLink } from 'src/lib/downloadFileByLink'
import { TFunction } from 'src/lib/i18n/i18n'
import { createEntityCachedQueryHook, EntityCachedQueryHook } from 'src/lib/react-hooks/entity-cached-query-hook'
import { loadData } from 'src/lib/requests'
import { entityGenerator, paramsSerializer, validateEmail } from 'src/lib/utils'
import { Currency } from 'src/types/common'
import { Locales } from 'src/types/locale'
import { UserStatus } from 'src/types/user'

import { sendUserFetchedEvent } from './analytics'
import { HTTPMethod, Role, User, UserKeys, UserWithPolicies } from './index'

export { fullName } from './users.utils'

export const emptyUser = entityGenerator<User>({
	id: 0,
	email: '',
	first_name: '',
	middle_name: '',
	last_name: '',
	status: UserStatus.Created,
	auto_select_self: true,
	language_code: Locales.enUS,
	currency_code: Currency.USD,
	gender: null,
	deactivate_at: null,
	deleted: false,
})

const userMandatoryFields: UserKeys[] = ['first_name', 'last_name', 'email']
export const notNulllableFields: UserKeys[] = []

export function isValidUser(user: User) {
	return userMandatoryFields.every((f) => Boolean(user[f])) && validateEmail(user.email)
}

export interface UserSearchParams {
	search?: string
	managerial_statuses?: Role[]
	ids?: number[]
	group_id?: number
	exclude_deactivated?: boolean
}

const baseUrl = appConfig.API_DOMAIN

export async function queryUsers(params: UserSearchParams) {
	try {
		const result = await loadData<User[]>({ resourcePath: 'users', params, paramsSerializer })
		return result.data
	} catch (e) {
		console.warn('error in search:', e)
		return []
	}
}

export async function queryUser(id: number) {
	const { data } = await loadData<User>({ method: HTTPMethod.GET, resourcePath: `users/${id}` })

	return data
}

export async function queryUsersWithPolicies(params: UserSearchParams) {
	try {
		const result = await loadData<UserWithPolicies[]>({ resourcePath: 'admin/users/search', params, paramsSerializer })
		return result.data
	} catch (e) {
		console.warn('error in search:', e)
		return []
	}
}

export function dispatchUserInfo(user: User) {
	sendUserFetchedEvent(user)
	setUserForErrorTracking(user)
}

export async function downloadUsersList() {
	const hashPathBase = 'admin/users/download'
	const hash = await loadData<string>({ resourcePath: hashPathBase })

	downloadFileByLink(`${baseUrl}/${hashPathBase}/${hash.data}.xls`)
}

type ApproverForType = 'pre_trip_green_light' | 'office' | 'department' | 'company' | 'policy_group'

export type DeactivateUserInfo = {
	approver_for: { type: ApproverForType; name: string }[]
	last_trip_return_date: string | null
	last_trip_approval_date: string | null
}

export function getApproverForList(approver_for: { type: ApproverForType; name: string }[]) {
	return approver_for
		.map(({ name, type }) => {
			switch (type) {
				case 'company':
					return (
						<Trans i18nKey="deactivate-user.prompt.approver-for.company">
							the <strong>company approver</strong>
						</Trans>
					)
				case 'pre_trip_green_light':
					return (
						<Trans i18nKey="deactivate-user.prompt.approver-for.pre-trip-green-light">
							the <strong>green light approver</strong>
						</Trans>
					)
				case 'office':
					return (
						<Trans i18nKey="deactivate-user.prompt.approver-for.office">
							the approver for the <strong>{{ name }}</strong> office
						</Trans>
					)
				case 'department':
					return (
						<Trans i18nKey="deactivate-user.prompt.approver-for.department">
							the approver for the <strong>{{ name }}</strong> department
						</Trans>
					)
				case 'policy_group':
					return (
						<Trans i18nKey="deactivate-user.prompt.approver-for.policy-group">
							the approver for the <strong>{{ name }}</strong> policy group
						</Trans>
					)
			}
		})
		.reduce((prev, curr, i) => {
			if (i === 0) {
				return curr
			}
			return (
				<>
					{prev}, {curr}
				</>
			)
		}, null)
}

export function getLastTripMinDeactivationDate(dateString: string | null) {
	return dateString ? addDays(dateString, 1) : undefined
}

export function getLastTripApprovalMinDeactivationDate(dateString: string | null) {
	return dateString ? addDays(dateString, 3) : undefined
}

export function getMinDeactivationDate({
	last_trip_approval_date,
	last_trip_return_date,
}: Pick<DeactivateUserInfo, 'last_trip_approval_date' | 'last_trip_return_date'>) {
	const dates = filterEmptyValues([
		addDays(new Date(), 1),
		getLastTripMinDeactivationDate(last_trip_return_date),
		getLastTripApprovalMinDeactivationDate(last_trip_approval_date),
	])
	return max(dates)
}

export function getDeactivationDateReason(
	{
		last_trip_approval_date,
		last_trip_return_date,
	}: Pick<DeactivateUserInfo, 'last_trip_approval_date' | 'last_trip_return_date'>,
	t: TFunction,
	format: string,
) {
	const lastTripLabel =
		last_trip_return_date &&
		t('deactivate-user.prompt.deactivate-date.last-trip', 'has a trip ending on {{date}}', {
			date: moment(last_trip_return_date).format(format),
		})
	const lastApproverLabel =
		last_trip_approval_date &&
		t('deactivate-user.prompt.deactivate-date.approver', 'is an approver for the trip to be approved by {{date}}', {
			date: moment(last_trip_approval_date).format(format),
		})
	const conjunctionLabel =
		lastTripLabel && lastApproverLabel && t('deactivate-user.prompt.deactivate-date.conjunction', ' and ')

	return [lastTripLabel, conjunctionLabel, lastApproverLabel].filter(Boolean).join('')
}

const userPlaceholder = (id: number) =>
	({
		id,
		first_name: 'user',
		last_name: id + '',
	}) as User

export const useUserCachedQuery = createEntityCachedQueryHook(
	'users',
	'id',
	queryUser,
	(query: string) => queryUsers({ search: query }),
	({ getEntity, queryEntity, searchEntities }: EntityCachedQueryHook<number, User>) => ({
		getUserById: (id?: number, placeholder: typeof userPlaceholder = userPlaceholder) => getEntity(id, placeholder),
		queryUser: queryEntity,
		queryUsers: searchEntities,
	}),
)
