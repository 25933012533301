import React from 'react'

import { E2E } from 'src/lib/e2e-utils'
import { useTranslation } from 'src/lib/i18n/i18n'
import { isDefinedValue } from 'src/lib/isDefinedValue'
import { AdminUsersState } from 'src/redux/reducers/admin-users.reducer'
import styled from 'src/styles'
import { getRoleLabel, Role } from 'src/travelsuit'
import { FlightClassRules } from 'src/travelsuit/policy'

import { PolicySettingSummaryValue } from './PolicySettings.components'
import { useClassRolesOptions } from './useClassRolesOptions'
import { isRoleEligible } from './utils'

const StyledPolicySettingSummaryValue = styled(PolicySettingSummaryValue)`
	${(p) => p.theme.breakpoints.up('lg')} {
		margin-top: 8px;
	}
`

interface FlightClassSummaryRowProps extends E2E {
	rules: FlightClassRules
	label: string
	role?: Role
	users: AdminUsersState
}

export function FlightClassSummaryRow({ rules, label, users, role, e2e }: FlightClassSummaryRowProps) {
	const businessClassRolesOptions = useClassRolesOptions()

	const { t } = useTranslation()

	const { role: roleRules, for_long_flights: longFlightsRuleArgs } = rules

	const roleFlyBusinessUsers = roleRules.user_ids.map((u) => users.get(u))
	const businessLongFlightsNumberValue: number = longFlightsRuleArgs.min_number_of_hours
	const businessLongFlightsAllowedValue: boolean = longFlightsRuleArgs.allow
	const roleFlyBusinessValue: Role | null = roleRules.role

	const isEligible = role && roleFlyBusinessValue ? isRoleEligible(role, roleFlyBusinessValue) : false

	const description =
		!role || isEligible
			? [
					businessClassRolesOptions.find((i) => i.value === roleFlyBusinessValue)!.label,
					role && !isEligible && roleFlyBusinessValue !== Role.Employee && roleFlyBusinessUsers.length
						? t('flight-policy-settings.additional-user', '{{count}} additional user', {
								count: roleFlyBusinessUsers.length,
							})
						: null,
					!businessLongFlightsAllowedValue && !!businessLongFlightsNumberValue
						? t('flight-policy-settings.all-flights-longer-than', 'all flights longer than {{count}} hour', {
								count: businessLongFlightsNumberValue,
							})
						: null,
				]
			: role
				? [
						t('flight-policy-settings.business-not-allowed', 'Not allowed for {{role}}', {
							role: getRoleLabel({ role, t, count: 2 }),
						}),
					]
				: []

	if (!description.length) {
		return null
	}

	return (
		<StyledPolicySettingSummaryValue e2e={e2e}>
			{label}
			{' - '}
			{description.filter(isDefinedValue).join(', ')}
		</StyledPolicySettingSummaryValue>
	)
}
