import {
	ApprovalStatus,
	PreTripRequest,
	PreTripRequestManageData,
	PreTripRequestUpdateData,
	VoidCallback,
} from 'src/travelsuit'

import { IAction, IActionNoPayload } from './action-helpers'
import { TripRequestsTypes as Types } from './actions.types'

export const Actions = {
	getTripRequests(
		callback?: VoidCallback<PreTripRequest[]>,
		errCallback?: VoidCallback<never>,
	): IActionNoPayload<PreTripRequest[]> {
		return {
			type: Types.GetTripRequests.REQUEST,
			callback,
			errCallback,
		}
	},
	createTripRequest(
		request: PreTripRequestUpdateData,
		callback?: VoidCallback<PreTripRequest>,
		errCallback?: VoidCallback<never>,
	): IAction<PreTripRequestUpdateData, PreTripRequest, PreTripRequest> {
		return {
			type: Types.CreateTripRequest.REQUEST,
			payload: request,
			callback,
			errCallback,
		}
	},
	submitTripRequest(
		requestId: number,
		data: Partial<PreTripRequestUpdateData>,
		callback?: VoidCallback<PreTripRequest>,
		errCallback?: VoidCallback<never>,
	): IAction<{ id: number }, Partial<PreTripRequestUpdateData>, PreTripRequest> {
		return {
			type: Types.SubmitTripRequest.REQUEST,
			payload: { id: requestId },
			body: data,
			callback,
			errCallback,
		}
	},
	approveTripRequest(
		requestId: number,
		message: string,
		callback?: VoidCallback<PreTripRequest>,
		errCallback?: VoidCallback<never>,
	): IAction<{ id: number }, Partial<PreTripRequestManageData>, PreTripRequest> {
		return {
			type: Types.ManageTripRequest.REQUEST,
			payload: { id: requestId },
			body: { message, status: ApprovalStatus.Approved },
			callback,
			errCallback,
		}
	},
	rejectTripRequest(
		requestId: number,
		message: string,
		callback?: VoidCallback<PreTripRequest>,
		errCallback?: VoidCallback<never>,
	): IAction<{ id: number }, Partial<PreTripRequestManageData>, PreTripRequest> {
		return {
			type: Types.ManageTripRequest.REQUEST,
			payload: { id: requestId },
			body: { message, status: ApprovalStatus.NotApproved },
			callback,
			errCallback,
		}
	},
	deleteTripRequest(
		requestId: number,
		callback?: VoidCallback<PreTripRequest>,
		errCallback?: VoidCallback<never>,
	): IAction<{ id: number }, Partial<PreTripRequestManageData>, PreTripRequest> {
		return {
			type: Types.DeleteTripRequest.REQUEST,
			payload: { id: requestId },
			callback,
			errCallback,
		}
	},
}
