import memoizeOne from 'memoize-one'

/**
 * Looks like `Jun 15, 07:15 AM`
 * Used as the departure/arrival time
 */
export const getShortDateTimeFormat = memoizeOne(
	(locale: string) =>
		new Intl.DateTimeFormat(locale, {
			day: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
			month: 'short',
		}),
)

/**
 * Looks like `Jun 15, 2024, 07:15 AM`
 * Used for booking date/time
 */
export const getYearDateTimeFormat = memoizeOne(
	(locale: string) =>
		new Intl.DateTimeFormat(locale, {
			day: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
			month: 'short',
			year: 'numeric',
		}),
)

/**
 * Looks like `6 August 2024`
 */
export const getYearDateFormat = memoizeOne(
	(locale: string) =>
		new Intl.DateTimeFormat(locale, {
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		}),
)

/**
 * Looks like `6 Aug 2024`
 */
export const getShortYearDateFormat = memoizeOne(
	(locale: string) =>
		new Intl.DateTimeFormat(locale, {
			day: 'numeric',
			month: 'short',
			year: 'numeric',
		}),
)
