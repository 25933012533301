import { VoidCallback } from 'src/travelsuit'
import { ReportingFieldSettings } from 'src/travelsuit/report-settings.type'

import { IAction } from './action-helpers'
import { ReportSettingsTypes } from './actions.types'

const convertDataForBackend = (reportSettings: ReportingFieldSettings[]) => {
	return reportSettings.map((reportSetting) => ({
		id: reportSetting.id,
		data: { ...reportSetting },
	}))
}

export const Actions = {
	getReportSettings(callback?: VoidCallback<ReportingFieldSettings[]>, errCallback?: VoidCallback<never>): IAction {
		return {
			type: ReportSettingsTypes.GetReportSettings.REQUEST,
			callback,
			errCallback,
		}
	},
	updateReportSettings(
		reportSettings: ReportingFieldSettings[],
		callback?: VoidCallback<ReportingFieldSettings[]>,
		errCallback?: VoidCallback<never>,
	): IAction {
		return {
			type: ReportSettingsTypes.UpdateReportSettings.REQUEST,
			payload: { reportSettings },
			body: convertDataForBackend(reportSettings),
			callback,
			errCallback,
		}
	},
}
