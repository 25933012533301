import React from 'react'

import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import { Trans, useTranslation } from 'src/lib/i18n/i18n'
import { capitalize, smartJoin } from 'src/lib/utils'
import { mainBlack } from 'src/refactor/colors'
import styled, { font, GridProps } from 'src/styles'
import { DefaultElProps, Role } from 'src/travelsuit'
import { TravelPolicy, TravelPolicyMinimal } from 'src/travelsuit/policy'
import { fullName } from 'src/travelsuit/users'

import {
	PolicySettingSummary,
	PolicySettingSummaryColumn,
	PolicySettingSummaryItem,
	PolicySettingSummaryTitle,
	PolicySettingSummaryValue,
} from './PolicySettings.components'
import { isTravelPolicyLoaded } from './utils'

interface IProps extends DefaultElProps<'div'> {
	policy: TravelPolicy | TravelPolicyMinimal
	columns?: GridProps['columns']
	role?: Role
	showDisclaimer?: boolean
}

const PreTripSettingsSummaryContainer = styled.div``

const PreTripSummaryText = styled.div.attrs(addE2EAttrs)<E2E>`
	margin-left: 30px;
	margin-bottom: 50px;
	${font({ size: 12 })};
	color: ${mainBlack};
	max-width: 500px;
`

const PreTripSettingsSummary: React.FunctionComponent<IProps> = (props) => {
	const { className, policy, columns, role, showDisclaimer } = props
	const { t } = useTranslation()

	if (!isTravelPolicyLoaded(policy)) {
		return null
	}

	const { pre_trip_custom_approvers, pre_trip_fallback_approvers, pre_trip_policy_data, company_approvers } = policy
	const isOriginCondition = pre_trip_policy_data?.conditions?.length > 0
	const condition = isOriginCondition
		? pre_trip_policy_data.conditions[0]
		: { require_office_approver: false, require_department_approver: false }
	const rules = { ...pre_trip_policy_data, pre_trip_custom_approvers, pre_trip_fallback_approvers }
	const selectedOfficeApprover = condition.require_office_approver
	const selectedDepartmentApprover = condition.require_department_approver
	const selectedCompanyApprover = isOriginCondition
		? pre_trip_custom_approvers.length > 0 && company_approvers.some((v) => v.id === pre_trip_custom_approvers[0].id)
		: false
	const selectedOtherApprover = isOriginCondition
		? pre_trip_custom_approvers.length > 0 && !company_approvers.some((v) => v.id === pre_trip_custom_approvers[0].id)
		: false

	return (
		<PreTripSettingsSummaryContainer className={className}>
			<PreTripSummaryText e2e={'PreTripSettingsSummary.Description'}>
				{showDisclaimer && (
					<Trans i18nKey="pre-trip-policy-settings.summary-text">
						When active, all employees (apart from exceptions) will need to have their route approved before being able
						to proceed with their booking.
					</Trans>
				)}
			</PreTripSummaryText>
			{rules.enabled ? (
				<PolicySettingSummary columns={columns}>
					<PolicySettingSummaryColumn>
						<PolicySettingSummaryItem>
							<PolicySettingSummaryTitle e2e={'PreTripSettingsSummary.GreenLightApproverTitle'}>
								<Trans i18nKey="pre-trip-policy-settings.green-light-approver">Green Light Approver</Trans>
							</PolicySettingSummaryTitle>
							<PolicySettingSummaryValue e2e={'PreTripSettingsSummary.GreenLightApproverStatus'}>
								{selectedDepartmentApprover
									? t('pre-trip-policy-settings.department-manager', 'Department Manager')
									: selectedOfficeApprover
										? t('pre-trip-policy-settings.office-manager', 'Office Manager')
										: selectedCompanyApprover
											? t('pre-trip-policy-settings.comapny-approver', 'Company Approver ({{name}})', {
													name: fullName(policy.pre_trip_custom_approvers[0]),
												})
											: selectedOtherApprover
												? t('pre-trip-policy-settings.custom-approver', 'Custom Approver ({{name}})', {
														name: fullName(policy.pre_trip_custom_approvers[0]),
													})
												: null}
							</PolicySettingSummaryValue>
						</PolicySettingSummaryItem>
						{policy?.pre_trip_fallback_approvers?.length > 0 ? (
							<PolicySettingSummaryItem>
								<PolicySettingSummaryTitle e2e={'PreTripSettingsSummary.FallbackApproverTitle'}>
									{t('pre-trip-policy-settings.fallback-approver', 'Fallback Approver')}
								</PolicySettingSummaryTitle>
								<PolicySettingSummaryValue e2e={'PreTripSettingsSummary.FallbackApproverStatus'}>
									{t('pre-trip-policy-settings.comapny-approver', 'Company Approver ({{name}})', {
										name: fullName(policy.pre_trip_fallback_approvers[0]),
									})}
								</PolicySettingSummaryValue>
							</PolicySettingSummaryItem>
						) : null}
						{!role ? (
							rules.roles ? (
								<PolicySettingSummaryItem>
									<PolicySettingSummaryTitle e2e={'PreTripSettingsSummary.AppliesToTitle'}>
										{t('pre-trip-policy-settings.applies-to', 'Applies To')}
									</PolicySettingSummaryTitle>
									<PolicySettingSummaryValue e2e={'PreTripSettingsSummary.AppliesToStatus'}>
										{smartJoin(
											rules.roles.map((role) => capitalize(role)),
											3,
											'',
											', ',
										)}
									</PolicySettingSummaryValue>
								</PolicySettingSummaryItem>
							) : null
						) : rules.roles.includes(role) ? (
							<PolicySettingSummaryItem>
								<PolicySettingSummaryTitle>
									{t('pre-trip-policy-settings.applies-to', 'Applies To')}
								</PolicySettingSummaryTitle>
								<PolicySettingSummaryValue>{capitalize(role)}</PolicySettingSummaryValue>
							</PolicySettingSummaryItem>
						) : null}
					</PolicySettingSummaryColumn>
				</PolicySettingSummary>
			) : null}
		</PreTripSettingsSummaryContainer>
	)
}

PreTripSettingsSummary.defaultProps = {
	showDisclaimer: true,
}

export default PreTripSettingsSummary
