import { AxiosError } from 'axios'

import {
	FlightSearchDetails,
	HotelSearchDetails,
	ProductType,
	SearchData,
	SearchRequestWithTrain,
	SearchResult,
	VoidCallback,
} from 'src/travelsuit'
import { prepareSearchDetailsForRequest } from 'src/travelsuit/search'
import { SearchId } from 'src/types/search'

import { IAction } from './action-helpers'
import { SearchesTypes as Types } from './actions.types'

export const Actions = {
	getSearch(
		searchId: number,
		callback?: VoidCallback<SearchData>,
		errCallback?: VoidCallback<AxiosError>,
	): IAction<{ searchId: SearchId }, unknown, SearchData> {
		return {
			type: Types.GetSearch.REQUEST,
			payload: { searchId },
			callback,
			errCallback,
		}
	},
	startSearch(
		searchRequest: SearchRequestWithTrain,
		callback?: VoidCallback<SearchResult>,
		errCallback?: VoidCallback<AxiosError>,
	): IAction<SearchRequestWithTrain, unknown, SearchResult> {
		return {
			type: Types.CreateSearch.REQUEST,
			payload: searchRequest,
			callback,
			errCallback,
		}
	},
	updateSearch<T extends {} = FlightSearchDetails | HotelSearchDetails>(
		searchId: number,
		details: T,
		callback?: VoidCallback<SearchData>,
		errCallback?: VoidCallback<AxiosError>,
	) {
		return {
			type: Types.UpdateSearch.REQUEST,
			payload: { searchId, details },
			body: {
				details: prepareSearchDetailsForRequest(
					details as any,
					details.hasOwnProperty('routes') ? ProductType.Flights : ProductType.Hotels,
				),
			},
			callback,
			errCallback,
		}
	},
}
