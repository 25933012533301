import { CountryPOS as TSCountryPOS } from 'src/travelsuit'

import { IAction } from '../actions/action-helpers'
import { CountryPOSList } from '../actions/actions.types'

export type countryPosState = TSCountryPOS[]

export function countryPOSReducer(state: countryPosState = [], action: IAction): countryPosState {
	switch (action.type) {
		case CountryPOSList.GetCountryPOSList.SUCCESS:
			return action.payload
	}
	return state
}

export default countryPOSReducer
