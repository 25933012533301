import { getTimePeriod } from 'src/lib/reports-utils'
import { ExpenseReportsTypes } from 'src/redux/actions/actions.types'
import { denormalize, normalize } from 'src/redux/reducers/expense-reports.reducer'
import { Callback, VoidCallback } from 'src/travelsuit'
import {
	ExpenseReport,
	ExpenseReportApi,
	ExpenseReportsExportApi,
	ExpenseReportsFilterSet,
} from 'src/travelsuit/expenses'

type ExportFileType = 'csv' | 'xls'

export const Actions = {
	getReport(id: number, callback?: VoidCallback<ExpenseReport>) {
		return {
			type: ExpenseReportsTypes.GetReport.REQUEST,
			payload: { id },
			callback: (expenseReport: ExpenseReportApi) => callback?.(normalize(expenseReport)),
		}
	},
	getReports(callback?: VoidCallback<ExpenseReport[]>) {
		return {
			type: ExpenseReportsTypes.GetReports.REQUEST,
			callback: (expenseReportsApi: ExpenseReportApi[]) => callback?.(expenseReportsApi.map(normalize)),
		}
	},
	deleteReport(report: ExpenseReport, callback?: Callback) {
		return {
			type: ExpenseReportsTypes.DeleteReport.REQUEST,
			payload: { id: report.id },
			callback,
		}
	},
	updateReport(expenseReport: ExpenseReport, callback?: Callback) {
		const expenseReportApi = denormalize(expenseReport)
		return {
			type: ExpenseReportsTypes.UpdateReport.REQUEST,
			payload: { id: expenseReportApi.id },
			body: expenseReportApi,
			callback,
		}
	},
	createReport(expenseReport: ExpenseReport, callback?: VoidCallback<string>) {
		const expenseReportApi = denormalize(expenseReport)
		return {
			type: ExpenseReportsTypes.CreateReport.REQUEST,
			body: expenseReportApi,
			callback,
		}
	},
	submitReport(expenseReport: ExpenseReport, callback?: Callback) {
		const expenseReportApi = denormalize(expenseReport)
		return {
			type: ExpenseReportsTypes.SubmitReport.REQUEST,
			payload: { id: expenseReport.id },
			body: expenseReportApi,
			callback,
		}
	},
	approveReport(expenseReport: ExpenseReport, callback?: VoidCallback<ExpenseReport>) {
		return {
			type: ExpenseReportsTypes.ApproveReport.REQUEST,
			payload: { id: expenseReport.id },
			callback: (expenseReport: ExpenseReportApi) => callback?.(normalize(expenseReport)),
		}
	},
	rejectReport(expenseReport: ExpenseReport, reason: string, callback?: Callback) {
		return {
			type: ExpenseReportsTypes.RejectReport.REQUEST,
			payload: { id: expenseReport.id },
			body: { reason },
			callback,
		}
	},
	exportReports(filters: ExpenseReportsFilterSet, fileType: ExportFileType, callback?: Callback) {
		return {
			type: ExpenseReportsTypes.ExportReports.REQUEST,
			payload: { fileType },
			body: getExportParams(filters),
			responseType: 'blob',
			callback,
		}
	},
}

function getExportParams(filters: ExpenseReportsFilterSet): ExpenseReportsExportApi {
	return {
		submitter_id: filters.submitter,
		invoice_profile_display_name: filters.invoiceProfile,
		status: filters.status,
		searchQuery: filters.searchQuery,
		...getTimePeriod(filters.timePeriod),
	}
}
