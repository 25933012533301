import { UserPOS as TSUserPOS } from 'src/travelsuit'

import { IAction } from '../actions/action-helpers'
import { UserPOS } from '../actions/actions.types'

export type userPosState = TSUserPOS | null

function userPOSReducer(state: userPosState = null, action: IAction): userPosState {
	switch (action.type) {
		case UserPOS.GetUserPOS.SUCCESS:
			return action.payload
	}
	return state
}

export default userPOSReducer
