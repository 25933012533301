import React from 'react'
import { useTranslation } from 'react-i18next'

import Confirmation from 'src/atoms/Confirmation/Confirmation'
import { DeclinedPaymentBookingsContent } from 'src/molecules/ItineraryStatusBox/DeclinedPaymentBookingsContent'

import { ApiPaymentDeclinedError } from './ApiPaymentDeclinedError'

interface IProps {
	error: unknown
	onClose: () => void
}

const PaymentError: React.FC<IProps> = ({ error, onClose }) => {
	const { t } = useTranslation()

	const isPaymentError = error instanceof ApiPaymentDeclinedError

	return (
		<Confirmation
			title={t('itinerary-submit.payment-declined', 'Payment declined')}
			prompt={!isPaymentError && t('itinerary-submit.general-error', 'Something went wrong.')}
			variant="alert"
			onOK={onClose}
			okLabel="Close"
		>
			{isPaymentError ? (
				<DeclinedPaymentBookingsContent />
			) : (
				<p>{t('itinerary-submit.contact-support-msg', 'Please try again later or contact our support team.')}</p>
			)}
		</Confirmation>
	)
}

export default PaymentError
