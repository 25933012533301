import { DailyAllowancesPolicyTypes } from 'src/redux/actions/actions.types'
import { Callback, VoidCallback } from 'src/travelsuit'
import {
	DailyAllowanceDestination,
	DailyAllowanceDestinationRequest,
	DailyAllowanceRate,
	DailyAllowanceRateRequest,
} from 'src/types/expenses'

export const Actions = {
	getDestinations(callback?: VoidCallback<DailyAllowanceDestination[]>) {
		return {
			type: DailyAllowancesPolicyTypes.GetDestinations.REQUEST,
			callback,
		}
	},
	createDestination(
		dailyAllowanceDestination: DailyAllowanceDestinationRequest,
		callback?: VoidCallback<DailyAllowanceDestination>,
	) {
		return {
			type: DailyAllowancesPolicyTypes.CreateDestination.REQUEST,
			body: dailyAllowanceDestination,
			callback,
		}
	},
	updateDestination(
		dailyAllowanceDestination: DailyAllowanceDestinationRequest,
		id: number,
		callback?: VoidCallback<DailyAllowanceDestination>,
	) {
		return {
			type: DailyAllowancesPolicyTypes.UpdateDestination.REQUEST,
			payload: { id },
			body: dailyAllowanceDestination,
			callback,
		}
	},
	deleteDestination(dailyAllowanceDestination: DailyAllowanceDestination, callback?: Callback) {
		return {
			type: DailyAllowancesPolicyTypes.DeleteDestination.REQUEST,
			payload: { id: dailyAllowanceDestination.id },
			callback,
		}
	},

	createRate(dailyAllowanceRate: DailyAllowanceRateRequest, callback?: Callback) {
		return {
			type: DailyAllowancesPolicyTypes.CreateRate.REQUEST,
			body: dailyAllowanceRate,
			callback,
		}
	},
	updateRate(dailyAllowanceRate: DailyAllowanceRateRequest, id: number, callback?: Callback) {
		return {
			type: DailyAllowancesPolicyTypes.UpdateRate.REQUEST,
			payload: { id },
			body: dailyAllowanceRate,
			callback,
		}
	},
	deleteRate(rate: DailyAllowanceRate, callback?: Callback) {
		return {
			type: DailyAllowancesPolicyTypes.DeleteRate.REQUEST,
			payload: { id: rate.id },
			callback,
		}
	},
}
