import React from 'react'

import { lightMidGray, warning } from 'src/_vars'
import JoinNodes, { AffixConfig } from 'src/atoms/JoinNodes/JoinNodes'
import Tooltip from 'src/atoms/Tooltip/Tooltip'
import { getTestId, TestId } from 'src/lib/e2e-utils'
import { useTranslation } from 'src/lib/i18n/i18n'
import { secondaryBlack } from 'src/refactor/colors'
import styled from 'src/styles'
import { DefaultElProps, SelectOption } from 'src/travelsuit'

type IProps = DefaultElProps<'div'> &
	TestId & {
		options: SelectOption[]
		selections: any[][]
		primaryGroupingLabel?: React.ReactNode
		secondaryGroupingLabel?: React.ReactNode
		hideEmpty?: boolean
		color?: string
		renderGroup?(
			child: React.ReactNode,
			originalChildren: React.ReactNode,
			originalGroup: any[],
			separator: React.ReactNode,
			index: number,
		): React.ReactNode
		renderItem?(
			child: React.ReactNode,
			originalChildren: React.ReactNode,
			originalGroup: any[],
			separator: React.ReactNode,
			index: number,
		): React.ReactNode
	}

const LogicalGroupTextReprContainer = styled.div`
	line-height: 1.5;
`

const GroupTextRepr = styled.span<{ warning?: boolean; color?: string }>`
	color: ${(props) => (props.warning ? warning : props.color ? props.color : secondaryBlack)};
	${(props) =>
		props.warning
			? `
				font-weight: 500;
				cursor: help;
			`
			: ''}
`

const TextReprSep = styled.span`
	color: ${lightMidGray};
`

const LogicalGroupTextRepr: React.FunctionComponent<IProps> = (props) => {
	const { t } = useTranslation()
	const {
		className,
		options,
		selections,
		hideEmpty,
		primaryGroupingLabel = t('logical-group-text.or', ' or '),
		secondaryGroupingLabel = t('logical-group-text.and', ' and '),
		color,
		renderGroup,
		renderItem: renderChild,
	} = props
	const filtered = hideEmpty ? selections.filter((grp) => grp?.length) : [...selections]

	if (!filtered.length) {
		return (
			<LogicalGroupTextReprContainer className={className} data-test={getTestId(props)}>
				<TextReprSep>{t('logical-group-text.no-selection', 'No Selection')}</TextReprSep>
			</LogicalGroupTextReprContainer>
		)
	}

	return (
		<LogicalGroupTextReprContainer className={className} data-test={getTestId(props)}>
			<JoinNodes
				separator={<TextReprSep>{primaryGroupingLabel}</TextReprSep>}
				affix={{
					wrapItem: renderGroup,
				}}
			>
				{filtered.map((grp, i) => {
					const mapped = grp.map((val, j) => (
						<React.Fragment key={j}>{options.find((opt) => val === opt.value)!.label}</React.Fragment>
					))
					const overlap = isGroupIncludedInAnother(selections, i)
					const parantheses: AffixConfig = filtered.length > 1 && grp.length > 1 ? { prefix: '(', suffix: ')' } : {}

					return (
						<Tooltip key={i} title={overlap ? t('logical-group-text.overlapped', 'Overlapped selection') : undefined}>
							<GroupTextRepr warning={overlap} color={color}>
								<JoinNodes separator={secondaryGroupingLabel} affix={{ ...parantheses, wrapItem: renderChild }}>
									{mapped}
								</JoinNodes>
							</GroupTextRepr>
						</Tooltip>
					)
				})}
			</JoinNodes>
		</LogicalGroupTextReprContainer>
	)
}

LogicalGroupTextRepr.defaultProps = {
	hideEmpty: true,
}

function isGroupIncludedInAnother(selections: any[][], i: number): boolean {
	if (!selections?.[i]?.length) {
		return false
	}
	const cur = selections[i]
	const filtered = [...selections]
	filtered.splice(i, 1)
	return filtered.some((f) => isGroupIncludedInGroup(cur, f))
}

function isGroupIncludedInGroup(source: any[], target: any[]) {
	return source.every((s) => target.includes(s))
}

export default LogicalGroupTextRepr
