import { TimePeriodDetailed } from 'src/lib/reports-utils.helpers'
import { Currency } from 'src/types/common'

import { CreditCardResponse, InvoiceProfile, MinimalTrip, PaymentInfo, TimeOptions, User } from './index'
import { WorldlineOrderStatus } from './worldline'

export interface FeesFilters {
	period: TimeOptions | TimePeriodDetailed
	service_type: {
		[key in ServiceFeeType]: boolean
	}
	payment_fee_type: PaymentFeeType | null
	country: {
		[key: number]: boolean
	}
	invoice_profile: {
		[key: number]: boolean
	}
}

export enum ServiceFeeType {
	Trip = 'trip',
	SupportRequest = 'support_request',
	AnnualPayment = 'annual_payment',
	MonthlyPayment = 'monthly_payment',
	ProfiledUser = 'profiled_user',
	ActiveUser = 'active_user',
}

export enum PaymentFeeType {
	WithFee = 'with_fee',
	WhithoutFee = 'without_fee',
	UnpaidFees = 'unpaid',
}

export type FeeInfo<T extends FeesData> = {
	[key in ServiceFeeType]: T
}

export type Fee<T extends FeesData> = FeeInfo<T> & {
	service_type: ServiceFeeType
}

export type FeesData = BaseFee & Partial<SupportRequestFee & TripFee & PackagePaymentFee & UserFee & ActiveUserFee>

interface BaseFee {
	id: number
	total_tax: number
	total_price: number
	fee_type: string
	created_dt: string
	currency: Currency
	status: WorldlineOrderStatus | null
}

export interface FeePaymentDetails {
	credit_card: CreditCardResponse | null
	charge_dt: string | null
	refunded_by: User | null
	refund_dt: string | null
}

export interface SupportRequestFee extends BaseFee, FeePaymentDetails {
	trip_id: number | null
	not_applicable: boolean
	request_by_user: User
	service: string
	ticket: number
	category: string
	created_by_user: User
	type: string
	billable: boolean
}

export interface TripFee extends BaseFee, FeePaymentDetails {
	trip: MinimalTrip
	user: User
	user_id: number
}

export interface ActiveUserFee extends BaseFee, FeePaymentDetails {
	users_number: number
	invoice_profile: InvoiceProfile
	next_payment_date: string
	remaining_price: number
	remaining_tax: number
}

export interface UserFee extends BaseFee {
	request_by_user: User
	trip_id: number | null
	is_active: boolean
}

export interface PackagePaymentFee extends BaseFee {
	payment_info: PaymentInfo
	is_annual: boolean
	next_payment_date: string
}
