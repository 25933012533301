import { Callback } from 'src/travelsuit'
import { DataForTrainRedirect } from 'src/travelsuit/trains'

import { IAction } from './action-helpers'
import { Train } from './actions.types'

export const Actions = {
	railRedirect(data: DataForTrainRedirect, callback?: Callback, errCallback?: Callback): IAction {
		return {
			type: Train.RailRedirect.REQUEST,
			payload: data,
			callback,
			errCallback,
		}
	},
}
