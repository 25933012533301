import { useCallback, useEffect, useMemo } from 'react'

import { AbortableRequest, WithAbortRequestConfig } from 'src/lib/requests'

export function useAbortableRequest<T, Y>(callback: (args: T & WithAbortRequestConfig) => Promise<{ data: Y }>) {
	const abortableRequest = useMemo(() => new AbortableRequest(callback), [callback])

	const abort = useCallback(() => abortableRequest.abort(), [abortableRequest])

	useEffect(() => {
		return abort
	}, [abort])

	const performRequest = useCallback((args: T) => abortableRequest.performRequest(args), [abortableRequest])

	return {
		performRequest,
		abort,
	}
}
