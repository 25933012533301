import { filterEmptyValues } from 'src/lib/array-utils'
import { Location, LocationTypes } from 'src/travelsuit'

export function getLocationName(location: Location) {
	return filterEmptyValues([
		location.name,
		location.location_type && [LocationTypes.Airport, LocationTypes.City].includes(location.location_type)
			? location.country_name
			: undefined,
	]).join(', ')
}
