import { useContext } from 'react'
import React from 'react'

import { ModalContext } from '.'

export const useShowModal = () => {
	const { show } = useContext(ModalContext)
	return show
}

export function showConfirmation(
	Confirmation: ({ onCancel, onConfirm }: { onCancel: () => void; onConfirm: () => void }) => JSX.Element,
	show: ReturnType<typeof useShowModal>,
) {
	return new Promise((resolve) => {
		show(({ close }) => (
			<Confirmation
				onCancel={() => {
					close()
					resolve(false)
				}}
				onConfirm={() => {
					close()
					resolve(true)
				}}
			/>
		)).catch(() => resolve(false))
	})
}
