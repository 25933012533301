import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SetOptional } from 'type-fest'

import CircularLoader from 'src/atoms/Loader'
import { generateSessionToken, getAddressDetails, getPlaceDetails, searchAddress } from 'src/lib/places-api'
import { useAsyncAction } from 'src/lib/react-hooks/useAsyncAction'

import Autocomplete, { AutocompleteProps } from './Autocomplete'

type AddressPrediction = google.maps.places.AutocompletePrediction | undefined

export type OnAddressPrediction = (address: ReturnType<typeof getAddressDetails>) => void

export type AddressAutocompleteControlledProps = Omit<
	AutocompleteProps,
	'query' | 'labelFn' | 'itemValueOf' | 'onChange' | 'selected'
> &
	Pick<Exclude<AutocompleteProps['inputProps'], undefined>, 'fullWidth' | 'label' | 'disabled'> & {
		autocompleteSessionToken: google.maps.places.AutocompleteSessionToken
		countryCode?: string
		onChange(selected: AddressPrediction, e?: React.MouseEvent<HTMLElement>): void
		selected: AddressPrediction
	}

export function AddressAutocompleteControlled(props: AddressAutocompleteControlledProps) {
	const { autocompleteSessionToken, countryCode, disabled, fullWidth, inputProps, label, ...rest } = props

	return (
		<Autocomplete
			alwaysShowOnOpen={true}
			labelFn={(option) => option?.description ?? ''}
			itemValueOf={(option) => option?.place_id}
			query={(search: string) => searchAddress(search, autocompleteSessionToken, { countryCode })}
			inputProps={{
				fullWidth: fullWidth,
				disabled: disabled,
				label: label,
				variant: 'filled-rounded',
				...inputProps,
			}}
			{...rest}
		/>
	)
}

export type AddressAutocompleteProps = SetOptional<
	Omit<AddressAutocompleteControlledProps, 'autocompleteSessionToken' | 'onChange' | 'selected'>,
	'label'
> & {
	onAddressPrediction: OnAddressPrediction
}

export function AddressAutocomplete(props: AddressAutocompleteProps) {
	const { disabled, inputProps, label, onAddressPrediction, ...rest } = props
	const { t } = useTranslation()
	const { autocompleteSessionToken, isAddressLoading, onChange, selected } = useAddressAutocomplete({
		onAddressPrediction,
	})

	return (
		<AddressAutocompleteControlled
			autocompleteSessionToken={autocompleteSessionToken}
			disabled={disabled || isAddressLoading}
			inputProps={{ leading: isAddressLoading && <CircularLoader size={16} />, ...inputProps }}
			label={label || t('Search address by street, city, postal code')}
			onChange={onChange}
			selected={selected}
			{...rest}
		/>
	)
}

export function useAddressAutocomplete({ onAddressPrediction }: { onAddressPrediction: OnAddressPrediction }) {
	const [addressPrediction, setAddressPrediction] = useState<AddressPrediction>()
	const autocompleteSessionToken = useMemo(generateSessionToken, [])
	const { isRequestInProgress, performAsyncAction } = useAsyncAction()

	const onAddressCompletionChanged = useCallback<AddressAutocompleteControlledProps['onChange']>(
		(completion) => {
			setAddressPrediction(completion)

			if (completion?.place_id) {
				performAsyncAction(async () => {
					const place = await getPlaceDetails(completion?.place_id, autocompleteSessionToken)

					if (!place) {
						return
					}

					const address = getAddressDetails(place)

					onAddressPrediction(address)

					setAddressPrediction(undefined)
				})
			}
		},
		[autocompleteSessionToken, onAddressPrediction, performAsyncAction, setAddressPrediction],
	)

	return {
		autocompleteSessionToken,
		isAddressLoading: isRequestInProgress,
		onChange: onAddressCompletionChanged,
		selected: addressPrediction,
	}
}

export function splitStreetName(streetName: string) {
	return streetName
		.split(' ')
		.filter(Boolean)
		.reduce(
			(acc, v) => {
				const last = acc[acc.length - 1]
				const next = v.trim()
				if (last.length + next.length < (acc.length === 1 ? 60 : 40)) {
					acc[acc.length - 1] = last ? `${last} ${next}` : next
				} else {
					acc.push(next)
				}
				return acc
			},
			[''],
		)
		.filter((_, i) => i < 4)
}
