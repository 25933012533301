import React from 'react'

import { openExternalChatPopup } from 'src/lib/fresh-chat.utils'
import { Trans, useTranslation } from 'src/lib/i18n/i18n'
import { getCurrentLocale } from 'src/lib/locale-utils'
import { useUserPOS } from 'src/lib/react-hooks/useUserPOS'
import { eventStopper } from 'src/lib/utils'
import BookmarkIcon from 'src/refactor/assets/icons/bookmark.svg'
import EnvelopOpenIcon from 'src/refactor/assets/icons/envelope-open.svg'
import MessagesIcon from 'src/refactor/assets/icons/messages.svg'
import SupportIcon from 'src/refactor/assets/icons/support.svg'
import WhatsAppIcon from 'src/refactor/assets/icons/whatsapp.svg'
import { DefaultProps } from 'src/travelsuit'
import { Locales } from 'src/types/locale'

import {
	HelpText,
	ItemContent,
	ItemIcon,
	ItemRow,
	ItemSubtitle,
	ItemTitle,
	StyledLink,
	StyledLinkButton,
	SupportSummaryContainer,
} from './SupportSummary.components'

const getLinkToKnoledgeBase = (lang: Locales) =>
	`https://help.getgoing.com/${lang.slice(0, 2).toLowerCase()}/support/home`

interface IProps extends DefaultProps {
	mobile?: boolean
	onClickOutside?(e: React.MouseEvent<any>): void
	onClickItem?(e: React.MouseEvent<any>): void
}

export const SupportSummaryContent: React.FunctionComponent<IProps> = (props) => {
	const { className, mobile, onClickOutside, onClickItem } = props
	const { t } = useTranslation()

	const userPOS = useUserPOS({})
	const cleanWhatsAppRegExp = new RegExp(/\D/g)

	function openLiveChat(e: React.MouseEvent<any>) {
		openExternalChatPopup(() => {
			onClickOutside?.(e)
			onClickItem?.(e)
		})
	}

	const linkToKnoledgeBase = React.useMemo(() => getLinkToKnoledgeBase(getCurrentLocale()), [])

	return (
		<SupportSummaryContainer
			className={className}
			mobile={mobile}
			e2e={'SupportSummary'}
			onClick={eventStopper.stopPropagation()}
		>
			<HelpText>{t('support-summary.knowledge-base-section', 'GetGoing Knowledge Base')}</HelpText>
			<ItemRow clickable>
				<ItemIcon src={BookmarkIcon} />
				<ItemContent>
					<ItemTitle
						as="a"
						href={linkToKnoledgeBase}
						e2e="Support-knowledge-base"
						target="_blank"
						rel="noopener noreferrer"
					>
						{t(
							'support-summary.knowledge-base-title',
							'Everything you always wanted to know about GetGoing but were afraid to ask',
						)}
					</ItemTitle>
					<ItemSubtitle>
						<Trans i18nKey="support-summary.knowledge-base-subtitle">
							<StyledLink href={linkToKnoledgeBase} target="_blank" rel="noopener noreferrer">
								Click here
							</StyledLink>{' '}
							to access GetGoing Knowledge base
						</Trans>
					</ItemSubtitle>
				</ItemContent>
			</ItemRow>
			<HelpText>{t('support-summary.help-text', 'Contact our Travel Experts during regular business hours')}</HelpText>
			<ItemRow clickable onClick={mobile ? openLiveChat : undefined}>
				<ItemIcon src={MessagesIcon} />
				<ItemContent>
					<ItemTitle onClick={openLiveChat} e2e="Support-LiveChat">
						{t('support-summary.live-chat-title', 'Live Chat with us')}
					</ItemTitle>
					<ItemSubtitle>
						<Trans i18nKey="support-summary.live-chat-subtitle">
							<StyledLinkButton onClick={openLiveChat}>Click here</StyledLinkButton>
							to instantly start a live chat with a Travel Expert
						</Trans>
					</ItemSubtitle>
				</ItemContent>
			</ItemRow>
			{userPOS?.whatsapp ? (
				<ItemRow clickable>
					<ItemIcon src={WhatsAppIcon} />
					<ItemContent>
						<ItemTitle
							as="a"
							href={`https://wa.me/${userPOS?.whatsapp.replace(cleanWhatsAppRegExp, '')}`}
							e2e="Support-WhatsApp"
						>
							{t('support-summary.whatsapp-title', 'WhatsApp ' + userPOS?.whatsapp, {
								phone_number: userPOS?.whatsapp,
							})}
						</ItemTitle>
						<ItemSubtitle>{t('support-summary.whatsapp-subtitle', 'Send us a message for assistance')}</ItemSubtitle>
					</ItemContent>
				</ItemRow>
			) : null}
			<ItemRow clickable>
				<ItemIcon src={EnvelopOpenIcon} />
				<ItemContent>
					<ItemTitle as="a" href={`mailto:${userPOS?.email}`} e2e="Support-Email">
						{userPOS?.email || ''}
					</ItemTitle>
					<ItemSubtitle>
						{t('support-summary.email-subtitle', 'Reach out via email with any requests or to report a problem')}
					</ItemSubtitle>
				</ItemContent>
			</ItemRow>
			<HelpText>{t('support-summary.phone-header', 'Our Travel Experts are available 24/7 by phone')}</HelpText>
			<ItemRow clickable>
				<ItemIcon src={SupportIcon} />
				<ItemContent>
					<ItemTitle as="a" href={`tel:${userPOS?.contact_phone || ''}`} e2e="Support-Phone">
						{userPOS?.contact_phone || ''}
					</ItemTitle>
					<ItemSubtitle>
						{t('support-summary.phone-subtitle', 'Travel Experts are available to assist in cases of emergency')}
					</ItemSubtitle>
				</ItemContent>
			</ItemRow>
		</SupportSummaryContainer>
	)
}
