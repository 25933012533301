import React from 'react'

import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import { usePreviousValue } from 'src/lib/react-hooks/generic-hooks'
import { useTravelPolicyForUsers } from 'src/lib/react-hooks/travel-policy-hooks'
import { eventStopper } from 'src/lib/utils'
import PolicyPreviewSidebar from 'src/organisms/PolicyPreviewSidebar/PolicyPreviewSidebar'
import ShieldIcon from 'src/refactor/assets/icons/shield.svg'
import styled, { flex } from 'src/styles'
import { DefaultElProps, ProductType, User } from 'src/travelsuit'
import { track } from 'src/travelsuit/analytics'
import { TripTravelPolicy } from 'src/travelsuit/policy'

import { LinkButton } from '../Button'
import SvgIcon from '../SvgIcon/SvgIcon'

interface IProps extends DefaultElProps<'div', 'onClick'> {
	policies?: TripTravelPolicy[]
	users?: User[]
	label?: React.ReactNode
	productType?: ProductType
	onClick?(e: React.MouseEvent<HTMLElement>): void
	render?(props: { onClick(e: React.MouseEvent<HTMLElement>): void }): React.ReactNode
}

const LinkContainer = styled(LinkButton).attrs(addE2EAttrs)<E2E>`
	${flex({ justify: 'flex-start', align: 'center', display: 'inline-flex', gap: 8 })}
`

/** At least one of `label` and `render` must be specified */
const PolicyPreviewLink: React.FunctionComponent<IProps> = track({})((props) => {
	const { className, label, policies: _policies, users, productType, render, onClick: _onClick, ...rest } = props
	const [open, toggle] = React.useState(false)
	const wasOpen = usePreviousValue(open)
	const statePolicies = useTravelPolicyForUsers(users ?? [], { refresh: open && !wasOpen })
	const policies = _policies ?? statePolicies

	if (!policies.length && !users?.length) {
		console.warn(new Error('Either policies or users must be supplied'))
		return null
	}

	function onClick(e: React.MouseEvent<HTMLElement>) {
		toggle(true)
		_onClick?.(e)
	}

	const sidebar = open && (
		<PolicyPreviewSidebar open onClose={() => toggle(false)} policies={policies} productType={productType} />
	)

	if (render) {
		return (
			<div className={className} {...rest}>
				{render({ onClick: eventStopper.stopPropagation(onClick) })}
				{sidebar}
			</div>
		)
	}

	return (
		<div className={className} {...rest}>
			<LinkContainer e2e={'ViewTravelPolicy'} onClick={eventStopper.stopPropagation(onClick)}>
				<SvgIcon src={ShieldIcon} />
				{label}
			</LinkContainer>
			{sidebar}
		</div>
	)
})

export default PolicyPreviewLink
