import { CircularProgress } from '@material-ui/core'
import React from 'react'

import Button, { IProps as IButtonProps } from 'src/atoms/Button/Button'
import { filterEmptyValues } from 'src/lib/array-utils'
import { passTestId } from 'src/lib/e2e-utils'
import { useTranslation } from 'src/lib/i18n/i18n'
import { fontBlack } from 'src/refactor/colors'
import { font } from 'src/refactor/fonts'
import styled, { margin } from 'src/styles'
import { DefaultProps } from 'src/travelsuit'

import Modal, { DOMTreePosition, IProps as ModalProps } from '../Modal/Modal'

export interface ModalButtonProps {
	buttonProps?: {
		okButton?: IButtonProps
		cancelButton?: IButtonProps
	}
}
interface IProps extends DefaultProps, ModalButtonProps {
	title: React.ReactNode
	prompt?: React.ReactNode
	variant?: ModalProps['variant']
	okLabel?: React.ReactNode
	cancelLabel?: React.ReactNode
	width?: number | string
	onOK?: (e: React.MouseEvent<any>) => void
	onCancel?: (e: React.MouseEvent<any>) => void
	domTreePosition?: DOMTreePosition
	open?: boolean
	showCancelButton?: boolean
	zIndex?: number
	buttonWidth?: string
	containerClassName?: string
	showLoading?: boolean
}

const Content = styled.div`
	${font({ size: 14 })}
	color: ${fontBlack};
`

const ChildrenContainer = styled.div`
	&:not(:first-child) {
		${margin.symmetric({ vertical: 10 })}
	}
`

const Confirmation: React.FunctionComponent<IProps> = (props) => {
	const { t } = useTranslation()
	const {
		className,
		open,
		domTreePosition,
		children,
		prompt,
		onCancel,
		onOK,
		variant,
		title,
		width,
		showCancelButton,
		cancelLabel = t('confirmation.cancel', 'Cancel'),
		okLabel = t('confirmation.ok', 'OK'),
		// KILLME: BCDTDRCT-1400 Drawer backdrop zIndex is 10000
		// KILLME: Sidebar zIndex is 100000
		zIndex = 100005,
		buttonWidth,
		containerClassName,
		showLoading,
	} = props

	return (
		<Modal
			data-test={passTestId(props)}
			className={className}
			open={open}
			title={title}
			onClose={onCancel ?? onOK}
			variant={variant}
			width={width}
			domTreePosition={domTreePosition}
			zIndex={zIndex}
			containerClassName={containerClassName}
			controlButtons={
				onOK || (showCancelButton && onCancel)
					? filterEmptyValues([
							showLoading ? (
								<div style={{ alignSelf: 'center' }}>
									<CircularProgress />
								</div>
							) : null,
							showCancelButton && onCancel ? (
								<Button
									data-test="Confirmation.Button.Cancel"
									key="cancel"
									width={buttonWidth || 'auto'}
									color={variant === 'alert' ? 'secondary' : 'blueOutline'}
									onClick={onCancel}
									disabled={showLoading}
									{...props.buttonProps?.cancelButton}
								>
									{cancelLabel}
								</Button>
							) : null,
							onOK ? (
								<Button
									data-test="Confirmation.Button.Confirm"
									key="ok"
									width={buttonWidth || 'auto'}
									minWidth={showCancelButton && onCancel ? undefined : 160}
									color={variant === 'alert' ? 'negative' : 'bluePrimary'}
									onClick={onOK}
									disabled={showLoading}
									{...props.buttonProps?.okButton}
								>
									{okLabel}
								</Button>
							) : null,
						])
					: []
			}
		>
			<Content data-test="Confirmation.Content">
				{prompt}

				{children ? <ChildrenContainer>{children}</ChildrenContainer> : null}
			</Content>
		</Modal>
	)
}

Confirmation.defaultProps = {
	showCancelButton: true,
}

export default Confirmation
