import React from 'react'

import RoundedLock from 'src/assets/rounded-lock.svg'
import SvgIcon from 'src/atoms/SvgIcon/SvgIcon'
import Tooltip from 'src/atoms/Tooltip/Tooltip'
import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import { useTranslation } from 'src/lib/i18n/i18n'
import { purple, white } from 'src/refactor/colors'
import { font } from 'src/refactor/fonts'
import styled from 'src/styles'

const BadgeContainer = styled.span.attrs(addE2EAttrs)<E2E>`
	display: inline-block;
	border-radius: 30px;
	padding: 10px 8px;
	text-transform: uppercase;
	${font({ size: 10, weight: 800 })}
	text-align: center;
	color: ${white};
	background-color: ${purple};
`

const StatusIcon = styled(SvgIcon)`
	width: 1.5em;
	vertical-align: middle;
	margin-right: 5px;
	stroke: none;
`

export interface AvailableWithUpgradeProps {
	tooltipTitle?: string
	className?: string
	tipAutoWidth?: boolean
	subject?: string
	title?: string
}

export function AvailableWithUpgrade({
	className,
	tooltipTitle,
	tipAutoWidth,
	subject,
	title,
}: AvailableWithUpgradeProps) {
	const { t } = useTranslation()

	return (
		<Tooltip title={tooltipTitle} autoWidth={tipAutoWidth}>
			<BadgeContainer className={className} e2e="AvailableWithUpgrade">
				<StatusIcon src={RoundedLock} />
				{subject ? subject + ' ' : ''}
				{title ?? t('upgrade-payment-plan.available-with-upgrage', 'Available with upgrade')}
			</BadgeContainer>
		</Tooltip>
	)
}
