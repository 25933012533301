import React from 'react'

import { CompanyTypeIndicator } from 'src/atoms/CompanyTypeIndicator/CompanyTypeIndicator'
import { useTranslation } from 'src/lib/i18n/i18n'
import { DefaultProps } from 'src/travelsuit'

interface IProps extends DefaultProps {
	isAdmin: boolean
	isSupportUser: boolean
	companyType: string
}

const CompanyTooltipText = ({ isAdmin, isSupportUser, companyType }: IProps) => {
	const { t } = useTranslation()
	if (companyType === 'restricted') {
		return (
			<>
				<p>
					{t('company-type-popup.popup-text.status', 'Your company account has been set to')}
					<CompanyTypeIndicator label={t('company-type-popup.restricted', 'Restricted')} companyType={companyType} />
					<br />
					{t(
						'company-type-popup.popup-text.restricted.explain',
						'Restricted mode means that booking functionalities are unavailable.',
					)}
					<br />
				</p>

				<p>
					{isAdmin || isSupportUser
						? t(
								'company-type-popup.popup-text.restricted.adminMode',
								'Please contact your Customer Success Consultant for more details.',
							)
						: t(
								'company-type-popup.popup-text.restricted.userMode',
								'Please contact your company travel coordinator for more details.',
							)}
				</p>
			</>
		)
	}

	if (companyType === 'demo') {
		return (
			<p>
				{t('company-type-popup.popup-text.status', 'Your company account has been set to')}
				<CompanyTypeIndicator label={t('company-type-popup.demo', 'Demo')} companyType={companyType} />
				<br />
				{t(
					'company-type-popup.popup-text.demo.explain',
					'Demo mode means that the booking functionalities are for demo purpose`s only: no live booking is made with any supplier during the booking process, except for Deutsche Bahn where bookings on the DB platform are live.',
				)}
			</p>
		)
	}

	return <></>
}

export default CompanyTooltipText
