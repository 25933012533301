import React from 'react'
import { useTranslation } from 'react-i18next'

import HandImageUrl from 'src/assets/hand.svg'
import Box from 'src/atoms/Box/Box'
import Button from 'src/atoms/Button/Button'
import { Routes } from 'src/lib/route-utils'
import { purple, white } from 'src/refactor/colors'
import styled, { font } from 'src/styles'

interface UpgradePaymentPlanProps {
	title: React.ReactNode
	className?: string
}

const Title = styled.div`
	${font({ weight: 700, size: 20 })}
	line-height: 28px;
`

const UpgradePlanBox = styled(Box)`
	color: ${white};

	display: flex;
	padding: 30px 70px 30px 0;

	background-color: ${purple};
`

const Image = styled.img`
	transform: rotateY(180deg);

	width: 175px;
`

const Content = styled.div`
	${font({ weight: 400, size: 16 })}
	line-height: 22px;

	margin: 10px 0 20px;
`

export function UpgradePaymentPlan({ title, children, className }: React.PropsWithChildren<UpgradePaymentPlanProps>) {
	const { t } = useTranslation()

	const onUpgradeClick = () => window.open(Routes.BillingChangePlan, '_blank')

	return (
		<UpgradePlanBox radius={20} className={className}>
			<Image src={HandImageUrl} />
			<div>
				<Title>{title}</Title>
				<Content>{children}</Content>
				<Button onClick={onUpgradeClick} color="primary">
					{t('upgrade-payment-plan.upgrade', 'Upgrade')}
				</Button>
			</div>
		</UpgradePlanBox>
	)
}
