import React from 'react'

import SvgIcon from 'src/atoms/SvgIcon/SvgIcon'
import Tooltip from 'src/atoms/Tooltip/Tooltip'
import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import { white } from 'src/refactor/colors'
import { font } from 'src/refactor/fonts'
import styled from 'src/styles'

interface StatusBadgeProps extends E2E {
	className?: string
	reverseColors?: boolean
	tooltip?: string
	color: string
	fontColor?: string
	hasReducedPadding?: boolean
	transformToUpperCase?: boolean
	label: React.ReactNode
	iconSrc?: string
	applyMargin?: boolean
}

const BadgeContainer = styled(Tooltip).attrs(addE2EAttrs)<
	E2E & {
		backgroundColor: string
		textColor: string
		hasBorder?: boolean
		applyMargin?: boolean
		hasReducedPadding?: boolean
		transformToUpperCase?: boolean
	}
>`
	display: inline-block;
	border-radius: 30px;
	padding: ${(p) => (p.hasReducedPadding ? '3px 8px' : '10px 10px')};
	text-transform: ${(p) => (p.transformToUpperCase ? 'uppercase' : '')};
	background: gray;
	${font({ size: 10, weight: 800 })}
	text-align: center;

	background-color: ${(p) => p.backgroundColor};
	border: ${(p) => (p.hasBorder ? `1px solid ${p.backgroundColor}` : '')};
	color: ${(p) => p.textColor};

	&:not(:first-child) {
		margin-left: 3ex;
	}

	${(p) => p.theme.breakpoints.down('md')} {
		white-space: nowrap;
	}
`

const StatusIcon = styled(SvgIcon)`
	width: 1.5em;
	vertical-align: middle;
	margin-right: 5px;
	stroke: none;
`

export function StatusBadge(props: StatusBadgeProps) {
	const {
		className,
		e2e = 'StatusBadge',
		tooltip,
		color,
		fontColor = white,
		label,
		reverseColors,
		iconSrc,
		applyMargin = true,
		hasReducedPadding,
		transformToUpperCase = true,
	} = props

	return (
		<BadgeContainer
			title={tooltip}
			multiline
			className={className}
			e2e={e2e}
			backgroundColor={reverseColors ? fontColor : color}
			textColor={reverseColors ? color : fontColor}
			hasBorder={reverseColors}
			hasReducedPadding={hasReducedPadding}
			transformToUpperCase={transformToUpperCase}
			applyMargin={applyMargin}
		>
			{iconSrc && <StatusIcon src={iconSrc} />}
			{label}
		</BadgeContainer>
	)
}
