import React from 'react'
import {
	FieldPath,
	FieldPathValue,
	FieldValues,
	UnpackNestedValue,
	useController,
	UseControllerProps,
} from 'react-hook-form'

import { ValidationSchema } from 'src/common/forms'
import { Maybe } from 'src/lib/types'
import { User } from 'src/travelsuit'

import { ApproverAutocomplete, ApproverAutocompleteProps } from './ApproverAutocomplete'

export type FormApproverAutocompleteProps<
	Form extends FieldValues,
	Name extends FieldPath<Form>,
	Value extends UnpackNestedValue<FieldPathValue<Form, Name>> = UnpackNestedValue<FieldPathValue<Form, Name>>,
> = Omit<ApproverAutocompleteProps, 'onChange'> &
	Omit<UseControllerProps<Form, Name>, 'name'> & {
		schema?: ValidationSchema<Form>
		name: Name
		disabled?: boolean
		label: string
		fullWidth?: boolean
		/** Transforms the option to the form value */
		transformOnChange?: (option: User) => Value
		/** Transforms the form value to the option */
		transformSelectedValue?: (value: Value) => Maybe<User>
	}

export function FormApproverAutocomplete<Form extends FieldValues, Name extends FieldPath<Form>>(
	props: FormApproverAutocompleteProps<Form, Name>,
) {
	const {
		control,
		name,
		schema,
		rules: rawRules,
		label,
		disabled,
		fullWidth,
		transformOnChange,
		transformSelectedValue,
		inputProps,
		...rest
	} = props

	const rules = schema?.[name] || rawRules

	const {
		field: { onChange: onChangeRaw, value },
	} = useController({ control, rules, name })

	const onChange = (option: User) => onChangeRaw(transformOnChange ? transformOnChange(option) : option)

	return (
		<ApproverAutocomplete
			selected={transformSelectedValue ? transformSelectedValue(value) : value}
			alwaysShowOnOpen={true}
			onChange={onChange}
			inputProps={{
				fullWidth,
				required: !!rules?.required,
				disabled: disabled,
				label: label,
				...inputProps,
			}}
			{...rest}
		/>
	)
}
