import { z } from 'zod'

import { FlightBookingOptionZ } from 'src/types/flights/search'
import { SearchIdZ } from 'src/types/search'

import { CreateJobResponseZ, getJobGroupResultZ } from '../job'
import { UserIdZ } from '../user'
import { FlightFareGroupKeyZ, FlightIdentifierZ, FlightOptionKeyZ } from './common'

export const FlightFaresPricingCreateJobRequestZ = z.array(
	z.strictObject({
		fare_group_key: FlightFareGroupKeyZ,
		flight_option_keys: z.array(FlightOptionKeyZ),
		flight_identifier: FlightIdentifierZ,
		user_id: UserIdZ,
		search_id: SearchIdZ,
		fare_families: z.boolean().optional(),
	}),
)

export type FlightFaresPricingCreateJobRequest = z.infer<typeof FlightFaresPricingCreateJobRequestZ>

export const FlightFaresPricingCreateJobResponseZ = CreateJobResponseZ

export type FlightFaresPricingCreateJobResponse = z.infer<typeof FlightFaresPricingCreateJobResponseZ>

export const FlightFaresPricingJobResultResponseZ = getJobGroupResultZ(
	z.strictObject({
		fare_groups: z.array(FlightBookingOptionZ),
		session_ids: z.unknown(),
	}),
)

export type FlightFaresPricingJobResultResponse = z.infer<typeof FlightFaresPricingJobResultResponseZ>
