import { ScreenSizes } from 'src/css-helpers/media-query'

// eslint:disable:only-arrow-functions
const w = window as any
const d = document as any

// Internet Explorer 6-11
export const isIE = /*@cc_on!@*/ false || !!d.documentMode

// Edge 20+
export const isEdge = !isIE && !!w.StyleMedia

export const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !w.MSStream

export const isMobile = () => window.innerWidth <= ScreenSizes.Mobile
export const isTablet = () => window.innerWidth < ScreenSizes.Desktop
export const isDesktop = () => window.innerWidth >= ScreenSizes.Desktop
