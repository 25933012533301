import { z } from 'zod'

import { CurrencyZ, EmailZ, GenderZ, RelativeURLZ } from 'src/types/common'
import { LocalesZ } from 'src/types/locale'

// TODO: Fix Expense forms to be able to use brands
export const UserIdZ = z.number() // .brand<'UserId'>()

export type UserId = z.infer<typeof UserIdZ>

export enum UserStatus {
	Created = 'CREATED',
	Invited = 'INVITED',
	Registered = 'REGISTERED',
	Deactivated = 'DEACTIVATED',
}

export const UserStatusZ = z.nativeEnum(UserStatus)

export const PersonNamesZ = z.object({
	first_name: z.string(),
	middle_name: z.string().nullable(),
	last_name: z.string(),
})

export type PersonNames = z.infer<typeof PersonNamesZ>

// TODO: not all properties have schema created
export const UserZ = z.object({
	auto_select_self: z.boolean(),
	bookable: z.boolean().optional(),
	currency_code: CurrencyZ,
	deactivate_at: z.date().or(z.string()).nullable(),
	deleted: z.boolean(),
	email: EmailZ,
	gender: GenderZ.nullish(),
	id: UserIdZ,
	image_url: RelativeURLZ.nullable(),
	invoice_profile: z.unknown().nullish(), // (InvoiceProfile | null)?
	is_admin: z.boolean().optional(),
	language_code: LocalesZ.nullable(),
	status: UserStatusZ,
	mock_enabled: z.boolean().optional(),
	tokenizer_mock_enabled: z.boolean().optional(),
	worldline_mock_enabled: z.boolean().optional(),
	traveler_profile: z.any().nullish(), // TravelProfile?
	user_permissions: z.array(z.unknown()).nullish(), // UserPermission[]?
	user_roles: z.array(z.unknown()).nullish(), // UserRole[]?
	...PersonNamesZ.shape,
})

export type User = z.infer<typeof UserZ>

export const UserMiniZ = UserZ.pick({
	deactivate_at: true,
	deleted: true,
	first_name: true,
	id: true,
	image_url: true,
	last_name: true,
	middle_name: true,
	status: true,
})

export type UserMini = z.infer<typeof UserMiniZ>

export const SetIsMockEnabledRequestZ = UserZ.pick({
	mock_enabled: true,
}).required()

export const SetIsTokenizerMockEnabledRequestZ = UserZ.pick({
	tokenizer_mock_enabled: true,
}).required()

export const SetIsWorldlineMockEnabledRequestZ = UserZ.pick({
	worldline_mock_enabled: true,
}).required()
