import { ExpensePolicyTypes, IAction } from 'src/redux/actions'
import { ExpensePolicy } from 'src/travelsuit/expenses'

export type ExpensePolicyState = ExpensePolicy

function expensePolicy(state: ExpensePolicyState = {}, action: IAction) {
	switch (action.type) {
		case ExpensePolicyTypes.GetPolicy.SUCCESS:
			return action.payload
		case ExpensePolicyTypes.UpdateApproversPolicy.SUCCESS:
			return action.payload
		default:
			return state
	}
}

export default expensePolicy
