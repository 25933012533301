import { Callback, UserTrackingQS } from 'src/travelsuit'

import { IAction } from './action-helpers'
import { UserTrackingTypes as Types } from './actions.types'

export const Actions = {
	trackUser(data: UserTrackingQS, callback?: Callback, errCallback?: Callback): IAction {
		return {
			type: Types.UserTracking.REQUEST,
			payload: data,
			body: { ...data, user_tracking_id: undefined },
			callback,
			errCallback,
		}
	},
}
