import { CompanyHotelProgram, CompanyHotelProgramCreationData, VoidCallback } from 'src/travelsuit'

import { IAction } from './action-helpers'
import { CompanyHotelProgramsTypes } from './actions.types'

export const Actions = {
	getCompanyHotelPrograms(callback?: VoidCallback<CompanyHotelProgram[]>, errCallback?: VoidCallback<never>): IAction {
		return {
			type: CompanyHotelProgramsTypes.GetCompanyHotelPrograms.REQUEST,
			callback,
			errCallback,
		}
	},
	createCompanyHotelProgram(
		formData: CompanyHotelProgramCreationData,
		callback?: VoidCallback<CompanyHotelProgram>,
		errCallback?: VoidCallback<never>,
	): IAction {
		return {
			type: CompanyHotelProgramsTypes.CreateCompanyHotelProgram.REQUEST,
			body: formData,
			callback,
			errCallback,
		}
	},
	updateCompanyHotelProgram(
		id: number,
		formData: CompanyHotelProgramCreationData,
		callback?: VoidCallback<CompanyHotelProgram>,
		errCallback?: VoidCallback<never>,
	): IAction {
		return {
			type: CompanyHotelProgramsTypes.UpdateCompanyHotelProgram.REQUEST,
			body: formData,
			payload: { id },
			callback,
			errCallback,
		}
	},
	deleteCompanyHotelProgram(id: number, callback?: VoidCallback<never>, errCallback?: VoidCallback<never>): IAction {
		return {
			type: CompanyHotelProgramsTypes.DeleteCompanyHotelProgram.REQUEST,
			payload: { id },
			callback,
			errCallback,
		}
	},
}
