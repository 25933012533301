import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { style } from '@material-ui/system'

/** This type allows to specify the value or the value by the breakpoints */
export type AdaptiveValue<T> = T | { [K in Breakpoint]?: T }

export const direction = style({
	prop: 'direction',
	cssProperty: 'flexDirection',
})

export const gap = style({
	prop: 'gap',
	cssProperty: 'gap',
	themeKey: 'spacing',
})

export const spacing = style({
	prop: 'spacing',
	cssProperty: 'gap',
	themeKey: 'spacing',
})
