import { IProps as SvgAnimationProps } from '../SvgAnimation/SvgAnimation'
import WalkingHands from './lottie/planeloader.json'
import ChristmasHands from './lottie/santaloader.json'

const loaderAnimMap = {
	normal: WalkingHands,
	christmas: ChristmasHands,
}

class DateWithoutYear {
	public day: number
	public month: number

	constructor(month: number, day: number) {
		this.month = month
		this.day = day
	}

	public toDate(year: number): Date {
		return new Date(year, this.month, this.day)
	}
}

interface AnimationDateConfig {
	animation: keyof typeof loaderAnimMap
	fromDate: DateWithoutYear
	toDate: DateWithoutYear
}

const animationDates: AnimationDateConfig[] = [
	{
		animation: 'christmas',
		fromDate: new DateWithoutYear(11, 15),
		toDate: new DateWithoutYear(0, 2),
	},
]

export function getLoaderByDate(now: Date): SvgAnimationProps['animation'] {
	for (const animDate of animationDates) {
		const currentYear = now.getFullYear()
		const fromYear =
			now < animDate.fromDate.toDate(currentYear) || now > animDate.toDate.toDate(currentYear)
				? currentYear
				: currentYear - 1
		const toYear = fromYear + 1

		const fromDate = animDate.fromDate.toDate(fromYear)
		const toDate = animDate.toDate.toDate(toYear)

		if (now >= fromDate && now <= toDate) {
			return loaderAnimMap[animDate.animation]
		}
	}

	return WalkingHands
}
