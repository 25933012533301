import React from 'react'

import { fgPrimary60, separatorLight } from 'src/_vars'
import { SvgIcon } from 'src/atoms/SvgIcon'
import { Tooltip } from 'src/atoms/Tooltip'
import { CircleUserPhoto as Photo } from 'src/atoms/UserPhoto/UserPhoto'
import QuestionCircle from 'src/refactor/assets/icons/question-circle.svg'
import styled, { flex } from 'src/styles'
import { DefaultProps, User } from 'src/travelsuit'
import { fullName } from 'src/travelsuit/users'

export type UserListItemProps = DefaultProps & {
	infoHint?: string
	user: User
	onClick?: React.MouseEventHandler<HTMLDivElement>
}

const ListItem = styled.div`
	${flex({ align: 'center', gap: 5 })}

	&:not(:last-child) {
		border-bottom: 1px solid ${separatorLight};
	}
`

const UserName = styled.span`
	color: ${fgPrimary60};
	word-break: break-word;
`

const UserListItem = (props: UserListItemProps) => {
	const { infoHint, user, ...rest } = props

	return (
		<ListItem {...rest}>
			<Photo user={user} size={28} mobileSize={28} />
			<UserName data-test="User.FullName">{fullName(user)}</UserName>
			{infoHint && (
				<Tooltip title={infoHint} autoWidth>
					<SvgIcon src={QuestionCircle} />
				</Tooltip>
			)}
		</ListItem>
	)
}

export default UserListItem
