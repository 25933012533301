import { IAction } from '../actions/action-helpers'
import { TripCancellationStatus } from '../actions/actions.types'

export type TripCancellationStatusState = { cancellationStatus: string | null }

function tripCancellationStatusReducer(
	state: TripCancellationStatusState = { cancellationStatus: null },
	action: IAction,
): TripCancellationStatusState {
	switch (action.type) {
		case TripCancellationStatus.GetCancellationStatus.SUCCESS:
			return { ...state, cancellationStatus: action.payload.cancellation_status }
	}
	return state
}

export default tripCancellationStatusReducer
