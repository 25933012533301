import { AxiosError } from 'axios'

import { Company } from 'src/travelsuit'

import { IAction } from './action-helpers'
import { SupportTypes as Types } from './actions.types'

export const Actions = {
	getAllCompanies(): IAction {
		return {
			type: Types.GetAllCompanies.REQUEST,
		}
	},
	impersonateSupportCompany(company: Company | null, clb?: () => void): IAction {
		return {
			type: Types.ImpersonateSupportCompany.REQUEST,
			payload: { company },
			body: { data: { company_id: company ? company.id : null } },
			callback: clb,
		}
	},
	approveTripOnBehalf(tripId: number, userId: number, clb?: () => void, errClb?: (err: AxiosError) => void): IAction {
		return {
			type: Types.ApproveTripOnBehalf.REQUEST,
			payload: { tripId, userId },
			callback: clb,
			errCallback: errClb,
		}
	},
}
