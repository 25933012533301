import { css, FlattenSimpleInterpolation, SimpleInterpolation } from 'styled-components'

export type TemplateStringsFunction = (
	strings: TemplateStringsArray,
	...interpolations: SimpleInterpolation[]
) => FlattenSimpleInterpolation

function mediaLiteral(cond: string): TemplateStringsFunction {
	return (strings: TemplateStringsArray, ...interpolations: SimpleInterpolation[]) => {
		return css`
			@media ${cond} {
				${css(strings, ...interpolations).join('')}
			}
		`
	}
}

type ScreenSizeName =
	| 'smallMobile'
	| 'mediumMobile'
	| 'mobile'
	| 'desktop'
	| 'desktopWide'
	| 'tablet'
	| 'largeDesktop'
	| 'largeMobile'

export interface ScreenSizeTemplates {
	smallMobile: TemplateStringsFunction
	/** Apply to mobile sizes only */
	mobileOnly: TemplateStringsFunction
	/** Apply to tablet sizes only */
	tabletOnly: TemplateStringsFunction
	/** Apply to desktop sizes only */
	desktopOnly: TemplateStringsFunction
	largeDesktopOnly: TemplateStringsFunction
	/** Apply only at {ScreenSizes} device */
	only: (size: 'mobile' | 'tablet' | 'desktop' | 'desktopWide') => TemplateStringsFunction
	/** Apply above {ScreenSizes} or number of pixels */
	above: (size: ScreenSizeName | number) => TemplateStringsFunction
	/** Apply below {ScreenSizes} or number of pixels */
	below: (size: ScreenSizeName | number) => TemplateStringsFunction
	/** Apply between {ScreenSizes} or number of pixels */
	between: (
		min: 'mobile' | 'tablet' | 'desktop' | 'desktopWide' | number,
		max: 'mobile' | 'tablet' | 'desktop' | 'desktopWide' | number,
	) => TemplateStringsFunction
	/** Apply custom media query from string. *It is NOT wrapped in parentheses to allow multiple values.* */
	custom: (cond: string) => TemplateStringsFunction
	/** Apply `display: none;` below {ScreenSizes} or number of pixels */
	hideBelow: (size: 'tablet' | 'desktop' | 'desktopWide' | number) => string
	/** Apply `display: none;` above {ScreenSizes} or number of pixels */
	hideAbove: (size: 'mobile' | 'tablet' | 'desktop' | number) => string
}

const NARROW_GUTTER_SIZE = 15

export enum ScreenSizes {
	SmallMobile = 419,
	MediumMobile = 480,
	Mobile = 600,
	LargeMobile = 879,
	Tablet = 800,
	Desktop = 1024,
	DesktopWide = 1024 + NARROW_GUTTER_SIZE * 2,
	LargeDesktop = 1440,
}

const ScreenToPx: Record<ScreenSizeName, number> = {
	smallMobile: ScreenSizes.SmallMobile,
	mediumMobile: ScreenSizes.MediumMobile,
	mobile: ScreenSizes.Mobile,
	tablet: ScreenSizes.Tablet,
	desktop: ScreenSizes.Desktop,
	desktopWide: ScreenSizes.DesktopWide,
	largeDesktop: ScreenSizes.LargeDesktop,
	largeMobile: ScreenSizes.LargeMobile,
}

/**
 * Use this type and accompanying function to easily apply styles with overridable props for any media query on a
 * single-use basis. See `passResponsive` for information on how to use that feature.
 * The following type extends `T` and then spreads it across responsive-aware mappings.
 * Use `K` to inject into specific name for `between` query.
 * @example
 * // A fully-customized mapping, where `T` is `{ color: string }` and `K` is `props`:
 * {
 * 	color: 'green', // global default
 * 	[ScreenSize.Mobile]: { // Apply on mobile only
 * 		color: 'pink'
 * 	}
 * 	above: { // min-width query
 * 		[ScreenSize.Desktop]: { color: 'red' } // Apply on desktops+
 * 	},
 * 	below: { // max-width query
 * 		[ScreenSize.Tablet]: { color: 'blue' } // Apply on tablets-mobiles
 * 	},
 * 	between { // combo
 * 		min: ScreenSize.Tablet,
 * 		max: ScreenSize.Desktop,
 * 		props: { // use `K` to change this name
 * 			color: 'yellow' // Apply on tablets only
 * 		}
 * 	}
 * }
 */
export type ResponsiveProps<T, K extends string | number | symbol = 'props'> = Partial<
	T &
		Partial<Record<keyof typeof ScreenToPx, Partial<T>>> &
		Partial<Record<'above' | 'below', Partial<Record<keyof typeof ScreenToPx | number, Partial<T>>>>> &
		Partial<Record<'between', [Record<'min' | 'max', keyof typeof ScreenToPx | number> & { [k in K]: Partial<T> }]>>
>

type MediaParams = [string, number]
interface MediaQueryDefinition {
	content?: string
	conditions: MediaParams[]
}

type MediaQueryCallback = (...a: any[]) => MediaQueryDefinition

function screenKeyToPx(size: keyof typeof ScreenToPx | number): number {
	// @ts-expect-error todo if you see this please remove this comment and fix the type error, thank you :)
	return ScreenToPx[size] ?? Number(size)
}

const fixedSizes: Record<keyof Omit<ScreenSizeTemplates, 'custom'>, MediaQueryDefinition | MediaQueryCallback> = {
	smallMobile: { conditions: [['max-width', screenKeyToPx('smallMobile')]] },
	mobileOnly: { conditions: [['max-width', screenKeyToPx('mobile')]] },
	tabletOnly: {
		conditions: [
			['min-width', screenKeyToPx('mobile') + 1],
			['max-width', screenKeyToPx('desktop') - 1],
		],
	},
	desktopOnly: { conditions: [['min-width', screenKeyToPx('desktop')]] },
	largeDesktopOnly: { conditions: [['min-width', screenKeyToPx('largeDesktop')]] },

	above: (size: ScreenSizeName | number) => ({ conditions: [['min-width', screenKeyToPx(size)]] }),
	below: (size: ScreenSizeName | number) => ({
		conditions: [['max-width', screenKeyToPx(size)]],
	}),
	only: (size: 'mobile' | 'tablet' | 'desktop' | 'desktopWide') => ({
		conditions:
			size === 'mobile'
				? [['max-width', screenKeyToPx('mobile')]]
				: size === 'tablet'
					? [
							['min-width', screenKeyToPx('mobile') + 1],
							['max-width', screenKeyToPx('desktop') - 1],
						]
					: size === 'desktop'
						? [
								['min-width', screenKeyToPx('desktop')],
								['max-width', screenKeyToPx('desktopWide') - 1],
							]
						: [['min-width', screenKeyToPx('desktopWide')]],
	}),
	between: (
		min: 'mobile' | 'tablet' | 'desktop' | 'desktopWide' | number,
		max: 'mobile' | 'tablet' | 'desktop' | 'desktopWide' | number,
	) => ({
		conditions: [
			['min-width', screenKeyToPx(min)],
			['max-width', screenKeyToPx(max)],
		],
	}),
	hideBelow: (size: 'tablet' | 'desktop' | 'desktopWide' | number) => ({
		conditions: [
			[
				'max-width',
				screenKeyToPx(
					size === 'tablet' ? 'mobile' : size === 'desktop' ? 'tablet' : size === 'desktopWide' ? 'desktop' : size,
				),
			],
		],
		content: 'display: none;',
	}),
	hideAbove: (size: 'mobile' | 'tablet' | 'desktop' | number) => ({
		conditions: [['min-width', screenKeyToPx(size) + 1]],
		content: 'display: none;',
	}),
}

function parseMediaQueryDefinition(def: MediaQueryDefinition | MediaQueryCallback, ...args: any[]) {
	const { conditions, content } = typeof def === 'function' ? def(...args) : def
	const condArr: string[] = []
	for (const cond of conditions) {
		const [breakType, breakPx] = cond
		condArr.push(`(${breakType}: ${breakPx}px)`)
	}
	const condTxt = condArr.filter(Boolean).join(' and ')
	if (content) {
		return mediaLiteral(`screen and ${condTxt}`)`${content}`.join('')
	}

	return mediaLiteral(`screen and ${condTxt}`)
}

const mediaQuery = Object.keys(fixedSizes).reduce((acc, label) => {
	// @ts-expect-error todo if you see this please remove this comment and fix the type error, thank you :)
	if (typeof fixedSizes[label] === 'function') {
		return {
			...acc,
			[label]: (...args: any[]) => {
				// @ts-expect-error todo if you see this please remove this comment and fix the type error, thank you :)
				return parseMediaQueryDefinition(fixedSizes[label], ...args)
			},
		}
	}

	return {
		...acc,
		// @ts-expect-error todo if you see this please remove this comment and fix the type error, thank you :)
		[label]: parseMediaQueryDefinition(fixedSizes[label]),
	}
}, {} as ScreenSizeTemplates)

mediaQuery.custom = mediaLiteral
export { mediaQuery }

/**
 * Use this function to easily apply styles with overridable props for any media query on a single-use basis.
 * @see ResponsiveProps
 * @example
 * // Extend your props interface, like so:
 * type YourStyleControlProps = { highlight: boolean }
 * interface IProps extends ResponsiveProps<YourStyleControlProps> {
 * 	//...
 * }
 *
 * // And then your component:
 * const Container = styled.div<IProps>`
 * 	${passResponsive((p: Partial<YourStyleControlProps>) => p.highlight ? `border: 1px solid blue;` : '')}
 * `
 * // The result props will accept `{ highlight: boolean }` and also the same interface but divided by media query selectors.
 * <Container highlight={true}
 *  		below={{ScreenSizes.Tablet: { highlight: false } }} />
 *
 * @param style Function that accepts the singular prop `<T>` and returns finalized style.
 */
export function passResponsive<T>(style: (sz: Partial<T>) => string): (props: Partial<ResponsiveProps<T>>) => string {
	return (props) =>
		css`
			${style(props)}
			${!props.above
				? ''
				: Object.keys(props.above!)
						// @ts-expect-error todo if you see this please remove this comment and fix the type error, thank you :)
						.filter((k) => props.above![k])
						// @ts-expect-error todo if you see this please remove this comment and fix the type error, thank you :)
						.map((sz) => mediaQuery.above(screenKeyToPx(sz as any))`${style(props.above![sz])}`.join(''))}
		${!props.below
				? ''
				: Object.keys(props.below!)
						// @ts-expect-error todo if you see this please remove this comment and fix the type error, thank you :)
						.filter((k) => props.below![k])
						// @ts-expect-error todo if you see this please remove this comment and fix the type error, thank you :)
						.map((sz) => mediaQuery.below(screenKeyToPx(sz as any))`${style(props.below![sz])}`.join(''))}
		${!props.between
				? ''
				: props.between.map((def) =>
						mediaQuery.between(screenKeyToPx(def.min), screenKeyToPx(def.max))`${style(def.props)}`.join(''),
					)}
		${/* @ts-expect-error todo if you see this please remove this comment and fix the type error, thank you :) */ ''}
		${Object.keys(ScreenToPx).map((sz: any) => (!props[sz] ? '' : mediaQuery.only(sz)`${style(props[sz]!)}`.join('')))}
		`.join('')
}
