import React from 'react'

import { travelerChipPhotoBackground } from 'src/_vars'
import styled, { size } from 'src/styles'
import { User } from 'src/travelsuit'
import { userInitials } from 'src/travelsuit/users.utils'

import { CircleUserPhoto } from '../UserPhoto/UserPhoto'

const PhotoBackground = styled.div`
	${size(30)}
	background: ${travelerChipPhotoBackground};
	position: relative;
	border-radius: 50%;
	overflow: hidden;
`
const Initials = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`
export const UserIcon = ({ user }: { user: User }) => {
	return user.image_url ? (
		<CircleUserPhoto user={user} size={28} mobileSize={28} />
	) : (
		<PhotoBackground>
			<Initials>{userInitials(user)}</Initials>
		</PhotoBackground>
	)
}
