import { ImmutableMap } from 'src/lib/immutable/ImmutableMap'
import { HotelInfo } from 'src/travelsuit'

import { IAction } from '../actions/action-helpers'
import { HotelsInfoTypes, SearchResultsTypes } from '../actions/actions.types'

export type HotelsInfoState = ImmutableMap<string, HotelInfo>

function hotelsInfoReducer(state: HotelsInfoState = new ImmutableMap(), action: IAction): HotelsInfoState {
	switch (action.type) {
		case HotelsInfoTypes.GetHotelInfo.SUCCESS:
			return state.set(action.requestPayload.hotelId, action.payload)
		case SearchResultsTypes.LikeHotel.SUCCESS:
			return state.updatePart(action.requestPayload.id, (hotelInfo) => {
				const user = action.requestPayload.user
				const users = (hotelInfo.liked_users ?? []).filter((u) => u.id !== user.id)
				return { liked_users: [...users, user] }
			})
		case SearchResultsTypes.UnlikeHotel.SUCCESS:
			return state.updatePart(action.requestPayload.id, (hotelInfo) => {
				const user = action.requestPayload.user
				const users = (hotelInfo.liked_users ?? []).filter((u) => u.id !== user.id)
				return { liked_users: users }
			})
	}
	return state
}

export default hotelsInfoReducer
