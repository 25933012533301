import { DataForTrainRedirect } from 'src/travelsuit/trains'

import { IAction } from '../actions/action-helpers'
import { TrainTicketDiscountTypes } from '../actions/actions.types'

export type RailRedirectState = DataForTrainRedirect | null

function railRedirectReducer(state: RailRedirectState = null, action: IAction): RailRedirectState {
	switch (action.type) {
		case TrainTicketDiscountTypes.getTicketDiscounts.SUCCESS:
			return action.payload
	}
	return state
}

export default railRedirectReducer
