import React from 'react'

import AddressIcon from 'src/assets/address.svg'
import StarFilledIcon from 'src/assets/star.svg'
import Train from 'src/assets/train.svg'
import { getLocationName } from 'src/lib/getLocationName'
import AirplaneTakingOff from 'src/refactor/assets/icons/airplane-take-off.svg'
import styled, { flex, padPx } from 'src/styles'
import { DefaultProps, Location, LocationTypes } from 'src/travelsuit'

import SvgIcon, { IProps as SvgIconProps } from '../SvgIcon/SvgIcon'

interface IProps extends DefaultProps {
	location: Location
	calculateLocationName?: (location: Location) => string
}

const LocationSearchOptionContainer = styled.div`
	${flex({ align: 'center', justify: 'stretch' })}
`

const LocationIcon = styled(SvgIcon)<{ iconSize: number | undefined } & SvgIconProps>`
	${(props) => `font-size: ${padPx(props.iconSize)}`};
	color: rgb(214, 214, 214);
	line-height: 1;
	margin-top: 5px;
`

const IconContainer = styled.div`
	text-align: center;
	margin-right: 15px;
`

const NameContainer = styled.div``

const LocationSearchOption: React.FunctionComponent<IProps> = (props) => {
	const { className, location, calculateLocationName = getLocationName } = props
	if (!location) {
		return null
	}

	const [icon, iconSize] =
		location.id && location.visibility === 'public'
			? [StarFilledIcon, 20]
			: location.location_type === LocationTypes.Airport
				? [AirplaneTakingOff, 16]
				: location.location_type === LocationTypes.Rail
					? [Train, undefined]
					: [AddressIcon, 18]

	return (
		<LocationSearchOptionContainer className={className}>
			<IconContainer>
				<LocationIcon src={icon} iconSize={iconSize} />
			</IconContainer>
			<NameContainer>{calculateLocationName(location)}</NameContainer>
		</LocationSearchOptionContainer>
	)
}

export default LocationSearchOption
