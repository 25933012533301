import React from 'react'

import JoinNodes from 'src/atoms/JoinNodes/JoinNodes'
import Tooltip from 'src/atoms/Tooltip/Tooltip'
import UserChip from 'src/atoms/UserChip/UserChip'
import { passTestId } from 'src/lib/e2e-utils'
import { useTranslation } from 'src/lib/i18n/i18n'
import styled from 'src/styles'
import { DefaultElProps, User } from 'src/travelsuit'
import { fullName } from 'src/travelsuit/users'

interface IProps extends DefaultElProps<'div'> {
	users: User[]
	limit?: number
	chipClassName?: string
	maxLabelWidth?: number
	withTooltip?: boolean
}

const UserChipListContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 10px 6px;
`

const More = styled(Tooltip)`
	display: inline-block;
	vertical-align: bottom;
	line-height: 32px;
	cursor: default;
`

function UserChipList(props: IProps) {
	const { className, chipClassName, maxLabelWidth, users, limit = Infinity, withTooltip, ...rest } = props

	const { t } = useTranslation()
	const shown = users.slice(0, limit)
	const hidden = users.slice(limit)

	return (
		<UserChipListContainer className={className} {...rest} data-test={passTestId(props)}>
			{shown.map((user) => (
				<React.Fragment key={user.id!}>
					<UserChip
						data-test={passTestId(props, 'UserChip')}
						user={user}
						className={chipClassName}
						maxLabelWidth={maxLabelWidth}
						withTooltip={withTooltip}
					/>
				</React.Fragment>
			))}
			{hidden.length ? (
				<More render={() => <JoinNodes separator={<br />}>{hidden.map(fullName)}</JoinNodes>}>
					{t('user-chip-list.more', `+${hidden.length} more`, { count: hidden.length })}
				</More>
			) : null}
		</UserChipListContainer>
	)
}

export default UserChipList
