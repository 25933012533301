import { ProductType, Trip, TripGap, VoidCallback } from 'src/travelsuit'

import { IAction } from './action-helpers'
import { TripGapsTypes as Types } from './actions.types'

export const Actions = {
	getTripGaps(trip: Trip, type: ProductType, callback?: VoidCallback<TripGap[]>, errCallback?: VoidCallback): IAction {
		return {
			type: Types.GetTripGaps.REQUEST,
			payload: { trip },
			body: { requested_type: type },
			callback,
			errCallback,
		}
	},
	setTripGapForSearch(tripGap: TripGap): IAction {
		return {
			type: Types.SetTripGapForSearch,
			payload: { tripGap },
		}
	},
	resetTripGaps(): IAction {
		return {
			type: Types.ResetTripGaps,
		}
	},
}
