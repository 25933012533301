import { boxShadowFloat } from 'src/_vars'
import { ButtonBase } from 'src/atoms/Button'
import FloatingContainer from 'src/atoms/FloatingContainer/FloatingContainer'
import { size } from 'src/css-helpers/positioning'
import { hoverBlue } from 'src/refactor/colors'
import { font, FontType } from 'src/refactor/fonts'
import styled from 'src/styles'

export const Title = styled.h3`
	${font({ size: 14, weight: 'bold' })}
	margin-bottom: 20px;
`

export const Container = styled.div`
	position: relative;
`

export const LanguageSwitcherButton = styled(ButtonBase)`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 5px;
	transition: all 150ms ease-in-out;
	padding: 5px;
	margin: -5px;
	border: none;
	background: none;
	gap: 5px;
	${font({ family: FontType.Comfortaa, size: 12, weight: 700 })}

	&:hover {
		background: ${hoverBlue};
	}
`

export const Menu = styled(FloatingContainer).attrs(() => ({
	background: true,
	dropShadow: true,
	animation: 'slideInTop',
	animationDuration: 300,
}))`
	${size(220, 'auto')}
	padding: 20px;
	border-radius: 10px;
	box-shadow: ${boxShadowFloat};
`
