import { Alliance } from 'src/travelsuit'

import { IAction } from './action-helpers'
import { AlliancesTypes as Types } from './actions.types'

export const Actions = {
	setAlliances(alliances: Alliance[]): IAction {
		return {
			type: Types.SetAlliances,
			payload: alliances,
		}
	},
}
