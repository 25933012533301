import moment from 'moment'

import { getTimeObjectFromString } from 'src/lib/datetime'
import { replaceEntityById } from 'src/lib/entity/replaceEntityById'
import { ExpensesNewTypes, IAction } from 'src/redux/actions'
import {
	Expense,
	ExpenseApi,
	ExpenseAttendee,
	ExpenseSubmitter,
	FullExpense,
	QuickExpenseApi,
	StandardExpense,
	taxAndVatRateNone,
} from 'src/travelsuit/expenses'
import {
	DailyAllowance,
	DailyAllowanceRequest,
	DailyAllowanceResponse,
	Mileage,
	MileageRequestApi,
	MileageResponseApi,
	TaxAndVatRateApi,
} from 'src/types/expenses'

export type ExpensesNewState = Array<Expense>

function expensesNewReducer(state: ExpensesNewState = [], action: IAction) {
	switch (action.type) {
		case ExpensesNewTypes.GetExpense.SUCCESS: {
			const normalized = normalize(action.payload)
			return normalized ? replaceEntityById(state, normalized) : state
		}
		case ExpensesNewTypes.GetExpenses.SUCCESS:
			return action.payload.map(normalize)
		default:
			return state
	}
}

export default expensesNewReducer

export function normalize(expense: ExpenseApi | MileageResponseApi | DailyAllowanceResponse) {
	const mapTypeToNormalized: Record<
		typeof expense.expense_type,
		(expense: ExpenseApi | MileageResponseApi | DailyAllowanceResponse) => Expense
	> = {
		standard: (expense: ExpenseApi) => normalizeStandardExpense(expense),
		mileage: (mileage: MileageResponseApi) => normalizeMileage(mileage),
		daily_allowance: (da: DailyAllowanceResponse) => normalizeDailyAllowance(da),
	}

	return mapTypeToNormalized[expense.expense_type](expense)
}

export function denormalize(expense: FullExpense | Mileage | DailyAllowance) {
	const mapTypeToDenormalized: Record<
		typeof expense.type,
		(expense: FullExpense | Mileage | DailyAllowance) => DenormalizedExpense | MileageRequestApi | DailyAllowanceRequest
	> = {
		standard: (expense: FullExpense) => denormalizeStandardExpense(expense),
		mileage: (mileage: Mileage) => denormalizeMileage(mileage),
		daily_allowance: (da: DailyAllowance) => denormalizeDailyAllowance(da),
	}

	return mapTypeToDenormalized[expense.type](expense)
}

export function normalizeStandardExpense(expense: QuickExpenseApi | ExpenseApi): StandardExpense {
	return {
		id: Number(expense.id),
		submitter: expense.submitter as ExpenseSubmitter,
		merchant: expense.merchant,
		date: expense.date,
		category: expense.category,
		currency: expense.currency,
		amount: expense.amount,
		attendees: expense.attendees as ExpenseAttendee[],
		description: expense.description,
		type: expense.expense_type,
		status: expense.expense_status,
		...('ocr_status' in expense && expense.ocr_status ? { ocr_status: expense.ocr_status } : {}),
		...('ocr_results' in expense && expense.ocr_results ? { ocr_results: expense.ocr_results } : {}),
		created_at: new Date(expense.created_dt),
		...(expense.file_data ? { file: { ...expense.file_data, url: expense.receipt_url } } : {}),
		report_amount: expense.report_amount,
		...('vat_rate' in expense && expense.vat_rate
			? {
					merchant_country: {
						code: expense.vat_rate.merchant_country_code ?? null,
						name: expense.vat_rate.merchant_country_name,
					},
					vat_rate: expense.vat_rate,
				}
			: expense.merchant_country_name && expense.merchant_country_code
				? {
						merchant_country: { code: expense.merchant_country_code, name: expense.merchant_country_name },
						vat_rate: taxAndVatRateNone as TaxAndVatRateApi,
					}
				: {}),
		...('expense_report' in expense && expense.expense_report && expense.report_id
			? {
					report: { id: expense.report_id, name: expense.expense_report },
				}
			: {}),
	}
}

type DenormalizedExpense = Omit<ExpenseApi, 'vat_rate'> & { vat_rate_id: number | null }

function denormalizeStandardExpense(expense: FullExpense): DenormalizedExpense {
	return {
		expense_type: 'standard',
		id: expense.id.toString(),
		merchant: expense.merchant,
		category: expense.category,
		currency: expense.currency,
		amount: expense.amount,
		description: expense.description,
		ocr_status: expense.ocr_status,
		ocr_results: expense.ocr_results,
		...(expense.file
			? {
					receipt_url: expense.file.url ?? '',
					file_data: { name: expense.file.name ?? '', size: expense.file.size ?? 0 },
				}
			: {}),
		created_dt: expense.created_at.toString(),
		expense_status: expense.status,
		date: moment(expense.date).format('YYYY-MM-DD'),
		...(expense.vat_rate.id === -1 ? { vat_rate_id: null } : { vat_rate_id: expense.vat_rate.id }),
		merchant_country_name: expense.merchant_country.name,
		attendees: expense.attendees.map((attendee) => {
			return attendee.id ? { id: attendee.id } : attendee
		}),
		expense_report: expense.report?.name || '',
		submitter_id: expense.submitter.id,
	} as DenormalizedExpense
}

function normalizeMileage(mileage: MileageResponseApi): Mileage {
	return {
		id: mileage.id,
		date: mileage.date,
		points: mileage.destination_points.map((point) => ({ action: { id: point.id }, full_address: point.full_address })),
		distance: mileage.distance,
		calculatedDistance: mileage.calculated_distance,
		showCalculatedDistance: mileage.show_calculated_distance,
		currency: mileage.currency,
		type: mileage.expense_type,
		status: mileage.expense_status,
		rate: mileage.mileage_rate,
		attendees: mileage.attendees,
		receiptUrl: mileage.receipt_url,
		description: mileage.description,
		submitter: mileage.submitter,
		amount: mileage.amount,
		report_amount: mileage.report_amount,
		unitOfLength: mileage.unit_of_length.toUpperCase() as Uppercase<typeof mileage.unit_of_length>,
		isItTravel:
			mileage.is_travel_related === undefined
				? mileage.is_travel_related
				: (Number(mileage.is_travel_related).toString() as '0' | '1'),
		created_at: new Date(mileage.created_dt),
		...('expense_report' in mileage && mileage.expense_report && mileage.report_id
			? {
					report: {
						id: mileage.report_id,
						name: mileage.expense_report,
					},
				}
			: {}),
	}
}

function denormalizeMileage(mileage: Mileage): MileageRequestApi {
	return {
		expense_type: 'mileage',
		expense_status: mileage.status,
		destination_points: mileage.points.map((point) => ({ id: point.action.id, full_address: point.full_address })),
		date: moment(mileage.date).format('YYYY-MM-DD'),
		receipt_url: mileage.receiptUrl,
		attendees: mileage.attendees.map((attendee) => (attendee.id ? { id: attendee.id } : attendee)),
		currency: mileage.rate.currency,
		mileage_rate_id: mileage.rate.id,
		description: mileage.description,
		submitter_id: mileage.submitter.id,
		unit_of_length: mileage.unitOfLength.toLowerCase() as Lowercase<typeof mileage.unitOfLength>,
		is_travel_related: Boolean(Number(mileage.isItTravel)),
		distance: mileage.distance,
		calculated_distance: mileage.calculatedDistance,
		show_calculated_distance: mileage.showCalculatedDistance,
		expense_report: mileage.report?.name || '',
	}
}

export function normalizeDailyAllowance(da: DailyAllowanceResponse): DailyAllowance {
	return {
		id: da.id,
		submitter: da.submitter,
		category: da.category,
		currency: da.currency,
		amount: da.amount,
		destination: { name: da.destination, id: da.destination_id },
		report_amount: da.report_amount,
		attendees: da.attendees,
		daily_allowance_rates: da.daily_allowance_rates,
		start_date: da.start_date,
		end_date: da.end_date,
		start_time: da.start_time,
		end_time: da.end_time,
		type: da.expense_type,
		status: da.expense_status,
		...(da.file_data ? { file: { ...da.file_data, url: da.receipt_url! } } : {}),
		isItTravel:
			da.is_travel_related === undefined
				? da.is_travel_related
				: (Number(da.is_travel_related).toString() as '0' | '1'),
		created_at: new Date(da.created_dt),
		description: da.description,
		...('expense_report' in da && da.expense_report && da.report_id
			? {
					report: {
						id: da.report_id,
						name: da.expense_report,
					},
				}
			: {}),
	}
}

export function denormalizeDailyAllowance(da: DailyAllowance): DailyAllowanceRequest {
	return {
		expense_type: 'daily_allowance',
		category: da.category,
		attendees: da.attendees.map((attendee) => (attendee.id ? { id: attendee.id } : attendee)),
		expense_status: da.status,
		expense_report: da.report?.name || '',
		receipt_url: da.file?.url,
		file_data: da.file,
		currency: da.currency,
		destination_id: da.destination.id,
		is_travel_related: Boolean(Number(da.isItTravel)),
		daily_allowance_rates: da.daily_allowance_rates.map((rate) => ({
			...rate,
			amount: parseFloat((rate.number_of_days * rate.daily_allowance_rate.amount).toFixed(2)),
			rate_id: rate.daily_allowance_rate.id,
		})),
		amount: parseFloat(
			da.daily_allowance_rates
				.reduce((sum, rate) => sum + parseFloat((rate.number_of_days * rate.daily_allowance_rate.amount).toFixed(2)), 0)
				.toFixed(2),
		),
		submitter_id: da.submitter.id,
		start_date: moment(da.start_date).format('YYYY-MM-DD'),
		end_date: moment(da.end_date).format('YYYY-MM-DD'),
		start_time: getTimeObjectFromString(da.start_time)!.toTimeValueString(),
		end_time: getTimeObjectFromString(da.end_time)!.toTimeValueString(),
		description: da.description,
	}
}
