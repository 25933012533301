import { CreditCardResponse, Trip, TripPaymentMethodConfigPayload, VoidCallback } from 'src/travelsuit'

import { IAction } from './action-helpers'
import { TripPaymentMethodsTypes as Types } from './actions.types'

export const Actions = {
	getPaymentMethods(
		tripId: number,
		callback?: VoidCallback<CreditCardResponse[]>,
		errCallback?: VoidCallback<never>,
	): IAction {
		return {
			type: Types.GetPaymentMethods.REQUEST,
			payload: { tripId },
			callback,
			errCallback,
		}
	},
	updatePaymentMethod(
		tripId: number,
		tripPaymentMethodPayload: TripPaymentMethodConfigPayload,
		callback?: VoidCallback<Trip>,
		errCallback?: VoidCallback<never>,
	): IAction {
		return {
			type: Types.UpdatePaymentMethod.REQUEST,
			payload: { tripId, ...tripPaymentMethodPayload },
			callback,
			errCallback,
		}
	},
}
