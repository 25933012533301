import { ImmutableMap } from 'src/lib/immutable/ImmutableMap'
import { IAction } from 'src/redux/actions/action-helpers'
import { CarRatesTypes } from 'src/redux/actions/actions.types'
import { CarResult } from 'src/travelsuit'
import { CarRate } from 'src/travelsuit/cars.type'

export type CarRatesState = ImmutableMap<CarResult['res_id'], CarRate>
export const emptyCarRatesState: CarRatesState = new ImmutableMap()

export function carRatesReducer(state: CarRatesState = emptyCarRatesState, action: IAction): CarRatesState {
	let carResult: CarResult
	let carRate: CarRate

	switch (action.type) {
		case CarRatesTypes.GetCarRates.SUCCESS:
			carResult = action.requestPayload
			carRate = action.payload
			return state.set(carResult.res_id, carRate)
	}
	return state
}

export default carRatesReducer
