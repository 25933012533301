import { z } from 'zod'

export const BillingPackageIdZ = z.number().brand<'BillingPackageId'>()

export type BillingPackageId = z.infer<typeof BillingPackageIdZ>

export enum BillingType {
	CreditCard = 'credit_card',
	MonthlyCredit = 'monthly_credit',
}

export const BillingTypeZ = z.nativeEnum(BillingType)

export enum PackageStatus {
	ActiveSell = 'active_sell',
	Draft = 'draft',
	Pending = 'pending',
	Scheduled = 'scheduled',
	StopSell = 'stop_sell',
}

export const PackageStatusZ = z.nativeEnum(PackageStatus)

export enum FeatureScope {
	Free = 'FREE',
	TravelExpense = 'TRAVEL_EXPENSE',
	TravelOnly = 'TRAVEL_ONLY',
}

export const FeatureScopeZ = z.nativeEnum(FeatureScope)

export enum TripContentAllowance {
	Allowed = 'allowed',
	Default = 'default',
	NotAllowed = 'not_allowed',
}

export const TripContentAllowanceZ = z.nativeEnum(TripContentAllowance)

// TODO: Add PricingModel schema
export const BillingPackageZ = z.object({
	id: BillingPackageIdZ,
	name: z.string(),
	comments: z.string().nullable(),
	start_date: z.string(),
	previous_package_id: z.number().nullable(),
	status: PackageStatusZ,
	original_package_id: z.number().nullable(),
	feature_scope: FeatureScopeZ,
	stop_sell_date: z.string().nullable(),
	replaced_by_id: z.number().nullable(),
	pricing_models: z.array(z.unknown()), // PricingModel[]
	migrate_clients: z.boolean().nullable(),
})

export type BillingPackage = z.infer<typeof BillingPackageZ>

export const GetBillingPackagesResponseZ = z.array(BillingPackageZ)
