import React from 'react'

import useTranslation from 'src/lib/i18n/UseTranslation'
import EmptyState from 'src/refactor/molecules/EmptyState/EmptyState'

interface IProps {
	resetFilters: () => void
}

const FeesEmptyState: React.FC<IProps> = ({ resetFilters }) => {
	const { t } = useTranslation()

	return (
		<EmptyState
			variant="handsEyes"
			title={t('usage-details.fees.empty-state.title', '"None of your fees match the filtering criteria"')}
			subtitle={t('usage-details.fees.empty-state.subtitle', 'Please remove or change some filters to show results')}
			actionLabel={t('usage-details.fees.empty-state.button', 'Reset Filters')}
			actionHandler={resetFilters}
		/>
	)
}

export default FeesEmptyState
