import { createApiHookWithLoadingState } from 'src/lib/react-hooks/generic-hooks'
import { PackagesAndBillingSettingsActions, PackagesAndBillingSettingsTypes } from 'src/redux/actions'
import { PackagesAndBillingSettingsState } from 'src/redux/reducers/packages-and-billing-settings.reducer'

export const useLoadPackagesAndBillingSettings = createApiHookWithLoadingState<{}, PackagesAndBillingSettingsState>({
	loadAction: (_, { onLoad, onError }) =>
		PackagesAndBillingSettingsActions.getPackagesAndBillingSettings(onLoad, onError),
	loadingSelectors: PackagesAndBillingSettingsTypes.GetPackagesAndBillingSettings,
	storeMapper: ({ packagesAndBillingSettings }) => packagesAndBillingSettings,
})
