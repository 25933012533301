import React from 'react'

import { useTranslation } from 'src/lib/i18n/i18n'
import { P } from 'src/molecules/ItineraryStatusBox/ItineraryStatusBox.components'

import { getDeclinedPaymentErrorTextParts } from './getDeclinedPaymentErrorTextParts'

export function DeclinedPaymentBookingsContent() {
	const { t } = useTranslation()

	const [declinedTransaction, verifyInformation, contactBank] = getDeclinedPaymentErrorTextParts(t)

	return (
		<>
			{[
				{ e2e: 'DeclinedPaymentBookingsContent.DeclinedTransaction', text: declinedTransaction },
				{ e2e: 'DeclinedPaymentBookingsContent.VerifyInformation', text: verifyInformation },
				{ e2e: 'DeclinedPaymentBookingsContent.ContactBank', text: contactBank },
			].map(({ e2e, text }, index) => (
				<P key={index} data-test={e2e}>
					{text}
				</P>
			))}
		</>
	)
}
