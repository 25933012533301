import React from 'react'

import useTranslation from 'src/lib/i18n/UseTranslation'
import { Fee, UserFee } from 'src/travelsuit/fees.types'
import { fullName } from 'src/travelsuit/users.utils'

import BaseFeeCard from './BaseFeeCard'

interface IProps {
	fee: Fee<UserFee>
	onFeesReload: () => void
}

const UserFeeCard: React.FC<IProps> = ({ fee }) => {
	const { t } = useTranslation()
	const { trip_id, request_by_user, is_active } = fee[fee.service_type]

	const country = request_by_user.invoice_profile!.country!

	return (
		<BaseFeeCard
			fee={fee}
			country={country}
			secondaryChipText={is_active ? t('usage-details.fees.trip-id', 'Trip ID - {{tripId}}', { trip_id }) : undefined}
			primaryInfo={fullName(request_by_user)}
			invoiceProfileName={request_by_user.invoice_profile?.display_name || ''}
		/>
	)
}

export default UserFeeCard
