import React from 'react'
import { Redirect, Route } from 'react-router'

import PrivateRoute from 'src/atoms/PrivateRoute/PrivateRoute'
import { Routes } from 'src/lib/route-utils'

import { RestrictedMobileAppContent } from './RestrictedMobileAppContent'

export function RestrictedMobileAppRoute(props: React.ComponentProps<typeof PrivateRoute>) {
	return (
		<RestrictedMobileAppContent fallback={<Route {...props} render={() => <Redirect to={Routes.Home} />} />}>
			<PrivateRoute {...props} />
		</RestrictedMobileAppContent>
	)
}
