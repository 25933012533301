import React from 'react'

import { white } from 'src/_vars'
import { Spacer } from 'src/atoms/GenericComponents/GenericComponents'
import { ButtonLoader } from 'src/atoms/Loader/Loader'
import { Tooltip } from 'src/atoms/Tooltip'
import { E2E } from 'src/lib/e2e-utils'
import { Trans } from 'src/lib/i18n/i18n'
import { useStickyBottomPanel } from 'src/lib/react-hooks/useStickyBottomPanel'
import { scrollTo } from 'src/lib/utils'
import { DefaultElProps, GroupTypes } from 'src/travelsuit'
import { TravelPolicy } from 'src/travelsuit/policy'

import {
	BottomRow,
	EditButton,
	PolicyBox,
	PolicyContentContainer,
	PolicyPageTitle,
	PolicySettingsContainer,
	PolicySummaryContainer,
	SaveButton,
} from './PolicySettings.components'

export interface PolicySettingCommonProps<T> extends Omit<DefaultElProps<'div'>, 'onChange'> {
	policy: TravelPolicy
	isSaving: boolean
	expanded: boolean
	visible: boolean
	groupType: GroupTypes
	onExpand?(state: boolean): void
	onSave?(changes: T): void
	disableSave?: boolean
	e2eForTitle?: string
	saveButtonTooltip?: string
}

interface IProps extends E2E, Omit<PolicySettingCommonProps<never>, 'policy' | 'title'> {
	title: React.ReactNode
	summary: React.ReactNode
	isViewOnly?: boolean
}

const PolicySettings: React.FunctionComponent<IProps> = (props) => {
	const {
		className,
		children,
		title,
		summary,
		isSaving,
		disableSave,
		onSave,
		expanded,
		visible,
		onExpand,
		e2e,
		e2eForTitle,
		isViewOnly,
		saveButtonTooltip,
	} = props

	const { containerRef, panelRef, panelStyle, placeholder } = useStickyBottomPanel()

	return (
		<PolicySettingsContainer ref={containerRef} e2e={e2e} className={className}>
			<PolicyBox expanded={expanded} visible={visible}>
				<PolicyPageTitle e2e={e2eForTitle}>{title}</PolicyPageTitle>
				{expanded ? (
					<PolicyContentContainer>
						{children}
						<Spacer />
						{!isViewOnly && (
							<BottomRow ref={panelRef} style={{ background: white, ...panelStyle }}>
								<Tooltip title={saveButtonTooltip}>
									<SaveButton e2e="Company.Policy.Save.Button" disabled={disableSave ?? isSaving} onClick={onSave}>
										{!isSaving ? <Trans i18nKey="policy-settings.save">Save</Trans> : <ButtonLoader color="contrast" />}
									</SaveButton>
								</Tooltip>
							</BottomRow>
						)}
						{placeholder}
					</PolicyContentContainer>
				) : (
					<PolicySummaryContainer>
						{summary}
						<Spacer />
						<BottomRow>
							<EditButton
								e2e={'PolicySettings.EditButton'}
								onClick={() => {
									onExpand?.(!expanded)
									scrollTo({ y: 0 })
								}}
							>
								<Trans i18nKey="policy-settings.edit">Edit</Trans>
							</EditButton>
						</BottomRow>
					</PolicySummaryContainer>
				)}
			</PolicyBox>
		</PolicySettingsContainer>
	)
}

export default PolicySettings
