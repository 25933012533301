import { MileageRatesTypes } from 'src/redux/actions/actions.types'
import { denormalizeRate, normalizeRate } from 'src/redux/reducers/mileage-rates.reducer'
import { Callback, VoidCallback } from 'src/travelsuit'
import { MileageRate, MileageRateApi } from 'src/types/expenses'

export const Actions = {
	getRates(callback?: VoidCallback<MileageRate[]>) {
		return {
			type: MileageRatesTypes.GetRates.REQUEST,
			callback: (rates: MileageRateApi[]) => callback?.(rates.map(normalizeRate)),
		}
	},
	updateRate(mileageRate: MileageRate, callback?: VoidCallback<MileageRate>) {
		const rate = denormalizeRate(mileageRate)
		return {
			type: MileageRatesTypes.UpdateRate.REQUEST,
			payload: { id: rate.id },
			body: rate,
			callback,
		}
	},
	createRate(mileageRate: MileageRate, callback?: VoidCallback<MileageRate>) {
		const rate = denormalizeRate(mileageRate)
		return {
			type: MileageRatesTypes.CreateRate.REQUEST,
			body: rate,
			callback,
		}
	},
	deleteRate(mileageRate: MileageRate, callback?: Callback) {
		return {
			type: MileageRatesTypes.DeleteRate.REQUEST,
			payload: { id: mileageRate.id },
			callback,
		}
	},
}
