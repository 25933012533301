const predictionsCache: Record<string, google.maps.places.AutocompletePrediction[]> = {}
const PLACES_TYPES_TO_SEARCH = ['geocode', 'establishment']
const PLACES_TYPES_TO_FILTER = ['country']

export function generateSessionToken() {
	try {
		return new google.maps.places.AutocompleteSessionToken()
	} catch (e) {
		return (Math.random() * 100000).toFixed(0)
	}
}

const filterCountries = (places: google.maps.places.AutocompletePrediction[]) =>
	places.filter((place) => place.types && !place.types.some((type) => PLACES_TYPES_TO_FILTER.includes(type)))

export async function searchAddress(
	input: string,
	sessionToken: google.maps.places.AutocompleteSessionToken,
	opts?: { countryCode?: string },
) {
	if (!input.length) {
		return []
	}

	const cacheKey = `${opts?.countryCode?.trim().toLowerCase() ?? '_'}:${input.trim().toLocaleLowerCase()}`
	const service = new google.maps.places.AutocompleteService()
	const request = {
		input,
		types: PLACES_TYPES_TO_SEARCH,
		sessionToken,
		componentRestrictions: opts?.countryCode ? { country: opts.countryCode } : undefined,
	} as google.maps.places.AutocompletionRequest

	if (predictionsCache.hasOwnProperty(cacheKey)) {
		return predictionsCache[cacheKey]
	}

	return new Promise<google.maps.places.AutocompletePrediction[]>((resolve) => {
		try {
			service.getPlacePredictions(request, (results) => {
				if (results) {
					const filteredResults = filterCountries(results)
					predictionsCache[cacheKey] = filteredResults
					resolve(filteredResults)
				} else {
					resolve([])
				}
			})
		} catch (e) {
			resolve([])
			throw e
		}
	})
}

export async function getPlaceDetails(
	placeId: string,
	autocompleteSessionToken: google.maps.places.AutocompleteSessionToken,
) {
	const service = new google.maps.places.PlacesService(document.createElement('div'))
	return new Promise<google.maps.places.PlaceResult | null>((resolve, reject) => {
		try {
			service.getDetails(
				{
					placeId,
					sessionToken: autocompleteSessionToken,
					// https://developers.google.com/maps/documentation/javascript/places#place_details_fields
					// If you do not specify at least one field with a request, or if you omit the fields parameter
					// from a request, ALL possible fields will be returned, and you will be billed accordingly.
					fields: [
						'address_components',
						'formatted_address',
						'geometry',
						'international_phone_number',
						'place_id',
						'type',
					],
				},
				(result) => {
					resolve(result)
				},
			)
		} catch (e) {
			reject(e)
		}
	})
}

export function getComponentByType(place: google.maps.places.PlaceResult, ...types: string[]) {
	return place.address_components?.find((ac) => ac.types.some((t) => types.includes(t)))
}

export function getCityName(place: google.maps.places.PlaceResult): string | undefined {
	return place.address_components?.find((adrComp) =>
		adrComp.types.some((type) => ['locality', 'administrative_area_level_3', 'postal_town'].includes(type)),
	)?.long_name
}

export function getCountryCode(place: google.maps.places.PlaceResult): string | undefined {
	return place.address_components?.find((adrComp) => adrComp.types.some((type) => type === 'country'))?.short_name
}

export function getStateName(place: google.maps.places.PlaceResult): string | undefined {
	return place.address_components?.find((adrComp) =>
		adrComp.types.some((type) => type === 'administrative_area_level_1'),
	)?.long_name
}

export function getAddressDetails(place: google.maps.places.PlaceResult) {
	return {
		building: getComponentByType(place, 'subpremise')?.long_name,
		city_name: getComponentByType(place, 'administrative_area_level_3', 'locality', 'postal_town')?.long_name,
		country_code: getComponentByType(place, 'country')?.short_name,
		district: getComponentByType(place, 'neighborhood')?.long_name,
		floor: getComponentByType(place, 'floor')?.long_name,
		postal_code: getComponentByType(place, 'postal_code')?.long_name,
		room: getComponentByType(place, 'room')?.long_name,
		state_code: getComponentByType(place, 'administrative_area_level_1')?.short_name,
		state_name: getComponentByType(place, 'administrative_area_level_1')?.long_name,
		street: getComponentByType(place, 'route')?.long_name,
		street_number: getComponentByType(place, 'street_number')?.long_name,
	}
}
