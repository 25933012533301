import { GroupTypes, User, VoidCallback } from 'src/travelsuit'
import {
	PartialPolicyWithID,
	TravelPolicy,
	TravelPolicyMinimal,
	TripTravelPolicy,
	UpdatePolicyParams,
} from 'src/travelsuit/policy'

import { IAction } from './action-helpers'
import { TravelPolicyTypes as Types } from './actions.types'

function preparePolicyJson({
	approvers,
	users,
	pre_trip_policy_data,
	...data
}: PartialPolicyWithID): UpdatePolicyParams {
	const policy_data = { ...pre_trip_policy_data! }
	delete policy_data.pre_trip_custom_approvers
	delete policy_data.pre_trip_fallback_approvers
	if (Object.keys(policy_data).length > 0) {
		return {
			...data,
			pre_trip_policy_data: policy_data,
			approver_ids: approvers ? approvers.map((user) => user.id!) : undefined,
			user_ids: users ? users.map((user) => user.id!) : undefined,
			pre_trip_custom_approver_ids: pre_trip_policy_data?.pre_trip_custom_approvers?.map((user) => user.id!),
			pre_trip_fallback_approver_ids: pre_trip_policy_data?.pre_trip_fallback_approvers?.map((user) => user.id!),
		}
	} else {
		return {
			...data,
			approver_ids: approvers ? approvers.map((user) => user.id!) : undefined,
			user_ids: users ? users.map((user) => user.id!) : undefined,
			pre_trip_custom_approver_ids: pre_trip_policy_data?.pre_trip_custom_approvers?.map((user) => user.id!),
			pre_trip_fallback_approver_ids: pre_trip_policy_data?.pre_trip_fallback_approvers?.map((user) => user.id!),
		}
	}
}

export const Actions = {
	getTravelPolicy(id: number, callback?: VoidCallback<TravelPolicy>, errCallback?: VoidCallback): IAction {
		return {
			type: Types.GetPolicy.REQUEST,
			payload: id,
			callback,
			errCallback,
		}
	},
	getAllTravelPolicies(callback?: VoidCallback<TravelPolicyMinimal[]>, errCallback?: VoidCallback): IAction {
		return {
			type: Types.GetAllPolicies.REQUEST,
			callback,
			errCallback,
		}
	},
	getTravelPolicyByType(
		type: GroupTypes | null,
		callback?: VoidCallback<TravelPolicyMinimal[]>,
		errCallback?: VoidCallback,
	): IAction {
		return {
			type: Types.GetPoliciesByType.REQUEST,
			payload: type,
			callback,
			errCallback,
		}
	},
	updateTravelPolicy(
		type: GroupTypes,
		policyData: PartialPolicyWithID,
		callback?: VoidCallback<TravelPolicy>,
		errCallback?: VoidCallback,
	): IAction {
		return {
			type: Types.UpdatePolicy.REQUEST,
			body: preparePolicyJson(policyData),
			payload: { type, group_id: policyData.group_id },
			callback,
			errCallback,
		}
	},
	createCustomPolicy(
		sourcePolicyToDuplicate: number,
		policyName: string,
		users: User[],
		callback?: VoidCallback<TravelPolicy>,
		errCallback?: VoidCallback,
	): IAction {
		return {
			type: Types.CreateCustomPolicy.REQUEST,
			payload: {
				source_group_id: sourcePolicyToDuplicate,
				target_group_name: policyName,
				user_ids: users.map((u) => u.id),
			},
			callback,
			errCallback,
		}
	},
	createOfficePolicy(
		sourcePolicyToDuplicate: number,
		targetGroupForPolicy: number,
		callback?: VoidCallback<TravelPolicy>,
		errCallback?: VoidCallback,
	): IAction {
		return {
			type: Types.CreateOfficePolicy.REQUEST,
			payload: {
				source_group_id: sourcePolicyToDuplicate,
				target_group_id: targetGroupForPolicy,
			},
			callback,
			errCallback,
		}
	},
	deletePolicy(groupIdForPolicy: number, callback?: VoidCallback<never>, errCallback?: VoidCallback): IAction {
		return {
			type: Types.DeletePolicy.REQUEST,
			payload: {
				groupIdForPolicy,
			},
			callback,
			errCallback,
		}
	},
	getIgnoredUsers(callback?: VoidCallback<User[]>, errCallback?: VoidCallback): IAction {
		return {
			type: Types.GetIgnoredUsers.REQUEST,
			callback,
			errCallback,
		}
	},
	updateIgnoredUsers(users: User[], callback?: VoidCallback<never>, errCallback?: VoidCallback): IAction {
		return {
			type: Types.UpdateIgnoredUsers.REQUEST,
			payload: {
				user_ids: users.map((u) => u.id!).filter(Boolean),
			},
			callback,
			errCallback,
		}
	},
	getPolicyForUsers(users: User[], callback?: VoidCallback<TripTravelPolicy[]>, errCallback?: VoidCallback): IAction {
		return {
			type: Types.GetPolicyForUsers.REQUEST,
			payload: {
				user_ids: users.map((u) => u.id!).filter(Boolean),
			},
			callback,
			errCallback,
		}
	},
}
