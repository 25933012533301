import { z } from 'zod'

export const AbsoluteURLZ = z.string().brand<'AbsoluteURL'>()

export type AbsoluteURL = z.infer<typeof AbsoluteURLZ>

export enum Currency {
	AUD = 'AUD',
	EUR = 'EUR',
	GBP = 'GBP',
	ILS = 'ILS',
	SEK = 'SEK',
	USD = 'USD',
}

export const CurrencyZ = z.nativeEnum(Currency)

export const CurrencyObjectZ = z.object({
	currency_code: CurrencyZ,
	amount: z.string(),
})

export type CurrencyObject = z.infer<typeof CurrencyObjectZ>

export const EmailZ = z.string().email()

export type Email = z.infer<typeof EmailZ>

export enum Gender {
	Female = 'female',
	Male = 'male',
}

export const GenderZ = z.nativeEnum(Gender)

export const ISOCountry2Z = z.string().brand<'ISOCountry2'>()

export type ISOCountry2 = z.infer<typeof ISOCountry2Z>

export const ISODateStringZ = z.string().datetime({ offset: true })

export type ISODateString = z.infer<typeof ISODateStringZ>

export const OriginalAmountZ = z.strictObject({
	amount: z.string(),
	currency: CurrencyZ,
	billing_date: ISODateStringZ.nullable(),
})

export type OriginalAmount = z.infer<typeof OriginalAmountZ>

export const PricesBucketsZ = z.object({
	0: z.number().nullable(),
	25: z.number().nullable(),
	50: z.number().nullable(),
	75: z.number().nullable(),
	100: z.number().nullable(),
})

export type PricesBuckets = z.infer<typeof PricesBucketsZ>

export const PricesMetadataZ = z
	.object({
		total: PricesBucketsZ,
	})
	.and(z.record(z.number().or(z.string()), PricesBucketsZ))

export type PricesMetadata = z.infer<typeof PricesMetadataZ>

export const RelativeURLZ = z.string().brand<'RelativeURL'>()

export type RelativeURL = z.infer<typeof RelativeURLZ>

export const ShortDistanceUnitsZ = z.enum(['KM', 'MI'])
export type ShortDistanceUnits = z.infer<typeof ShortDistanceUnitsZ>

export const TimezoneZ = z.string().brand<'Timezone'>()

export type Timezone = z.infer<typeof TimezoneZ>
