import { Callback } from 'src/travelsuit'

import { IAction } from './action-helpers'
import { EssentialTravelInformationTypes as Types } from './actions.types'

export const Actions = {
	getEssentialTravelInformation(tripId: number, callback?: Callback, errCallback?: Callback): IAction {
		return {
			type: Types.GetEssentialTravelInformation.REQUEST,
			callback,
			errCallback,
			payload: { tripId },
		}
	},
}
