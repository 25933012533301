import { z } from 'zod'

export const BookingSegmentIdZ = z.number().brand<'BookingSegmentId'>()

export type BookingSegmentId = z.infer<typeof BookingSegmentIdZ>

export const BSTTIdZ = z.number().brand<'BSTTId'>()

export type BSTTId = z.infer<typeof BSTTIdZ>

export const TripIdZ = z.number() // .brand<'TripId'>()

export type TripId = z.infer<typeof TripIdZ>

/** Used on search, corresponds to BookingSegmentProductType on the backend */
export enum ProductType {
	Flights = 'flights',
	Hotels = 'hotels',
	Cars = 'cars',
	Rails = 'rails',
}

export const ProductTypeZ = z.nativeEnum(ProductType)

export enum TripDirection {
	OneWay = 'one_way',
	RoundTrip = 'round_trip',
	MultiDestination = 'multi_destination',
}

export const TripDirectionZ = z.nativeEnum(TripDirection)

export enum LocationTypes {
	Home = 'home',
	Office = 'office',
	Client = 'client',
	Hotel = 'hotel',
	Airport = 'airport',
	Other = 'other',
	City = 'city',
	Country = 'country',
	CarRentAgency = 'car_rent_agency',
	TrainStation = 'train',
	Rail = 'rail',
}

export const LocationTypesZ = z.nativeEnum(LocationTypes)

export const LocationZ = z.object({
	id: z.string().or(z.number()).nullable(),
	external_id: z.string().nullable().optional(),
	name: z.string(),
	location_type: LocationTypesZ,
})
