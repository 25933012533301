import { init, reactRouterV5Instrumentation } from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { appConfig } from 'src/app-config/appConfig'
import { CURRENT_VERSION_HASH, DeployEnv, ENV } from 'src/lib/env'
import { matchPath, Routes } from 'src/lib/route-utils'

export function initializeErrorTracking() {
	init({
		dsn: appConfig.SENTRY_DSN,
		release: `travelsuit-webapp@${CURRENT_VERSION_HASH}`,
		environment: [DeployEnv.production, DeployEnv.staging].includes(appConfig.DEPLOY_ENV)
			? appConfig.DEPLOY_ENV
			: DeployEnv.develop,
		integrations: [
			new Integrations.BrowserTracing({
				routingInstrumentation: reactRouterV5Instrumentation(
					history,
					// @ts-expect-error todo if you see this please remove this comment and fix the type error, thank you :)
					Object.keys(Routes).map((e) => ({ path: Routes[e] })),
					matchPath,
				),
			}),
		],
		debug: ENV.IS_DEBUG,
		sampleRate: appConfig.SENTRY_SAMPLE_RATE,
	})
}
