import { createLoadOnceAPIHook } from 'src/lib/react-hooks/generic-hooks'
import { CountryPOSList, UserPOS, UserPOSActions } from 'src/redux/actions'
import { countryPosState } from 'src/redux/reducers/country-pos.reducer'
import { userPosState } from 'src/redux/reducers/user-pos.reducer'

export const useUserPOS = createLoadOnceAPIHook<{}, userPosState>({
	loadAction: (_, { onLoad, onError }) => UserPOSActions.getUserPOS(onLoad, onError),
	isEmpty: (userPOS) => !userPOS,
	loadingSelectors: UserPOS.GetUserPOS,
	storeMapper: ({ userPOS }) => userPOS,
})

export const useCountryPOS = createLoadOnceAPIHook<[], countryPosState>({
	loadAction: (_, { onLoad, onError }) => UserPOSActions.getCountryPOSList(onLoad, onError),
	isEmpty: (POSList) => !POSList.length,
	loadingSelectors: CountryPOSList.GetCountryPOSList,
	storeMapper: ({ posList }) => posList,
})
