import React from 'react'

import { countryFlag } from 'src/assets/countryFlags'
import { LanguageSelector } from 'src/atoms/LanguageSelector'
import { Flag } from 'src/atoms/LanguageSelector/LanguageSelector.components'
import { useTranslation } from 'src/lib/i18n/i18n'
import { getRegionFromLocale } from 'src/lib/locale-utils'
import { AppBarCollapse } from 'src/organisms/AppBar/AppBar.components'

import { useLanguageSwitcher } from './LanguageSwitcher.hooks'

export const LanguageSwitcherMobile: React.FC = () => {
	const { t } = useTranslation()
	const { onChange, countryLanguages, locale } = useLanguageSwitcher()
	const country = getRegionFromLocale(locale)

	return (
		<AppBarCollapse
			title={
				<>
					{/*@ts-expect-error todo if you see this please remove this comment and fix the type error, thank you :)*/}
					<Flag src={countryFlag.svgUrl[country]} />
					{t('locale-selector.locale', 'Language')}
				</>
			}
		>
			<LanguageSelector availableLanguages={countryLanguages} language={locale} onChange={onChange} />
		</AppBarCollapse>
	)
}
