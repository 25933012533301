import axios from 'axios'
import { useDispatch } from 'react-redux'

import { appConfig } from 'src/app-config/appConfig'
import { useAsyncAction } from 'src/lib/react-hooks/useAsyncAction'
import { setVerificationRedirectData } from 'src/lib/redirect-utils'
import { redirectTo3DSVerification } from 'src/lib/redirectTo3DSVerification'
import { enrichWithBrowserData } from 'src/lib/requests/enrichWithBrowserData'
import { loadData } from 'src/lib/requests/loadData'
import { MyCompanyActions } from 'src/redux/actions'
import { WorldlineOrder, WorldlineOrderStatus } from 'src/travelsuit/worldline'

import { ApiPaymentDeclinedError } from './ApiPaymentDeclinedError'

async function pollFeeStatus(feeId: number) {
	let data: WorldlineOrder | null = null

	// eslint-disable-next-line no-constant-condition
	while (true) {
		data = (
			await loadData<WorldlineOrder>({
				resourcePath: `/fee/${feeId}/status`,
			})
		).data

		if (data?.status !== WorldlineOrderStatus.Draft) {
			break
		}
		await new Promise((resolve) => setTimeout(resolve, 1000))
	}
	return data!
}

const usePayAgainFee = (loadFees: () => void) => {
	const { isRequestInProgress, performAsyncAction } = useAsyncAction()
	const dispatch = useDispatch()

	const handlePaymentRequest = async (feeId: number, creditCardId: number) => {
		const data = {
			credit_card_id: creditCardId,
		}

		try {
			await performAsyncAction(() =>
				axios.post<{}>(`${appConfig.API_DOMAIN}/fee/${feeId}/charge`, enrichWithBrowserData(data)),
			)
		} catch (error) {
			if (error.response?.data?.reason === 'worldline') {
				throw new ApiPaymentDeclinedError()
			}
			throw error
		}

		const order = await performAsyncAction(() => pollFeeStatus(feeId))

		if (order.status === WorldlineOrderStatus.Redirected) {
			setVerificationRedirectData<{ fee_id: number }>({
				returnToUrl: window.location.pathname,
				data: { fee_id: feeId },
			})
			redirectTo3DSVerification(order.redirect_url!)
			return
		}

		if (order.status === WorldlineOrderStatus.CaptureFailed) {
			throw new ApiPaymentDeclinedError('payment declined')
		}

		dispatch(MyCompanyActions.getMyCompany())
		loadFees()
	}

	return {
		isRequestInProgress,
		handlePaymentRequest,
	}
}

export default usePayAgainFee
