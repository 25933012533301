import { replaceInArrayByIndex } from '../replaceInArrayByIndex'
import { addEntity } from './addEntity'
import { ID, Identifiable, Optional } from './types'

export function replaceEntity<T extends Identifiable>(
	entities: Optional<T[]>,
	entityId: ID,
	entityForReplacement: T,
	getEntityId = (entity: T) => entity.id,
) {
	entities = entities ?? []
	const entityIndex = entities.findIndex((entity) => getEntityId(entity) === entityId)
	if (entityIndex === -1) {
		return addEntity(entities, entityForReplacement)
	}

	return replaceInArrayByIndex(entities, entityIndex, entityForReplacement)
}
