import React, { Component, ComponentType } from 'react'

import { debounced } from 'src/lib/utils'

export function withWindowResize<T>(WrappedComponent: ComponentType<T>, debounceTime = 400): ComponentType<T> {
	class WindowResizeComponent extends Component<T> {
		public componentDidMount() {
			window.addEventListener('resize', this.onWindowResize)
		}

		public componentWillUnmount() {
			window.removeEventListener('resize', this.onWindowResize)
		}

		@debounced(debounceTime)
		private onWindowResize() {
			this.forceUpdate()
		}

		render() {
			return <WrappedComponent {...this.props} />
		}
	}

	return WindowResizeComponent
}
