import { SelectedDocuments } from 'src/travelsuit'

import { SelectedDocumentsForTrip } from '../actions'
import { IAction } from '../actions/action-helpers'

export type SelectedDocumentsForTripsState = Record<string | number, SelectedDocuments[]>

export function selectedDocumentsForTripsReducer(state: SelectedDocumentsForTripsState = {}, action: IAction) {
	switch (action.type) {
		case SelectedDocumentsForTrip.SetSelectedDocuments:
			return { ...state, ...action.payload }
	}

	return state
}
