import { IAction } from 'src/redux/actions/action-helpers'
import { CustomerSuccessConsultantTypes } from 'src/redux/actions/actions.types'
import { CustomerSuccessConsultant } from 'src/travelsuit'

export type CustomerSuccessConsultantState = CustomerSuccessConsultant | null

export function customerSuccessConsultantReducer(
	state: CustomerSuccessConsultantState = null,
	action: IAction,
): CustomerSuccessConsultantState {
	switch (action.type) {
		case CustomerSuccessConsultantTypes.GetCustomerSuccessConsultant.SUCCESS:
			return action.payload
	}
	return state
}
