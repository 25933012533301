import useTranslation from 'src/lib/i18n/UseTranslation'
import withTranslation from 'src/lib/i18n/WithTranslation'

import { TFunction as TFunctionHelper, WithTranslation as WithTranslationHelper } from './i18n.helper'
import Trans from './Trans'

export interface TFunction extends TFunctionHelper {}
export interface WithTranslation extends WithTranslationHelper {}

export { Trans, withTranslation, useTranslation }
