import { Auth0DecodedHash, WebAuth } from 'auth0-js'
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import jwtDecode from 'jwt-decode'
import moment from 'moment'

import { appConfig } from 'src/app-config/appConfig'
import { isIE } from 'src/lib/browser'
import history from 'src/lib/history'
import { dumpQueryString, parseQueryString } from 'src/lib/utils'
import { AuthActions, RootActions } from 'src/redux/actions'
import { AuthState, clearLocalStorage } from 'src/redux/reducers/auth.reducer.helpers'
import { getStore, getStoreState } from 'src/redux/stores'
import { HTTPMethod, LoginDetails, LoginStatus } from 'src/travelsuit'
import { setPermissionsWhenUserLogsIn } from 'src/travelsuit/permissions'
import { dispatchUserInfo } from 'src/travelsuit/users'

import { getRefreshTokenFromStorage } from './getRefreshTokenFromStorage'
import { ImmutableMap } from './immutable/ImmutableMap'
import { loadData } from './requests'
import { redirectToLogin } from './route-utils'

let tokenTimeout: number
let authProvider: WebAuth

const getAccessToken = () => localStorage.getItem('access_token')

export function initializeAuthProvider() {
	authProvider = new WebAuth({
		audience: appConfig.AUTH0_AUDIENCE,
		clientID: appConfig.AUTH0_CLIENT_ID,
		domain: appConfig.AUTH0_DOMAIN,
		redirectUri: appConfig.AUTH0_CALLBACK_URL,
		responseType: 'token id_token',
		scope: 'openid profile email user_metadata app_metadata offline_access',
	})
}

let timerModal: NodeJS.Timeout | null = null
let timerSessionLogout: NodeJS.Timeout | null = null
let timerCheckSession: NodeJS.Timeout | null = null

export const getMillisecondsToExpire = (expiresAt: Date) => {
	const timezoneOffset = new Date().getTimezoneOffset()
	const now = moment(new Date()).add(timezoneOffset, 'minutes').utc()

	return moment(expiresAt).diff(now)
}

function setAuthHeader({
	config,
	accessToken,
}: {
	config: { headers?: { Authorization: string } }
	accessToken: string
}) {
	if (config.headers) {
		config.headers.Authorization = `Bearer ${accessToken}`
	}
}

function onAuthError() {
	clearUserSession(false)
	redirectToLogin()
}

export function calculateExpirationTime({ expiresIn }: { expiresIn: number }) {
	return expiresIn * 1000 + Date.now()
}

export function persistAuthInfoInStorage(authResult: Required<auth0.Auth0DecodedHash>) {
	localStorage.setItem('expires_at', String(calculateExpirationTime(authResult)))
	localStorage.setItem('id_token', authResult.idToken)
	localStorage.setItem('access_token', authResult.accessToken)
	if (authResult.refreshToken) {
		localStorage.setItem('refresh_token', authResult.refreshToken)
	}
}

type AxiosAuthConfig = AxiosRequestConfig & { _authAttempt?: number }

export function createGetGoingAuthRequestInterceptor() {
	return function getGoingAuthRequestInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
		const accessToken = getAccessToken()

		if (accessToken) {
			setAuthHeader({ config, accessToken })
		}

		return config
	}
}

export function createGetGoingAuthFailedResponseInterceptor(client: AxiosInstance = axios) {
	return async function getGoingAuthFailedResponseInterceptor(error: AxiosError) {
		if (error.response) {
			if (error.response.status !== 401) {
				throw error
			}

			if ('_authAttempt' in error.config) {
				const authAttempt = (error.config as AxiosAuthConfig)._authAttempt
				if (authAttempt && authAttempt >= 3) {
					throw error
				}
			}

			const refreshToken = getRefreshTokenFromStorage()

			if (!refreshToken) {
				onAuthError()
				throw error
			}

			const { accessToken } = await waitAccessTokenRefresh(refreshToken)
			const retryRequestConfig: AxiosAuthConfig = {
				...error.config,
				headers: { ...error.config.headers },
				_authAttempt: ((error.config as AxiosAuthConfig)._authAttempt ?? 0) + 1,
			}
			setAuthHeader({ config: retryRequestConfig, accessToken })

			return client.request(retryRequestConfig)
		}

		throw error
	}
}

axios.interceptors.request.use((config) => {
	config.validateStatus = (status: number) => status >= 200 && status < 400
	return config
})
axios.interceptors.request.use(createGetGoingAuthRequestInterceptor())

axios.interceptors.response.use(undefined, createGetGoingAuthFailedResponseInterceptor())

let exchangeRefreshTokenPromise: Promise<{ accessToken: string }> | undefined = undefined

function waitAccessTokenRefresh(refreshToken: string) {
	exchangeRefreshTokenPromise =
		exchangeRefreshTokenPromise ??
		new Promise<{ accessToken: string }>((resolve, reject) => {
			authProvider.client.oauthToken(
				{
					grantType: 'refresh_token',
					refreshToken,
				},
				(err, result) => {
					if (err) {
						onAuthError()
						reject(err)
						return
					}

					persistAuthInfoInStorage(result)
					scheduleProviderAuthRenewal(calculateExpirationTime(result))

					const accessToken = result.accessToken

					getStore().dispatch(AuthActions.internalLogin(() => resolve({ accessToken }), reject))
				},
			)
		}).finally(() => {
			exchangeRefreshTokenPromise = undefined
		})

	return exchangeRefreshTokenPromise
}

function scheduleAuthSessionInfoRefresh() {
	getStore().dispatch(AuthActions.getSessionInfo(setTimerCheckSessionInfo))
}

function setTimerCheckSessionInfo() {
	const state = getStoreState()
	const sessionInfo = state.auth.get('sessionInfo')
	const minPeriod = 30000
	const period = Math.max(
		minPeriod,
		(getMillisecondsToExpire(sessionInfo?.expires_at) - appConfig.TIME_TO_NOTIFY_SESSION_EXPIRATION) / 2,
	)

	const refreshToken = getRefreshTokenFromStorage()

	if (refreshToken) {
		timerCheckSession = setTimeout(async () => {
			await waitAccessTokenRefresh(refreshToken)
			scheduleAuthSessionInfoRefresh()
		}, period)

		return
	}

	setTimerShowModal()
	setTimerLogout()

	timerCheckSession = setTimeout(async () => {
		scheduleAuthSessionInfoRefresh()
	}, period)
}

export function setTimerShowModal() {
	const state = getStoreState()
	const sessionInfo = state.auth.get('sessionInfo')
	const period = getMillisecondsToExpire(sessionInfo?.expires_at) - appConfig.TIME_TO_NOTIFY_SESSION_EXPIRATION

	if (timerModal) {
		clearTimeout(timerModal)
	}

	timerModal = setTimeout(() => {
		getStore().dispatch(AuthActions.showModal())
	}, period)
}

export function setTimerLogout() {
	const state = getStoreState()
	const sessionInfo = state.auth.get('sessionInfo')
	const showModal = state.auth.get('showModal')
	const period = getMillisecondsToExpire(sessionInfo?.expires_at)

	if (timerSessionLogout) {
		clearTimeout(timerSessionLogout)
	}

	timerSessionLogout = setTimeout(() => {
		getStore().dispatch(
			AuthActions.getSessionInfo((sessionInfo) => {
				if (
					showModal !== null &&
					sessionInfo.expires_at !== showModal &&
					getMillisecondsToExpire(sessionInfo.expires_at) > 0
				) {
					return
				}

				if (timerCheckSession) {
					clearTimeout(timerCheckSession)
				}

				if (getMillisecondsToExpire(sessionInfo.expires_at) <= 0) {
					requestProviderLogout()
				}
			}),
		)
	}, period)
}

export const clearTimers = () => {
	if (timerModal) {
		clearTimeout(timerModal)
	}
	if (timerSessionLogout) {
		clearTimeout(timerSessionLogout)
	}
	if (timerCheckSession) {
		clearTimeout(timerCheckSession)
	}
}

export const getAuthPreloadedState = (): AuthState => {
	const idToken = localStorage.getItem('id_token')
	const internalUser = localStorage.getItem('internal_user')
	const termsAccepted = localStorage.getItem('terms_accepted')

	if (internalUser) {
		dispatchUserInfo(JSON.parse(internalUser))
	}

	const expiresAt = Number(localStorage.getItem('expires_at'))
	if (!getRefreshTokenFromStorage() && moment(expiresAt).valueOf() < moment(new Date()).valueOf()) {
		return new ImmutableMap() as AuthState
	}

	scheduleProviderAuthRenewal(expiresAt)
	const accessToken = localStorage.getItem('access_token')
	return new ImmutableMap(
		Object.entries({
			user: idToken ? jwtDecode(idToken) : null,
			accessToken,
			idToken: localStorage.getItem('id_token'),
			expiresAt: expiresAt || undefined,
			internalUser: internalUser ? JSON.parse(internalUser) : null,
			termsAccepted: termsAccepted ? JSON.parse(termsAccepted) : null,
		}),
	) as AuthState
}

export async function providerLogin({
	username,
	password,
	shouldUseRefreshToken,
}: {
	username: string
	password: string
	shouldUseRefreshToken?: boolean
}): Promise<LoginDetails> {
	try {
		await clearUserSession(false)
		const authResult = await new Promise<Auth0DecodedHash>((resolve, reject) => {
			authProvider.client.login(
				{
					username,
					password,
					realm: appConfig.AUTH0_DB_CONNECTION,
				},
				(err, result) => (err ? reject(err) : resolve(result)),
			)
		})

		if (!shouldUseRefreshToken) {
			delete authResult.refreshToken
		}

		await saveUserSession(authResult)
		return await internalLogin()
	} catch (e) {
		clearUserSession(true)
		throw e
	}
}

export async function providerPwdReset(email: string, clearUserOnFailure = true) {
	try {
		const resetResult = await new Promise<string>((resolve, reject) => {
			authProvider.changePassword(
				{
					email,
					connection: appConfig.AUTH0_DB_CONNECTION,
				},
				(err, result) => (err ? reject(err) : resolve(result)),
			)
		})
		return resetResult
	} catch (e) {
		if (clearUserOnFailure) {
			clearUserSession(true)
		}
		throw e
	}
}

export async function internalLogin() {
	return new Promise<LoginDetails>((resolve, reject) => {
		getStore().dispatch(
			AuthActions.internalLogin((details) => {
				if (!details) {
					return
				}
				if (details.login_status === LoginStatus.UserExists) {
					setPermissionsWhenUserLogsIn()
				}

				scheduleAuthSessionInfoRefresh()

				return resolve(details)
			}, reject),
		)
	})
}

export async function tryHashQueryLogin() {
	if (history.location.hash) {
		const params = new URLSearchParams(history.location.hash.slice(1))

		const accessTokenValue = params.get('access_token')
		const idTokenValue = params.get('id_token')

		if (accessTokenValue && idTokenValue) {
			try {
				const accessToken = jwtDecode<{ exp: number }>(accessTokenValue)
				jwtDecode(idTokenValue)

				const expiresIn = accessToken ? Math.floor((accessToken.exp * 1000 - Date.now()) / 1000) : 0
				if (expiresIn > 0) {
					params.delete('access_token')
					params.delete('id_token')

					localStorage.removeItem('refresh_token')

					history.replace(`#${params.toString()}`)

					await saveUserSession({ accessToken: accessTokenValue, idToken: idTokenValue, expiresIn })
					await internalLogin()
				}
			} catch (err) {
				console.error('tryHashQueryLogin failed', err)
			}
		}
	}
}

export async function acceptTermsOfService() {
	const { data } = await loadData<LoginDetails>({ method: HTTPMethod.POST, resourcePath: 'accept_tos' })

	if (data.login_status === LoginStatus.UserExists) {
		const { user, ...rest } = data
		getStore().dispatch(AuthActions.setInternalUser(user, rest))
		getStore().dispatch(AuthActions.setTermsAccepted(true))
		setPermissionsWhenUserLogsIn()
	}
}

export function requestProviderLogout() {
	localStorage.removeItem('locale')
	clearTimers()

	loadData({ method: HTTPMethod.POST, resourcePath: 'logout' }).then(() => {
		if (getStoreState().auth.get('user')) {
			authProvider.logout({
				returnTo: `${window.location.protocol}//${window.location.host}/logout`,
			})

			clearUserSession(false)
		}
	})
}

function renewProviderAuth() {
	authProvider.checkSession({}, (err, result) => {
		if (!err) {
			return saveUserSession(result)
		}

		console.warn(err)
		return clearUserSession(true)
	})
}

export function scheduleProviderAuthRenewal(expiresAt: number) {
	const delay = (expiresAt || Date.now() - 1) - Date.now()

	if (delay <= 0) {
		return
	}

	window.clearTimeout(tokenTimeout)
	tokenTimeout = window.setTimeout(renewProviderAuth, delay)
}

async function saveUserSession(authResult: Auth0DecodedHash) {
	return new Promise((resolve) => {
		getStore().dispatch(
			AuthActions.finishLogin(authResult, () => {
				return resolve(authResult)
			}),
		)
	})
}

async function clearUserSession(fail: boolean) {
	if (fail) {
		getStore().dispatch(AuthActions.failLogin())
	}
	clearLocalStorage()
	clearTimeout(tokenTimeout)
	getStore().dispatch(
		RootActions.clearStore({
			auth: getAuthPreloadedState(),
		}),
	)
	return true
}

export async function handleUserReloadRequest() {
	const qs = parseQueryString(history.location.search)
	if (qs.reloadUser) {
		try {
			await internalLogin()
			const newQs = dumpQueryString({ ...qs, reloadUser: undefined })
			const hash = history.location.hash
			const trail = `${newQs ? '?' + newQs : ''}${hash ? '#' + hash : ''}`
			history.replace(history.location.pathname + trail)
		} catch (err) {
			console.error('handleUserReloadRequest', err)
		}
	}
}

function handleIE() {
	if (isIE) {
		requestProviderLogout()
	}
}

window.addEventListener('DOMContentLoaded', () => {
	handleIE()
})
