import { Callback } from 'src/travelsuit'

import { IAction } from './action-helpers'
import { PackagesUsagesTypes as Types } from './actions.types'

export const Actions = {
	getPackagesUsage(callback?: Callback, errCallback?: Callback): IAction {
		return {
			type: Types.GetUsageDetails.REQUEST,
			callback,
			errCallback,
		}
	},
}
