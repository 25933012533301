import { UserRole } from 'src/travelsuit'

import { IAction } from './action-helpers'
import { MyUserRolesTypes as Types } from './actions.types'

export const Actions = {
	getMyUserRoles(): IAction {
		return {
			type: Types.GetMyUserRoles.REQUEST,
		}
	},
	addToMyUserRoles(userRole: UserRole): IAction {
		return {
			type: Types.AddToMyUserRoles,
			payload: userRole,
		}
	},
	removeFromMyUserRoles(userRoleId: number): IAction {
		return {
			type: Types.RemoveFromMyUserRoles,
			payload: { userRoleId },
		}
	},
}
