import { UserRole } from 'src/travelsuit'

import { IAction } from '../actions/action-helpers'
import { AuthTypes, MyUserRolesTypes } from '../actions/actions.types'

export type MyUserRolesState = UserRole[]

function myUserRolesReducer(state: MyUserRolesState = [], action: IAction): MyUserRolesState {
	switch (action.type) {
		case MyUserRolesTypes.GetMyUserRoles.SUCCESS:
			return action.payload
		case MyUserRolesTypes.AddToMyUserRoles:
			return state.concat([action.payload])
		case MyUserRolesTypes.RemoveFromMyUserRoles:
			return state.filter((r) => r.id !== action.payload.userRoleId)
		case AuthTypes.LogoutDone:
			return []
	}
	return state
}

export default myUserRolesReducer
