import { font } from 'src/refactor/fonts'
import styled from 'src/styles'

export const Bold = styled.span`
	${font({ weight: 'bold' })}
`

export const P = styled.p`
	padding: 12px 0;

	&:last-child {
		padding-bottom: 0;
	}
`
