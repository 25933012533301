import { Company } from 'src/travelsuit'
import { CompanyZ } from 'src/types/company'

import { getGGClient, RequestOverrides } from './GetGoingClient'

export async function getMyCompany(overrides?: RequestOverrides) {
	return getGGClient()
		.request('GET', `companies`, {
			responseSchema: CompanyZ,
			...overrides,
		})
		.then((r) => r.data as Company)
}
