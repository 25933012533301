import { black } from 'src/_vars'
import { Tooltip } from 'src/atoms/Tooltip'
import { font } from 'src/refactor/fonts'
import styled, { FontType } from 'src/styles'

import { dialogViewHorizontalPadding } from './dialogViewHorizontalPadding'

export const MobileDialogTitle = styled(Tooltip)<{ horizontalPadding?: boolean }>`
	display: flex;
	justify-content: space-between;

	color: ${black};
	${font(FontType.Comfortaa, { size: 16, weight: 700 })};

	${(p) => (p.horizontalPadding ? dialogViewHorizontalPadding() : '')}

	padding-bottom: ${(p) => p.theme.spacing(3)}px;
`
