import { SelectedDocuments } from '../../travelsuit'
import { IAction } from './action-helpers'
import { SelectedDocumentsForTrip as Types } from './actions.types'

export const Actions = {
	setSelectedDocuments(selectedDocuments: SelectedDocuments[], tripId: string | number): IAction {
		return {
			type: Types.SetSelectedDocuments,
			payload: { [tripId]: selectedDocuments },
		}
	},
}
