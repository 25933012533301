import { addEntity } from 'src/lib/entity/addEntity'
import { removeEntityById } from 'src/lib/entity/removeEntityById'
import { replaceEntity } from 'src/lib/entity/replaceEntity'
import { replaceEntityById } from 'src/lib/entity/replaceEntityById'
import { ImmutableMap } from 'src/lib/immutable/ImmutableMap'
import { IAction } from 'src/redux/actions/action-helpers'
import { AdminUsersTypes, TravelersTypes } from 'src/redux/actions/actions.types'
import { TravelProfile } from 'src/travelsuit'
import { UserRailCard } from 'src/travelsuit/trains'

export type TravelersState = ImmutableMap<number, TravelProfile>

function remapPreferredCards(railCards: UserRailCard[], railCard: UserRailCard) {
	if (!railCard.is_preferred) {
		return railCards
	}

	return railCards.map((card) => {
		if (card.is_preferred && card.card_type_name === railCard.card_type_name) {
			return { ...card, is_preferred: false }
		}

		return card
	})
}

function addTravelerCard({ traveler, addedCard }: { traveler: TravelProfile; addedCard: UserRailCard }) {
	const remappedCards = remapPreferredCards(traveler.rail_cards.concat(), addedCard)

	return { ...traveler, rail_cards: [...remappedCards, addedCard] }
}

function updateTravelerCard({
	traveler,
	updatedCard,
}: {
	traveler: TravelProfile
	updatedCard: UserRailCard
}): TravelProfile {
	const cardIndexToUpdate = traveler.rail_cards.findIndex((p) => p.id === updatedCard.id)

	const remappedCards = remapPreferredCards(traveler.rail_cards.concat(), updatedCard)
	remappedCards.splice(cardIndexToUpdate, 1, updatedCard)

	return { ...traveler, rail_cards: remappedCards }
}

function removeTravelerCard({
	traveler,
	cardToRemove,
	nextPreferredCardId,
}: {
	traveler: TravelProfile
	cardToRemove: UserRailCard
	nextPreferredCardId: string
}) {
	const railCardsWithFreshPreferences = traveler.rail_cards
		.map((card) => {
			if (card.id === nextPreferredCardId) {
				return { ...card, is_preferred: true }
			}

			return card
		})
		.filter((card) => card.id !== cardToRemove.id)

	return { ...traveler, rail_cards: railCardsWithFreshPreferences }
}

function travelersReducer(state: TravelersState = new ImmutableMap(), action: IAction): TravelersState {
	const travelerUserId = action.requestPayload?.traveler?.user_id
	switch (action.type) {
		case TravelersTypes.GetTraveler.SUCCESS:
			return state.set(Number(action.requestPayload.userId), action.payload)
		case AdminUsersTypes.GetAllUsers.SUCCESS:
			for (const user of action.payload ?? []) {
				state = state.updatePart(user.id, (traveler) => ({ ...traveler, ...user.traveler_profile }))
			}
			return state

		case TravelersTypes.CreateTraveler.SUCCESS:
		case TravelersTypes.UpdateTraveler.SUCCESS:
			return state.set(Number(action.payload.user_id), action.payload)
		case TravelersTypes.RemoveTraveler.SUCCESS:
			return state.delete(Number(action.requestPayload.userId))

		case TravelersTypes.AddPassport.SUCCESS:
			return state.updatePart(travelerUserId, (traveler) => ({
				passports: addEntity(traveler.passports, action.payload),
			}))
		case TravelersTypes.UpdatePassport.SUCCESS:
			return state.updatePart(travelerUserId, (traveler) => ({
				passports: replaceEntity(traveler.passports, action.requestPayload.passport.id, action.payload),
			}))
		case TravelersTypes.RemovePassport.SUCCESS:
			return state.updatePart(travelerUserId, (traveler) => ({
				passports: removeEntityById(traveler.passports, action.requestPayload.passportId),
			}))

		case TravelersTypes.AddNationalId.SUCCESS:
			return state.updatePart(travelerUserId, (traveler) => ({
				national_ids: addEntity(traveler.national_ids, action.payload),
			}))
		case TravelersTypes.UpdateNationalId.SUCCESS:
			return state.updatePart(travelerUserId, (traveler) => ({
				national_ids: replaceEntity(traveler.national_ids, action.requestPayload.nationalId.id, action.payload),
			}))
		case TravelersTypes.RemoveNationalId.SUCCESS:
			return state.updatePart(travelerUserId, (traveler) => ({
				national_ids: removeEntityById(traveler.national_ids, action.requestPayload.nationalIdId),
			}))

		case TravelersTypes.AddFrequentFlyer.SUCCESS:
			return state.updatePart(travelerUserId, (traveler) => ({
				frequent_flyer_cards: addEntity(traveler.frequent_flyer_cards, action.payload),
			}))
		case TravelersTypes.UpdateFrequentFlyer.SUCCESS:
			return state.updatePart(travelerUserId, (traveler) => ({
				frequent_flyer_cards: replaceEntityById(traveler.frequent_flyer_cards, action.payload),
			}))
		case TravelersTypes.RemoveFrequentFlyer.SUCCESS:
			return state.updatePart(travelerUserId, (traveler) => ({
				frequent_flyer_cards: removeEntityById(traveler.frequent_flyer_cards, action.requestPayload.frequentFlyerId),
			}))

		case TravelersTypes.AddHotelLoyalty.SUCCESS:
			return state.updatePart(travelerUserId, (traveler) => ({
				hotel_loyalty_programs: addEntity(traveler.hotel_loyalty_programs, action.payload),
			}))
		case TravelersTypes.UpdateHotelLoyalty.SUCCESS:
			return state.updatePart(travelerUserId, (traveler) => ({
				hotel_loyalty_programs: replaceEntityById(traveler.hotel_loyalty_programs, action.payload),
			}))
		case TravelersTypes.RemoveHotelLoyalty.SUCCESS:
			return state.updatePart(travelerUserId, (traveler) => ({
				hotel_loyalty_programs: removeEntityById(traveler.hotel_loyalty_programs, action.requestPayload.hotelLoyaltyId),
			}))

		case TravelersTypes.AddCarLoyalty.SUCCESS:
			return state.updatePart(travelerUserId, (traveler) => ({
				car_loyalty_programs: addEntity(traveler.car_loyalty_programs, action.payload),
			}))
		case TravelersTypes.UpdateCarLoyalty.SUCCESS:
			return state.updatePart(travelerUserId, (traveler) => ({
				car_loyalty_programs: replaceEntityById(traveler.car_loyalty_programs, action.payload),
			}))
		case TravelersTypes.RemoveCarLoyalty.SUCCESS:
			return state.updatePart(travelerUserId, (traveler) => ({
				car_loyalty_programs: removeEntityById(traveler.car_loyalty_programs, action.requestPayload.carLoyaltyId),
			}))

		case TravelersTypes.AddRailCard.SUCCESS:
			return state.updatePart(travelerUserId, (traveler) => addTravelerCard({ traveler, addedCard: action.payload }))
		case TravelersTypes.UpdateRailCard.SUCCESS:
			return state.updatePart(travelerUserId, (traveler) =>
				updateTravelerCard({ traveler, updatedCard: action.payload }),
			)
		case TravelersTypes.RemoveRailCard.SUCCESS:
			return state.updatePart(travelerUserId, (traveler) =>
				removeTravelerCard({
					traveler,
					cardToRemove: action.requestPayload.railCard,
					nextPreferredCardId: action.payload.id,
				}),
			)
	}
	return state
}

export default travelersReducer
