import SvgIcon from 'src/atoms/SvgIcon/SvgIcon'
import Clock from 'src/refactor/assets/icons/clock.svg'
import { yellow } from 'src/refactor/colors'
import styled, { font } from 'src/styles'

export const Bolder = styled.span`
	font-weight: 700;
	white-space: nowrap;
`

export const Text = styled.p`
	padding: 0;
	margin-bottom: 20px;
	${font({ size: 14 })}
`

export const Container = styled.div`
	${Text}:last-child {
		margin-bottom: 0;
	}
`

export const ClockIcon = styled(SvgIcon).attrs({ src: Clock })`
	width: 16px;
	height: 16px;
	margin-right: 5px;
	vertical-align: middle;
	color: ${yellow};
`
