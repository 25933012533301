import React from 'react'

import { Flex } from 'src/atoms/GenericComponents/GenericComponents'
import SvgAnimation from 'src/atoms/SvgAnimation/SvgAnimation'
import { font, FontType } from 'src/refactor/fonts'
import styled, { mediaQuery } from 'src/styles'
import { DefaultProps } from 'src/travelsuit'

import { getLoaderByDate } from './LoaderAnimations'

interface IProps extends DefaultProps {
	size?: number
	label?: React.ReactNode
	color?: 'primary' | 'light' | 'secondary' | 'contrast'
	variant?: 'animated' | 'circle'
}

type IPropsNoVariant = Omit<IProps, 'variant'>

const AnimationContainer = styled(Flex).attrs(() => ({ direction: 'column', justify: 'center' }))``
const AnimationLabel = styled.h2`
	${font(FontType.Comfortaa, { size: 20 })}
	text-align: center;
	margin-top: -80px;
	${mediaQuery.mobileOnly`max-width: 100vw; padding: 0 15px;`}
`

export const AnimatedLoader: React.FunctionComponent<IPropsNoVariant> = (props) => {
	const { className, size = 500, label, ...rest } = props
	const animation = getLoaderByDate(new Date())
	return (
		<AnimationContainer className={className} {...rest}>
			<SvgAnimation animation={animation} height={size} />
			{label ? <AnimationLabel>{label}</AnimationLabel> : null}
		</AnimationContainer>
	)
}

export default AnimatedLoader
