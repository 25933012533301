import React from 'react'

import { preferredRatesOrange, preferredRatesOrangeDark, separatorLight } from 'src/_vars'
import StarSvg from 'src/assets/star.svg'
import SvgIcon, { IconSize } from 'src/atoms/SvgIcon/SvgIcon'
import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import { WithTranslation, withTranslation } from 'src/lib/i18n/i18n'
import { range } from 'src/lib/utils'
import TrustYouIcon from 'src/refactor/assets/icons/trustyou.svg'
import { brightTurquoise, brightTurquoiseLight, trustYou, white, yellow } from 'src/refactor/colors'
import styled, { flex, font } from 'src/styles'
import { DefaultProps } from 'src/travelsuit'

export interface IProps extends DefaultProps, WithTranslation {
	variant?: 'primary' | 'primaryLight' | 'gray' | 'trustyou' | 'orange' | 'orangeDark'
	rating: number
	showEmptyOutOf?: number
	typeLabel?: string
	position?: string
	reversed?: boolean
	size?: IconSize
}

interface SingleStarProps extends E2E {
	fraction?: number
	variant: IProps['variant']
	empty?: boolean
	position?: string
	reversed?: boolean
	size?: IconSize
	className?: string
}

const variantToColor: Record<Exclude<IProps['variant'], undefined>, string> = {
	primary: brightTurquoise,
	primaryLight: brightTurquoiseLight,
	gray: separatorLight,
	orange: preferredRatesOrange,
	orangeDark: preferredRatesOrangeDark,
	trustyou: white,
}

const Star = styled(SvgIcon).attrs(addE2EAttrs)<
	E2E & {
		fraction?: number
		empty?: boolean
		position?: string
		variant?: 'primary' | 'primaryLight' | 'gray' | 'trustyou' | 'orange' | 'orangeDark'
		reversed?: boolean
	}
>`
	display: inline-block;
	color: ${(props) => (props.reversed ? 'white' : variantToColor[props.variant!])};
	${(props) =>
		props.fraction
			? `
			clip-path: inset(0 ${props.fraction > 0 ? (1 - props.fraction) * 100 : 0}% 0 0);
		`
			: props.empty
				? `color: ${separatorLight};`
				: ''}

	${(props) =>
		props.position
			? `
		position: ${props.position};
		margin-top: 2.5px;
	`
			: ''}
`

export const StarIcon: React.FunctionComponent<SingleStarProps> = (props) => {
	const { className, variant, fraction, empty, position, reversed, size, e2e } = props
	return (
		<Star
			className={className}
			e2e={e2e}
			variant={variant}
			src={StarSvg}
			empty={empty}
			fraction={fraction}
			position={position}
			reversed={reversed}
			size={size}
		/>
	)
}

StarIcon.defaultProps = {
	size: 16,
}

const EmptyFractionContainer = styled.div`
	display: inline-block;
	position: relative;
`

const StarsRatingContainer = styled.div.attrs(addE2EAttrs)<{ reversed?: boolean } & E2E>`
	display: inline-block;
	vertical-align: middle;
	white-space: nowrap;
	${font({ size: 16 }, { useDefaultBase: false })}
	line-height: 1em;

	${(props) =>
		props.reversed
			? `
		background: ${yellow};
		padding: 0 5px;
		height: 20px;
		border-radius: 5px;
	`
			: ''}
`

const TrustYouContainer = styled.div`
	background: ${trustYou};
	padding: 2px 5px;
	border-radius: 5px;
	height: 20px;
	color: white;
	${flex({ align: 'center' })}
`

const TrustYouLogo = styled(SvgIcon)`
	margin-right: 5px;
`

const TrustYouRating = styled.span`
	${font({ size: 12, weight: 800 })}
`

const StarRating: React.FunctionComponent<IProps & E2E> = (props) => {
	const { className, variant, showEmptyOutOf, rating, typeLabel, t, reversed, size, e2e } = props
	const fraction = rating - Math.floor(rating)

	if (rating === undefined || rating === null) {
		return null
	}

	if (variant === 'trustyou') {
		return (
			<TrustYouContainer>
				<TrustYouLogo src={TrustYouIcon} size={size} />
				<TrustYouRating>{rating}</TrustYouRating>
			</TrustYouContainer>
		)
	}

	return (
		<StarsRatingContainer className={className} reversed={reversed} e2e={e2e}>
			{Math.floor(rating) > 0 ? (
				range(Math.floor(rating)).map((i) => (
					<StarIcon e2e={'RatingStar'} key={i} reversed={reversed} variant={variant} size={size} />
				))
			) : !showEmptyOutOf ? (
				<div>{t('star-rating.empty', 'No {{typeLabel}} Rating', { typeLabel: ` ${typeLabel ?? ''} ` })}</div>
			) : null}
			{fraction ? (
				showEmptyOutOf ? (
					<EmptyFractionContainer>
						<StarIcon e2e={'RatingStar'} reversed={reversed} variant={variant} size={size} empty position="absolute" />
						<StarIcon e2e={'RatingStar'} reversed={reversed} variant={variant} size={size} fraction={fraction} />
					</EmptyFractionContainer>
				) : (
					<StarIcon e2e={'RatingStar'} reversed={reversed} variant={variant} fraction={fraction} size={size} />
				)
			) : null}
			{showEmptyOutOf && Math.ceil(rating) < showEmptyOutOf
				? range(showEmptyOutOf - Math.ceil(rating)).map((i) => (
						<StarIcon
							e2e={'RatingStar'}
							reversed={reversed}
							key={i + Math.floor(rating)}
							variant={variant}
							size={size}
							empty
						/>
					))
				: null}
		</StarsRatingContainer>
	)
}

StarRating.defaultProps = {
	variant: 'primaryLight',
}

export default withTranslation()(StarRating)
