import React from 'react'

import Button from 'src/atoms/Button/Button'
import { MultiSelect } from 'src/atoms/MultiSelect'
import { SelectMenuItem } from 'src/atoms/Select/Select'
import SvgIcon from 'src/atoms/SvgIcon/SvgIcon'
import ToggleSwitch from 'src/atoms/ToggleSwitch/ToggleSwitch'
import { useTranslation } from 'src/lib/i18n/i18n'
import { isDefinedValue } from 'src/lib/isDefinedValue'
import CrossIcon from 'src/refactor/assets/icons/cross.svg'
import { linkBlue, white } from 'src/refactor/colors'
import styled, { font } from 'src/styles'
import { NamedIdentifiable } from 'src/travelsuit'
import { fullName } from 'src/travelsuit/users.utils'

const StyledResetButton = styled(Button)`
	cursor: pointer;
	padding: 0;
	background: transparent;
	color: ${white};
	border: none;
	pointer-events: all;

	&:hover {
		background: transparent;
		color: ${white};
	}
`

const StyledFlex = styled.div`
	display: inline-flex;
	position: relative;
`

const StyledMenuItem = styled(SelectMenuItem)`
	display: flex;
	align-items: center;
	justify-content: space-between;

	${font({ size: 12 })}
`

const StyledMultiSelect = styled(MultiSelect)<{ areSomeSelected: boolean }>`
	width: 200px;

	${({ areSomeSelected }) =>
		!areSomeSelected
			? ''
			: `
		& .MuiSelect-root {
			background-color: ${linkBlue};
			color: ${white};
		}

		& .MuiSelect-icon:not(${StyledResetButton}) {
			display: none;
		}
	`}
`

interface UserSelectProps {
	className?: string
	users: NamedIdentifiable[]
	selectedUserIds: number[]
	toggleAll: () => void
	onChange: (values: number[]) => void
	onReset: () => void
}

export function UserSelect({ users, onChange, selectedUserIds, onReset, toggleAll }: UserSelectProps) {
	const { t } = useTranslation()

	const areAllSelected = selectedUserIds.length === users.length
	const areSomeSelected = !!selectedUserIds.length && !areAllSelected

	return (
		<StyledFlex>
			<StyledMultiSelect
				areSomeSelected={areSomeSelected}
				e2e="UserSelect"
				renderPlaceholder={() =>
					areAllSelected ? (
						t('unused-tickets-user-select.all-travelers', 'All travelers')
					) : areSomeSelected ? (
						<span>
							{fullName(users.find(({ id }) => selectedUserIds.includes(id!)))}
							{` +${selectedUserIds.length}`}
						</span>
					) : (
						t('unused-tickets-user-select.select-travelers', 'Select travelers')
					)
				}
				selectedValues={selectedUserIds}
				onChange={(values: number[]) => {
					if (values.every(isDefinedValue)) {
						onChange(values)
					}
				}}
				options={users.map(({ id, ...user }) => ({
					label: fullName(user),
					value: id,
				}))}
				renderInModal
				hideNull
				menuDecoration={
					<StyledMenuItem onClick={toggleAll}>
						{t('unused-tickets-user-select.select-all-travelers', 'Select all travelers')}
						<ToggleSwitch checked={areAllSelected} onToggle={toggleAll} />
					</StyledMenuItem>
				}
			/>
			{areSomeSelected && (
				<StyledResetButton onClick={onReset} className="MuiSelect-icon">
					<SvgIcon src={CrossIcon} />
				</StyledResetButton>
			)}
		</StyledFlex>
	)
}
