import React from 'react'

import { LinkButton } from 'src/atoms/Button/Button'
import Confirmation from 'src/atoms/Confirmation/Confirmation'
import {
	MaxRateBody,
	MaxRateCell,
	MaxRateHeadCell,
	MaxRateHeader,
	MaxRateRow,
	MaxRateTable,
	RowControls,
} from 'src/atoms/MaxRateTable/MaxRateTable.components'
import { withTranslation, WithTranslation } from 'src/lib/i18n/i18n'
import { currency } from 'src/lib/number-utils'
import { eventStopper } from 'src/lib/utils'
import styled from 'src/styles'
import { DefaultProps, HotelPriceCapRoute } from 'src/travelsuit'
import { Currency } from 'src/types/common'

import HotelMaxRateDialog from '../HotelMaxRateDialog/HotelMaxRateDialog'

interface IProps extends DefaultProps, WithTranslation {
	rates: HotelPriceCapRoute[]
	currency?: Currency
	onSave?(changes: HotelPriceCapRoute[]): void
	disabled?: boolean
}

interface IState {
	dialogOpen: boolean
	editingRow: number | null
	deletingRow: number | null
}

const HotelMaxRateTableContainer = styled.div``

// TASK migrate to React.FunctionComponent OR remove this if not possible
class HotelMaxRateTable extends React.Component<IProps, IState> {
	// public static defaultProps: Partial<IProps> = {
	// 	//
	// }

	public state: IState = {
		dialogOpen: false,
		editingRow: null,
		deletingRow: null,
	}

	public render() {
		const { t, className, rates, currency: policyCurrency, disabled } = this.props
		const { dialogOpen, editingRow, deletingRow } = this.state
		const emptyState = rates.length === 0

		return (
			<HotelMaxRateTableContainer className={className}>
				<MaxRateTable>
					<MaxRateHeader>
						<MaxRateRow>
							<MaxRateHeadCell emptyState={emptyState}>{t('max-rate.hotels.city', 'City of stay')}</MaxRateHeadCell>
							<MaxRateHeadCell emptyState={emptyState} width={60}>
								{t('max-rate.hotels.price', 'Price')}
							</MaxRateHeadCell>
							<MaxRateHeadCell emptyState={emptyState} width={60} />
						</MaxRateRow>
					</MaxRateHeader>
					<MaxRateBody>
						{rates.map((rate, i) => (
							<MaxRateRow key={`rate-${rate.city_name}-${rate.max_rate}`}>
								<MaxRateCell>{rate.city_name}</MaxRateCell>
								<MaxRateCell>{currency(rate.max_rate, { removeZeroes: true, currency: policyCurrency })}</MaxRateCell>
								<MaxRateCell>
									{!disabled && (
										<RowControls
											onEdit={eventStopper(() => this.openEdit(i))}
											onDelete={eventStopper(() => this.openDelete(i))}
										/>
									)}
								</MaxRateCell>
							</MaxRateRow>
						))}
						<MaxRateRow>
							<MaxRateCell colSpan={3} emptyState={emptyState}>
								<LinkButton onClick={() => this.addNewRow()} disabled={disabled} data-test="AddNewPriceCap">
									+ {t('max-rate.hotels.add-new', 'Add New Price Cap')}
								</LinkButton>
							</MaxRateCell>
						</MaxRateRow>
					</MaxRateBody>
				</MaxRateTable>
				{dialogOpen ? (
					<HotelMaxRateDialog
						currency={policyCurrency}
						rate={editingRow !== null ? rates[editingRow] : undefined}
						onSave={(rate, addAnother) => this.onSave(rate, editingRow, addAnother)}
						onClose={() => this.closeDialog()}
					/>
				) : null}
				{deletingRow !== null ? (
					<Confirmation
						title={t('max-rate.hotels.delete.title', 'Delete Price Cap')}
						prompt={t('max-rate.hotels.delete.prompt', 'Are you sure you want to delete this price cap?')}
						variant="alert"
						onOK={eventStopper(() => this.onDelete(deletingRow))}
						onCancel={eventStopper(() => this.setState({ deletingRow: null }))}
					/>
				) : null}
			</HotelMaxRateTableContainer>
		)
	}

	private onSave(rate: HotelPriceCapRoute, idx: number | null, addAnother: boolean) {
		const { rates, onSave } = this.props
		if (onSave) {
			if (idx !== null) {
				rates.splice(idx, 1, rate)
			} else {
				rates.push(rate)
			}
			onSave(rates)
		}
		this.setState({ editingRow: null, dialogOpen: addAnother })
	}

	private onDelete(idx: number) {
		const { rates, onSave } = this.props
		if (onSave) {
			rates.splice(idx, 1)
			onSave(rates)
		}
		this.setState({ deletingRow: null })
	}

	private openEdit(i: number) {
		this.setState({ editingRow: i, dialogOpen: true })
	}

	private openDelete(i: number) {
		this.setState({ deletingRow: i, dialogOpen: false, editingRow: null })
	}

	private addNewRow() {
		this.setState({ dialogOpen: true, editingRow: null })
	}

	private closeDialog() {
		this.setState({ dialogOpen: false, editingRow: null })
	}
}

export default withTranslation()(HotelMaxRateTable)
