import { ImmutableMap } from 'src/lib/immutable/ImmutableMap'
import { PermissionRole } from 'src/travelsuit'

import { IAction } from '../actions/action-helpers'
import { RolesTypes } from '../actions/actions.types'

export type RolesState = ImmutableMap<keyof TStoreDef, any>

interface TStoreDef {
	roles: PermissionRole[]
}

function rolesReducer(state: RolesState = new ImmutableMap(), action: IAction): RolesState {
	switch (action.type) {
		case RolesTypes.GetAllRoles.SUCCESS:
			return state.set('roles', action.payload)
	}
	return state
}

export default rolesReducer
