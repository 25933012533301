import { TripPurposeOption } from 'src/travelsuit'

import { TripPurposeTypes } from '../actions'
import { IAction } from '../actions/action-helpers'

export type TripPurposesState = TripPurposeOption[]

export function tripPurposesReducer(state: TripPurposesState = [], action: IAction) {
	switch (action.type) {
		case TripPurposeTypes.GetTripPurposes.SUCCESS:
			return action.payload
	}

	return state
}
