import { replaceEntityById } from 'src/lib/entity/replaceEntityById'
import { updateEntityById } from 'src/lib/entity/updateEntityById'
import { IAction } from 'src/redux/actions/action-helpers'
import { CompanyInvoiceProfilesTypes, PackagesAndBillingSettingsTypes } from 'src/redux/actions/actions.types'
import { CreditCardResponse, InvoiceProfile, PaymentInfo } from 'src/travelsuit'

export type CompanyInvoiceProfilesState = InvoiceProfile[]

function clearObsoleteDefaultForFeesFlagFromCards(
	creditCards: CreditCardResponse[],
	{ default_for_fees, id }: CreditCardResponse,
) {
	if (!default_for_fees) {
		return creditCards
	}

	return creditCards.map((creditCard) => {
		if (creditCard.id === id || !creditCard.default_for_fees) {
			return creditCard
		}

		return {
			...creditCard,
			default_for_fees: false,
		}
	})
}

function updateInvoiceProfileByPaymentInfo(invoiceProfiles: InvoiceProfile[], paymentInfo: PaymentInfo) {
	const updatedInvoiceProfile = paymentInfo.invoice_profile
	return updatedInvoiceProfile ? replaceEntityById(invoiceProfiles, updatedInvoiceProfile) : invoiceProfiles
}

function updateInvoiceProfilesByPackagesAndBillingSettings(
	invoiceProfiles: InvoiceProfile[],
	packagesAndBillingSettings: PaymentInfo[],
) {
	return packagesAndBillingSettings.reduce(updateInvoiceProfileByPaymentInfo, invoiceProfiles)
}

export function companyInvoiceProfilesReducer(
	state: CompanyInvoiceProfilesState = [],
	action: IAction,
): CompanyInvoiceProfilesState {
	switch (action.type) {
		case CompanyInvoiceProfilesTypes.GetCompanyInvoiceProfiles.SUCCESS:
			return action.payload
		case CompanyInvoiceProfilesTypes.CreateCompanyInvoiceProfile.SUCCESS:
			return [...state, action.payload]
		case CompanyInvoiceProfilesTypes.UpdateCompanyInvoiceProfile.SUCCESS:
		case CompanyInvoiceProfilesTypes.GetCompanyInvoiceProfile.SUCCESS:
		case CompanyInvoiceProfilesTypes.ConfirmCompanyInvoiceProfile.SUCCESS:
		case CompanyInvoiceProfilesTypes.AcceptTermsAndConditionsForInvoiceProfile.SUCCESS:
			return replaceEntityById(state, action.payload)
		case CompanyInvoiceProfilesTypes.DeleteCompanyInvoiceProfile.SUCCESS:
			return state.filter(({ id }) => id !== action.requestPayload.id)
		case CompanyInvoiceProfilesTypes.AddCompanyCreditCard:
			return updateEntityById(state, action.payload.invoiceProfileId, (invoiceProfile) => ({
				credit_cards: clearObsoleteDefaultForFeesFlagFromCards(
					[...(invoiceProfile.credit_cards ?? []), action.payload.creditCard],
					action.payload.creditCard,
				),
			}))
		case CompanyInvoiceProfilesTypes.UpdateCompanyCreditCard:
			return updateEntityById(state, action.payload.invoiceProfileId, (invoiceProfile) => ({
				credit_cards: clearObsoleteDefaultForFeesFlagFromCards(
					replaceEntityById(invoiceProfile.credit_cards ?? [], action.payload.creditCard),
					action.payload.creditCard,
				),
			}))
		case CompanyInvoiceProfilesTypes.DeleteCompanyCreditCard:
			return updateEntityById(state, action.payload.invoiceProfileId, (invoiceProfile) => ({
				credit_cards: invoiceProfile.credit_cards?.filter(({ id }) => action.payload.creditCardId !== id),
			}))
		case PackagesAndBillingSettingsTypes.CreatePackagesAndBillingSettings.SUCCESS:
		case PackagesAndBillingSettingsTypes.UpdatePackagesAndBillingSettings.SUCCESS:
			return updateInvoiceProfilesByPackagesAndBillingSettings(state, action.payload)
		case PackagesAndBillingSettingsTypes.UpdatePaymentInfo.SUCCESS:
			return updateInvoiceProfileByPaymentInfo(state, action.payload)
	}
	return state
}
