import SvgIcon from 'src/atoms/SvgIcon/SvgIcon'
import Tooltip from 'src/atoms/Tooltip/Tooltip'
import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import { secondaryBlack, white } from 'src/refactor/colors'
import styled from 'src/styles'

export const StyledTooltip = styled(Tooltip)`
	display: flex;
	margin-left: 5px;
`

export const SecureAuthIconBlock = styled(SvgIcon).attrs(addE2EAttrs)<E2E & { mobile: boolean }>`
	width: 16px;
	height: 16px;

	color: ${({ mobile }) => (mobile ? 'transparent' : secondaryBlack)};
	stroke: none;

	& path {
		fill: ${({ mobile }) => (mobile ? white : 'currentColor')};
	}
`
