import React from 'react'

import Tooltip from 'src/atoms/Tooltip/Tooltip'
import { withTranslation, WithTranslation } from 'src/lib/i18n/i18n'
import styled from 'src/styles'
import { DefaultProps } from 'src/travelsuit'

interface OwnProps extends DefaultProps, WithTranslation {
	maxItems?: number
}

type IProps = OwnProps

const LimitedLengthSummaryListContainer = styled.div`
	position: relative;
`

const More = styled.span`
	line-height: 30px;
	cursor: default;
`

const LimitedLengthSummaryList: React.FunctionComponent<IProps> = (props) => {
	const { className, children, maxItems, t } = props
	if (!children) {
		return null
	}

	const childrenArr: React.ReactNode[] =
		children.constructor === Array ? (children as React.ReactNode[]) : [children as React.ReactNode]
	const shown = childrenArr.slice(0, maxItems || undefined)
	const hidden = maxItems ? childrenArr.slice(maxItems) : []

	return (
		<LimitedLengthSummaryListContainer className={className}>
			{shown}

			<More>
				<Tooltip place="bottom" render={() => <div>{hidden}</div>}>
					{hidden.length
						? `+${t('limited-length-summary-list.more', `${hidden.length} more`, { count: hidden.length })}`
						: null}
				</Tooltip>
			</More>
		</LimitedLengthSummaryListContainer>
	)
}

export default withTranslation()(LimitedLengthSummaryList)
