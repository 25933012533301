import React from 'react'
import TextAreaAutosize, { ResizeProps } from 'react-autosize-textarea'

import { brightRed } from 'src/refactor/colors'
import { FontType } from 'src/refactor/fonts'
import styled from 'src/styles'

export type AutosizeTextAreaResizeProps = ResizeProps

const StyledValidationMessage = styled.p<{ error?: boolean }>`
	${({ error }) => (error ? `color: ${brightRed};` : '')}
	font-size: 0.75rem;
	font-family: ${FontType.OpenSans};
`

const StyledTextArea = styled(TextAreaAutosize)<{ error?: boolean }>`
	box-sizing: border-box;
	${({ error }) => (error ? `border: 1px solid ${brightRed};` : '')}
`

export function AutosizeTextArea({
	error,
	helperText,
	...rest
}: Omit<React.ComponentProps<typeof TextAreaAutosize>, 'ref'> & { error?: boolean; helperText?: string }) {
	return (
		<>
			<StyledTextArea error={error} {...rest} />
			{helperText && <StyledValidationMessage error={error}>{helperText}</StyledValidationMessage>}
		</>
	)
}
