export enum WorldlineOrderStatus {
	Draft = 'DRAFT',
	Hold = 'HOLD',
	HoldFailed = 'HOLD_FAILED',
	HoldCancelPending = 'HOLD_CANCEL_PENDING',
	HoldCanceled = 'HOLD_CANCELED',
	HoldCancelFailed = 'HOLD_CANCEL_FAILED',
	Captured = 'CAPTURED',
	CapturePending = 'CAPTURE_PENDING',
	CaptureFailed = 'CAPTURE_FAILED',
	Refunded = 'REFUNDED',
	RefundPending = 'REFUND_PENDING',
	RefundFailed = 'REFUND_FAILED',
	Redirected = 'REDIRECTED',
	Validating = 'VALIDATING',
	PartialRefund = 'PARTIAL_REFUND',
}

export interface WorldlineOrder {
	id: number
	status: WorldlineOrderStatus
	redirect_url?: string
}
