import { useMediaQuery } from '@material-ui/core'

interface MediaQueryCheckProps {
	children: (matches: boolean) => JSX.Element
	matcher: Parameters<typeof useMediaQuery>[0]
}

export function MediaQueryCheck({ children, matcher }: MediaQueryCheckProps) {
	const matches = useMediaQuery(matcher)

	return children(matches)
}
