import classNames from 'classnames'
import React from 'react'

import StarRating, { IProps as StarRatingProps } from 'src/atoms/StarRating/StarRating'
import { passTestId } from 'src/lib/e2e-utils'
import { range } from 'src/lib/utils'
import { DefaultProps } from 'src/travelsuit'

import css from './AdjustableStarRating.module.css'

type IPropsBase = DefaultProps & StarRatingProps
interface IProps extends IPropsBase {
	hoverColor?: StarRatingProps['variant']
	onRate?(star: number): void
}

interface IState {
	hoverStar: number
}

// TASK migrate to React.FunctionComponent OR remove this if not possible
class AdjustableStarRating extends React.Component<IProps, IState> {
	public static defaultProps: Partial<IProps> = {
		hoverColor: 'primary',
	}

	public state: IState = {
		hoverStar: 0,
	}

	public render() {
		const { className, rating, variant, hoverColor, onRate } = this.props
		const { hoverStar } = this.state
		const adjustableStarRatingCls = classNames(css.AdjustableStarRating, className)

		if (variant === 'trustyou') {
			console.warn("Can't use adjustable rating with TrustYou")
			return null
		}

		return (
			<div className={adjustableStarRatingCls} data-test={passTestId(this.props)}>
				{range(5).map((i) => (
					<span
						key={i}
						onMouseOver={() => this.setState({ hoverStar: i + 1 })}
						onMouseLeave={() => this.setState({ hoverStar: 0 })}
						onClick={() => onRate?.(i + 1)}
					>
						{i < rating ? (
							<StarRating rating={1} className={css.star} variant={hoverStar > i ? hoverColor : variant} />
						) : (
							<StarRating rating={1} className={css.star} variant={hoverStar > i ? hoverColor : 'gray'} />
						)}
					</span>
				))}
			</div>
		)
	}
}

export default AdjustableStarRating
