import { createBrowserHistory } from 'history'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { asyncWait } from './asyncWait'

const history = createBrowserHistory()

const historyKeys: (string | undefined)[] = []
let historyKey: string | undefined = history.location.key

if (historyKey) {
	historyKeys.push(historyKey)
}

// Keeps history track
history.listen((location, action) => {
	if (!location.key) {
		historyKey = undefined

		return
	}

	if (action === 'PUSH') {
		if (historyKeys.includes(location.key)) {
			historyKey = location.key

			return
		}

		if (!historyKey) {
			historyKeys.length = 0
		} else {
			const index = historyKeys.indexOf(historyKey)
			if (index > -1) {
				historyKeys.length = index + 1
			}
		}

		historyKey = location.key
		historyKeys.push(historyKey)

		return
	}

	if (action === 'REPLACE') {
		const index = historyKeys.indexOf(historyKey)
		if (index > -1) {
			historyKey = location.key
			historyKeys.splice(index, 1, historyKey)

			return
		}

		if (!historyKeys.length && location.key) {
			historyKeys.push(location.key)
		}
	}

	historyKey = location.key
})

export default history

// Distance between current state and typically some state in the past
export const historyDelta = (key?: string) => {
	const fromIndex = historyKeys.indexOf(history.location.key)
	const toIndex = historyKeys.indexOf(key)

	if (fromIndex < 0) {
		return 0
	}

	if (toIndex < 0) {
		return -1 - fromIndex
	}

	return toIndex - fromIndex
}

export function useHistoryIndex() {
	const [{ index }, setState] = useState<{ index: number; key?: string }>({ key: history.location.key, index: -1 })

	useEffect(
		() =>
			history.listen(() => {
				setState((state) => {
					const index = -1 - historyDelta(state.key)
					return index === state.index ? state : { ...state, index }
				})
			}),
		[],
	)

	return index
}

export function removeLocationSearchParams(...keys: string[]) {
	const query = new URLSearchParams(history.location.search)
	for (const key of keys) {
		query.delete(key)
	}
	history.replace(`?${query.toString()}`)
}

export function useLocationSearchParams() {
	const { search } = useLocation()
	return useMemo(() => new URLSearchParams(search), [search])
}

/**
 * Saves history location point and let us get back to this point after series of navigation actions
 * @param name - param in history.location.search
 * @returns
 */
export function useLocationPoint(name: string) {
	const { search } = useLocation()

	const state = useRef({ baseLocation: history.location, basic: true })

	const [isResetting, setResetting] = useState(false)

	useEffect(
		() =>
			history.listen((location, action) => {
				const { baseLocation, basic } = state.current

				if (basic && action === 'REPLACE') {
					state.current.baseLocation = location
				} else if (action === 'POP' && location.key === baseLocation.key) {
					state.current.basic = true
				} else {
					state.current.basic = false
				}
			}),
		[],
	)

	return {
		...useMemo(
			() => ({
				pushPoint(value: string) {
					const query = new URLSearchParams(history.location.search)
					query.set(name, value)

					history.push(`?${query.toString()}`)
				},

				replacePoint(value: string) {
					const query = new URLSearchParams(history.location.search)
					query.set(name, value)

					history.replace(`?${query.toString()}`)
				},

				// Back to initial state
				resetPoint() {
					const { baseLocation } = state.current

					const delta = historyDelta(baseLocation.key)
					if (delta > -1) {
						const query = new URLSearchParams(history.location.search)
						if (query.has(name)) {
							query.delete(name)
							history.replace(`?${query.toString()}`)
						}

						return
					}

					const query = new URLSearchParams(history.location.search)
					const prevLocation = `?${query.toString()}`

					// Watch blocker
					const unregister = history.listen(async (location, action) => {
						// Skip blocker action if any
						if (action === 'PUSH') {
							return
						}

						unregister()

						const query = new URLSearchParams(location.search)
						if (query.has(`__reset_${name}`)) {
							// Unblocked
							const query = new URLSearchParams(baseLocation.search)

							const delta = historyDelta(baseLocation.key)
							if (delta < 0) {
								setResetting(true)

								history.replace(prevLocation)

								await asyncWait(100)

								history.go(delta)

								if (query.has(name)) {
									await asyncWait(100)

									query.delete(name)
									history.replace(`?${query.toString()}`)
								}

								await asyncWait(100)

								setResetting(false)
							} else if (query.has(name)) {
								query.delete(name)
								history.replace(`?${query.toString()}`)
							}
						}
					})

					// Try to bypass blocker
					query.delete(name)
					query.set(`__reset_${name}`, '')
					history.replace(`?${query.toString()}`)
				},
			}),
			[name],
		),
		...useMemo(
			() => ({
				locationPoint: isResetting ? null : new URLSearchParams(search).get(name),
			}),
			[name, search, isResetting],
		),
	}
}
