import React from 'react'

import { midGray, tripsProgressForeground } from 'src/_vars'
import CabinClassIconSrc from 'src/assets/cabin-class.svg'
import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import { useTranslation } from 'src/lib/i18n/i18n'
import { linkBlue, mainBlack } from 'src/refactor/colors'
import { font, pixelFontSize } from 'src/refactor/fonts'
import styled, { flex, padPx } from 'src/styles'
import { CallbackMap } from 'src/travelsuit'

import RadioInput, { Label as RadioLabel } from '../RadioInput/RadioInput'
import SvgIcon, { DeleteIcon, EditIcon, Icon } from '../SvgIcon/SvgIcon'
import Tooltip from '../Tooltip/Tooltip'

interface CommonProps {
	width: number | string
	emptyState?: boolean
}

type CellProps = Partial<CommonProps>

const ActionsContainer = styled.div`
	opacity: 0;
	padding-right: 12px;
	font-size: 0.8rem;
	transition: opacity 100ms ease-in-out;
	color: ${midGray};
	${flex({ justify: 'space-around', align: 'flex-end' })}

	& ${Icon} {
		cursor: pointer;
		transition: opacity 100ms ease-in-out;

		&:hover {
			opacity: 0.5;
		}
	}
`

export const MaxRateTable = styled.table`
	width: 100%;
	border-collapse: collapse;
	font-size: ${pixelFontSize(14)};
`

export const MaxRateHeader = styled.thead``

export const MaxRateHeadCell = styled.th<CellProps>`
	text-align: left;
	color: ${tripsProgressForeground};
	${(props) => (props.width ? `width: ${padPx(props.width)};` : '')}
	${(props) => (props.emptyState ? `padding-bottom: 15px;` : '')}

	&:first-child {
		padding-left: 30px;
	}
`

export const MaxRateCell = styled.td.attrs(addE2EAttrs)<CellProps & E2E>`
	text-align: left;
	padding: 12px 0;
	border-bottom: 1px solid rgb(232, 232, 232);
	line-height: 1.25;
	vertical-align: middle;
	${(props) => (props.width ? `width: ${padPx(props.width)};` : '')}
	${(props) => (props.emptyState ? `border-top: 1px solid rgb(232, 232, 232);` : '')}

	&:first-child {
		padding-left: 30px;
	}
`

export const MaxRateRow = styled.tr`
	&:hover ${ActionsContainer} {
		opacity: 1;
	}

	&:last-child ${MaxRateCell} {
		border-bottom: 0;
	}
`

export const MaxRateBody = styled.tbody`
	color: ${mainBlack};
	& > ${MaxRateRow}:first-child ${MaxRateCell} {
		padding-top: 10px;
	}
`

export const AddRowLink = styled.span.attrs(addE2EAttrs)<E2E>`
	color: ${linkBlue};
	font-weight: 500;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`

export const RowControls: React.FunctionComponent<
	CallbackMap<'onEdit' | 'onDelete', void, React.MouseEvent<HTMLElement>>
> = (props) => {
	const { t } = useTranslation()
	return (
		<ActionsContainer>
			<Tooltip title={t('max-rate.table.actions.edit', 'Edit')}>
				<EditIcon onClick={props.onEdit} e2e="MaxRateTable.Edit" />
			</Tooltip>
			<Tooltip title={t('max-rate.table.actions.delete', 'Delete')}>
				<DeleteIcon onClick={props.onDelete} e2e="MaxRateTable.Delete" />
			</Tooltip>
		</ActionsContainer>
	)
}

export const CabinClassIcon = styled(SvgIcon).attrs(() => ({ src: CabinClassIconSrc }))``

export const MaxRateRadioInput = styled(RadioInput).attrs(() => ({ size: 17, fillSize: 9, offset: { x: 5, y: 0 } }))`
	color: ${mainBlack};

	& ${RadioLabel} {
		${font({ size: 14 })}
	}
`
