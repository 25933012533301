import moment from 'moment'

import { TimeFormatsLocaleUnsafe } from 'src/lib/utils'

export function getAllTimePeriod() {
	return {
		start_date: moment('2018-01-01').format(TimeFormatsLocaleUnsafe.DateOnly),
		end_date: moment().format(TimeFormatsLocaleUnsafe.DateOnly),
	}
}
