import { getSearchResults } from 'src/clients/gg/search'
import { BookingOption } from 'src/organisms/FlightSearchNew/types'
import {
	FlightCreateBookingSegmentRequest,
	FlightCreateBookingSegmentRequestZ,
	FlightCreateBookingSegmentResponseZ,
	FlightFaresPricingCreateJobRequestZ,
	FlightFaresPricingCreateJobResponseZ,
	FlightFaresPricingJobResultResponseZ,
	GetFlightSearchResultsResponseZ,
} from 'src/types/flights'
import { CreateJobResponseZ } from 'src/types/job'
import { SearchId } from 'src/types/search'
import { TripId } from 'src/types/trip'

import { getGGClient, JobRequestOverrides, mapJobGroupResult, mapJobResult, RequestOverrides } from './GetGoingClient'

export async function createFlightBookingSegment(
	tripId: TripId,
	bookingData: FlightCreateBookingSegmentRequest,
	overrides?: JobRequestOverrides,
) {
	const url = `trips/${tripId}/v2/flights` as const

	const { job_id } = await getGGClient()
		.request('POST', url, {
			requestSchema: FlightCreateBookingSegmentRequestZ,
			responseSchema: CreateJobResponseZ,
			data: bookingData,
			...overrides?.create,
		})
		.then((r) => r.data)

	return getGGClient().pollJobResult(url, {
		params: { job_id },
		responseSchema: FlightCreateBookingSegmentResponseZ,
		mapResult: mapJobResult,
		...overrides?.poll,
	})
}

export async function getFlightFaresPricing(
	searchId: SearchId,
	data: { bookingOption: BookingOption; fareFamilies?: boolean }[],
	overrides?: JobRequestOverrides,
) {
	const url = `flights/pricing` as const
	const { job_id } = await getGGClient()
		.request('POST', url, {
			requestSchema: FlightFaresPricingCreateJobRequestZ,
			responseSchema: FlightFaresPricingCreateJobResponseZ,
			data: data.map(({ bookingOption, fareFamilies }) => ({
				fare_group_key: bookingOption.fare_group_key,
				flight_option_keys: bookingOption.flights.map((fo) => fo.flight_option_key),
				flight_identifier: bookingOption.flight_identifier,
				user_id: bookingOption.flight_option_keys![0].traveler_id,
				search_id: searchId,
				fare_families: fareFamilies,
			})),
			...overrides?.create,
		})
		.then((r) => r.data)

	const result = await getGGClient().pollJobResult(url, {
		params: {
			job_id,
			search_id: searchId,
		},
		responseSchema: FlightFaresPricingJobResultResponseZ,
		mapResult: mapJobGroupResult,
		...overrides?.poll,
	})

	// Temporary while Zod types haven replaced old types yet
	return result as unknown as ({ fare_groups: BookingOption[] } | { errors: string[] })[]
}

export async function getFlightSearchResults(searchId: SearchId, overrides?: RequestOverrides) {
	return getSearchResults(searchId, {
		// @ts-expect-error -- A temporary hack to override the response schema
		responseSchema: GetFlightSearchResultsResponseZ,
		...overrides,
	})
}
