import { ImmutableMap } from 'src/lib/immutable/ImmutableMap'
import { TripApproval } from 'src/travelsuit'

import { IAction } from '../actions/action-helpers'
import { TripApprovalsTypes } from '../actions/actions.types'

export type TripApprovalsState = ImmutableMap<number, TripApproval[]>

function tripApprovalsReducer(state: TripApprovalsState = new ImmutableMap(), action: IAction): TripApprovalsState {
	switch (action.type) {
		case TripApprovalsTypes.GetTripApprovals.SUCCESS:
			return state.set(action.requestPayload.tripId, action.payload)
	}
	return state
}

export default tripApprovalsReducer
