const MINUTE_MILLISECONDS = 60 * 1000

function getUTCOffsetMilliseconds(date: Date) {
	return date.getTimezoneOffset() * MINUTE_MILLISECONDS
}

export function addUTCOffset(date: Date) {
	return new Date(date.getTime() + getUTCOffsetMilliseconds(date))
}

export function removeUTCOffest(date: Date) {
	return new Date(date.getTime() - getUTCOffsetMilliseconds(date))
}
