import { i18n as i18nOriginal } from 'i18next'
import { useTranslation as useTranslationOriginal } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ApplicationState } from 'src/redux/stores'

import { getTWithGender, TFunction } from './i18n.helper'

type UseTranslationResponse = [TFunction, i18nOriginal, boolean] & {
	t: TFunction
	i18n: i18nOriginal
	ready: boolean
}

interface TranslationOptions {
	passUserContext: boolean
}

function useTranslation(options: Partial<TranslationOptions> = {}): UseTranslationResponse {
	const [t, i18n, tReady] = useTranslationOriginal()
	const user = useSelector((state: ApplicationState) => state.auth.get('internalUser'))
	const newT = getTWithGender(t, options.passUserContext ? user : undefined)

	const ret: any = Object.assign([newT, i18n, tReady], {
		t: newT,
		i18n,
		tReady,
	})

	return ret
}

export default useTranslation
