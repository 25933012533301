import { Role, RolePriority } from 'src/travelsuit'
import { NonFlexibleRuleArgs, PolicyRefundOptions, TravelPolicy, TravelPolicyMinimal } from 'src/travelsuit/policy'

export function isRoleEligible(roleToCheck: Role, baseRole: Role): boolean {
	return RolePriority[roleToCheck] >= RolePriority[baseRole!]
}

export function getNonFlexibleRefundOption(nonFlexibleRule: NonFlexibleRuleArgs): PolicyRefundOptions {
	const { allow = false, permit_up_to = 0 } = nonFlexibleRule || {}
	if (allow) {
		return PolicyRefundOptions.Always
	}
	if (permit_up_to > 0) {
		return PolicyRefundOptions.UpToHours
	}
	return PolicyRefundOptions.Never
}

export function isTravelPolicyLoaded(policy?: TravelPolicyMinimal | TravelPolicy | null): policy is TravelPolicy {
	return policy ? (policy as TravelPolicy).flight_policy_data !== undefined : false
}
