import { AxiosResponse } from 'axios'
import { z } from 'zod'

import { appConfig } from 'src/app-config/appConfig'
import { asyncWait } from 'src/lib/asyncWait'
import { createGetGoingAuthFailedResponseInterceptor, createGetGoingAuthRequestInterceptor } from 'src/lib/auth'
import { JobGroupResult, JobResult, JobStatus } from 'src/types/job'

import { BaseApiClient, BaseRequestConfig, EnforceRelativeURL, RequestOverrides } from '../BaseApiClient'

export type { RequestOverrides } from '../BaseApiClient'

const POLL_ACTION = Symbol()

export type JobRequestOverrides = {
	create?: RequestOverrides
	poll?: RequestOverrides
}

export type JobResultMapper<T, R> = (job: AxiosResponse<T>, poll: typeof POLL_ACTION) => R | typeof POLL_ACTION

export function mapJobResult<T>(
	{ data }: AxiosResponse<JobResult<T>>,
	poll: typeof POLL_ACTION,
): T | typeof POLL_ACTION {
	if (data.status === JobStatus.Completed) {
		return data.result
	}
	if (data.status === JobStatus.Failed) {
		throw new Error(data.errors?.join(';') ?? 'Unknown error')
	}
	return poll
}

export function mapJobGroupResult<T>(
	{ data }: AxiosResponse<JobGroupResult<T>>,
	poll: typeof POLL_ACTION,
): (T | { errors: string[] })[] | typeof POLL_ACTION {
	if (data.status === JobStatus.Completed) {
		return data.result
	}
	if (data.status === JobStatus.Failed) {
		throw new Error(data.result.flatMap((r) => r.errors).join(';'))
	}
	return poll
}

class GetGoingClient extends BaseApiClient {
	constructor() {
		super(appConfig.API_DOMAIN)

		this.client.interceptors.request.use(createGetGoingAuthRequestInterceptor())
		this.client.interceptors.response.use(undefined, createGetGoingAuthFailedResponseInterceptor(this.client))
	}

	/**
	 * Only polls. You need to create a job first by yourself.
	 * Defaults:
	 * - maxAttempts = 300
	 * - pollDelay = 2 sec
	 *
	 * Together they give 10 min of polling until an error.
	 */
	async pollJobResult<URL extends string, ResZ extends z.ZodTypeAny, R>(
		url: EnforceRelativeURL<URL>,
		config: Omit<BaseRequestConfig<z.ZodType<void>, ResZ>, 'params'> & {
			mapResult: JobResultMapper<z.infer<ResZ>, R>
			maxAttempts?: number
			params?: { [x: string]: any; job_id: string }
			pollDelay?: number
		},
	): Promise<R> {
		// 150 attempts of 2 secs sums up as 5 minutes.
		const { mapResult, maxAttempts = 150, pollDelay = 2000, ...reqConfig } = config
		const getResult = () => this.request('GET', url, reqConfig).then((job) => mapResult(job, POLL_ACTION))

		let attempt = 1
		let result = await getResult()

		while (result === POLL_ACTION) {
			if (attempt > maxAttempts) {
				throw new Error('Request has been aborted')
			}
			await asyncWait(pollDelay)
			result = await getResult()
			attempt++
		}

		return result
	}
}

let client: GetGoingClient

export function getGGClient() {
	if (!client) {
		client = new GetGoingClient()
	}
	return client
}
