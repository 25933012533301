import React from 'react'

import { PageSubtitle } from 'src/atoms/GenericComponents'
import styled, { mediaQuery, padPx } from 'src/styles'
import { DefaultElProps, DefaultProps } from 'src/travelsuit'

interface IProps extends DefaultProps {
	width?: number | string
	contentProps?: DefaultElProps<'div'> & { as?: React.ElementType; ref?: React.Ref<HTMLDivElement> }
	pageRef?: ((ref: HTMLDivElement | null) => void) | React.Ref<HTMLDivElement>
	offsetTop?: number
}

const PageContainer = styled.div`
	padding: 40px ${(p) => p.theme.spacing(3)}px;
	${(p) => mediaQuery.below('desktop')`
		padding: ${p.theme.spacing(4)}px;
		margin-top: 0;
	`}
`

const PageContent = styled.div<{ width?: number | string }>`
	max-width: ${(props) => padPx(props.width)};
	width: 100%;
	margin: 0 auto;

	& > ${PageSubtitle} {
		margin-bottom: ${(p) => p.theme.spacing(8)}px;

		${(p) => p.theme.breakpoints.down('md')} {
			margin-bottom: ${(p) => p.theme.spacing(4)}px;
		}
	}
`

function Page({ className, width = 1024, contentProps, pageRef, children, ...rest }: IProps) {
	return (
		<PageContainer className={className} ref={pageRef} {...rest}>
			<PageContent width={width} {...contentProps}>
				{children}
			</PageContent>
		</PageContainer>
	)
}

export default Page
