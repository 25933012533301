import React from 'react'

import CircularLoader from 'src/atoms/Loader'
import styled, { animations } from 'src/styles'

const StyledLoader = styled(CircularLoader)`
	${animations.fadeIn.animate(2000)}

	${(p) => p.theme.breakpoints.up('lg')} {
		padding: ${(p) => p.theme.spacing(2)}px 0;
	}
`

export function LoadingIndicator() {
	return <StyledLoader size={20} />
}
