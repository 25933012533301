import { DailyAllowancesPolicyTypes, IAction } from 'src/redux/actions'
import { DailyAllowanceDestination } from 'src/types/expenses'

export type DailyAllowancesPolicyState = DailyAllowanceDestination[]

function dailyAllowancesPolicy(state: DailyAllowancesPolicyState = [], action: IAction) {
	switch (action.type) {
		case DailyAllowancesPolicyTypes.GetDestinations.SUCCESS:
			return action.payload
		default:
			return state
	}
}

export default dailyAllowancesPolicy
