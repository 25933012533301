import { mainBlack, white } from 'src/refactor/colors'
import styled from 'src/styles'

export const PopperContainer = styled.div<{ dark?: boolean }>`
	z-index: 100500;
	display: inline-block;
	background: #ffffff;
	padding: 10px;
	border-radius: 4px;
	border: 1.5px solid rgba(0, 0, 0, 0.12);
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);

	${({ dark }) =>
		dark
			? `
	background-color: ${mainBlack};
	color: ${white};
	`
			: ''}
`

export const PopperArrow = styled.div`
	visibility: hidden;
	position: absolute;
	width: 16px;
	height: 16px;
	background: inherit;

	&::before {
		visibility: visible;
		content: '';
		transform: rotate(45deg);
		position: absolute;
		width: 16px;
		height: 16px;
		background: inherit;
	}

	${PopperContainer}[data-popper-placement^='top'] > & {
		bottom: -8px;
	}

	${PopperContainer}[data-popper-placement^='bottom'] > & {
		top: -8px;
	}

	${PopperContainer}[data-popper-placement^='left'] > & {
		right: -8px;
	}

	${PopperContainer}[data-popper-placement^='right'] > & {
		left: -8px;
	}
`
