import { CompanyCarProgram, CompanyCarProgramCreationData, VoidCallback } from 'src/travelsuit'

import { IAction } from './action-helpers'
import { CompanyCarProgramsTypes } from './actions.types'

export const Actions = {
	getCompanyCarPrograms(callback?: VoidCallback<CompanyCarProgram[]>, errCallback?: VoidCallback<never>): IAction {
		return {
			type: CompanyCarProgramsTypes.GetCompanyCarPrograms.REQUEST,
			callback,
			errCallback,
		}
	},
	createCompanyCarProgram(
		formData: CompanyCarProgramCreationData,
		callback?: VoidCallback<CompanyCarProgram>,
		errCallback?: VoidCallback<never>,
	): IAction {
		return {
			type: CompanyCarProgramsTypes.CreateCompanyCarProgram.REQUEST,
			body: formData,
			callback,
			errCallback,
		}
	},
	updateCompanyCarProgram(
		id: number,
		formData: CompanyCarProgramCreationData,
		callback?: VoidCallback<CompanyCarProgram>,
		errCallback?: VoidCallback<never>,
	): IAction {
		return {
			type: CompanyCarProgramsTypes.UpdateCompanyCarProgram.REQUEST,
			body: formData,
			payload: { id },
			callback,
			errCallback,
		}
	},
	deleteCompanyCarProgram(id: number, callback?: VoidCallback<never>, errCallback?: VoidCallback<never>): IAction {
		return {
			type: CompanyCarProgramsTypes.DeleteCompanyCarProgram.REQUEST,
			payload: { id },
			callback,
			errCallback,
		}
	},
}
