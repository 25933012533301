import React, { useState } from 'react'
import { connect } from 'react-redux'

import { CompanyTypeIndicator } from 'src/atoms/CompanyTypeIndicator/CompanyTypeIndicator'
import CompanyTooltipContainer from 'src/atoms/CompanyTypeTooltip/CompanyTypeTooltip'
import { makeAbsoluteUrl } from 'src/lib/staticFiles'
import { ApplicationState } from 'src/redux/stores'
import { hoverBlue } from 'src/refactor/colors'
import styled, { css } from 'src/styles'
import { Company } from 'src/travelsuit'

interface IProps {
	myCompany: Company | null
}

const CompanyLogoWrapper = styled.div<{ hasIndicator: boolean }>`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	box-size: border-box;
	border-radius: 15px;
	font-weight: 700;
	padding: 5px 0;
	width: 100%;
	text-align: center;

	${({ hasIndicator }) =>
		hasIndicator &&
		css`
			cursor: pointer;
			&:hover {
				background-color: ${hoverBlue};
				border-color: ${hoverBlue};
			}
		`}
`

const Logo = styled.div<{ logo_url?: string | null }>`
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: ${({ logo_url }) => (logo_url ? `url(${makeAbsoluteUrl(logo_url)})` : 'none')};
	min-height: 50px;
	min-width: 60px;
`

const CompanyLogo = ({ myCompany }: IProps) => {
	const [isTooltipOpen, setTooltipOpen] = useState(false)

	if (!myCompany) {
		return null
	}
	const hasIndicator = myCompany.company_type !== 'normal'

	return (
		<CompanyLogoWrapper
			data-test="CompanyLogo.Wrapper"
			hasIndicator={hasIndicator}
			onMouseEnter={hasIndicator ? () => setTooltipOpen(true) : undefined}
			onMouseLeave={hasIndicator ? () => setTooltipOpen(false) : undefined}
		>
			<CompanyTypeIndicator companyType={myCompany.company_type} />
			{myCompany.logo_url ? <Logo data-test="CompanyLogo.Logo" logo_url={myCompany.logo_url} /> : myCompany.name}

			{isTooltipOpen ? <CompanyTooltipContainer companyType={myCompany?.company_type} /> : null}
		</CompanyLogoWrapper>
	)
}

export default connect(({ myCompany }: ApplicationState) => ({ myCompany }))(CompanyLogo)
