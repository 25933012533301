import { appConfig } from 'src/app-config/appConfig'
import { CountriesCode, countryByLanguage, Locales, tryDefineLanguage } from 'src/i18nConfig'
import { User } from 'src/travelsuit'

export function getCurrentLocaleForUser(user: User | null) {
	const tempLocale: Locales = window.localStorage.getItem('defaultLocale')! as Locales

	const browserLanguage = navigator.language

	const priorities = [
		user ? user.language_code : tempLocale,
		user?.language_code ?? tempLocale,
		tryDefineLanguage(browserLanguage),
		getDefaultLocale(),
		Locales.enUS,
	] as Locales[]

	return priorities.find(Boolean)!
}

export function getCurrentLocale(): Locales {
	const user: User | null = JSON.parse(window.localStorage.getItem('internal_user') ?? 'null')

	return getCurrentLocaleForUser(user)
}

/**
 * Returns the default locale that was sent in the environment variables
 */
export function getDefaultLocale(): string {
	return appConfig.DEFAULT_LANGUAGE
}

export function getRegionFromLocale(locale: Locales): CountriesCode {
	return countryByLanguage[locale]
}
