import React from 'react'
import { useTranslation } from 'react-i18next'

import { PageTitle } from 'src/atoms/GenericComponents/GenericComponents'
import PageBackButton from 'src/atoms/PageBackButton/PageBackButton'
import { UserStatusBadge } from 'src/atoms/UserStatusBadge/UserStatusBadge'
import { useStoreValue } from 'src/lib/react-hooks/generic-hooks'
import { parseQueryString } from 'src/lib/utils'
import styled, { flex } from 'src/styles'

const TitleBox = styled.div`
	${flex({ justify: 'space-between', align: 'center' })}

	margin: 40px auto 0 auto;
	max-width: 1024px;
`

const TitleContainer = styled.div`
	flex: 1;
`

export interface TravelProfilePageTitleProps {
	userId?: number
}

export function TravelProfilePageTitle({ userId }: TravelProfilePageTitleProps) {
	const { returnTo = null } = parseQueryString(document.location.search) as Record<string, string>

	const user = useStoreValue(({ adminUsers }) => (userId === undefined ? undefined : adminUsers.get(userId)))

	const { t } = useTranslation()

	return (
		<TitleBox>
			<PageBackButton to={returnTo} style={{ alignSelf: 'flex-start' }} />
			<TitleContainer>
				<PageTitle flex>
					{user
						? t('edit-traveler.title', 'Traveler Profile')
						: t('edit-traveler.create-title', 'Create Traveler Profile')}
					{user && <UserStatusBadge status={user.status} deactivationDate={user.deactivate_at} />}
				</PageTitle>
			</TitleContainer>
		</TitleBox>
	)
}
