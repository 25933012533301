import React from 'react'
import { useTranslation } from 'react-i18next'

import TravelerIconSrc from 'src/assets/traveler.svg'
import { IconSpan } from 'src/atoms/IconSpan'
import { getYearDateFormat } from 'src/lib/format/dateTimeFormat'
import { routeFor, Routes } from 'src/lib/route-utils'
import { DateLabel, GoToDetailSourceButton, SectionCollapse, StyledGrid } from 'src/molecules/ReportDetails'
import { TripStatus } from 'src/travelsuit'
import { ActiveUserFeeReportDetail } from 'src/types/usageDetails'

interface ActiveUserFeeReportDetailSection {
	activeUserFeeReportDetail: ActiveUserFeeReportDetail
}

export function ActiveUserFeeReportDetailSection({ activeUserFeeReportDetail }: ActiveUserFeeReportDetailSection) {
	const {
		t,
		i18n: { language },
	} = useTranslation()

	const tripsDetails = activeUserFeeReportDetail.trips

	const formatter = getYearDateFormat(language)

	return (
		<>
			{tripsDetails.length ? (
				<SectionCollapse
					title={<IconSpan src={TravelerIconSrc}>{t('active-user-fee-details.trips', 'Trips')}</IconSpan>}
				>
					{tripsDetails.map((trip) => {
						const dateToDisplay = trip.approved_date || trip.booked_date
						const dateLabel = dateToDisplay ? <DateLabel>{formatter.format(new Date(dateToDisplay))}</DateLabel> : null

						const tripId = trip.trip_id

						return (
							<StyledGrid columns="5fr 2fr auto" key={tripId}>
								<div>{trip.name}</div>
								<div>
									{trip.status === TripStatus.Booked
										? t('active-user-fee-details.booked-on', 'Booked on')
										: trip.status === TripStatus.Approved
											? t('active-user-fee-details.approved-on', 'Approved on')
											: null}{' '}
									{dateLabel}
								</div>
								<GoToDetailSourceButton
									onClick={() => window.open(routeFor(Routes.Itinerary, { id: tripId }), '_blank')}
									data-test="GoToTrip"
								/>
							</StyledGrid>
						)
					})}
				</SectionCollapse>
			) : null}
		</>
	)
}
