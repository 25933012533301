import Backdrop from '@material-ui/core/Backdrop'
import MUIDrawer, { DrawerProps } from '@material-ui/core/Drawer'
import React from 'react'

import { CloseIcon } from 'src/atoms/SvgIcon/SvgIcon'
import { useZIndexContext, ZIndexContext } from 'src/common/z-index'
import { blockBodyScroll } from 'src/lib/utils'
import { useChatPosition } from 'src/refactor/hooks/useChatPosition'
import styled from 'src/styles'
import { mediaQuery } from 'src/styles'
import { DefaultElProps } from 'src/travelsuit'

import { IconButton } from '../Button/Button'

export interface IProps extends Omit<DefaultElProps<'div'>, 'title'>, Omit<DrawerProps, 'id' | 'classes' | 'variant'> {
	open?: boolean
	width?: number
	zIndex?: number
	onClose?(e: {}): void
	closeDisabled?: boolean
}

const CloseIconButton = styled(IconButton)`
	position: absolute;
	z-index: 1;
	top: ${(p) => p.theme.spacing(4)}px;
    right ${(p) => p.theme.spacing(3)}px;

	width: 24px;

	${(p) => p.theme.breakpoints.up('lg')} {	
		top: ${(p) => p.theme.spacing(6)}px;
		right: ${(p) => p.theme.spacing(8)}px;
  	}
`

const StyledMUIDrawer = styled(MUIDrawer)<{ width: number; zIndex?: number }>`
	& .MuiPaper-root.MuiDrawer-paper {
		overflow: hidden;
		${(props) => mediaQuery.above('mobile')`width: ${props.width}px;`}
		${mediaQuery.mobileOnly`width: 100vw;`}

		${mediaQuery.smallMobile`
			top: initial;
		`}
	}

	& + & {
		.MuiBackdrop-root {
			background: none;
		}
	}

	${(props) =>
		props.zIndex !== undefined
			? `
			z-index: ${props.zIndex} !important;
		& .MuiPaper-root.MuiDrawer-paper {
			z-index: ${props.zIndex + 2} !important;
		}
		& .MuiBackdrop-root {
			z-index: ${props.zIndex + 1} !important;
		}
	`
			: ''}
`

const StyledBackdrop = styled(Backdrop)`
	&.MuiBackdrop-root {
		z-index: 10000;
	}
	& ~ .MuiPaper-root {
		z-index: 10001;
	}
`

export function Drawer(props: IProps) {
	const {
		className,
		children,
		open = false,
		anchor = 'right',
		onClose,
		width = 600,
		closeDisabled,
		e2e = 'Drawer.Container',
		container = document.getElementById('root'),
		PaperProps,
		zIndex,
		...rest
	} = props

	React.useEffect(() => {
		if (open !== undefined) {
			blockBodyScroll(open)
		}
	}, [open])

	const isOpenRef = React.useRef<boolean>(open)
	isOpenRef.current = open

	React.useEffect(
		() => () => {
			if (isOpenRef.current) {
				blockBodyScroll(false)
			}
		},
		[],
	)

	const refContainer = React.useRef<HTMLDivElement | null>(null)
	useChatPosition({
		refContainer,
		trigger: open,
	})

	const zIndexToApply = useZIndexContext({ overrideValue: zIndex, defaultValue: 10000 })

	return (
		<StyledMUIDrawer
			ModalProps={{ container }}
			className={className}
			width={width}
			open={open}
			anchor={anchor}
			onClose={onClose}
			variant="temporary"
			disableRestoreFocus
			disableEnforceFocus
			BackdropComponent={StyledBackdrop}
			PaperProps={{
				...PaperProps,
				ref: refContainer,
				'data-test': e2e,
			}}
			zIndex={zIndexToApply}
			{...rest}
		>
			{!!onClose && (
				<CloseIconButton e2e="Drawer.Button.Close" onClick={(e) => onClose?.(e)} disabled={closeDisabled}>
					<CloseIcon size={24} />
				</CloseIconButton>
			)}
			<ZIndexContext.Provider value={zIndexToApply}>{children}</ZIndexContext.Provider>
		</StyledMUIDrawer>
	)
}

Drawer.defaultProps = {
	open: true,
}

export default Drawer
