import React from 'react'

import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import styled from 'src/styles'

import Loader from '../Loader/Loader'

const Div = styled.div.attrs(addE2EAttrs)``

interface IProps<C extends React.ComponentType<any>> extends E2E {
	className?: string
	/** The default container component is a div */
	component?: C
	/** `true` will display the loader */
	loading: unknown
	/** Replace the default <Loader /> with anything else. */
	loader?: React.ReactNode
	/** Adds topMargin to style, irrelevant if a custom `loader` is specified. */
	loaderOffsetY?: number
	/** Sets size of loader, irrelevant if a custom `loader` is specified. */
	loaderSize?: number
	/** Will be rendered when `props.loading` is `false`. Takes precedence over `props.children`. */
	render?(): React.ReactNode
}

/**
 * Container that uses a loader while data is being loaded.
 *
 * The component will attempt to use `render()` or `children`, in that order. `render()` is useful for when you want
 * to block errors from being thrown before the data is properly prepared. Otherwise, `children` will work just as well.
 *
 * @param loading `true` will display the loader
 * @param loader Replace the default loader with anything else.
 * @param children Will be rendered when `props.loading` is `false`
 * @param render Will be rendered when `props.loading` is `false`. Takes precedence over `props.children`.
 */
export default function LoadingContainer<
	C extends React.ComponentType<any> = typeof Div,
	CP extends React.ComponentPropsWithoutRef<C> = React.ComponentPropsWithoutRef<C>,
>(props: Omit<CP, keyof React.PropsWithChildren<IProps<C>>> & React.PropsWithChildren<IProps<C>>) {
	const { className, children, loader: _loader, loading, loaderOffsetY, loaderSize, render, component, ...rest } = props
	const offset = loaderOffsetY ? { style: { marginTop: loaderOffsetY, width: loaderSize, height: loaderSize } } : {}
	const loader = _loader ?? <Loader color="primary" {...offset} />
	const content: React.ReactNode = render ? render() : children
	const Container = component ?? Div

	return (
		// @ts-expect-error todo if you see this please remove this comment and fix the type error, thank you :)
		<Container className={className} {...rest}>
			{loading ? loader : content}
		</Container>
	)
}
