import { AxiosError } from 'axios'

import { objDiff, pick } from 'src/lib/utils'
import { IAction } from 'src/redux/actions/action-helpers'
import { TravelersTypes as Types } from 'src/redux/actions/actions.types'
import { getStoreState } from 'src/redux/stores'
import {
	Callback,
	CarLoyaltyCardCreationData,
	FrequentFlyer,
	NationalId,
	Passport,
	Role,
	ServiceLoyaltyCardFormData,
	TravelProfile,
} from 'src/travelsuit'
import { prepareFrequentFlyerJson } from 'src/travelsuit/freq_flyers'
import { prepareNationalIdJson } from 'src/travelsuit/nationalIds'
import { preparePassportJson } from 'src/travelsuit/passports'
import { prepareUserRailCardJson, UserRailCard } from 'src/travelsuit/trains'
import { emptyTraveler, notNulllableFields, travelerMandatoryFields } from 'src/travelsuit/travelers'

export const Actions = {
	getUser(userId: number, callback?: Callback, errCallback?: Callback): IAction {
		return {
			type: Types.GetUser.REQUEST,
			payload: { userId },
			callback,
			errCallback,
		}
	},
	getUserTitles(callback?: Callback, errCallback?: Callback): IAction {
		return {
			type: Types.GetUserTitles.REQUEST,
			callback,
			errCallback,
		}
	},
	getTraveler(userId: number, callback?: Callback, errCallback?: Callback): IAction {
		return {
			type: Types.GetTraveler.REQUEST,
			payload: { userId },
			callback,
			errCallback,
		}
	},
	createTraveler(traveler: TravelProfile, callback?: Callback, errCallback?: Callback): IAction {
		const existingTraveler = emptyTraveler()
		const changes = { ...objDiff(existingTraveler, traveler), ...pick(traveler, travelerMandatoryFields) }

		delete changes.home_location
		delete changes.office
		delete changes.group

		if (!changes.role) {
			changes.role = Role.Employee
		}
		return {
			type: Types.CreateTraveler.REQUEST,
			payload: { traveler },
			body: changes,
			callback,
			errCallback,
		}
	},
	updateTraveler(traveler: Partial<TravelProfile>, callback?: Callback, errCallback?: Callback): IAction {
		const existingTraveler = getStoreState().travelers.get(traveler.user_id!)
		traveler.group_id = traveler.group ? traveler.group.id : null
		const changes = objDiff(existingTraveler, traveler)
		notNulllableFields.forEach((k) => {
			if (changes[k] === null) {
				delete changes[k]
			}
		})
		delete changes.home_location
		delete changes.office

		return {
			type: Types.UpdateTraveler.REQUEST,
			payload: { traveler },
			body: changes,
			callback,
			errCallback,
		}
	},
	removeTraveler(traveler: TravelProfile): IAction {
		return {
			type: Types.RemoveTraveler.REQUEST,
			payload: traveler,
		}
	},
	addPassport(traveler: TravelProfile, passport: Passport, errClb?: (error: AxiosError) => void) {
		return {
			type: Types.AddPassport.REQUEST,
			payload: { traveler, passport },
			body: preparePassportJson(passport),
			errCallback: errClb,
		}
	},
	updatePassport(traveler: TravelProfile, passport: Passport, errClb?: (error: AxiosError) => void) {
		return {
			type: Types.UpdatePassport.REQUEST,
			payload: { traveler, passport },
			body: preparePassportJson(passport),
			errCallback: errClb,
		}
	},
	removePassport(traveler: TravelProfile, passportId: number) {
		return {
			type: Types.RemovePassport.REQUEST,
			payload: { traveler, passportId },
		}
	},
	addNationalId(traveler: TravelProfile, nationalId: NationalId, errClb?: (error: AxiosError) => void) {
		return {
			type: Types.AddNationalId.REQUEST,
			payload: { traveler, nationalId },
			body: prepareNationalIdJson(nationalId),
			errCallback: errClb,
		}
	},
	updateNationalId(traveler: TravelProfile, nationalId: NationalId, errClb?: (error: AxiosError) => void) {
		return {
			type: Types.UpdateNationalId.REQUEST,
			payload: { traveler, nationalId },
			body: prepareNationalIdJson(nationalId),
			errCallback: errClb,
		}
	},
	removeNationalId(traveler: TravelProfile, nationalIdId: number) {
		return {
			type: Types.RemoveNationalId.REQUEST,
			payload: { traveler, nationalIdId },
		}
	},
	addFrequentFlyer(traveler: TravelProfile, frequentFlyer: FrequentFlyer) {
		return {
			type: Types.AddFrequentFlyer.REQUEST,
			payload: { traveler, frequentFlyer },
			body: prepareFrequentFlyerJson(frequentFlyer),
		}
	},
	updateFrequentFlyer(traveler: TravelProfile, frequentFlyer: FrequentFlyer) {
		return {
			type: Types.UpdateFrequentFlyer.REQUEST,
			payload: { traveler, frequentFlyer },
			body: prepareFrequentFlyerJson(frequentFlyer),
		}
	},
	removeFrequentFlyer(traveler: TravelProfile, frequentFlyerId: number) {
		return {
			type: Types.RemoveFrequentFlyer.REQUEST,
			payload: { traveler, frequentFlyerId },
		}
	},
	addHotelLoyalty(traveler: TravelProfile, hotelLoyaltyFormData: ServiceLoyaltyCardFormData) {
		return {
			type: Types.AddHotelLoyalty.REQUEST,
			payload: { traveler },
			body: hotelLoyaltyFormData,
		}
	},
	updateHotelLoyalty(
		traveler: TravelProfile,
		hotelLoyaltyId: number,
		hotelLoyaltyFormData: ServiceLoyaltyCardFormData,
	) {
		return {
			type: Types.UpdateHotelLoyalty.REQUEST,
			payload: { traveler, hotelLoyaltyId },
			body: hotelLoyaltyFormData,
		}
	},
	removeHotelLoyalty(traveler: TravelProfile, hotelLoyaltyId: number) {
		return {
			type: Types.RemoveHotelLoyalty.REQUEST,
			payload: { traveler, hotelLoyaltyId },
		}
	},
	addCarLoyalty(traveler: TravelProfile, formData: CarLoyaltyCardCreationData) {
		return {
			type: Types.AddCarLoyalty.REQUEST,
			payload: { traveler },
			body: formData,
		}
	},
	updateCarLoyalty(traveler: TravelProfile, carLoyaltyId: number, formData: CarLoyaltyCardCreationData) {
		return {
			type: Types.UpdateCarLoyalty.REQUEST,
			payload: { traveler, carLoyaltyId },
			body: formData,
		}
	},
	removeCarLoyalty(traveler: TravelProfile, carLoyaltyId: number) {
		return {
			type: Types.RemoveCarLoyalty.REQUEST,
			payload: { traveler, carLoyaltyId },
		}
	},
	addRailCard(traveler: TravelProfile, railCard: UserRailCard) {
		return {
			type: Types.AddRailCard.REQUEST,
			payload: { traveler, railCard },
			body: railCard,
		}
	},
	updateRailCard(traveler: TravelProfile, railCard: UserRailCard) {
		return {
			type: Types.UpdateRailCard.REQUEST,
			payload: { traveler, railCard },
			body: prepareUserRailCardJson(railCard),
		}
	},
	removeRailCard(traveler: TravelProfile, railCard: UserRailCard) {
		return {
			type: Types.RemoveRailCard.REQUEST,
			payload: { traveler, railCard },
		}
	},
}
