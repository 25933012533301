import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Button from 'src/atoms/Button/Button'
import { Flex, Grid } from 'src/atoms/GenericComponents/GenericComponents'
import Modal from 'src/atoms/Modal/Modal'
import { linkBlue, mainBlack, secondaryBlack, white } from 'src/refactor/colors'
import { font, FontType, mediaQuery } from 'src/styles'

export const CardWrapper = styled(Grid).attrs(() => ({
	rows: ['auto', 'auto'],
	columns: ['1fr', 'minmax(min-content,max-content)'],
	gap: 15,
}))`
	grid-auto-rows: auto;
	background: ${white};
	border-radius: 10px;
	padding: 20px;

	${mediaQuery.below('tablet')`
		grid-template-rows: auto;
	`}
`

export const CardHeader = styled(Flex)`
	grid-column: 1 / -1;
`

export const Country = styled.p`
	padding: 0;
	${font({ size: 12, weight: 700 })}
`

export const PrimaryInfo = styled.p`
	padding: 0;
	${font(FontType.Comfortaa, { size: 14, weight: 700 })}
`

export const DataWithIconsWrapper = styled(Flex).attrs(() => ({
	justify: 'flex-start',
	wrap: 'wrap',
	gap: 20,
}))`
	${font(FontType.Comfortaa, { size: 12 })}
	${mediaQuery.below('tablet')`
		flex-direction: column;
		align-items: flex-start;
	`}
`

export const DataWithIcons = styled(Flex).attrs(() => ({
	justify: 'flex-start',
	align: 'flex-start',
	gap: 5,
}))``

export const Price = styled(Flex).attrs(() => ({
	direction: 'column',
	align: 'flex-end',
	justify: 'flex-end',
	gap: 10,
}))`
	grid-row: 2/3;
	grid-column: 2;
	align-self: 'end';
	justify-self: 'end';
`

export const PricePart = styled(Flex).attrs(() => ({
	justify: 'space-between',
	gap: 32,
}))<{ isTotal?: boolean }>`
	${font(FontType.Comfortaa, { size: 12 })}
	width: 100%;
	color: ${(props) => (props.isTotal ? mainBlack : secondaryBlack)};
`

export const Status = styled(Flex).attrs(() => ({
	justify: 'flex-end',
	align: 'flex-start',
	gap: 10,
}))`
	margin-left: auto;
`

export const PayAgainButton = styled(Button).attrs(() => ({
	color: 'primary',
	rounded: true,
}))`
	width: 111px;
	padding: 10px 20px;
	grid-column: 2;
	grid-row: 3;
	justify-self: end;
`

export const StyledLink = styled(Link)`
	${font(FontType.OpenSans, { size: 14 })}
	color: ${linkBlue}
	text-decoration: none;
`

export const StyledModal = styled(({ className, ...props }) => <Modal {...props} contentClassName={className} />)`
	overflow: inherit;

	& .--scrollAuto {
		min-height: 265px;
		overflow: inherit;
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
`
