import React from 'react'

import Chip from 'src/atoms/Chip/Chip'
import { UserIcon } from 'src/atoms/UserIcon/UserIcon'
import { white, yellowPaleSemiTransparent } from 'src/refactor/colors'
import { DefaultElProps, User } from 'src/travelsuit'
import { fullName } from 'src/travelsuit/users'

interface IProps extends DefaultElProps<'div'> {
	user: User
	isCurrent?: boolean
	onDelete?(e: React.MouseEvent<HTMLElement>): void
	maxLabelWidth?: number | null
	hideIcon?: boolean
	status?: string
	withTooltip?: boolean
}

const UserChip: React.FunctionComponent<IProps> = (props) => {
	const {
		className,
		user,
		onDelete,
		isCurrent,
		maxLabelWidth,
		'data-test': dataTest = 'UserChip',
		hideIcon,
		status,
		withTooltip,
		...rest
	} = props
	const name = fullName(user)
	const label = status ? `${name} ${status}` : name

	return !user ? null : (
		<Chip
			color={isCurrent ? yellowPaleSemiTransparent : white}
			className={className}
			label={label}
			onDelete={onDelete}
			data-test={dataTest}
			{...rest}
			icon={!hideIcon && <UserIcon user={user} />}
			maxLabelWidth={maxLabelWidth}
			tooltip={withTooltip ? label : undefined}
		/>
	)
}

export default UserChip
