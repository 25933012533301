import React from 'react'

import Checkbox from 'src/atoms/Checkbox/Checkbox'
import Select, { SelectProps } from 'src/atoms/Select/Select'
import { E2E } from 'src/lib/e2e-utils'
import { toggleValueInArray } from 'src/lib/toggleValueInArray'
import { font } from 'src/refactor/fonts'
import styled from 'src/styles'
import { SelectOption } from 'src/travelsuit'

const OptionLabel = styled.span`
	${font({ size: 14 })}
	line-height: 14px;
`

interface MultiSelectProps<T = unknown> extends Omit<SelectProps, 'onChange'>, E2E {
	className?: string
	onChange: (values: T[]) => void
	selectedValues?: T[]
	renderPlaceholder?: () => React.ReactNode
}

export function MultiSelect<T>({
	placeholder,
	selectedValues = [],
	onChange,
	renderPlaceholder,
	...selectProps
}: MultiSelectProps<T>) {
	const numberOfSelectedValues = selectedValues.length

	const placeholderToRender = renderPlaceholder?.() ?? (
		<>
			{placeholder}
			{!!numberOfSelectedValues && <>&nbsp;{`(${numberOfSelectedValues})`}</>}
		</>
	)

	const renderValue = () => placeholderToRender

	const renderOptionLabel = (option: SelectOption) => (
		<Checkbox
			label={<OptionLabel>{option.label}</OptionLabel>}
			checked={!!selectedValues?.find((value) => value === option.value)}
			disabled={option.disabled}
			onToggle={() => onChange?.(toggleValueInArray(selectedValues, option.value))}
		/>
	)

	return (
		<Select
			{...selectProps}
			placeholder={placeholderToRender}
			renderValue={renderValue}
			renderOptionLabel={renderOptionLabel}
			onChange={onChange}
			value={selectedValues}
			isMultiSelect
		/>
	)
}
