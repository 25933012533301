import React, { FC, useEffect, useState } from 'react'

export interface IProps {
	seconds?: number
	onFinishTimer?: () => void
	getSeconds?: () => number
}

export const getTimeLeft = (seconds: number) => {
	const ONE_MINUTE = 60
	const minutes = Math.floor(seconds / ONE_MINUTE)
	const sec = seconds % ONE_MINUTE

	if (minutes === 0) {
		return `${sec}s`
	}

	return `${minutes}m ${sec}s`
}

export const Timer: FC<IProps> = ({ seconds, onFinishTimer, getSeconds }) => {
	const [secondsLeft, setSecondsLeft] = useState(seconds || getSeconds?.() || 0)

	useEffect(() => {
		const timerId = setInterval(() => {
			setSecondsLeft((prevSeconds) => {
				const secondsFromCallback = getSeconds?.() || 0
				if (prevSeconds <= 1 || secondsFromCallback <= 1) {
					clearInterval(timerId)
					onFinishTimer?.()
				}

				if (secondsFromCallback) {
					return secondsFromCallback > 0 ? secondsFromCallback : 0
				}

				return prevSeconds - 1
			})
		}, 1000)

		return () => {
			clearInterval(timerId)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getSeconds, seconds])

	return <>{getTimeLeft(secondsLeft)}</>
}
