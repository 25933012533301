import React, { createContext } from 'react'

import { IModalContext, ModalAction, ModalsContainerProps } from './ModalApi.types'

export const defaultModalContext: IModalContext = {
	show: <T,>(cb: Function) => {
		return Promise.resolve<T>(cb())
	},
}

export const ModalContext = createContext<IModalContext>(defaultModalContext)

export const ModalsContainer = ({ modalActions }: ModalsContainerProps) => (
	<>
		{modalActions.map(({ component: Component, props }: ModalAction, index) =>
			React.createElement(React.Fragment, { key: `${index}_${props.zIndex}` }, Component),
		)}
	</>
)
