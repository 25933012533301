import { useCallback, useState } from 'react'

import { NamedIdentifiable } from 'src/travelsuit'

export function useUserSelect({ users }: { users: NamedIdentifiable[] }) {
	const getInitialUserIds = useCallback(() => users.map(({ id }) => id!), [users])
	const [selectedUserIds, setSelectedUserIds] = useState(getInitialUserIds)

	const toggleAll = useCallback(() => {
		setSelectedUserIds((prevValues) => {
			if (prevValues.length === users.length) {
				return []
			}
			return getInitialUserIds()
		})
	}, [users, getInitialUserIds])

	const onReset = useCallback(() => setSelectedUserIds(getInitialUserIds()), [getInitialUserIds])

	return { toggleAll, selectedUserIds, setSelectedUserIds, onReset }
}
