import React from 'react'

import CollapseBase, { IProps } from './CollapseBase'
import WizardCollapse from './WizardCollapse'

export * from './CollapseBase'

interface Variant {
	variant?: 'normal' | 'wizard'
}

export const Collapse: React.FunctionComponent<IProps & Variant> = (props) => {
	const { variant, ...rest } = props

	if (variant === 'wizard') {
		return <WizardCollapse {...rest} />
	}

	return <CollapseBase {...rest} />
}

export default Collapse
