import { z } from 'zod'

import { AbsoluteURLZ } from '../common'

export enum UTAAssessment {
	Benefit = 'benefit',
	Fee = 'fee',
	Restriction = 'restriction',
}

export const UTAAssessmentZ = z.nativeEnum(UTAAssessment)

/**
 * `Ext` are values coming from the 3rd party API not yet remapped by ASB
 */
export enum UTAName {
	AdvanceChange = 'advance-change',
	AdvanceChangeV2 = 'advance_change',
	BoardingPriority = 'boarding-priority',
	BoardingPriorityV2 = 'boarding_priority',
	Cancellation = 'cancellation',
	CarryOnAllowance = 'carry-on-allowance',
	CarryOnAllowanceV2 = 'carry_on_bag',
	CheckedBagAllowance = 'checked-bag-allowance',
	CheckedBagAllowanceV2 = 'checked_bag',
	CheckInPriority = 'check-in-priority',
	CheckInPriorityV2 = 'check_in_priority',
	LoungeAccess = 'lounge-access',
	LoungeAccessV2 = 'lounge_access',
	SameDayChangeV2 = 'same_day_change',
	SeatSelection = 'seat-selection',
	SeatSelectionV2 = 'seat_selection',
	UpgradeEligibility = 'upgrade-eligibility',
	UpgradeEligibilityV2 = 'upgrade_eligibility',
}

export const UTANameZ = z.nativeEnum(UTAName)

export const UTABagLimitZ = z.strictObject({
	size_lcm: z.number().nullable(),
	weight_kg: z.number().nullable(),
})

export type UTABagLimit = z.infer<typeof UTABagLimitZ>

export const UTAFeeZ = z.strictObject({
	amount: z.number(),
	amount_max: z.number().nullable(),
	amount_min: z.number().nullable(),
	currency: z.string(),
})

export type UTAFee = z.infer<typeof UTAFeeZ>

export const UTAZ = z.strictObject({
	assessment: UTAAssessmentZ,
	bag_limits: UTABagLimitZ.nullable(),
	description: z.string(),
	fees: z.array(UTAFeeZ).nullable(),
	headline: z.string(),
	is_allowed: z.boolean(),
	large_icon_url: AbsoluteURLZ.nullable(),
	name: UTANameZ,
})

export type UTA = z.infer<typeof UTAZ>

export const FlightSliceUTAZ = z.array(UTAZ).nullable()

export type FlightSliceUTA = z.infer<typeof FlightSliceUTAZ>

export const BookingOptionUTAZ = z.array(FlightSliceUTAZ).nullable()

export type BookingOptionUTA = z.infer<typeof BookingOptionUTAZ>
