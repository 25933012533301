import React from 'react'

import { FeatureScope } from 'src/travelsuit'

import { useFeaturePermit } from './useFeaturePermit'

export interface FeaturePermitProps {
	children: ({ isLoading, isPermitted }: { isLoading: boolean; isPermitted: boolean }) => React.ReactElement
	requiredFeature?: FeatureScope
}

export function FeaturePermit({ children, requiredFeature }: React.PropsWithChildren<FeaturePermitProps>) {
	const permissionData = useFeaturePermit(requiredFeature)

	return children(permissionData)
}
