import i18next from 'i18next'
import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getCurrentLocale } from 'src/lib/locale-utils'
import { useUser } from 'src/lib/react-hooks/generic-hooks'
import { AdminUsersActions } from 'src/redux/actions'
import { ApplicationState } from 'src/redux/stores'
import { VoidCallback } from 'src/travelsuit'
import { Locales } from 'src/types/locale'

export function useLanguageSwitcher() {
	const countryLanguages = useSelector((state: ApplicationState) => state.countryLanguages)
	const locales = React.useMemo(
		() =>
			countryLanguages
				.map((language) => language.code)
				.slice()
				.sort((a, b) => b.localeCompare(a)),
		[countryLanguages],
	)

	const my = useUser()
	const dispatch = useDispatch()

	const [locale, setLocale] = React.useState(my?.language_code ?? getCurrentLocale())

	const userExists = !!my

	const updateLanguage = (language: Locales, cb?: VoidCallback, errCb?: VoidCallback) => {
		i18next.changeLanguage(language, (err) => {
			if (err) {
				return console.error(`something went wrong loading ${language} language`, err)
			}
		})
		moment.locale([language, Locales.enUS])
		document.documentElement.lang = language
		return dispatch(AdminUsersActions.updateUserLanguage(language, cb, errCb))
	}

	const passOnChange = () => {
		window.location.reload()
	}

	const onChange: (newLocale: Locales) => void = (newLocale) => {
		setLocale(newLocale)

		if (userExists && newLocale !== locale) {
			updateLanguage(newLocale, passOnChange)
		} else {
			passOnChange()
		}
	}

	return {
		onChange,
		locale,
		countryLanguages: locales,
	}
}
