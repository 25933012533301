import React from 'react'

import { CurrencyIcons } from 'src/assets/currency'
import CurrencySelector from 'src/atoms/CurrencySelector/CurrencySelector'
import { SizedBox } from 'src/atoms/GenericComponents/GenericComponents'
import { useTranslation } from 'src/lib/i18n/i18n'
import { Trans } from 'src/lib/i18n/i18n'
import { CurrencySigns } from 'src/travelsuit'

import {
	CurrencyDisclaimer,
	CurrencyIcon,
	CurrencySelectorButton,
	LocaleCurrencySelectorContainer,
	Menu,
	Title,
} from './CurrencySelector.components'
import { useCurrencySelector } from './useCurrencySelector'

export const CurrencySelectorNormal: React.FC = () => {
	const { t } = useTranslation()
	const { companyCurrency, currency, onChange } = useCurrencySelector()
	const [open, setOpen] = React.useState(false)

	const buttonRef = React.createRef<HTMLButtonElement>()
	const menuRef = React.createRef<HTMLDivElement>()

	const currentCurrencyIcon = CurrencyIcons[currency]

	return (
		<LocaleCurrencySelectorContainer data-test="Curency">
			<CurrencySelectorButton
				ref={buttonRef}
				onClick={() => {
					setOpen((open) => !open)
				}}
			>
				{currentCurrencyIcon ? <CurrencyIcon src={currentCurrencyIcon} size={12} /> : CurrencySigns[currency]}
			</CurrencySelectorButton>
			{open && (
				<Menu
					attachTo={{ top: true, right: true }}
					attachOffset={{ y: 50 }}
					onClickOut={(e) => {
						const isClickonMenuIcon = (node: any) => buttonRef.current?.contains(node)

						if (!isClickonMenuIcon(e.target)) {
							setOpen(false)
						}
					}}
				>
					<SizedBox ref={menuRef} height={menuRef.current?.offsetHeight}>
						<>
							<Title>{t('locale-selector.select-currency', 'Select currency')}</Title>
							<CurrencySelector
								currency={currency}
								onChange={(curr) => {
									onChange(curr)
									setOpen(false)
								}}
							/>
							{!!companyCurrency && (
								<CurrencyDisclaimer>
									<Trans i18nKey="locale-selector.disclaimer">
										<b>Important</b> You'll see prices in the selected currency, but the invoice will be charged in
										{{ currency: companyCurrency }}.
									</Trans>
								</CurrencyDisclaimer>
							)}
						</>
					</SizedBox>
				</Menu>
			)}
		</LocaleCurrencySelectorContainer>
	)
}
