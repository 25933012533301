import { LinkButton } from 'src/atoms/Button/Button'
import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import { brightTurquoise, darkBlue, disabledGray, fontBlack, secondaryBlack } from 'src/refactor/colors'
import { font, FontType } from 'src/refactor/fonts'
import styled, { animations } from 'src/styles'

import Box from '../Box/Box'
import { Grid } from '../GenericComponents/GenericComponents'
import SvgIcon from '../SvgIcon/SvgIcon'

export const SupportSummaryContainer = styled(Box).attrs(() => ({ radius: 5, clickable: false }))<{ mobile?: boolean }>`
	color: ${fontBlack};
	padding: 0;

	${animations.slideInTop.animate(300)}

	${(p) => p.theme.breakpoints.down('md')} {
		transform-origin: top center;
		display: ${(p) => (!p.mobile ? 'none' : '')};
		margin-bottom: 0;
	}
`

export const ItemIcon = styled(SvgIcon).attrs(() => ({ size: 24 }))`
	color: ${darkBlue};
`

export const ItemContent = styled.div``

export const ItemTitle = styled.h4.attrs(addE2EAttrs)<{ href?: string } & E2E>`
	color: ${darkBlue};
	${font(FontType.Comfortaa, { size: 12, weight: 'bold' })}
	text-decoration: none;
	display: block;
`

export const ItemSubtitle = styled.p`
	${font({ size: 12 })}
	color: ${secondaryBlack};
	padding: 0;
	margin-top: 5px;
`

export const StyledLink = styled.a`
	text-decoration: none;

	:hover {
		text-decoration: underline;
	}
`

export const StyledLinkButton = styled(LinkButton)`
	${font({ size: 12 })}

	:hover {
		text-decoration: underline;
	}
`

export const ItemRow = styled(Grid).attrs(() => ({ columns: [24, '1fr'], align: 'flex-start', gap: 10 }))<{
	clickable?: boolean
}>`
	padding: ${(p) => p.theme.spacing(3)}px;

	${(p) => p.theme.breakpoints.up('lg')} {
		padding: ${(p) => p.theme.spacing(4)}px;
	}

	&:not(:last-child) {
		border-bottom: 1px solid ${disabledGray};
	}

	${(props) =>
		props.clickable
			? `
		& ${ItemTitle} {
			cursor: pointer;
			&:hover {
				text-decoration: underline !important;
			}
		}
	`
			: ''}
`

export const HelpText = styled.h3`
	${font(FontType.Comfortaa, { size: 12, weight: 'bold' })}
	color: ${brightTurquoise};
	padding: ${(p) => p.theme.spacing(3)}px;
	padding-bottom: 0;

	${(p) => p.theme.breakpoints.down('md')} {
		:first-child {
			padding-top: 0;
		}
	}

	${(p) => p.theme.breakpoints.up('lg')} {
		padding: ${(p) => p.theme.spacing(4)}px;
		padding-bottom: 0;
	}
`
