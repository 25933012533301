import { Workbox } from 'workbox-window'

import history from 'src/lib/history'

export function registerServiceWorker() {
	// eslint-disable-next-line no-process-env
	if (process.env.REACT_APP_SKIP_SW_REGISTRATION === 'true') {
		return
	}

	const wb = new Workbox(process.env.PUBLIC_URL + '/sw.js') // eslint-disable-line no-process-env

	wb.addEventListener('waiting', () => {
		wb.messageSkipWaiting()
	})

	wb.addEventListener('controlling', () => {
		history.listen(() => {
			window.location.reload()
		})
	})

	wb.register().then((registration) => {
		setInterval(
			() => {
				registration?.update()
			},
			60 * 60 * 1000,
		)
	})
}
