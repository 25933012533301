import React from 'react'

import history from 'src/lib/history'
import styled, { size } from 'src/styles'
import { DefaultElProps } from 'src/travelsuit'

import Button from '../Button/Button'
import { GoBack } from '../SvgIcon/SvgIcon'

interface IProps extends DefaultElProps<'div'> {
	to?: string | null
	size?: number
	type?: BackButtonTypes
}

export enum BackButtonTypes {
	HTML = 'html',
	Icon = 'back',
}

const BackButton = styled(Button)<{ size: number; type: BackButtonTypes }>`
	${(props: IProps) => size(props.size || '')}
	box-shadow: none;
	background: none;
	border: none;
	padding: 0;
	margin-right: 0.5ex;

	width: 20px;
	&:hover {
		box-shadow: none;
		background: none;
	}

	& > * {
		${(props: IProps) =>
			props.type === BackButtonTypes.HTML
				? `
			display: block;
			margin-top: -6px;
		`
				: `
			& ${GoBack} {
				margin-left: -5px;
			}
		`}
	}
`

const PageBackButton: React.FunctionComponent<IProps> = (props) => {
	const { className, onClick, to, size: buttonSize, type, ...rest } = props
	const onClickWrapped = onClick || (() => (to ? history.push(to) : history.goBack()))

	return (
		// @ts-expect-error todo if you see this please remove this comment and fix the type error, thank you :)
		<BackButton
			className={className}
			onClick={onClickWrapped}
			size={buttonSize!}
			type={type!}
			{...(rest as any)}
			e2e={'Button.Back'}
		>
			{/* eslint-disable-next-line i18next/no-literal-string */}
			<div>{type === BackButtonTypes.HTML ? <React.Fragment>&lsaquo;</React.Fragment> : <GoBack />}</div>
		</BackButton>
	)
}

PageBackButton.defaultProps = {
	size: 40,
	type: BackButtonTypes.Icon,
}

export default PageBackButton
