import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router'

import { CenteredAbsolute } from 'src/atoms/GenericComponents/GenericComponents'
import { isLaunchedAsStandaloneApp } from 'src/lib/mobile-app'
import { loginRoute, Routes } from 'src/lib/route-utils'
import { isCompanyContactPersonUser } from 'src/lib/user-utils'
import { InactiveCompanyAccountPage } from 'src/pages/InactiveCompanyAccount'
import { ApplicationState } from 'src/redux/stores'
import { AuthUser, Company, User } from 'src/travelsuit'
import { isSupportUser } from 'src/travelsuit/my-user-roles'

import AnimatedLoader from '../AnimatedLoader/AnimatedLoader'

interface IProps extends RouteProps {
	user: AuthUser
	internalUser: User
	company: Company
	user_can_operate: boolean | null
}

const PrivateRoute: React.FunctionComponent<IProps> = (props) => {
	const { user, internalUser, company, path, user_can_operate } = props
	if (!user || !internalUser) {
		return <Redirect to={loginRoute()} />
	}

	if (!company) {
		return (
			<CenteredAbsolute>
				<AnimatedLoader />
			</CenteredAbsolute>
		)
	}

	const currentRoute = path as Routes

	const isCreditCard3DSCallback = [
		Routes.CreditCardCallbackVerification,
		Routes.WordlineOrderCallbackVerification,
	].includes(currentRoute)

	if (isCreditCard3DSCallback) {
		return <Route {...props} />
	}

	const userCanOperate = company.is_after_onboarding || user_can_operate
	const isOnboardingRoute = [Routes.CreateCompanyInvoices, Routes.OnboardingConfirmation].includes(currentRoute)
	const isSupportRoute = currentRoute === Routes.Support

	if (!userCanOperate && isLaunchedAsStandaloneApp()) {
		return <InactiveCompanyAccountPage />
	}

	if (!userCanOperate && !isCompanyContactPersonUser(internalUser, company) && !isOnboardingRoute && !isSupportUser()) {
		return <Redirect to={Routes.OnboardingConfirmation} />
	}

	if (!userCanOperate && !isOnboardingRoute && !isSupportRoute) {
		return <Redirect to={Routes.CreateCompanyInvoices} />
	}

	if (userCanOperate && isOnboardingRoute) {
		return <Redirect to={Routes.Home} />
	}

	return <Route {...props} />
}

const mapStateToProps = ({ auth, myCompany }: ApplicationState) => ({
	user: auth.get('user'),
	internalUser: auth.get('internalUser'),
	company: myCompany,
	user_can_operate: auth.get('user_can_operate'),
})

export default connect(mapStateToProps)(PrivateRoute)
