import CurrencyAustralianDollarlingIcon from 'src/refactor/assets/currencies/AUD.svg'
import CurrencyEuroIcon from 'src/refactor/assets/currencies/EUR.svg'
import CurrencyPoundSterlingIcon from 'src/refactor/assets/currencies/GBP.svg'
import CurrencyIsraeliNewShekelIcon from 'src/refactor/assets/currencies/ILS.svg'
import CurrencySwedishKronaIcon from 'src/refactor/assets/currencies/SEK.svg'
import CurrencyDollarIcon from 'src/refactor/assets/currencies/USD.svg'
import { Currency } from 'src/types/common'

export const CurrencyIcons: Record<Currency, string> = {
	[Currency.USD]: CurrencyDollarIcon,
	[Currency.EUR]: CurrencyEuroIcon,
	[Currency.GBP]: CurrencyPoundSterlingIcon,
	[Currency.AUD]: CurrencyAustralianDollarlingIcon,
	[Currency.SEK]: CurrencySwedishKronaIcon,
	[Currency.ILS]: CurrencyIsraeliNewShekelIcon,
}
