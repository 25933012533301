import React from 'react'

import { IconSize, SvgIcon } from '../SvgIcon'
import { Stack, StackProps } from '../System'

export type IconSpanProps = StackProps & {
	color?: string
	src: string
	iconSize?: IconSize
}

export function IconSpan({ children, color, src, iconSize, ...rest }: IconSpanProps) {
	return (
		<Stack display="inline-flex" direction="row" spacing={{ xs: 1, lg: 2 }} alignItems="center" {...rest}>
			<SvgIcon color={color} src={src} size={iconSize} />
			{children}
		</Stack>
	)
}
