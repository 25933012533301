/**
 * This helper allows to manage a list of string values (like a checkbox group)
 * with an additional special value `all` which is not added to the list,
 * but adds/removes all other values instead.
 * For example, if we have a checkbox group: `All`, `Users`, `Managers`.
 * The state is `['users', 'managers']` and `All` is shown as checked on the UI.
 * If I uncheck `All`, the state becomes `[]`.
 * If I check `Users`, the state becomes `['users']`.
 * If I check `Managers`, the state becomes `['users', 'managers']` and `All` is shown as checked again.
 */
export function createCheckboxGroupHelpers<All extends string, Rest extends string>(allValue: All, restValues: Rest[]) {
	function isAllState(state: [All] | Rest[]): state is [All] {
		return state.length === restValues.length
	}

	function isAllValue(value: All | Rest): value is All {
		return value === allValue
	}

	function isChecked(state: Rest[], value: Rest | All): boolean {
		return isAllState(state) || (state as string[]).includes(value)
	}

	function onChecked(state: Rest[], value: Rest | All, isChecked: boolean): Rest[] {
		if (!isChecked) {
			return isAllValue(value) ? [] : state.filter((v) => v !== value)
		}

		if (isAllState(state)) {
			return state
		}

		if (isAllValue(value)) {
			return [...restValues]
		}

		return [...state, value]
	}

	return { isAllState, isAllValue, isChecked, onChecked }
}
