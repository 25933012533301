import jwtDecode from 'jwt-decode'

import { calculateExpirationTime, persistAuthInfoInStorage, scheduleProviderAuthRenewal } from 'src/lib/auth'
import { ImmutableMap } from 'src/lib/immutable/ImmutableMap'
import { LoginDetails, LoginStatus, SessionInfo, User } from 'src/travelsuit'
import { dispatchUserInfo } from 'src/travelsuit/users'

import { IAction } from '../actions/action-helpers'
import { AdminUsersTypes, AuthTypes, SupportTypes } from '../actions/actions.types'
import { Actions as AuthActions } from '../actions/auth.actions'
import { AuthState as AuthStateHelper, clearLocalStorage, TStoreDef } from './auth.reducer.helpers'

export type AuthState = AuthStateHelper

function saveTokens(decodedHash: Required<auth0.Auth0DecodedHash>): AuthState {
	const expiresAt = calculateExpirationTime(decodedHash)
	scheduleProviderAuthRenewal(expiresAt)

	const newState: TStoreDef = {
		user: jwtDecode(decodedHash.idToken),
		accessToken: decodedHash.accessToken,
		idToken: decodedHash.idToken,
		expiresAt,
		internalUser: null,
		termsAccepted: null,
		is_impersonation: false,
		user_can_operate: null,
		sessionInfo: {
			valid: false,
			expires_at: new Date(),
		},
		showModal: null,
	}

	persistAuthInfoInStorage(decodedHash)

	return new ImmutableMap<keyof TStoreDef, any>(Object.entries(newState) as [])
}

function setInternalUser(data: { user: User; loginData: Omit<LoginDetails, 'user'> }, state: AuthState) {
	const localStorageInternalUser = localStorage.getItem('internal_user')
	if (!localStorageInternalUser) {
		dispatchUserInfo(data.user)
	}

	localStorage.setItem('internal_user', JSON.stringify(data.user))
	state = state.set('internalUser', data.user)
	if (data.loginData) {
		state = state.set('is_impersonation', data.loginData.is_impersonation)
		state = state.set('user_can_operate', data.loginData.user_can_operate)
	}
	return state
}

function authReducer(state: AuthState = new ImmutableMap(), action: IAction): AuthState {
	let loginData: LoginDetails
	let sessionInfo: SessionInfo
	switch (action.type) {
		case AuthTypes.LogoutDone:
		case AuthTypes.LoginError:
			return clearLocalStorage()
		case AuthTypes.LoginDone:
			return saveTokens(action.payload)
		case AuthTypes.InternalLogin.SUCCESS:
			loginData = action.payload
			if (!loginData) {
				return state
			}
			if (loginData.login_status === LoginStatus.UserExists) {
				const { user, ...rest } = loginData
				state = authReducer(state, AuthActions.setInternalUser(user, rest))
			}

			return authReducer(state, AuthActions.setTermsAccepted(loginData.login_status === LoginStatus.UserExists))
		case AuthTypes.SetInternalUser:
			return setInternalUser(action.payload, state)
		case AdminUsersTypes.UpdateUser.SUCCESS:
			if (action.requestPayload.user.id === state.getWithFallback('internalUser', {}).id) {
				return setInternalUser(action.requestPayload, state)
			}
			return state
		case AuthTypes.SetTermsAccepted:
			localStorage.setItem('terms_accepted', String(action.payload))
			return state.set('termsAccepted', action.payload)
		case SupportTypes.ImpersonateSupportCompany.SUCCESS:
			if (action.requestPayload.company) {
				return state.set('is_impersonation', true)
			}
			return state.set('is_impersonation', false)
		case AuthTypes.SessionInfo.SUCCESS:
			sessionInfo = action.payload

			if (state.get('showModal') !== null && sessionInfo.expires_at !== state.get('showModal')) {
				state.set('showModal', null)
			}

			return state.set('sessionInfo', action.payload)
		case AuthTypes.ShowModal:
			return state.set('showModal', state.get('sessionInfo').expires_at)
		case AuthTypes.HideModal:
			return state.set('showModal', null)
		case AuthTypes.SetIsMockEnabled.SUCCESS:
			return authReducer(
				state,
				AuthActions.setInternalUser(
					{
						...state.get('internalUser'),
						mock_enabled: action.payload.mock_enabled,
					},
					{
						login_status: LoginStatus.UserExists,
						is_impersonation: state.get('is_impersonation'),
						user_can_operate: state.get('user_can_operate'),
					},
				),
			)
		case AuthTypes.SetIsTokenizerMockEnabled.SUCCESS:
			return authReducer(
				state,
				AuthActions.setInternalUser(
					{
						...state.get('internalUser'),
						tokenizer_mock_enabled: action.payload.tokenizer_mock_enabled,
					},
					{
						login_status: LoginStatus.UserExists,
						is_impersonation: state.get('is_impersonation'),
						user_can_operate: state.get('user_can_operate'),
					},
				),
			)
		case AuthTypes.SetIsWorldlineMockEnabled.SUCCESS:
			return authReducer(
				state,
				AuthActions.setInternalUser(
					{
						...state.get('internalUser'),
						worldline_mock_enabled: action.payload.worldline_mock_enabled,
					},
					{
						login_status: LoginStatus.UserExists,
						is_impersonation: state.get('is_impersonation'),
						user_can_operate: state.get('user_can_operate'),
					},
				),
			)
	}
	return state
}

export default authReducer
