import React from 'react'

import { boxShadowFloat } from 'src/_vars'
import { requestProviderLogout } from 'src/lib/auth'
import { isMobile } from 'src/lib/browser'
import { WithTranslation, withTranslation } from 'src/lib/i18n/i18n'
import { getCurrentLocale } from 'src/lib/locale-utils'
import { openExternalUrl } from 'src/lib/openExternalUrl'
import { redirectTo, routeFor, Routes } from 'src/lib/route-utils'
import { eventStopper } from 'src/lib/utils'
import { fontBlack, hoverBlue, white } from 'src/refactor/colors'
import { font } from 'src/refactor/fonts'
import styled, { animations } from 'src/styles'
import { DefaultProps, User } from 'src/travelsuit'
import { PrivacyPolicyLinkByLanguage } from 'src/travelsuit/locations'

import Box from '../Box/Box'
import GenericClickOutside from '../GenericClickOutside/GenericClickOutside'
import PolicyPreviewLink from '../PolicyPreviewLink/PolicyPreviewLink'

interface IProps extends DefaultProps, WithTranslation {
	open?: boolean
	user?: User | null
	onClickOutside?(): void
	onClickMenuItem?(): void
}

const LoginMenuContainer = styled(Box).attrs(() => ({ radius: 5 }))<{ open?: boolean }>`
	padding: 0;
	color: ${fontBlack};
	opacity: 0;
	pointer-events: none;
	min-width: 150px;
	${(props) => (props.open ? animations.slideInTop.animate(300) : '')}

	${(props) =>
		props.open
			? `
				opacity: 1;
				pointer-events: all;
				`
			: ''}

	${(p) => p.theme.breakpoints.down('md')} {
		position: static;
		top: initial;
		right: initial;
		background: none;
		width: 100%;
		margin: 0 -${(p) => p.theme.spacing(4)}px 0;
	}

	${(p) => p.theme.breakpoints.up('lg')} {
		position: absolute;
		margin: 0;
		background: ${white};
		top: calc(100% + 40px);
		right: 0;
		transform-origin: top right;
		z-index: 20000;
		box-shadow: ${boxShadowFloat};
	}
`

const MenuItem = styled.a<{ support?: boolean }>`
	transition: all 150ms ease-in-out;
	cursor: pointer;
	${font({ size: 14 })}
	padding: 15px 20px;
	color: currentColor;
	text-decoration: none;
	display: block;

	white-space: pre;

	&:hover,
	&:active {
		background: ${hoverBlue};
	}

	${(props) =>
		props.support
			? `
				background: rgb(83, 109, 254);
				color: ${white};
			`
			: ''}
`

// TASK migrate to React.FunctionComponent OR remove this if not possible
class UserLoginMenu extends React.Component<IProps> {
	public render() {
		const { t, className, user, ...rest } = this.props
		const isSupportUser: boolean = false as any // TODO: find a way to determine if user has support capability
		const aTarget = isMobile() ? '_blank' : undefined

		return (
			<GenericClickOutside handleClickOutside={() => this.props.onClickOutside?.()}>
				<LoginMenuContainer className={className} open={rest.open}>
					{user ? (
						<>
							<MenuItem
								href={routeFor(Routes.EditTravelerProfile, { id: this.props.user!.id })}
								target={aTarget}
								data-test="MenuItem.EditProfile"
								onClick={eventStopper.preventDefault(() => this.openTravelerProfile())}
							>
								{t('user-menu.edit', 'Edit Profile')}
							</MenuItem>
							<PolicyPreviewLink
								users={[user]}
								render={({ onClick }) => (
									<MenuItem data-test="MenuItem.MyTravelPolicy" onClick={eventStopper.preventDefault(onClick)}>
										{t('user-menu.my-policy', 'My Travel Policy')}
									</MenuItem>
								)}
							/>
						</>
					) : null}
					<MenuItem
						data-test="MenuItem.PrivacyPolicy"
						onClick={eventStopper.preventDefault(() => this.openPrivacyPolicy())}
					>
						{t('user-menu.privacy', 'Privacy Policy')}
					</MenuItem>
					{isSupportUser ? (
						<MenuItem support href={Routes.Support} onClick={eventStopper.preventDefault(() => this.openSupport())}>
							{t('user-menu.support', 'Support')}
						</MenuItem>
					) : null}
					<MenuItem
						href={Routes.Logout}
						target={aTarget}
						data-test="MenuItem.Logout"
						onClick={eventStopper.preventDefault(() => this.logout())}
					>
						{t('user-menu.logout', 'Logout')}
					</MenuItem>
				</LoginMenuContainer>
			</GenericClickOutside>
		)
	}

	private logout() {
		requestProviderLogout()
	}

	private openPrivacyPolicy() {
		openExternalUrl(PrivacyPolicyLinkByLanguage[getCurrentLocale()], '_blank')

		if (this.props.onClickMenuItem) {
			this.props.onClickMenuItem()
		}
	}

	private openTravelerProfile() {
		if (this.props.onClickMenuItem) {
			this.props.onClickMenuItem()
		}

		redirectTo(Routes.EditTravelerProfile, { id: this.props.user!.id })
	}

	private openSupport() {
		if (this.props.onClickMenuItem) {
			this.props.onClickMenuItem()
		}

		redirectTo(Routes.Support)
	}
}

export default withTranslation()(UserLoginMenu)
