import qs from 'qs'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { getTimePeriod } from 'src/lib/reports-utils'
import { AbortableRequest, isAbortError, loadData } from 'src/lib/requests'
import { Fee, FeesData, FeesFilters } from 'src/travelsuit/fees.types'

import {
	clearOrderVerificationState,
	getOrderVerificationState,
	validateOrder,
} from '../OrderCallbackVerification/order-verification-utils'
import { getFilteredOptions } from './FeesFilters/FeesFilters.utils'

function getFilters(filters: FeesFilters) {
	const { period, service_type, payment_fee_type, country, invoice_profile } = filters

	return {
		...getTimePeriod(period),
		service_type: getFilteredOptions(service_type),
		payment_fee_type: payment_fee_type ?? undefined,
		country: getFilteredOptions(country),
		invoice_profile: getFilteredOptions(invoice_profile),
	}
}

export function useLoadFees({
	resourcePath,
	filters,
	shouldSkipLoading,
}: {
	resourcePath: string
	filters?: FeesFilters
	shouldSkipLoading?: boolean
}) {
	const [fees, setFees] = useState<Fee<FeesData>[] | undefined>([])
	const [isLoading, setIsLoading] = useState(!shouldSkipLoading)

	const abortableRequest = useMemo(
		() =>
			new AbortableRequest(async (args): Promise<{ data: Fee<FeesData>[] }> => {
				const orderVerificationData = getOrderVerificationState()
				if (orderVerificationData) {
					const { order_id, ...verificationData } = orderVerificationData
					clearOrderVerificationState()
					await validateOrder(order_id, verificationData)
					await new Promise((resolve) => setTimeout(resolve, 1000))
				}

				return loadData({
					...args,
					resourcePath: resourcePath,
					params: filters ? getFilters(filters) : undefined,
					paramsSerializer: (params) => {
						return qs.stringify(params, { arrayFormat: 'repeat' })
					},
				})
			}),
		[resourcePath, filters],
	)

	const loadFees = useCallback(async () => {
		setIsLoading(true)
		try {
			const { data } = await abortableRequest.performRequest({})
			setFees(data)
			setIsLoading(false)
		} catch (error) {
			if (isAbortError(error)) {
				return
			}

			setFees(undefined)
			setIsLoading(false)
		}
	}, [abortableRequest])

	useEffect(() => {
		if (shouldSkipLoading) {
			return
		}
		loadFees()
		return () => abortableRequest.abort()
	}, [loadFees, abortableRequest, shouldSkipLoading])

	return {
		fees,
		loadFees,
		isLoading,
	}
}
