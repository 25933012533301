import React from 'react'

import { dialogViewHorizontalPadding } from 'src/molecules/MobileDIalog'
import styled from 'src/styles'

import TextField, { IProps } from '../TextField/TextField'
import { Tooltip } from '../Tooltip'

interface AutocompleteInputProps extends IProps {
	options?: unknown[]
	emptyMessage?: string
	e2e?: string
	isOpen?: boolean
}

const StyledTextField = styled(TextField)`
	input {
		text-overflow: ellipsis;
	}
`

const StyledTooltip = styled(Tooltip)<{ isOpen?: boolean }>`
	${(p) => p.theme.breakpoints.down('md')} {
		${(p) => (p.isOpen ? dialogViewHorizontalPadding() : '')}
	}
`

export function AutocompleteInput({ options, emptyMessage, e2e, isOpen, ...rest }: AutocompleteInputProps) {
	return (
		<StyledTooltip title={options && !options.length && emptyMessage ? emptyMessage : ''} isOpen={isOpen}>
			<StyledTextField tabIndex={0} e2e={`${e2e ? `${e2e}.` : ''}Autocomplete.Input`} {...rest} />
		</StyledTooltip>
	)
}
