import React from 'react'

import { boxShadowSmall } from 'src/_vars'
import { brightTurquoise, fontSecondary, white } from 'src/refactor/colors'
import styled, { size } from 'src/styles'
import { DefaultProps } from 'src/travelsuit'

export type ToggleSwitchProps = DefaultProps & {
	onToggle?(state: boolean, e: React.ChangeEvent<HTMLInputElement>): void
	name?: string
	checked?: boolean
	disabled?: boolean
}

const BUTTON_SIZE = 16
const DEFAULT_BUTTON_OFFSET = 1

const SwitchButton = styled.input`
	cursor: pointer;

	${size(BUTTON_SIZE)}
	border-radius: 50%;
	position: absolute;
	appearance: none;

	top: 50%;
	transform: translateY(-50%);
	transition: all 150ms ease-in-out;

	background: ${white};

	:not(:checked) {
		left: ${DEFAULT_BUTTON_OFFSET}px;
	}
	:checked {
		left: calc(100% - ${DEFAULT_BUTTON_OFFSET + BUTTON_SIZE}px);
	}
`

const SwitchContainer = styled.label`
	${size(30, BUTTON_SIZE + DEFAULT_BUTTON_OFFSET * 2)}
	position: relative;

	display: inline-block;
	box-shadow: ${boxShadowSmall};
	border-radius: 2em;
	transition: all 150ms ease-in-out;

	background: ${fontSecondary};
	:has(> input:checked) {
		background: ${brightTurquoise};
	}

	cursor: pointer;

	:has(> input:disabled) {
		opacity: 0.5;
		cursor: initial;
	}

	flex-shrink: 0;
`

const ToggleSwitch = (props: ToggleSwitchProps) => {
	const { className, checked, onToggle, disabled, e2e = 'ToggleSwitch', name = '' } = props

	return (
		<SwitchContainer className={className}>
			<SwitchButton
				type="checkbox"
				data-test={e2e}
				checked={checked}
				onChange={(e) => onToggle?.(!checked, e)}
				name={name}
				disabled={disabled}
			/>
		</SwitchContainer>
	)
}

export default ToggleSwitch
