import React, { memo } from 'react'
import styled from 'styled-components'

import { countryFlagsUrl, DEFAULT_COUNTRY_FLAG } from 'src/assets/country-flags'
import { CountriesCode } from 'src/i18nConfig'
import { DefaultElProps } from 'src/travelsuit'

import { ImageWithFallback } from '../ImageWithFallback'

interface IProps extends DefaultElProps<'img'> {
	code: CountriesCode
	fallbackSrc?: string
}

const StyledImg = styled(ImageWithFallback)`
	display: block;
`

export const CountryFlag: React.FC<IProps> = memo(({ code, fallbackSrc = DEFAULT_COUNTRY_FLAG, ...imgProps }) => {
	return <StyledImg src={countryFlagsUrl[code]} fallbackSrc={fallbackSrc} {...imgProps} />
})
