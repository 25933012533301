import get from 'lodash/get'

let isStandalone: boolean | undefined = undefined

export function isLaunchedAsStandaloneApp() {
	if (isStandalone === undefined) {
		const navigator: unknown = window.navigator
		isStandalone = get(navigator, 'standalone') || window.matchMedia('(display-mode: standalone)').matches
	}

	return isStandalone
}
