import { ImmutableMap } from 'src/lib/immutable/ImmutableMap'
import { Company } from 'src/travelsuit'

import { IAction } from '../actions/action-helpers'
import { SupportTypes } from '../actions/actions.types'

export type SupportState = ImmutableMap<keyof TStoreDef, any>

interface TStoreDef {
	companies: Company[]
}

function supportReducer(state: SupportState = new ImmutableMap(), action: IAction): SupportState {
	switch (action.type) {
		case SupportTypes.GetAllCompanies.SUCCESS:
			return state.set('companies', action.payload)
	}
	return state
}

export default supportReducer
