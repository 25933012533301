import { z } from 'zod'

import { TripStatus } from 'src/travelsuit'
import { WorldlineOrderStatus } from 'src/travelsuit/worldline'

import { CurrencyZ } from './common'
import { UserZ } from './user'

export const ActiveUserFeeIdZ = z.number().brand<'ActiveUserFeeId'>()

export type ActiveUserFeeId = z.infer<typeof ActiveUserFeeIdZ>

const ActiveUserFeeReportDetailZ = z.object({
	user: UserZ.pick({ id: true, first_name: true, last_name: true, middle_name: true }),
	trips: z.array(
		z.object({
			name: z.string(),
			booked_date: z.string(),
			trip_id: z.number(),
			status: z.nativeEnum(TripStatus),
			approved_date: z.string().nullable(),
		}),
	),
	total_price: z.number(),
	currency: CurrencyZ,
	refund_dt: z.string().nullable(),
	status: z.nativeEnum(WorldlineOrderStatus).nullable(),
})

export type ActiveUserFeeReportDetail = z.infer<typeof ActiveUserFeeReportDetailZ>

export const ActiveUserFeeDetailsZ = z.array(ActiveUserFeeReportDetailZ)

export type ActiveUserFeeDetails = z.infer<typeof ActiveUserFeeDetailsZ>
