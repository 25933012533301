import { Location, VoidCallback } from 'src/travelsuit'
import { prepareLocationJson } from 'src/travelsuit/locations'

import { IAction } from './action-helpers'
import { AdminLocationsTypes as Types } from './actions.types'

export const Actions = {
	getAllLocations(callback?: VoidCallback<Location[]>, errCallback?: VoidCallback): IAction {
		return {
			type: Types.GetAdminLocations.REQUEST,
			callback,
			errCallback,
		}
	},
	addLocation(location: Location): IAction {
		return {
			type: Types.AddAdminLocation.REQUEST,
			payload: prepareLocationJson(location),
		}
	},
	updateLocation(location: Location): IAction {
		return {
			type: Types.UpdateAdminLocation.REQUEST,
			payload: prepareLocationJson(location),
		}
	},
	removeLocation(location: Location): IAction {
		return {
			type: Types.RemoveAdminLocation.REQUEST,
			payload: prepareLocationJson(location),
		}
	},
}
