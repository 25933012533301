import { Callback, SearchResult, User } from 'src/travelsuit'

import { IAction } from './action-helpers'
import { SearchResultsTypes as Types } from './actions.types'

export const Actions = {
	setSearchResult(searchResult: SearchResult): IAction {
		return {
			type: Types.SetSearchResult,
			payload: searchResult,
		}
	},
	unsetSearchResult(searchId: number): IAction {
		return {
			type: Types.UnsetSearchResult,
			payload: { searchId },
		}
	},
	likeHotel(id: string, searchId: number, user: User, callback?: Callback, errCallback?: Callback): IAction {
		return {
			type: Types.LikeHotel.REQUEST,
			payload: { id, searchId, user },
			callback,
			errCallback,
		}
	},
	unlikeHotel(id: string, searchId: number, user: User, callback?: Callback, errCallback?: Callback): IAction {
		return {
			type: Types.UnlikeHotel.REQUEST,
			payload: { id, searchId, user },
			callback,
			errCallback,
		}
	},
}
