import React from 'react'

import Chip from 'src/atoms/Chip/Chip'
import { useTranslation } from 'src/lib/i18n/i18n'
import { hoverBlue, linkBlue, secondaryBlack, successGreen, white } from 'src/refactor/colors'
import { UserStatus } from 'src/types/user'

interface IProps {
	status: UserStatus
	deactivationDate?: Date | string | null
}

const statusColorMap = {
	[UserStatus.Registered]: successGreen,
	[UserStatus.Created]: hoverBlue,
	[UserStatus.Deactivated]: secondaryBlack,
	[UserStatus.Invited]: linkBlue,
}

const statusTextColorMap = {
	[UserStatus.Registered]: white,
	[UserStatus.Created]: linkBlue,
	[UserStatus.Deactivated]: white,
	[UserStatus.Invited]: white,
}

export const UserStatusBadge: React.FC<IProps> = ({ status, deactivationDate }) => {
	const { t } = useTranslation()

	const statusLabel = {
		[UserStatus.Registered]: t('manage-travelers.status.registered', 'Registered'),
		[UserStatus.Created]: t('manage-travelers.status.created', 'Created'),
		[UserStatus.Deactivated]: t('manage-travelers.status.deactivated', 'Deactivated'),
		[UserStatus.Invited]: t('manage-travelers.status.invited', 'Invited'),
	}

	const deactivationDateLabel =
		status === UserStatus.Registered && deactivationDate
			? t('manage-travelers.status.temporary-active', 'until {{deactivationDate}}', {
					deactivationDate,
				})
			: ''

	return (
		<Chip
			color={statusColorMap[status]}
			label={`${statusLabel[status]} ${deactivationDateLabel}`}
			textColor={statusTextColorMap[status]}
			fontSize={10}
			variant="condensed"
		/>
	)
}
