import { addEntity } from 'src/lib/entity/addEntity'
import { removeEntityById } from 'src/lib/entity/removeEntityById'
import { replaceEntityById } from 'src/lib/entity/replaceEntityById'
import { IAction } from 'src/redux/actions'
import { CompanyHotelProgramsTypes } from 'src/redux/actions/actions.types'
import { CompanyHotelProgram } from 'src/travelsuit'

export type CompanyHotelProgramsState = CompanyHotelProgram[]

export function companyHotelProgramsReducer(state: CompanyHotelProgramsState = [], action: IAction) {
	switch (action.type) {
		case CompanyHotelProgramsTypes.GetCompanyHotelPrograms.SUCCESS:
			return action.payload
		case CompanyHotelProgramsTypes.CreateCompanyHotelProgram.SUCCESS:
			return addEntity(state, action.payload)
		case CompanyHotelProgramsTypes.UpdateCompanyHotelProgram.SUCCESS:
			return replaceEntityById(state, action.payload)
		case CompanyHotelProgramsTypes.DeleteCompanyHotelProgram.SUCCESS:
			return removeEntityById(state, action.requestPayload.id)
		default:
			return state
	}
}
