import { TFunction } from 'i18next'
import { chunk, sortBy } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'

import { outOfPolicy, separator } from 'src/_vars'
import { Checkbox, createCheckboxGroupHelpers } from 'src/atoms/Checkbox'
import EditablePriceInput from 'src/atoms/EditablePriceInput/EditablePriceInput'
import { Flex } from 'src/atoms/GenericComponents/GenericComponents'
import RadioInput from 'src/atoms/RadioInput/RadioInput'
import { Box, Stack } from 'src/atoms/System'
import { FeaturePermit } from 'src/common/feature-permit/FeaturePermit'
import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import { Trans, WithTranslation, withTranslation } from 'src/lib/i18n/i18n'
import { mapSelectOptions, resolveBindableLabel } from 'src/lib/utils'
import AdjustableStarRating from 'src/molecules/AdjustableStarRating/AdjustableStarRating'
import { UpgradePaymentPlan } from 'src/molecules/UpgradePaymentPlan/UpgradePaymentPlan'
import { AdminUsersState } from 'src/redux/reducers/admin-users.reducer'
import { ApplicationState } from 'src/redux/stores'
import { disabledGray, mainBlack } from 'src/refactor/colors'
import { pixelFontSize } from 'src/refactor/fonts'
import styled from 'src/styles'
import { FeatureScope, HotelPriceCapRoute, Role, SelectOption, SelectOptionsBuilder, User } from 'src/travelsuit'
import { AccommodationType, accommodationTypeSettings, PolicyAccommodationType } from 'src/travelsuit/hotels'
import { HotelPolicyRules, PolicyRefundOptions, TravelPolicy } from 'src/travelsuit/policy'
import { CompanyFeatureFlags } from 'src/types/company'

import HotelMaxRateTable from '../HotelMaxRateTable/HotelMaxRateTable'
import HotelPolicySettingsSummary from './HotelPolicySettingsSummary'
import PolicySettings, { PolicySettingCommonProps } from './PolicySettings'
import {
	PolicyLabelSelect,
	PolicyRadioOptionsContainer,
	PolicySettingContent,
	PolicySettingRightColumn,
	PolicySettingRow,
	PolicySettingSubtitle,
	PolicySettingTitle,
} from './PolicySettings.components'
import { PolicySettingsAvailableWithUpgrade } from './PolicySettingsAvailableWithUpgrade'
import { getNonFlexibleRefundOption } from './utils'

interface OwnProps extends PolicySettingCommonProps<HotelPolicyRules> {
	//
}

interface StateProps {
	my: User
	users: AdminUsersState
	companyFeatureFlags?: CompanyFeatureFlags | null
}

type IProps = OwnProps & StateProps & WithTranslation

interface IState {
	rules: HotelPolicyRules
}

const PriceCapTable = styled(HotelMaxRateTable)``
const PriceCapTableContainer = styled.div``
const GlobalPriceCapContainer = styled(Flex).attrs(() => ({ justify: 'space-between', align: 'center' }))`
	padding: 20px;
	padding-right: 30px;
	font-size: 0.85rem;
	color: ${mainBlack};

	&:not(:last-child) {
		border-bottom: 1px solid ${separator};
	}
`
const StyledAdjustableStarRating = styled(AdjustableStarRating).attrs(addE2EAttrs)<E2E>`
	font-size: ${pixelFontSize(15)};
`
const RoleLabel = styled.span<{ disabled?: boolean }>`
	color: ${({ disabled }) => (disabled ? disabledGray : 'inherit')};
`

export const accommodationTypeHelpers = createCheckboxGroupHelpers(
	AccommodationType.All as const,
	Object.values(AccommodationType).filter((v) => v !== AccommodationType.All) as PolicyAccommodationType[],
)

export const getAccommodationTypeLabels = (t: TFunction) =>
	sortBy(Object.entries(accommodationTypeSettings), ([_t, s]) => s.order).map(([type, s]) => [type, s.getLabel(t)]) as [
		AccommodationType,
		string,
	][]

// TASK migrate to React.FunctionComponent OR remove this if not possible
class HotelPolicySettings extends React.Component<IProps, IState> {
	public state: IState = {
		rules: { ...(this.props.policy || ({} as TravelPolicy)).hotel_policy_data },
	}

	public static refundableOptions: Array<SelectOptionsBuilder<HotelPolicySettings, PolicyRefundOptions>> = [
		{
			value: PolicyRefundOptions.Never,
			label: <Trans i18nKey="hotel-policy-settings.refundable-options.not-allowed">Not Allowed</Trans>,
		},
		{
			value: PolicyRefundOptions.Always,
			label: <Trans i18nKey="hotel-policy-settings.refundable-options.always-allowed">Always Allowed</Trans>,
		},
		{
			value: PolicyRefundOptions.UpToHours,
			label() {
				const value = this.getRule('non_flexible_hotel_rule', 'permit_up_to', 24)
				return (
					<React.Fragment>
						<Trans i18nKey="hotel-policy-settings.refundable-options.allow-up-to">
							{'Allow up to '}
							<PolicyLabelSelect
								options={mapSelectOptions([24, 48, 72], { mapLabel: (n) => `${n}H` })}
								value={value}
								required
								onChange={(v: any) => this.updateRule('non_flexible_hotel_rule', { permit_up_to: v, allow: false })}
								e2e="HotelPolicy.NonRefundableRooms.UpToSelect"
							/>
							{' before check-in'}
						</Trans>
					</React.Fragment>
				)
			},
		},
	]

	public static roleOptions: Array<SelectOptionsBuilder<HotelPolicySettings, Role>> = [
		{ value: Role.Employee, label: <Trans i18nKey="hotel-policy-settings.role-options.employees">Employees</Trans> },
		{ value: Role.Manager, label: <Trans i18nKey="hotel-policy-settings.role-options.managers">Managers</Trans> },
		{
			value: Role.Executive,
			label: <Trans i18nKey="hotel-policy-settings.role-options.executives">Executives</Trans>,
		},
	]

	public render() {
		const { className, companyFeatureFlags, isSaving, expanded, visible, groupType, policy, onExpand, t } = this.props
		const { rules } = this.state
		const { refundableOptions } = HotelPolicySettings
		const { globalPriceCap, priceCapRoutes, maxRatings, relevantRoleOptions } = this
		const nonFlexibleValue = getNonFlexibleRefundOption(rules.non_flexible_hotel_rule)
		const getPriceCap = (role: Role) => globalPriceCap[role] ?? ''
		const getMaxRating = (role: Role) => maxRatings[role] ?? 5
		const accommodationTypes = rules.accomodation_type.allowed
		const accommodationTypeLabels = getAccommodationTypeLabels(t)
		const isAccommodationTypeValid =
			accommodationTypes.length > 0 || !companyFeatureFlags?.enable_non_traditional_accommodation

		return (
			<FeaturePermit requiredFeature={FeatureScope.TravelOnly}>
				{({ isPermitted, isLoading }) => (
					<>
						{!isLoading && !isPermitted && expanded && (
							<UpgradePaymentPlan title={t('upgrade-payment-plan.hotel-settings.title', 'Unlock all hotel settings')}>
								{t(
									'upgrade-payment-plan.hotel-settings.desctiption',
									'Upgrade to set the business travel rules for booking hotels so each booking follows your custom or global travel policy.',
								)}
							</UpgradePaymentPlan>
						)}
						<PolicySettings
							e2e={'PolicySettings.HotelSettingsBlock'}
							e2eForTitle={'PolicySettings.HotelSettingsTitle'}
							className={className}
							disableSave={!isAccommodationTypeValid}
							title={
								<>
									<Trans i18nKey="hotel-policy-settings.title">Hotel Settings</Trans>
									{!isLoading && !isPermitted && expanded && (
										<PolicySettingsAvailableWithUpgrade
											tooltipTitle={t(
												'upgrade-payment-plan.hotel-settings.tooltip',
												'Upgrade to unlock all hotel settings',
											)}
										/>
									)}
								</>
							}
							isSaving={isSaving}
							expanded={expanded}
							visible={visible}
							onSave={() => this.saveChanges()}
							onExpand={onExpand}
							groupType={groupType}
							summary={<HotelPolicySettingsSummary policy={policy} />}
						>
							<PolicySettingRow data-test="HotelPolicy.NonRefundableRooms.Section">
								<PolicySettingTitle data-test="HotelPolicy.NonRefundableRooms.Title">
									<Trans i18nKey="hotel-policy-settings.non-refundable-rooms">Non-Refundable Rooms</Trans>
								</PolicySettingTitle>
								<PolicySettingContent>
									<PolicyRadioOptionsContainer>
										{refundableOptions.map((opt, i) => (
											<RadioInput
												key={opt.value + i}
												e2e={`HotelPolicy.NonRefundableRooms.${opt.value}`}
												name="non_flexible_hotel_rule"
												label={resolveBindableLabel(this, opt.label)}
												checked={nonFlexibleValue === opt.value}
												disabled={!isPermitted}
												onToggle={() =>
													this.updateRule('non_flexible_hotel_rule', {
														...rules.non_flexible_hotel_rule,
														allow: opt.value === PolicyRefundOptions.Always,
														permit_up_to:
															opt.value !== PolicyRefundOptions.UpToHours
																? 0
																: rules.non_flexible_hotel_rule.permit_up_to || 24,
													})
												}
											/>
										))}
									</PolicyRadioOptionsContainer>
								</PolicySettingContent>
							</PolicySettingRow>

							{companyFeatureFlags?.enable_non_traditional_accommodation && (
								<PolicySettingRow data-test="HotelPolicy.AccommodationType.Section">
									<PolicySettingTitle data-test="HotelPolicy.AccommodationType.Title">
										{t('hotel-policy-settings.accommodation-type.title', 'Accommodation Types')}
									</PolicySettingTitle>
									<PolicySettingContent as={Stack} gap={3}>
										<Stack direction={{ xs: 'column', lg: 'row' }} spacing={{ xs: 2, lg: 6 }}>
											{chunk(accommodationTypeLabels, 3).map((chunk) => (
												<Stack key={chunk[0][0]} spacing={2} minWidth="180px">
													{chunk.map(([type, label]) => (
														<Checkbox
															data-test={`HotelPolicy.AccommodationType.Option.${type}`}
															key={type}
															checked={accommodationTypeHelpers.isChecked(accommodationTypes, type)}
															disabled={!isPermitted}
															label={label}
															onToggle={(isChecked) =>
																this.updateRule('accomodation_type', {
																	allowed: accommodationTypeHelpers
																		.onChecked(accommodationTypes, type, isChecked)
																		.sort(),
																})
															}
														/>
													))}
												</Stack>
											))}
										</Stack>
										{!isAccommodationTypeValid && (
											<Box color={outOfPolicy} data-test="HotelPolicy.AccommodationType.NoCheckedMessage">
												{t(
													'hotel-policy-settings.accommodation-type.no-checked-message',
													'At least one accommodation type should be selected',
												)}
											</Box>
										)}
									</PolicySettingContent>
								</PolicySettingRow>
							)}

							<PolicySettingRow data-test="HotelPolicy.MaxHotelRating.Section">
								<PolicySettingTitle data-test="HotelPolicy.MaxHotelRating.Title">
									<Trans i18nKey="hotel-policy-settings.maximum-hotel-rating">Maximum Hotel Rating</Trans>
									<PolicySettingSubtitle>
										<Trans i18nKey="hotel-policy-settings.per-night">per night</Trans>
									</PolicySettingSubtitle>
								</PolicySettingTitle>
								<PolicySettingRightColumn>
									{relevantRoleOptions.map((role) => (
										<GlobalPriceCapContainer key={role.value}>
											<span data-test="HotelPolicy.Role">{role.label}</span>
											<span>
												<StyledAdjustableStarRating
													data-test={'HotelPolicySettings.RatingStar'}
													rating={getMaxRating(role.value)}
													variant="orange"
													hoverColor="orangeDark"
													onRate={(rating) => this.updateMaxRatingRule(role.value, rating)}
												/>
											</span>
										</GlobalPriceCapContainer>
									))}
								</PolicySettingRightColumn>
							</PolicySettingRow>
							<PolicySettingRow data-test="HotelPolicy.GlobalPriceCap.Section">
								<PolicySettingTitle data-test="HotelPolicy.GlobalPriceCap.Title">
									<Trans i18nKey="hotel-policy-settings.global-price-cap">Global Price Cap</Trans>
									<PolicySettingSubtitle>
										<Trans i18nKey="hotel-policy-settings.per-night">per night</Trans>
									</PolicySettingSubtitle>
								</PolicySettingTitle>
								<PolicySettingRightColumn>
									{relevantRoleOptions.map((role) => (
										<GlobalPriceCapContainer key={role.value}>
											<RoleLabel data-test="HotelPolicy.Role" disabled={!isPermitted}>
												{role.label}
											</RoleLabel>
											<span>
												<EditablePriceInput
													e2e="HotelPolicy.GlobalPriceCap.MaxPrice"
													disabled={!isPermitted}
													value={getPriceCap(role.value).toString()}
													currency={policy?.currency_code}
													onChange={(e) => this.updateMaxPriceRule(role.value, e.currentTarget.value.replace(/-/g, ''))}
												/>
											</span>
										</GlobalPriceCapContainer>
									))}
								</PolicySettingRightColumn>
							</PolicySettingRow>
							<PriceCapTableContainer data-test="HotelPolicy.ManualPriceCap.Section">
								<PolicySettingTitle data-test="HotelPolicy.ManualPriceCap.Title">
									<Trans i18nKey="hotel-policy-settings.manual-price-cap">Manual Price Cap</Trans>
								</PolicySettingTitle>
								<PriceCapTable
									disabled={!isPermitted}
									rates={priceCapRoutes}
									currency={policy?.currency_code}
									onSave={(rates) => this.updateRule('hotel_max_rate__rule', { rates })}
								/>
							</PriceCapTableContainer>
						</PolicySettings>
					</>
				)}
			</FeaturePermit>
		)
	}

	private updateRule<K extends keyof HotelPolicyRules>(ruleName: K, value: Partial<HotelPolicyRules[K]>) {
		const { rules } = this.state
		rules[ruleName] = { ...rules[ruleName], ...value }
		this.setState({ rules })
	}

	private getRule<K extends keyof HotelPolicyRules, S extends keyof HotelPolicyRules[K]>(
		ruleName: K,
		keyName: S,
		defaultValue?: any,
	): HotelPolicyRules[K][S] {
		const { rules } = this.state
		return !rules || !rules[ruleName] ? defaultValue : rules[ruleName][keyName] || defaultValue
	}

	private updateMaxPriceRule(role: Role, input: string) {
		const roleRates = this.getRule('hotel_max_rate__rule', 'max_rates_by_role', {})
		const num = parseInt(input, 10)
		const updateVal = isNaN(num) || num === 0 ? undefined : num
		return this.updateRule('hotel_max_rate__rule', { max_rates_by_role: { ...roleRates, [role]: updateVal } })
	}

	private updateMaxRatingRule(role: Role, rating: number) {
		const roleRates = this.getRule('max_hotel_rating', 'max_stars_by_role', {})
		return this.updateRule('max_hotel_rating', { max_stars_by_role: { ...roleRates, [role]: rating } })
	}

	private saveChanges() {
		if (this.props.onSave) {
			this.props.onSave(this.state.rules)
		}
	}

	private get globalPriceCap(): Record<Role, number | null> {
		return this.getRule('hotel_max_rate__rule', 'max_rates_by_role', {})
	}

	private get maxRatings(): Record<Role, number> {
		return this.getRule('max_hotel_rating', 'max_stars_by_role', {})
	}

	private get priceCapRoutes(): HotelPriceCapRoute[] {
		return this.getRule('hotel_max_rate__rule', 'rates', [])
	}

	private get relevantRoleOptions(): SelectOption[] {
		return HotelPolicySettings.roleOptions
	}
}

const mapStateToProps = ({ auth, adminUsers, myCompany }: ApplicationState): StateProps => {
	return {
		my: auth.get('internalUser'),
		users: adminUsers,
		companyFeatureFlags: myCompany?.feature_flag,
	}
}

export default connect<StateProps, never, OwnProps, ApplicationState>(mapStateToProps)(
	withTranslation()(HotelPolicySettings),
)
