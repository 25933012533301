import { EmptyVoidCallback, User } from 'src/travelsuit'

const FRESH_CHAT_TOKEN = '4c2c7b56-8fda-4121-ab09-968f20702918'
const PRODUCT_ACCOUNT_ID = '946246'
const GROUP_ID = '43000056464'

export function openExternalChatPopup(cb?: EmptyVoidCallback) {
	const w = window as any
	if (w.fcWidget) {
		w.fcWidget.open()
	} else {
		console.warn('freshchat not found')
	}
	if (cb) {
		cb?.()
	}
}

export const initFreshChat = (user: User) => {
	const initFreshChat = () => {
		const userData = {
			firstName: user.first_name,
			lastName: user.last_name,
			email: user.email,
			meta: {
				plan: 'Pro',
				status: 'Active',
				cf_pos: user.invoice_profile?.country?.name || '',
			},
		}
		const w = window as any
		w.fcWidget.on('widget:loaded', () => {
			w.fcWidget.user.isExists((response: { data: boolean; status: number; success: boolean }) => {
				if (response.data === false) {
					w.fcWidget.user.create(userData)
				} else {
					w.fcWidget.user.update(userData)
				}
			})
		})
		w.fcWidget.init({
			token: FRESH_CHAT_TOKEN,
			host: 'https://wchat.freshchat.com',
			open: false,
			config: {
				disableEvents: true,
				showFAQOnOpen: false,
				agent: {
					hideName: true,
					hidePic: true,
					hideBio: true,
				},
				headerProperty: {
					backgroundImage: 'https://public-data-cxbu.freddybot.com/autofaq/66457089-2297-403a-9a30-c4bfdf57084d.png',
					hideChatButton: false,
					direction: 'ltr',
				},
			},
			meta: {
				fw_product: 'freshdesk',
				fw_product_acc_id: PRODUCT_ACCOUNT_ID,
				fw_group_id: GROUP_ID,
			},
		})
	}

	if (document.getElementById('Freshdesk Messaging-js-sdk')) {
		initFreshChat()
	} else {
		const freshChatScript = document.createElement('script')

		freshChatScript.id = 'Freshdesk Messaging-js-sdk'
		freshChatScript.async = true
		freshChatScript.src = 'https://wchat.freshchat.com/js/widget.js'
		freshChatScript.onload = initFreshChat

		document.head.appendChild(freshChatScript)
	}
}
