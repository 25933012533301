import { ActionMap, RequestTypeActionMap } from 'src/redux/actions/action-helpers'

export const AdminLocationsTypes = RequestTypeActionMap(
	['GetAdminLocations', 'AddAdminLocation', 'UpdateAdminLocation', 'RemoveAdminLocation'],
	'AdminLocations',
)

export const CustomerSuccessConsultantTypes = RequestTypeActionMap(
	['GetCustomerSuccessConsultant'],
	'CustomerSuccessConsultant',
)

export const AdminUsersTypes = RequestTypeActionMap(
	[
		'GetAllUsers',
		'AddUser',
		'UpdateUser',
		'RemoveUser',
		'GetDeactivationInfo',
		'DeactivateUser',
		'AddUserRole',
		'RemoveUserRole',
		'InviteUser',
	],
	'AdminUsers',
)

export const AirlinesTypes = ActionMap(['SetAirlines'], 'Airlines')

export const AirportsTypes = ActionMap(['SetAirports'], 'Airports')

export const AlliancesTypes = ActionMap(['SetAlliances'], 'Alliances')

export const AuthTypes = {
	...ActionMap(
		[
			'LoginDone',
			'LoginError',
			'LogoutDone',
			'LogoutError',
			'SetInternalUser',
			'SetTermsAccepted',
			'ShowModal',
			'HideModal',
		],
		'Auth',
	),
	...RequestTypeActionMap(
		[
			'InternalLogin',
			'SessionInfo',
			'UpdateSession',
			'SetIsMockEnabled',
			'SetIsTokenizerMockEnabled',
			'SetIsWorldlineMockEnabled',
		],
		'Auth',
	),
}

export const CarRatesTypes = RequestTypeActionMap(['GetCarRates'], 'CarRates')

export const CountriesTypes = {
	...RequestTypeActionMap(['GetCountries'], 'Countries'),
	...ActionMap(['SetCountries'], 'Countries'),
}

export const DepartmentsTypes = RequestTypeActionMap(
	['GetAllDepartments', 'GetDepartment', 'UpdateDepartment', 'CreateDepartment', 'DeleteDepartment'],
	'Departments',
)

export const ExpensesTypes = RequestTypeActionMap(
	['AddExpense', 'UpdateExpense', 'RemoveExpense', 'DownloadExpenses'],
	'Expenses',
)

export const ExpensesNewTypes = {
	...RequestTypeActionMap(
		['GetExpense', 'GetExpenses', 'DeleteExpense', 'UpdateExpense', 'CreateExpense'],
		'ExpensesNew',
	),
}

export const ExpensePolicyTypes = {
	...RequestTypeActionMap(
		['GetPolicy', 'UpdatePolicy', 'UpdateApproversPolicy', 'GetMileagePolicy', 'GetCategories'],
		'ExpensePolicy',
	),
}

export const MileageRatesTypes = RequestTypeActionMap(
	['GetRates', 'UpdateRate', 'CreateRate', 'DeleteRate'],
	'MileageRates',
)

export const DailyAllowancesPolicyTypes = {
	...RequestTypeActionMap(
		[
			'GetDestinations',
			'CreateDestination',
			'UpdateDestination',
			'DeleteDestination',
			'CreateRate',
			'UpdateRate',
			'DeleteRate',
		],
		'DailyAllowancesPolicy',
	),
}

export const ExpenseReportsTypes = {
	...RequestTypeActionMap(
		[
			'GetReport',
			'GetReports',
			'DeleteReport',
			'UpdateReport',
			'CreateReport',
			'SubmitReport',
			'ApproveReport',
			'RejectReport',
			'ExportReports',
		],
		'ExpenseReports',
	),
}

export const TaxAndVatRateTypes = RequestTypeActionMap(
	['GetRates', 'CreateRate', 'UpdateRate', 'DeleteRate'],
	'TaxAndVatRates',
)

export const HomePageTypes = RequestTypeActionMap(['GetHomePage'], 'HomePage')

export const HotelsInfoTypes = RequestTypeActionMap(['GetHotelInfo'], 'HotelInfo')

export const HotelsRatesTypes = RequestTypeActionMap(['GetHotelRates'], 'HotelRates')

export const CompanyInvoiceProfilesTypes = {
	...RequestTypeActionMap(
		[
			'CreateCompanyInvoiceProfile',
			'GetCompanyInvoiceProfiles',
			'GetCompanyInvoiceProfile',
			'UpdateCompanyInvoiceProfile',
			'DeleteCompanyInvoiceProfile',
			'ConfirmCompanyInvoiceProfile',
			'AcceptTermsAndConditionsForInvoiceProfile',
		],
		'CompanyInvoiceProfile',
	),
	...ActionMap(['AddCompanyCreditCard', 'UpdateCompanyCreditCard', 'DeleteCompanyCreditCard'], 'CompanyInvoiceProfile'),
}

export const ItineraryTypes = {
	...RequestTypeActionMap(
		[
			'GetItinerary',
			'BookFlight',
			'BookFlightNew',
			'BookRail',
			'CheckFlightBooking',
			'CancelFlightBooking',
			'CancelTrainBooking',
			'BookCar',
			'CheckCarBooking',
			'CancelCarBooking',
			'BookHotelRoom',
			'CheckHotelBooking',
			'CancelHotelBooking',
			'AddFlightLuggage',
			'RemoveFlightLuggage',
			'ConfirmPriceChange',
			'RefusePriceChange',
		],
		'Itinerary',
	),
	...ActionMap(['ClearItineraryChanges'], 'Itinerary'),
}

export const LiveUserLocationsTypes = RequestTypeActionMap(['GetLiveUserLocations'], 'LiveUserLocations')

export const RecentSearchesTypes = RequestTypeActionMap(['GetRecentSearches'], 'RecentSearches')

export const MyCompanyTypes = RequestTypeActionMap(
	[
		'UpdateMyCompanyLogo',
		'GetMyCompany',
		'UpdateMyCompanyDetails',
		'UpdateMyCompanyPrefernces',
		'ValidateBmisNumberInMyCompany',
		'SaveBmisNumberInMyCompany',
		'CreateCompany',
		'AcceptTermsOfServices',
		'DeleteBmisNumberFromCompany',
	],
	'MyCompany',
)
export const PackagesTypes = RequestTypeActionMap(['GetPackages'], 'Packages')
export const PackagesUsagesTypes = RequestTypeActionMap(['GetUsageDetails'], 'PackagesUsages')

export const PackagesAndBillingSettingsTypes = RequestTypeActionMap(
	[
		'GetPackagesAndBillingSettings',
		'UpdatePaymentInfo',
		'CreatePackagesAndBillingSettings',
		'UpdatePackagesAndBillingSettings',
	],
	'PackagesAndBillingSettings',
)

export const MyUserRolesTypes = {
	...ActionMap(['AddToMyUserRoles', 'RemoveFromMyUserRoles'], 'MyUserRoles'),
	...RequestTypeActionMap(['GetMyUserRoles'], 'MyUserRoles'),
}

export const ReportsTypes = {
	...RequestTypeActionMap(
		[
			'GetOverview',
			'GetMeta',
			'GetFlightDetails',
			'GetHotelDetails',
			'GetCarDetails',
			'GetRailDetails',
			'GetTravelerDetails',
		],
		'Reports',
	),
	...ActionMap(['SetFilters', 'UpdateFilters'], 'Reports'),
}

export const RolesTypes = RequestTypeActionMap(['GetAllRoles'], 'Roles')

export const RootTypes = ActionMap(['ClearStore'], 'Root')

export const SearchResultsTypes = {
	...ActionMap(['SetSearchResult', 'UnsetSearchResult'], 'SearchResults'),
	...RequestTypeActionMap(['LikeHotel', 'UnlikeHotel'], 'SearchResults'),
}

export const SearchesTypes = {
	...RequestTypeActionMap(['CreateSearch', 'GetSearch', 'UpdateSearch'], 'Searches'),
}

export const SeatmapTypes = RequestTypeActionMap(
	['GetForFlight', 'SelectSeats', 'DeleteSeats', 'ManageItinerarySeat'],
	'SeatMap',
)

export const FareRuleTypes = RequestTypeActionMap(['GetFareRules'], 'FareRules')

export const SupportTypes = RequestTypeActionMap(
	['GetAllCompanies', 'ImpersonateSupportCompany', 'ApproveTripOnBehalf'],
	'Support',
)

export const TravelPolicyTypes = RequestTypeActionMap(
	[
		'GetPolicy',
		'GetAllPolicies',
		'GetPoliciesByType',
		'UpdatePolicy',
		'CreateOfficePolicy',
		'CreateCustomPolicy',
		'DeletePolicy',
		'GetIgnoredUsers',
		'UpdateIgnoredUsers',
		'GetPolicyForUsers',
	],
	'PolicyRules',
)

export const TravelersTypes = RequestTypeActionMap(
	[
		'GetUser',
		'GetUserTitles',
		'GetTraveler',
		'CreateTraveler',
		'UpdateTraveler',
		'RemoveTraveler',
		'AddPassport',
		'UpdatePassport',
		'RemovePassport',
		'AddNationalId',
		'UpdateNationalId',
		'RemoveNationalId',
		'AddFrequentFlyer',
		'UpdateFrequentFlyer',
		'RemoveFrequentFlyer',
		'AddHotelLoyalty',
		'UpdateHotelLoyalty',
		'RemoveHotelLoyalty',
		'AddCarLoyalty',
		'UpdateCarLoyalty',
		'RemoveCarLoyalty',
		'AddRailCard',
		'UpdateRailCard',
		'RemoveRailCard',
	],
	'Travelers',
)

export const TripApprovalsTypes = RequestTypeActionMap(['GetTripApprovals'], 'TripApprovals')

export const TripGapsTypes = {
	...RequestTypeActionMap(['GetTripGaps'], 'TripGaps'),
	...ActionMap(['SetTripGapForSearch', 'ResetTripGaps'], 'TripGaps'),
}

export const TripPaymentMethodsTypes = RequestTypeActionMap(
	['GetPaymentMethods', 'UpdatePaymentMethod'],
	'TripPaymentMethods',
)

export const PermissionsTypes = RequestTypeActionMap(
	['GetPermissions', 'AddPermission', 'RemovePermission'],
	'Permissions',
)

export const TripRequestsTypes = RequestTypeActionMap(
	['GetTripRequests', 'CreateTripRequest', 'SubmitTripRequest', 'ManageTripRequest', 'DeleteTripRequest'],
	'TripRequest',
)

export const TripSupportRequestsTypes = RequestTypeActionMap(
	['GetSupportRequests', 'CreateSupportRequest'],
	'TripSupportRequests',
)

export const TripTypes = {
	...RequestTypeActionMap(
		[
			'GetAllTrips',
			'GetTrip',
			'GetTripRailRedirectLink',
			'CreateTrip',
			'UpdateTrip',
			'RemoveTrip',
			'AddTraveler',
			'RemoveTraveler',
			'AddNote',
			'EditNote',
			'RemoveNote',
			'SubmitTrip',
			'SubmitTripForApproval',
			'ApproveTrip',
			'RejectTrip',
			'NotifyTripPlannerPriceChange',
			'NotifyTripPlannerRevert',
			'MergeTrip',
			'GetBookingStatus',
			'VerifyBookingPayment',
			'CancelBookingVerificationForSegment',
		],
		'Trips',
	),
	...ActionMap(['ClearTrips'], 'Trips'),
}

export const UserTrackingTypes = RequestTypeActionMap(['UserTracking'], 'UserTracking')

export const TrainTicketDiscountTypes = RequestTypeActionMap(['getTicketDiscounts'], 'TrainDiscountCards')

export const CompanyTypeModal = ActionMap(['ShowMyCompanyModal', 'HideMyCompanyModal'], 'MyCompanyModal')

export const Train = RequestTypeActionMap(['RailRedirect'], 'RailRedirect')

export const TripCancellationStatus = RequestTypeActionMap(['GetCancellationStatus'], 'Trips')

export const UserPOS = RequestTypeActionMap(['GetUserPOS'], 'UserPOS')

export const CountryPOSList = RequestTypeActionMap(['GetCountryPOSList'], 'CountryPOSList')

export const CovidInfoTypes = RequestTypeActionMap(['GetCovidInfo'], 'CovidInfo')

export const CountryLanguagesTypes = RequestTypeActionMap(['GetCountryLanguages'], 'CountryLanguages')

export const IndustriesTypes = RequestTypeActionMap(['GetIndustries'], 'Industries')

export const TripPurposeTypes = RequestTypeActionMap(['GetTripPurposes'], 'TripPurpose')

export const SelectedDocumentsForTrip = ActionMap(['SetSelectedDocuments'], 'SelectedDocuments')

export const EssentialTravelInformationTypes = RequestTypeActionMap(
	['GetEssentialTravelInformation'],
	'EssentialTravelInformation',
)

export const ReportSettingsTypes = RequestTypeActionMap(['GetReportSettings', 'UpdateReportSettings'], 'ReportSettings')

export const CompanyFlightProgramsTypes = RequestTypeActionMap(
	[
		'GetCompanyFlightPrograms',
		'CreateCompanyFlightProgram',
		'UpdateCompanyFlightProgram',
		'DeleteCompanyFlightProgram',
	],
	'CompanyFlightPrograms',
)

export const CompanyHotelProgramsTypes = RequestTypeActionMap(
	['GetCompanyHotelPrograms', 'CreateCompanyHotelProgram', 'UpdateCompanyHotelProgram', 'DeleteCompanyHotelProgram'],
	'CompanyHotelPrograms',
)

export const CompanyCarProgramsTypes = RequestTypeActionMap(
	['GetCompanyCarPrograms', 'CreateCompanyCarProgram', 'UpdateCompanyCarProgram', 'DeleteCompanyCarProgram'],
	'CompanyCarPrograms',
)
