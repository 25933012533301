import { i18n, StringMap, TFunction as TFunctionOriginal, TOptions } from 'i18next'

import { User } from 'src/travelsuit'

type TFunctionResult = string | object | Array<string | object> | undefined | null
type TFunctionKeys = string | TemplateStringsArray
export interface TFunction {
	// basic usage
	<
		TResult extends TFunctionResult = string,
		TKeys extends TFunctionKeys = string,
		TInterpolationMap extends object = StringMap,
	>(
		key: TKeys | TKeys[],
		options?: TOptions<TInterpolationMap> | string,
	): TResult
	// overloaded usage
	<
		TResult extends TFunctionResult = string,
		TKeys extends TFunctionKeys = string,
		TInterpolationMap extends object = StringMap,
	>(
		key: TKeys | TKeys[],
		defaultValue?: string,
		options?: TOptions<TInterpolationMap> | string,
	): TResult
}
interface WithT {
	t: TFunction
}

export interface WithTranslation extends WithT {
	i18n: i18n
	tReady: boolean
}

export function getTWithGender(t: TFunctionOriginal, user?: User): TFunction {
	return (key: string | TemplateStringsArray, defaultValue?: string, options?: { [key: string]: any }) => {
		let mergedOptions: any = {}
		if (options) {
			mergedOptions = { ...options }
		}

		// Add our gender info for i18n, if it doesn't already exist
		if (mergedOptions.context === undefined && user?.traveler_profile) {
			mergedOptions.context = user.traveler_profile.gender
		}

		return t(key, defaultValue, mergedOptions)
	}
}
