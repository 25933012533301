export class ImmutableMap<K, V> {
	private map: Map<K, V>

	constructor(entries: [K, V][] | Map<K, V> = []) {
		this.map = new Map(entries)
	}

	public get(key: K) {
		return this.map.get(key)
	}

	public getWithFallback(key: K, defaultValue: V): V {
		if (!this.has(key)) {
			return defaultValue
		}
		return this.map.get(key)!
	}

	public set(key: K, value: V) {
		const clone = this.clone()
		clone.map.set(key, value)
		return clone
	}

	public has(key: K) {
		return this.map.has(key)
	}

	public delete(key: K) {
		const clone = this.clone()
		clone.map.delete(key)
		return clone
	}

	public get size() {
		return this.map.size
	}

	public count() {
		return this.size
	}

	public toArray() {
		return [...this.values()]
	}

	public entries() {
		return this.map.entries()
	}

	public values() {
		return this.map.values()
	}

	public filter(predicate: (value: V, key: K) => boolean) {
		const clone = this.clone()
		this.map.forEach((value, key) => {
			if (!predicate(value, key)) {
				clone.map.delete(key)
			}
		})

		return clone
	}

	public update(key: K, updater: (value: V) => V) {
		const value = this.get(key)

		if (!value) {
			return this
		}

		return this.set(key, updater(value))
	}

	public updatePart(key: K, updater: (value: V) => Partial<V>) {
		return this.update(key, (entity) => ({
			...entity,
			...updater(entity),
		}))
	}

	public keys() {
		return this.map.keys()
	}

	public toJS() {
		const record: Record<string, V> = {}

		for (const [key, value] of this.map.entries()) {
			record[String(key)] = value
		}

		return record
	}

	private clone() {
		return new ImmutableMap(this.map)
	}

	public addEntries(entries: [K, V][]) {
		const cloned = this.clone()
		entries.forEach(([key, value]) => {
			cloned.map.set(key, value)
		})

		return cloned
	}
}
