import AirPlusImageURL from 'src/assets/credit-card-brands/air-plus.svg'
import AmericanExpressImageURL from 'src/assets/credit-card-brands/american-express.svg'
import DinersClubImageURL from 'src/assets/credit-card-brands/diners-club.svg'
import MasterCardImageURL from 'src/assets/credit-card-brands/master-card.svg'
import DefaultCreditCardIconURL from 'src/assets/credit-card-brands/other.png'
import VisaImageURL from 'src/assets/credit-card-brands/visa.png'
import { CreditCardType } from 'src/types/creditCard'

export const CreditCardBrandMap: { [keys in CreditCardType]: string } = {
	[CreditCardType.Other]: DefaultCreditCardIconURL,
	[CreditCardType.AmericanExpress]: AmericanExpressImageURL,
	[CreditCardType.AirPlus]: AirPlusImageURL,
	[CreditCardType.Diners]: DinersClubImageURL,
	[CreditCardType.Visa]: VisaImageURL,
	[CreditCardType.AmexBTA]: AmericanExpressImageURL,
	[CreditCardType.AmexBTAGermany]: AmericanExpressImageURL,
	[CreditCardType.MasterCard]: MasterCardImageURL,
}
