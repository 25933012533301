import { useCallback, useEffect, useRef } from 'react'

export function useDelayedAction() {
	const timeoutDataRef = useRef<{ timeoutId?: number; onReset?: () => void }>({})

	const resetTimeout = useCallback(() => {
		const { timeoutId, onReset } = timeoutDataRef.current
		window.clearTimeout(timeoutId)
		onReset?.()
		timeoutDataRef.current = {}
	}, [])

	useEffect(() => {
		return resetTimeout
	}, [resetTimeout])

	return useCallback(
		(executeAction: () => void, timeout = 0, onReset?: () => void) => {
			resetTimeout()
			timeoutDataRef.current = {
				timeoutId: window.setTimeout(executeAction, timeout),
				onReset,
			}
		},
		[resetTimeout],
	)
}
