import { IAction } from 'src/redux/actions/action-helpers'
import { FareRuleTypes } from 'src/redux/actions/actions.types'
import { Callback, VoidCallback } from 'src/travelsuit'
import { FareRulesRequestJobParams, FareRulesResponse } from 'src/travelsuit/fare-rules'

export const Actions = {
	getFareRules(
		params: FareRulesRequestJobParams,
		callback?: VoidCallback<FareRulesResponse>,
		errCallback?: Callback,
	): IAction {
		return {
			type: FareRuleTypes.GetFareRules.REQUEST_JOB,
			payload: params,
			callback,
			errCallback,
		}
	},
}
