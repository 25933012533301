import React from 'react'

import { Picture, PictureProps } from 'src/atoms/Picture'
import { Stack } from 'src/atoms/System'
import { UnavailablePageInfo } from 'src/atoms/UnavailablePageInfo'
import styled from 'src/styles'

interface ErrorPageBaseProps {
	className?: string
	buttons: React.ReactNode
	pictureProps: PictureProps
	title: string
	subtitle: string
	pageInfoContent?: React.ReactNode
}

const StyledPageInfo = styled(UnavailablePageInfo)`
	margin-top: auto;
`

const Container = styled(Stack)`
	justify-content: space-evenly;

	${(p) => p.theme.breakpoints.up('lg')} {
		justify-content: flex-end;
	}
`

export function ErrorPageBase({
	className,
	title,
	subtitle,
	buttons,
	pictureProps,
	pageInfoContent,
}: ErrorPageBaseProps) {
	return (
		<Container className={className}>
			<StyledPageInfo title={title} subtitle={subtitle} buttons={buttons}>
				{pageInfoContent}
			</StyledPageInfo>

			<Picture {...pictureProps} />
		</Container>
	)
}
