import { AlignSelfProperty, BgPosition, BgSize, Globals } from 'csstype'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { lightMidGray } from 'src/_vars'
import { Button } from 'src/atoms/Button'
import { SvgButton } from 'src/atoms/SvgButton'
import { CloseIcon, closeIconStyles } from 'src/atoms/SvgIcon'
import { passResponsive, ResponsiveProps } from 'src/css-helpers/media-query'
import { forceArray } from 'src/lib/array-utils'
import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import CrossRoundIcon from 'src/refactor/assets/icons/cross-round.svg'
import { backgroundGray, brightRed, disabledGray, linkBlue, mainBlack } from 'src/refactor/colors'
import { font, FontProps, FontType, pixelFontSize } from 'src/refactor/fonts'
import { css, flex, FlexProps, grid, GridProps, mediaQuery, padMulti, padPx, size } from 'src/styles'
import { ArrayOr, DefaultElProps } from 'src/travelsuit'

export enum PageMode {
	Edit = 'edit',
	Create = 'create',
	View = 'view',
}

export const PageTitle = styled.h1<{ flex?: FlexProps | boolean; bottomMargin?: number | string }>`
	color: ${mainBlack};
	${font(FontType.Comfortaa, { size: 32 })}
	${(props) =>
		props.flex ? flex({ align: 'center', gap: 10, ...(typeof props.flex === 'object' ? props.flex : {}) }) : ''}
	${(props) => (props.bottomMargin ? `margin-bottom: ${padPx(props.bottomMargin)}` : '')}
`

export const PageSubtitle = styled.div.attrs(addE2EAttrs)<E2E>`
	color: ${lightMidGray};
	font-size: ${pixelFontSize(16)};
	line-height: 1.4;
`

export const AddButton = styled(Button).attrs(() => ({ paddingSize: 'md', color: 'primary' }))`
	min-width: 160px;
	margin-left: 15px;
`

export const CloseButton = styled(CloseIcon)`
	cursor: pointer;
	transition: color 150ms ease-in-out;

	&:hover {
		color: ${linkBlue};
	}
`

export const RoundCloseButton = styled(SvgButton).attrs({ src: CrossRoundIcon })`
	${closeIconStyles()}
`

interface PositionedProps {
	zIndex: React.CSSProperties['zIndex']
	position: React.CSSProperties['position']
	display: React.CSSProperties['display']
	top: React.CSSProperties['top']
	right: React.CSSProperties['right']
	bottom: React.CSSProperties['bottom']
	left: React.CSSProperties['left']
}

// TODO: BCDTDRCT-1844 rework this component on styled.div, check that nothing is broken
const Positioned = forwardRef<HTMLDivElement, DefaultElProps<'div'> & Partial<PositionedProps>>((props, ref) => {
	const { className, children, style, position, zIndex, display, top, right, bottom, left, e2e, ...rest } = props

	return (
		<div
			ref={ref}
			className={className}
			data-test={e2e}
			style={{ position, zIndex, display, top, right, bottom, left, ...style }}
			{...rest}
		>
			{children}
		</div>
	)
})

export const RelativeContainer = styled(Positioned).attrs(() => ({
	position: 'relative',
}))``

export const AbsoluteContainer = styled(Positioned).attrs(() => ({
	position: 'absolute',
}))``

export const StickyContainer = styled(Positioned).attrs(() => ({ position: 'sticky' }))``

interface MediaQueryElProps {
	inline?: boolean
	width?: string
}

/** This element and its children will be only visible on desktop. */
export const DesktopOnly = styled.div<MediaQueryElProps>`
	${mediaQuery.hideBelow('desktop')}
	${(props) => (props.inline ? `display: inline-block;` : '')}
	${(props) => (props.width ? `width: ` + props.width : '')}
`

export const LargeScreenOnly = styled.div`
	${(p) => p.theme.breakpoints.down('md')} {
		display: none;
	}
`

export const BelowLargeScreen = styled.div`
	${(p) => p.theme.breakpoints.up('lg')} {
		display: none;
	}
`

/** This element and its children will be only visible on tablet. */
export const TabletOrAbove = styled.div<MediaQueryElProps>`
	${mediaQuery.hideBelow('tablet')}
	${(props) => (props.inline ? `display: inline-block;` : '')}
	${(props) => (props.width ? `width: ` + props.width : '')}
`
/** This element and its children will be only visible on mobile. */
export const MobileOnly = styled.div<MediaQueryElProps>`
	${mediaQuery.hideAbove('mobile')}
	${(props) => (props.inline ? `display: inline-block;` : '')}
`

export const Spacer = styled.span`
	flex: 1;
`
interface SizedBoxProps {
	width: number | string
	height: number | string
	maxWidth: number | string
	maxHeight: number | string
	inline: boolean
}
export const SizedBox = styled.div<ResponsiveProps<SizedBoxProps>>`
	flex-shrink: 0;
	${passResponsive(
		(props) => `
		${(props as any).as || props.inline ? `display: inline-block;` : ''}
		${props.width !== undefined || props.height !== undefined ? size(props.width ?? 'auto', props.height ?? 'auto') : ''}
		${
			props.maxWidth !== undefined || props.maxHeight !== undefined
				? `
			${props.maxWidth !== undefined ? `max-width: ${padPx(props.maxWidth)}` : ''}
			${props.maxHeight !== undefined ? `max-height: ${padPx(props.maxHeight)}` : ''}
		`
				: ''
		}
	`,
	)}
`
export const Font = styled.span<
	Partial<FontProps> & { mobileProps?: Partial<FontProps> } & {
		textWrap?: 'balance' | 'pretty' | 'stable' | 'wrap' | 'nowrap'
	}
>`
	color: ${(p) => p.color};
	${(props) => font(props)}
	${(props) => (props.mobileProps ? mediaQuery.mobileOnly`${font(props)}` : '')}
	${(props) => (props.textWrap ? `text-wrap: ${props.textWrap};` : '')}
`

export const NoWrap = styled.span.attrs(addE2EAttrs)<{ display?: string } & E2E>`
	white-space: nowrap;
	${(props) => (props.display ? `display: ${props.display};` : '')}
`
export const TextAlign = styled.div<{ align?: React.CSSProperties['textAlign'] }>`
	text-align: ${(props) => props.align};
`
export const Center = styled.div`
	${flex({ justify: 'center', align: 'center', direction: 'column' })}
`

export const FillAbsolute = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`

export const FillFixed = styled(FillAbsolute)`
	position: fixed;
`

export const centeredAbsoluteCss = () => css`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`

export const CenteredAbsolute = styled(Center)`
	${centeredAbsoluteCss()}
`
export const Expanded = styled.div`
	flex: 1;
`

/** @deprecated use Stack instead */
export const Flex = styled.div.attrs(addE2EAttrs)<FlexProps & E2E & { mobileProps?: FlexProps }>`
	${(props) => flex(props)}
	${(p) => p.theme.breakpoints.down('md')} {
		${(p) => (p.mobileProps ? flex(p.mobileProps) : '')}
	}
`
interface FlexItemProps {
	grow?: number
	shrink?: number
	width?: number | string
	maxWidth?: number
	minWidth?: number
	allignSelf?: AlignSelfProperty
}
export const FlexItem = styled.div.attrs(addE2EAttrs)<FlexItemProps & E2E>`
	${({ grow }) => `flex-grow: ${grow ?? 0};`}
	${({ shrink }) => `flex-shrink: ${shrink ?? 1};`}
	${({ width }) => `flex-basis: ${padPx(width) ?? 'auto'};`}
	${({ minWidth }) => (minWidth ? `min-width: ${padPx(minWidth)};` : '')}
	${({ maxWidth }) => (maxWidth ? `max-width: ${padPx(maxWidth)};` : '')}
	${({ allignSelf }) => (allignSelf ? `allign-self: ${allignSelf};` : '')}
`
export const Grid = styled.div.attrs(addE2EAttrs)<GridProps & E2E & { mobileProps?: GridProps }>`
	${(props) => grid(props)}
	${(p) => p.theme.breakpoints.down('md')} {
		${(p) => (p.mobileProps ? grid(p.mobileProps) : '')}
	}
`

export const GridItem = styled.div<{
	column?: number | string
	row?: number | string
	mobileColumn?: number | string
	mobileRow?: number | string
	flex?: string | number
	justify?: 'start' | 'end' | 'center' | 'stretch'
	align?: 'start' | 'end' | 'center' | 'stretch'
	color?: string
}>`
	${(props) => (props.column ? `grid-column: ${props.column};` : '')}
	${(props) => (props.row ? `grid-row: ${props.row};` : '')}
	${(props) => (props.mobileColumn ? mediaQuery.mobileOnly`grid-column: ${props.mobileColumn};`.join('') : '')}
	${(props) => (props.mobileRow ? mediaQuery.mobileOnly`grid-row: ${props.mobileRow};`.join('') : '')}
	${(props) => (props.flex ? `flex: ${props.flex};` : '')}
	${(props) =>
		props.justify
			? css`
					justify-self: ${props.justify};
				`
			: ''}
	${(props) =>
		props.align
			? css`
					align-self: ${props.align};
				`
			: ''}
	${(props) => (props.color ? `color: ${props.color};` : '')}
`

Flex.defaultProps = {
	justify: 'center',
	align: 'center',
	direction: 'row',
}

export const VerticalScroll = styled.div<{ maxHeight: number | string }>`
	max-height: ${(props) => padPx(props.maxHeight)};
	overflow-y: auto;
`

export const HorizontalScroll = styled.div<{ maxWidth: number | string; height: number | string }>`
	max-width: ${(props) => padPx(props.maxWidth)};
	overflow-x: auto;
	${(props) => (props.height ? `height:${padPx(props.height)}` : '')};
`

export const Bold = styled.b<{ fontWeight?: number | string }>`
	font-weight: ${(props) => props.fontWeight || 'bold'};
`

export const Link = styled.a<ResponsiveProps<{ disabled?: boolean; color?: string; font?: Partial<FontProps> }>>`
	${passResponsive(
		(props) => `
		${props.font ? font(props.font, { useDefaultBase: false }) : ''}
		color: ${!props.disabled ? props.color : disabledGray};
		cursor: ${!props.disabled && (props.onClick || props.href) ? 'pointer' : 'default'};
		text-decoration: none;

		&:hover {
			${!props.disabled && (props.onClick || props.href) ? `text-decoration: underline;` : ''}
		}
	`,
	)}
`
Link.defaultProps = {
	color: linkBlue,
}
export const LinkLike = styled(Link)
	.attrs(() => ({ as: 'span' }))
	.attrs(addE2EAttrs)<E2E>``
export const Hr = styled.hr<{
	color?: string
	gutterSize?: Array<number | string | undefined> | number | string
	fullWidth?: boolean
}>`
	color: ${(props) => props.color ?? backgroundGray};
	background: currentColor;
	height: 1px;
	flex-basis: 1px;
	flex-shrink: 0;
	border: none;
	${(props) => (props.gutterSize !== undefined ? gutterSize(props.gutterSize) : '')}
	${(p) => (p.fullWidth ? 'width: 100%;' : '')}
`

function gutterSize(sz: ArrayOr<number | string | undefined>) {
	const _sz = forceArray(sz, false)
	const def = 30

	return `
		margin-top: ${padPx(_sz[0] ?? def)};
		margin-bottom: ${padPx(_sz[_sz.length - 1] ?? def)};
	`
}

export const Asterisk = styled.span`
	color: ${brightRed};
`
type RepeatStyle = 'no-repeat' | 'repeat' | 'repeat-x' | 'repeat-y' | 'round' | 'space'

export interface BgImageProps {
	src: string
	width?: number | string
	height?: number | string
	size?: Globals | BgSize<never>
	position?: Globals | BgPosition<never>
	repeat?: Globals | RepeatStyle
}

export const BgImage = styled.div.attrs(({ src, style }: Pick<DefaultElProps<'img'>, 'src' | 'style'>) => ({
	style: { backgroundImage: `url(${src})`, ...style },
}))<BgImageProps>`
	${(props) => (props.width || props.height ? `${size(props.width! ?? props.height, props.height)}` : '')}
	background-size: ${(props) => props.size ?? 'contain'};
	background-position: ${(props) => props.position ?? 'center'};
	background-repeat: ${(props) => props.repeat ?? 'no-repeat'};
`

export const FirstChildWithMargin = styled.div<{ margin: number | Array<number> }>`
	> *:first-child {
		margin: ${(p) => padMulti(p.margin)};
	}
`

export const Line = styled.div`
	display: flex;
	gap: 15px;
	${mediaQuery.smallMobile`
		gap: 10px;
	`}
`
