import { ImmutableMap } from 'src/lib/immutable/ImmutableMap'
import { RoomRate } from 'src/travelsuit'

import { IAction } from '../actions/action-helpers'
import { HotelsRatesTypes } from '../actions/actions.types'

export type HotelsRatesState = ImmutableMap<string, RoomRate[]>

function hotelsRatesReducer(state: HotelsRatesState = new ImmutableMap(), action: IAction): HotelsRatesState {
	let hotelId: string
	let searchId: number
	switch (action.type) {
		case HotelsRatesTypes.GetHotelRates.SUCCESS:
			hotelId = action.requestPayload.hotelId
			searchId = action.requestPayload.searchId
			return state.set([searchId, hotelId].join('-'), action.payload)
	}
	return state
}

export default hotelsRatesReducer
