import classNames from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from 'src/atoms/Box/Box'
import Button from 'src/atoms/Button/Button'
import Checkbox from 'src/atoms/Checkbox/Checkbox'
import { Link } from 'src/atoms/GenericComponents/GenericComponents'
import { acceptTermsOfService, requestProviderLogout } from 'src/lib/auth'
import { WithTranslation, withTranslation } from 'src/lib/i18n/i18n'
import { Trans } from 'src/lib/i18n/i18n'
import { getCurrentLocale } from 'src/lib/locale-utils'
import { redirectTo, Routes } from 'src/lib/route-utils'
import { PageProps } from 'src/travelsuit'
import { PrivacyPolicyLinkByLanguage } from 'src/travelsuit/locations'

import { ButtonContainer, CheckboxText, Header, Subheading, TermsSection, TextBlock } from './TermsOfService.components'
import css from './TermsOfService.module.css'

interface IProps extends PageProps, WithTranslation {}

const TermsOfService: React.FC<IProps> = ({ className }) => {
	const { t } = useTranslation()
	const termsOfServiceCls = classNames(css.TermsOfService, css.center, className)

	const [checked, setChecked] = useState(false)

	const onToggle = (value: boolean) => {
		setChecked(value)
	}
	const acceptTermsOfServiceHandler = async () => {
		await acceptTermsOfService()
		redirectTo(Routes.Home, undefined, { replace: true })
	}

	const declineTermsOfServiceHandler = () => {
		requestProviderLogout()
	}

	return (
		<Box className={termsOfServiceCls} style={{ borderRadius: 6 }}>
			<Header>{t('term-of-service.title', 'Welcome to GetGoing')}</Header>
			<Subheading>
				{t('term-of-service.subtitle', 'Your journey to the future of business travel starts now!')}
			</Subheading>
			<TextBlock>
				<Trans i18nKey="term-of-service.text">
					GetGoing is a commercial brand and registered trademark of BCD Travel. By way of accessing and/or utilizing
					any of our services, you agree that your personal data will be used in accordance with{' '}
					<Link href={PrivacyPolicyLinkByLanguage[getCurrentLocale()]} target="_blank">
						BCD's Global Privacy Policy
					</Link>
					.
				</Trans>
			</TextBlock>
			<TermsSection>
				<Checkbox checked={checked} onToggle={onToggle} />
				<CheckboxText>
					<Trans i18nKey="term-of-service.privacy-policy">
						I confirm that I have read{' '}
						<Link href={PrivacyPolicyLinkByLanguage[getCurrentLocale()]} target="_blank">
							BCD's Global Privacy Policy
						</Link>
						.
					</Trans>
				</CheckboxText>
			</TermsSection>

			<ButtonContainer>
				<Button color="secondary" paddingSize="lg" onClick={declineTermsOfServiceHandler}>
					{t('term-of-service.decline', 'Decline')}
				</Button>
				<Button color="primary" disabled={!checked} paddingSize="lg" onClick={acceptTermsOfServiceHandler}>
					{t('term-of-service.proceed', 'Proceed')}
				</Button>
			</ButtonContainer>
		</Box>
	)
}

export default withTranslation()(TermsOfService)
