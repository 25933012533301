import { Callback } from 'src/travelsuit'

import { IAction } from './action-helpers'
import { PackagesTypes as Types } from './actions.types'

export const Actions = {
	getPackages(callback?: Callback, errCallback?: Callback): IAction {
		return {
			type: Types.GetPackages.REQUEST,
			callback,
			errCallback,
		}
	},
}
