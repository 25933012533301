import { TFunction } from 'i18next'
import moment from 'moment'
import React from 'react'

import { CreditCardInfo, CreditCardResponse } from 'src/travelsuit'
import { CreditCardStatus, CreditCardType } from 'src/types/creditCard'

import { filterEmptyValues } from './array-utils'
import { replaceBillingRelatedText } from './replaceBillingRelatedText'
import { Maybe } from './types'

const DIGITS_NUMBER_TO_DISPLAY = 4

export function getCreditCardLastDigits(cardNumber: string) {
	return cardNumber.slice(-DIGITS_NUMBER_TO_DISPLAY)
}

export const getHiddenCardNumber = (cardNumber: string) => `**** **** **** ${getCreditCardLastDigits(cardNumber)}`

export function getCreditCardNumbersWithName({ cardName, cardNumbers }: { cardNumbers: string; cardName: string }) {
	return `** ${getCreditCardLastDigits(cardNumbers)} ${cardName}`
}

export function getCreditCardTypeWithNumbersAndName(creditCard: CreditCardInfo) {
	return `${creditCard.card_type.toLocaleUpperCase()} ${getCreditCardNumbersWithName({
		cardNumbers: creditCard.card_number,
		cardName: creditCard.card_name,
	})}`
}

export function isCreditCardExpired({ card_expiration_date }: { card_expiration_date: string }) {
	const expDateInMomentObject = moment(card_expiration_date, 'MM/YY')
	expDateInMomentObject.add(1, 'M')

	return new Date() >= expDateInMomentObject.toDate()
}

export function isCreditCardInvalid(card: CreditCardInfo) {
	return card.status !== CreditCardStatus.Active
}

export interface PaymentMethodOptionAttributes {
	disabled?: boolean
	tooltipContent?: React.ReactNode | React.ReactNode[]
}

export type PaymentMethodOptionValue = CreditCardResponse & PaymentMethodOptionAttributes

export function isRestrictedForFees(card: CreditCardResponse) {
	return !card.permitted_for_fees
}

type CreditCardValidator = [(card: CreditCardResponse) => Maybe<boolean>, string | undefined]

export function calculateCardDisabledReason(card: CreditCardResponse, validators: Maybe<CreditCardValidator>[]) {
	return filterEmptyValues(
		filterEmptyValues(validators).map(([validate, reason]) => (validate(card) ? reason : undefined)),
	)
}

export function getCreditCardTypeNames(t: TFunction): { [keys in CreditCardType]: string } {
	return {
		'air-plus': t('add-credit-card.card-type-items.air-plus', 'AirPlus Company Account (UATP)'),
		'amex-bta': t('add-credit-card.card-type-items.amex-bta', 'Amex Business Travel Account (BTA)'),
		'amex-bta-germany': t(
			'add-credit-card.card-type-items.amex-bta-germany',
			'Amex Business Travel Account (I-BTA for Germany)',
		),
		visa: t('add-credit-card.card-type-items.visa', 'VISA'),
		'master-card': t('add-credit-card.card-type-items.master-card', 'MasterCard'),
		amex: t('add-credit-card.card-type-items.american-express', 'American Express'),
		diners: t('add-credit-card.card-type-items.diners', 'Diners club'),
		other: t('add-credit-card.card-type-items.other', 'Other'),
	}
}

export function getFeesCardDisabledReasons(card: CreditCardResponse, t: TFunction) {
	return calculateCardDisabledReason(card, [
		[isCreditCardExpired, t('credit-card-validation.expired', 'This card has expired.')],
		[isCreditCardInvalid, t('credit-card-validation.invalid', 'This card is invalid.')],
		[
			isRestrictedForFees,
			t(
				'credit-card-validation.not-allowed-for-services',
				'Your company does not allow this card to be used for {{services}} payments.',
				{
					services: replaceBillingRelatedText(
						t('credit-card-validation.service.fee', 'fee'),
						t('credit-card-validation.service.fulfilment-fee', 'Trip fulfilment fee'),
					),
				},
			),
		],
	])
}
