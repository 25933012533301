import React from 'react'

import { capitalize } from 'src/lib/utils'
import styled from 'src/styles'

const UserName = styled.span`
	white-space: nowrap;
`

interface UserGreetingProps {
	user: { first_name: string }
}

export function UserGreeting({ user }: UserGreetingProps) {
	const userName = capitalize(user.first_name || '')

	return <UserName data-test="UserLogin.UserLogin">{userName}</UserName>
}
