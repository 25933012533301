import React from 'react'

import { Grid } from 'src/atoms/GenericComponents'
import { SvgButton } from 'src/atoms/SvgButton'
import { Tooltip } from 'src/atoms/Tooltip'
import { passTestId } from 'src/lib/e2e-utils'
import { eventStopper } from 'src/lib/utils'
import CrossRoundIcon from 'src/refactor/assets/icons/cross-round.svg'
import { disabledGray, fontBlack, white } from 'src/refactor/colors'
import { font, FontSize, textOverflow } from 'src/refactor/fonts'
import styled, { padMulti, padPx } from 'src/styles'
import { DefaultProps } from 'src/travelsuit'

interface IProps extends DefaultProps {
	icon?: React.ReactNode
	label?: React.ReactNode
	radius?: number | string
	fontSize?: FontSize
	variant?: 'normal' | 'condensed'
	border?: string
	color?: string
	textColor?: string
	maxLabelWidth?: number | null
	onDelete?(e: React.MouseEvent<HTMLElement>): void
	onClick?: (e: React.MouseEvent<HTMLElement>) => void
	tooltip?: React.ReactNode
}

type StyleProps = Pick<
	IProps,
	'icon' | 'radius' | 'onDelete' | 'variant' | 'color' | 'children' | 'border' | 'textColor' | 'fontSize'
>

const ChipContainer = styled(Tooltip)<StyleProps>`
	display: inline-block;
	position: relative;
	${(props) =>
		props.variant === 'normal'
			? font({ size: props.fontSize ?? 14 })
			: font({ size: props.fontSize ?? 14, weight: 'bold' })}
	background-color: ${(props) => props.color};
	border-radius: ${(props) => padPx(props.radius)};
	padding: ${(props) => padMulti(props.variant === 'normal' ? [4] : [2, 4])};
	color: ${(props) => props.textColor ?? fontBlack};
	${(props) => props.border ?? (props.variant === 'normal' ? `border: 1px solid ${disabledGray};` : '')}
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ChipGrid = styled(({ icon, onDelete, radius, variant, color, ...rest }: StyleProps) => <Grid {...rest} />).attrs(
	({ icon, onDelete }: StyleProps) => ({
		columns: [icon ? 30 : null, '1fr', onDelete ? 'auto' : null].filter(Boolean),
		align: 'center',
	}),
)<StyleProps>``

const Label = styled.div<Pick<IProps, 'maxLabelWidth'>>`
	padding: 0 6px;
	${(props) => (props.maxLabelWidth ? textOverflow(props.maxLabelWidth) : '')}
`
const ChipCloseButton = styled(SvgButton).attrs({ fontColor: disabledGray, src: CrossRoundIcon, type: 'button' })`
	padding-right: 5px;
	display: flex;
`

const Chip: React.FunctionComponent<IProps> = (props) => {
	const {
		className,
		icon,
		label,
		onDelete,
		variant,
		color,
		children,
		maxLabelWidth,
		border,
		radius,
		textColor,
		fontSize,
		onClick,
		tooltip,
	} = props
	const styleProps = { icon, radius, variant, color, children, border, textColor, fontSize }

	return (
		<ChipContainer
			data-test={passTestId(props)}
			component="div"
			className={className}
			{...styleProps}
			onClick={onClick}
			render={() => tooltip}
		>
			<ChipGrid {...styleProps} onDelete={onDelete}>
				{icon}
				<Label maxLabelWidth={maxLabelWidth} data-test={passTestId(props, 'Label')}>
					{label ?? children}
				</Label>
				{onDelete ? (
					<ChipCloseButton data-test="ChipRemoveIcon" onClick={eventStopper.stopPropagation(onDelete)} />
				) : null}
			</ChipGrid>
		</ChipContainer>
	)
}

Chip.defaultProps = {
	radius: '2rem',
	variant: 'normal',
	color: white,
	maxLabelWidth: 200,
}

export default Chip
