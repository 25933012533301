import flatten from 'lodash/flatten'
import React from 'react'

import styled from 'src/styles'
import { DefaultElProps } from 'src/travelsuit'

import WizardPage from '../../atoms/WizardPage/WizardPage'

interface IProps extends Omit<DefaultElProps<'div'>, 'title'> {
	step?: number
	onWizardFinish?(e: React.MouseEvent<HTMLElement>): void
	successPage?: React.ReactNode
	children: JSX.Element | Array<JSX.Element | null | undefined | JSX.Element[]>
}

interface IState {
	step: number
}

const WizardContainer = styled.div`
	height: 100%;
	overflow: hidden;
`

// TASK migrate to React.FunctionComponent OR remove this if not possible
class Wizard extends React.Component<IProps, IState> {
	public state: IState = {
		step: this.props.step ?? 0,
	}

	public componentDidUpdate(prevProps: IProps) {
		if (this.props.step !== undefined && prevProps.step !== this.props.step) {
			this.setState({ step: this.props.step })
		}
	}

	public render() {
		const { className, successPage } = this.props
		const pages = this.children
		return (
			<WizardContainer className={className}>
				{pages.map((c, i) => {
					if (!c?.props) {
						return null
					}
					const { onProceed, children, ...props } = c.props
					const key = i
					const onProceedWrapped = (e: React.MouseEvent<HTMLElement>) => {
						e.persist()
						if (onProceed) {
							onProceed(e)
						}
						this.onProceedPage(e)
					}

					return (
						<WizardPage
							key={key}
							active={key === this.state.step}
							onProceed={onProceedWrapped}
							onPrev={i > 0 ? () => this.onPrevPage() : undefined}
							{...props}
						>
							{children}
						</WizardPage>
					)
				})}
				<div
					style={{
						display: this.state.step === pages.length ? undefined : 'none',
						height: '100%',
					}}
				>
					{successPage}
				</div>
			</WizardContainer>
		)
	}

	private get children(): JSX.Element[] {
		const { children } = this.props
		const childArr = (children instanceof Array ? children : [children]) as JSX.Element[]
		return flatten(childArr.filter(Boolean))
	}

	private onProceedPage(e: React.MouseEvent<HTMLElement>) {
		if (!e.defaultPrevented) {
			this.setState({ step: this.state.step + 1 })

			const { onWizardFinish } = this.props
			const submittable = this.state.step === this.children.length - 1

			if (submittable && onWizardFinish) {
				onWizardFinish(e)
			}
		}
	}

	private onPrevPage() {
		this.setState({ step: this.state.step - 1 })
	}
}

export default Wizard
