import { Maybe } from 'src/lib/types'
import { capitalize } from 'src/lib/utils'
import { PersonNames } from 'src/types/user'

export function fullName<T extends Partial<PersonNames> = Partial<PersonNames>>(u: Maybe<T>) {
	if (!u) {
		return ''
	}
	return capitalize([u.first_name, u.middle_name, u.last_name].filter(Boolean).join(' ').trim())
}

export function userInitials<T extends Partial<PersonNames> = Partial<PersonNames>>(u: Maybe<T>) {
	if (!u) {
		return ''
	}
	return [u.first_name, u.last_name]
		.filter(Boolean)
		.map((w) => w?.[0]?.toLocaleUpperCase())
		.join('')
}
