import { defaults, throttle } from 'lodash'
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react'

export interface UseStickyPanelOptions {
	isEnabled: boolean
	showThreshold: number
}

interface State {
	containerIsVisible: boolean
	panelIsVisible: boolean
	placeholderIsVisible: boolean
}

const defaultOptions: UseStickyPanelOptions = {
	isEnabled: true,
	showThreshold: 0,
}

const fixedStyle: CSSProperties = { position: 'fixed', bottom: 0 }

function getEmptyState(): State {
	return {
		containerIsVisible: false,
		panelIsVisible: false,
		placeholderIsVisible: false,
	}
}

export function useStickyBottomPanel(options?: Partial<UseStickyPanelOptions>) {
	const { isEnabled, showThreshold } = defaults({}, options, defaultOptions)

	const [containerNode, containerRef] = useState<HTMLDivElement | null>(null)
	const [panelNode, panelRef] = useState<HTMLDivElement | null>(null)
	const [placeholderNode, placeholderRef] = useState<HTMLDivElement | null>(null)
	const [panelSize, setPanelSize] = useState<Pick<CSSProperties, 'width' | 'height'>>()
	const stateRef = useRef(getEmptyState())

	useEffect(() => {
		if (!isEnabled || !containerNode || !panelNode || !placeholderNode) {
			return
		}

		const update = (changes: Partial<State>) => {
			const state = Object.assign(stateRef.current, changes)

			if (state.containerIsVisible && !state.panelIsVisible && !state.placeholderIsVisible && panelNode) {
				const rect = panelNode.getBoundingClientRect()
				setPanelSize({
					width: rect.width,
					height: rect.height,
				})
			} else if (state.panelIsVisible && (state.placeholderIsVisible || !state.containerIsVisible)) {
				setPanelSize(undefined)
			}
		}

		function observeElementIntersection(node: HTMLDivElement | null, stateKey: keyof State, threshold = 0) {
			if (!node) {
				return
			}

			const observer = new IntersectionObserver(
				(entries) =>
					entries.length &&
					update({
						[stateKey]: entries[0].isIntersecting,
					}),
				{ threshold: [threshold, 1] },
			)

			observer.observe(node)

			return observer
		}

		const resizeObserver = new ResizeObserver(
			throttle(
				([entry]) => entry && setPanelSize((s) => s && { width: entry.contentRect.width, height: s.height }),
				200,
			),
		)

		resizeObserver.observe(containerNode)

		const observers = [
			observeElementIntersection(containerNode, 'containerIsVisible', showThreshold),
			observeElementIntersection(panelNode, 'panelIsVisible'),
			observeElementIntersection(placeholderNode, 'placeholderIsVisible'),
			resizeObserver,
		]

		return () => {
			observers.forEach((o) => o?.disconnect())
			stateRef.current = getEmptyState()
			setPanelSize(undefined)
		}
	}, [isEnabled, showThreshold, containerNode, panelNode, placeholderNode])

	const panelStyle = useMemo(() => panelSize && { ...fixedStyle, ...panelSize }, [panelSize])
	const placeholder = <div ref={placeholderRef} style={{ display: panelSize ? 'block' : 'none', ...panelSize }} />

	return {
		containerRef: containerRef as (ref: HTMLDivElement | null) => void,
		panelRef: panelRef as (ref: HTMLDivElement | null) => void,
		panelSize,
		panelStyle,
		placeholder,
	}
}
