import { convertFiltersToPayload, ReportsFilters } from 'src/lib/reports-utils'
import { getStore, getStoreState } from 'src/redux/stores'
import {
	Callback,
	CallbackOptional,
	CarPerCity,
	FlightsPerTripTypeElement,
	HotelsPerCity,
	PerTravelerStats,
	RailDirection,
	RailsPerTripTypeElement,
	ReportsOverviewData,
} from 'src/travelsuit'

import { IAction } from './action-helpers'
import { ReportsTypes as Types } from './actions.types'
import { Actions as AdminUsersActions } from './admin-users.actions'

function getFilters() {
	return convertFiltersToPayload(getStoreState().reports.filters)
}

export const Actions = {
	setFilters(filters: ReportsFilters, callback?: Callback) {
		return {
			type: Types.SetFilters,
			payload: filters,
			callback,
		}
	},
	updateFilters(filters: Partial<ReportsFilters>) {
		return {
			type: Types.UpdateFilters,
			payload: filters,
		}
	},
	getOverview(callback?: CallbackOptional, errCallback?: CallbackOptional): IAction<string, ReportsOverviewData[]> {
		return {
			type: Types.GetOverview.REQUEST,
			params: getFilters(),
			callback: (data) => {
				if (data?.length) {
					const ids = (data as ReportsOverviewData[])
						.reduce(
							(all, i) => [...all, ...Object.keys(i.overview.top_travelers).filter((j) => !all.includes(j))],
							[] as string[],
						)
						.map(Number)
						.filter((n) => !isNaN(n) && !getStoreState().adminUsers.get(n))
					if (ids.length) {
						getStore().dispatch(AdminUsersActions.getAllUsers({ ids }, callback, errCallback))
						return
					} else if (callback) {
						callback()
					}
				}
				if (errCallback) {
					errCallback()
				}
			},
			errCallback,
		}
	},
	getMeta(callback?: Callback, errCallback?: Callback) {
		return {
			type: Types.GetMeta.REQUEST,
			callback,
			errCallback,
		}
	},
	getFlightDetails(tripType: string, path: FlightsPerTripTypeElement, cb?: Callback, errCb?: Callback) {
		return {
			type: Types.GetFlightDetails.REQUEST,
			payload: [tripType, path.key].join('-'),
			params: { trip_type: tripType, flight_path: path.key, ...getFilters() },
			callback: cb,
			errCallback: errCb,
		}
	},
	getRailDetails(tripType: RailDirection, path: RailsPerTripTypeElement, cb?: Callback, errCb?: Callback) {
		return {
			type: Types.GetRailDetails.REQUEST,
			payload: [tripType, path.key].join('-'),
			params: { trip_type: tripType, trip_path: path.key, ...getFilters() },
			callback: cb,
			errCallback: errCb,
		}
	},
	getHotelDetails(hotel: HotelsPerCity, cb?: Callback, errCb?: Callback) {
		return {
			type: Types.GetHotelDetails.REQUEST,
			payload: hotel.key,
			params: { city_with_country: hotel.key, ...getFilters() },
			callback: cb,
			errCallback: errCb,
		}
	},
	getCarDetails(car: CarPerCity, cb?: Callback, errCb?: Callback) {
		return {
			type: Types.GetCarDetails.REQUEST,
			payload: car.key,
			params: { city_or_airport_with_country: car.key, ...getFilters() },
			callback: cb,
			errCallback: errCb,
		}
	},
	getTravelerDetails(traveler: PerTravelerStats, cb?: Callback, errCb?: Callback) {
		return {
			type: Types.GetTravelerDetails.REQUEST,
			payload: traveler.key,
			params: { user_id: traveler.key, ...getFilters() },
			callback: cb,
			errCallback: errCb,
		}
	},
}
