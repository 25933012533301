import { z } from 'zod'

import { PricesMetadataZ } from 'src/types/common'
import { ProductTypeZ, TripIdZ } from 'src/types/trip'
import { UserIdZ, UserMiniZ } from 'src/types/user'

export const SearchIdZ = z.number() // .brand<'SearchId'>()

export type SearchId = z.infer<typeof SearchIdZ>

export enum SearchJobStatus {
	Created = 'created',
	Failed = 'failed',
	Sent = 'sent',
	Success = 'success',
}

export const SearchJobStatusZ = z.nativeEnum(SearchJobStatus)

export const GetSearchParamsResponseZ = z.strictObject({
	covid_warning: z.unknown(),
	details: z.unknown(), // TODO
	travelers: z.array(UserMiniZ),
	type: ProductTypeZ,
})

/** TODO */
export const GetSearchResultsResponseZ = z.strictObject({
	id: SearchIdZ,
	flight_results: z.unknown(),
	last_seen_result_index: z.number().optional(),
	max_result_index: z.number().optional(),
	price_metadata: PricesMetadataZ.nullish(),
	status: SearchJobStatusZ,
	car_results: z.unknown(),
	hotel_results: z.unknown(),
	rails_results: z.unknown(),
})

/** TODO: Replace SearchRequestWithTrain */
export const StartSearchRequestZ = z.strictObject({
	details: z.object({}), // TODO
	old_search_id: SearchIdZ.optional(),
	travelers_ids: z.array(UserIdZ),
	trip_id: TripIdZ,
	type: ProductTypeZ,
})

export const StartSearchResponseZ = z.object({
	id: SearchIdZ,
	price_metadata: z.null(),
	status: SearchJobStatusZ,
})
