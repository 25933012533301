import { lightMidGray, separatorLight } from 'src/_vars'
import Box from 'src/atoms/Box/Box'
import Button from 'src/atoms/Button/Button'
import { Flex, Grid, PageTitle } from 'src/atoms/GenericComponents/GenericComponents'
import { Label as RILabel, LabelText as RILabelText } from 'src/atoms/RadioInput/RadioInput'
import Select from 'src/atoms/Select/Select'
import SvgIcon from 'src/atoms/SvgIcon/SvgIcon'
import TextField from 'src/atoms/TextField/TextField'
import { GridProps } from 'src/css-helpers/flex'
import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import { disabledGray, mainBlack, secondaryBlack } from 'src/refactor/colors'
import styled, { animations, flex, font, padPx } from 'src/styles'
import { DefaultElProps } from 'src/travelsuit'

export const PolicySettingsContainer = styled.div.attrs(addE2EAttrs)<E2E>``

export const PolicyPageTitle = styled(PageTitle).attrs(addE2EAttrs)<E2E>`
	text-transform: none;
	${font({ size: 18, weight: 600 })}
	line-height: 25px;
	margin: ${(p) => p.theme.spacing(6)}px ${(p) => p.theme.spacing(6)}px 18px;

	${(p) => p.theme.breakpoints.down('md')} {
		margin-left: ${(p) => p.theme.spacing(4)}px;
	}
`

export const PolicyRadioOptionsContainer = styled(Flex).attrs(() => ({ justify: 'flex-start' }))`
	& ${RILabel} {
		margin-right: ${(p) => p.theme.spacing(10)}px;
	}

	${(p) => p.theme.breakpoints.down('md')} {
		display: block;

		${RILabel} {
			margin: 0;
		}
	}

	color: ${mainBlack};
`

export const HintLabel = styled.div``

export const PolicyContentContainer = styled(Flex).attrs(() => ({ direction: 'column', align: 'stretch' }))`
	flex: 1;
	margin-top: -${(p) => p.theme.spacing(6)}px;

	${(p) => p.theme.breakpoints.down('md')} {
		margin-top: 0;
	}
`

export const PolicySummaryContainer = styled.div``

const ANIMATION_LENGTH = 400

export const PolicyBox = styled(Box)<{ expanded: boolean; visible: boolean }>`
	margin-top: 0px;
	margin-bottom: ${(p) => p.theme.spacing(4)}px;
	padding: 0;
	transition: max-height ${ANIMATION_LENGTH}ms ease-in-out;
	overflow: hidden;
	${flex({ direction: 'column' })}
	${(props) =>
		!props.visible
			? `
		max-height: 0 !important;
		margin: 0 !important;
		${animations.fadeOut.animate(ANIMATION_LENGTH)}
	`
			: ''}
	& ${PolicyContentContainer} {
		${(props) =>
			props.expanded ? animations.fadeIn.animate(ANIMATION_LENGTH) : animations.fadeOut.animate(ANIMATION_LENGTH)}
	}
	& ${PolicySummaryContainer} {
		${(props) =>
			props.expanded ? animations.fadeOut.animate(ANIMATION_LENGTH) : animations.fadeIn.animate(ANIMATION_LENGTH)}
	}
`

export const PolicySettingTitle = styled.h3.attrs(addE2EAttrs)<E2E & { resizable?: boolean }>`
	padding: 0 ${(p) => p.theme.spacing(4)}px;

	${(p) => p.theme.breakpoints.up('lg')} {
		padding: ${(p) => p.theme.spacing(6)}px;
		padding-right: ${(p) => p.theme.spacing(3)}px;
	}

	color: ${secondaryBlack};
	${font({ size: 14, weight: 500 })}
	${(props) => flex({ direction: 'column', justify: props.resizable ? 'flex-start' : 'center' })}
`

export const PolicySettingRow = styled(Grid).attrs({
	columns: [240, '1fr'],
	collapse: true,
	align: 'center',
})<{ borderRight?: boolean; disabled?: boolean }>`
	transition: opacity 200ms ease-in-out;

	${PolicyPageTitle} + & {
		margin-top: -${(p) => p.theme.spacing(3)}px;
	}

	&:not(:last-child) {
		border-bottom: 1px solid ${separatorLight};
	}

	${(props) =>
		props.borderRight
			? `
		& ${PolicySettingTitle} {
			border-right: 1px solid ${separatorLight};
			height: 100%;
		}
	`
			: ''}

	${(props) =>
		props.disabled
			? `
		opacity: 0.3;
		pointer-events: none;
	`
			: ''}

	${(p) => p.theme.breakpoints.down('md')} {
		margin-bottom: ${(p) => p.theme.spacing(4)}px;
	}
`

export const PolicySettingRightColumn = styled.div`
	border-left: 1px solid ${separatorLight};
`

export const PolicySettingSubtitle = styled.div`
	${font({ size: 12 })}
	margin-top: ${(p) => p.theme.spacing(1)}px;
`

export const PolicySettingContent = styled.div`
	padding: ${(p) => p.theme.spacing(4)}px;
	color: ${mainBlack};
	&,
	& ${RILabelText} {
		${font({ size: 14 })}
	}
`

export const PolicySettingSummary = styled(Grid).attrs(({ columns }: GridProps & DefaultElProps<'div'>) => ({
	columns: columns ?? '2fr 3fr',
	collapse: true,
	gap: 20,
}))`
	padding: 0px ${(p) => p.theme.spacing(6)}px ${(p) => p.theme.spacing(6)}px;
	${font({ size: 14 })}
	${(p) => p.theme.breakpoints.down('md')} {
		padding: 0 ${(p) => p.theme.spacing(3)}px;
	}
`

export const PolicySettingSummaryColumn = styled.div``
export const PolicySettingSummaryItem = styled.div.attrs(addE2EAttrs)<E2E>`
	&:not(:last-child) {
		${(p) => p.theme.breakpoints.up('lg')} {
			margin-bottom: ${(p) => p.theme.spacing(6)}px;
		}

		margin-bottom: ${(p) => p.theme.spacing(4)}px;
	}
`

export const PolicySettingSummaryTitle = styled(PolicySettingTitle)`
	padding: 0;

	margin-bottom: ${(p) => p.theme.spacing(1)}px;

	display: block;
	height: auto;
	${font({ size: 14 })}

	${(p) => p.theme.breakpoints.up('lg')} {
		margin-bottom: 8px;
		padding: 0;
	}
`

export const PolicySettingSummaryValue = styled.div.attrs(addE2EAttrs)<E2E>`
	color: ${mainBlack};
	line-height: 20px;
`

export const BottomRow = styled.div`
	text-align: right;
	padding: ${(p) => p.theme.spacing(6)}px;
`

export const SaveButton = styled(Button)
	.attrs({
		color: 'bluePrimary',
	})
	.attrs(addE2EAttrs)<E2E>`
	min-width: 160px;
`

export const EditButton = styled(Button)
	.attrs({
		color: 'primary',
	})
	.attrs(addE2EAttrs)<E2E>`
	min-width: 160px;
`

export const PolicyLabelSelect = styled(Select)`
	display: inline-block;
	width: 60px;
	margin: 0 0.5ex;

	& .MuiSelect-root {
		padding: ${(p) => p.theme.spacing(1)}px;
	}

	& .MuiSelect-icon {
		height: auto;
		right: 6px;
	}

	& .MuiPaper-root {
		min-width: 100px !important;
	}
`

export const PriceSelect = styled(Select)`
	display: inline-block;
	width: 140px;
	margin-left: ${(p) => p.theme.spacing(2)}px;
`

export const PolicyLabelTextField = styled(TextField)`
	max-width: 80px;
	text-align: center;
	margin: 0px ${(p) => p.theme.spacing(3)}px;
	margin-top: -${(p) => p.theme.spacing(3)}px;
`
export const PriceCapTextField = styled(TextField)`
	max-width: 120px;
	text-align: center;
	margin: 0px ${(p) => p.theme.spacing(3)}px;
`

export const BusinessClassRoleRuleTimePicker = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	min-width: 220px;
`

export const DirectionIcon = styled(SvgIcon)`
	margin: 0 1ex;
	color: ${lightMidGray};
`

export const QuestionIcon = styled(SvgIcon)`
	color: ${disabledGray};
	margin-left: ${(p) => p.theme.spacing(1)}px;
`

export const MaxRateSummaryRow = styled(Flex)
	.attrs(() => ({ justify: 'flex-start' }))
	.attrs(addE2EAttrs)<E2E>`
	line-height: 20px;

	${(p) => p.theme.breakpoints.down('md')} {
		width: 100%;
	}
`

export const MaxRateSummaryRoute = styled(Flex).attrs(() => ({ justify: 'space-between' }))<{
	width?: number | string
}>`
	${(props) => (props.width ? `width: ${padPx(props.width)};` : '')}
`

export const MaxRateSummaryRouteSegment = styled.span.attrs(addE2EAttrs)<E2E>`
	display: inline-block;
	text-align: justify;
`

export const MaxRateSummaryPrice = styled.span`
	margin-left: ${(p) => p.theme.spacing(3)}px;
`
