import { Callback, SeatMapCollection, VoidCallback } from 'src/travelsuit'
import { SeatMapParams } from 'src/travelsuit/seatmaps'

import { IAction } from './action-helpers'
import { SeatmapTypes as Types } from './actions.types'

export const Actions = {
	getSeatmapForFlight(
		params: SeatMapParams,
		callback?: VoidCallback<SeatMapCollection[]>,
		errCallback?: Callback,
	): IAction {
		return {
			type: Types.GetForFlight.REQUEST_JOB,
			payload: params,
			callback,
			errCallback,
		}
	},
}
