import React from 'react'

type ImageWithFallbackProps = JSX.IntrinsicElements['img'] & {
	fallbackSrc: string
}

export function ImageWithFallback({ src, fallbackSrc, ...restProps }: ImageWithFallbackProps) {
	const imgSrc = src || fallbackSrc

	const imgRef = React.useRef<HTMLImageElement>(null)

	React.useLayoutEffect(() => {
		if (imgRef.current) {
			imgRef.current.src = imgSrc
		}
	}, [imgSrc])

	return (
		<img
			ref={imgRef}
			onError={() => {
				if (imgRef.current && imgRef.current.src !== fallbackSrc) {
					imgRef.current.src = fallbackSrc
				}
			}}
			src={imgSrc}
			{...restProps}
		/>
	)
}
