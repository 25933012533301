import { TFunction } from 'i18next'
import React, { useState } from 'react'

import Button from 'src/atoms/Button/Button'
import Checkbox from 'src/atoms/Checkbox/Checkbox'
import Loader from 'src/atoms/Loader'
import Select from 'src/atoms/Select/Select'
import { getFeesCardDisabledReasons } from 'src/lib/credit-card-utils'
import useTranslation from 'src/lib/i18n/UseTranslation'
import { CreditCardResponse, InvoiceProfile } from 'src/travelsuit'

import { PaymentMethodsAutocomplete } from '../TripPaymentMethod/PaymentMethodsAutocomplete'
import { StyledModal } from './FeeCard.components'

interface IProps {
	onClose: () => void
	onConfirm: (creditCardId: number, isCardDefault?: boolean) => void
	isLoading: boolean
	title: string
	subtitle: string
	invoiceProfiles?: InvoiceProfile[]
	defaultInvoiceProfile?: InvoiceProfile | null
	creditCards: CreditCardResponse[]
	defaultCreditCard?: CreditCardResponse | null
	canChangeDefaultCard?: boolean
}

const mapToOption = (card: CreditCardResponse, t: TFunction) => {
	const disabledReasons = getFeesCardDisabledReasons(card, t)

	return {
		...card,
		tooltipContent: disabledReasons,
		disabled: !!disabledReasons.length,
	}
}

const PaymentModal: React.FC<IProps> = ({
	onClose,
	onConfirm,
	isLoading,
	title,
	subtitle,
	invoiceProfiles,
	defaultInvoiceProfile,
	creditCards,
	defaultCreditCard,
	canChangeDefaultCard,
}) => {
	const { t } = useTranslation()
	const [creditCard, setCreditCard] = useState(defaultCreditCard && mapToOption(defaultCreditCard, t))
	const [invoiceProfile, setInvoiceProfile] = useState(defaultInvoiceProfile)
	const [isCardDefault, setCardDefault] = useState(false)

	const creditCardOptions = creditCards.map((card) => mapToOption(card, t))
	const allCardsDisabled = creditCardOptions.every((option) => option.disabled)
	const isConfirmDisabled = !creditCard || !creditCards || allCardsDisabled || isLoading || creditCard.disabled

	return (
		<StyledModal
			onClose={onClose}
			title={title}
			controlButtons={[
				<Button
					key="ok"
					width={160}
					color="bluePrimary"
					onClick={() => {
						if (creditCard) {
							onConfirm(creditCard.id, isCardDefault)
						}
					}}
					disabled={isConfirmDisabled}
				>
					{t('usage-details.pay-again-modal.button')}
				</Button>,
			]}
		>
			{creditCards && !isLoading ? (
				<>
					{subtitle}
					<Select
						value={invoiceProfile}
						label={t('usage-details.pay-again-modal.invoice-profile', 'Invoice Profile Name')}
						options={invoiceProfiles?.map((invoiceProfile) => ({
							label: <span>{invoiceProfile.display_name}</span>,
							value: invoiceProfile,
						}))}
						renderValue={(invoice) => invoice.display_name}
						placeholder={t('usage-details.pay-again-modal.invoice-profile', 'Invoice Profile Name')}
						onChange={setInvoiceProfile}
						disabled={!invoiceProfiles}
						renderInModal
						required
					/>
					<PaymentMethodsAutocomplete
						paymentMethods={creditCardOptions}
						selectedMethod={allCardsDisabled ? null : creditCard}
						disabled={!creditCardOptions.length}
						onChange={(card) => setCreditCard(card)}
						error={!creditCard || allCardsDisabled || creditCard?.disabled}
						helperText={
							allCardsDisabled
								? t(
										'usage-details.pay-again-modal.no-available-cards',
										'No credit cards available. Please add the card in the traveller profile or invoice profile',
									)
								: ''
						}
					/>
					{canChangeDefaultCard && (
						<Checkbox
							checked={isCardDefault || creditCard?.id === defaultCreditCard?.id}
							label={t('add-credit-card.select-as-default-for-fees', 'Set as a default card for fees')}
							onToggle={(v) => setCardDefault(v)}
							disabled={creditCard?.id === defaultCreditCard?.id || creditCard?.disabled}
						/>
					)}
				</>
			) : (
				<Loader />
			)}
		</StyledModal>
	)
}

export default PaymentModal
