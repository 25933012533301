import { ImmutableMap } from 'src/lib/immutable/ImmutableMap'
import { Location, LocationId } from 'src/travelsuit'
import { parseOfficeJson } from 'src/travelsuit/locations'

import { IAction } from '../actions/action-helpers'
import { AdminLocationsTypes } from '../actions/actions.types'

export type AdminLocationsState = ImmutableMap<LocationId, Location>

function adminLocationsReducer(state: AdminLocationsState = new ImmutableMap(), action: IAction): AdminLocationsState {
	switch (action.type) {
		case AdminLocationsTypes.GetAdminLocations.SUCCESS:
			for (const location of action.payload ?? []) {
				const locationPayload = parseOfficeJson(location)
				if (locationPayload.id !== null) {
					state = state.set(locationPayload.id, locationPayload)
				}
			}
			return state
		case AdminLocationsTypes.AddAdminLocation.SUCCESS:
			return state.set(action.payload.id, parseOfficeJson(action.payload))
		case AdminLocationsTypes.UpdateAdminLocation.SUCCESS:
			return state.set(action.payload.id, parseOfficeJson(action.payload))
		case AdminLocationsTypes.RemoveAdminLocation.SUCCESS:
			return state.delete(action.requestPayload.id)
	}
	return state
}

export default adminLocationsReducer
