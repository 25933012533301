import { Identifiable, Optional } from './types'

export function updateEntityById<T extends Identifiable>(
	entities: Optional<T[]>,
	id: number,
	updater: (entity: T) => Partial<T>,
) {
	entities = entities ?? []
	const entityIndex = entities.findIndex((entity) => id === entity.id)
	if (entityIndex === -1) {
		return entities
	}

	const entitiesCopy = [...entities]
	const entityToUpdate = entitiesCopy[entityIndex]
	entitiesCopy[entityIndex] = { ...entityToUpdate, ...updater(entityToUpdate) }

	return entitiesCopy
}
