import _MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem/MenuItem'
import React, { forwardRef } from 'react'

import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import { disabledGray } from 'src/refactor/colors'
import styled, { flex } from 'src/styles'

export type MenuItemPropsWithE2E = MenuItemProps & E2E & { noWrap?: boolean }

/**
 * `MenuItem` component from Material-UI adapted to use with Tooltip.
 * `disabled` property is filtered out and emulated with styles
 * so Tooltip still can pass through its handlers to show itself on disabled items.
 */
const MenuItem = styled(
	forwardRef<HTMLLIElement, MenuItemPropsWithE2E>(({ e2e: _e2e, disabled, onClick, ...rest }, ref) => (
		<_MenuItem {...rest} ref={ref} button aria-disabled={disabled} onClick={disabled ? undefined : onClick} />
	)),
).attrs(addE2EAttrs)<MenuItemPropsWithE2E>`
	${flex({ align: 'center', gap: 5 })};

	&,
	&.MuiMenuItem-root.MuiMenuItem-root {
		${(props) => (props.noWrap ? `white-space: nowrap;` : '')}
	}

	${(props) => (props.disabled ? `color: ${disabledGray};` : '')}
`

export default MenuItem
