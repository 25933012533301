import { z } from 'zod'

import { CurrencyObjectZ } from 'src/types/common'
import { RailPassengerIdZ, RailSeatPreferenceIdZ, RailSectionIdZ, RailTransportTypeZ } from 'src/types/rails/common'

const MOCK = z.literal('__mock__')

export enum RailCarriageLayoutType {
	Map = 'map',
}

export const RailCarriageLayoutTypeZ = z.nativeEnum(RailCarriageLayoutType)

export enum RailSeatAvailability {
	Available = 'available',
	Unavailable = 'unavailable',
}

export const RailSeatAvailabilityZ = z.nativeEnum(RailSeatAvailability)

export enum RailCarriageLayoutItemTypeName {
	Corridor = 'Corridor',
	EmptyArea = 'Empty area',
	Exit = 'Exit',
	LuggageCompartment = 'Luggage compartment',
	Seat = 'Seat',
	StairsDown = 'Stairs (Down)',
	StairsUp = 'Stairs (Up)',
	Table = 'Table',
	TechnicalRoom = 'Technical room',
	Toilet = 'Toilet',
}

export const RailCarriageLayoutItemTypeNameZ = z.nativeEnum(RailCarriageLayoutItemTypeName)

export const RailPassengerPricePointIdZ = z.string().brand<'RailPassengerPricePointId'>()

export type RailPassengerPricePointId = z.infer<typeof RailPassengerPricePointIdZ>

/** Example: outward-TGV32953-wagon3-deck1-seat68-id */
export const RailCarriageLayoutItemIdZ = z.string().brand<'RailCarriageLayoutItemId'>()

export type RailCarriageLayoutItemIdZ = z.infer<typeof RailCarriageLayoutItemIdZ>

export const RailPassengerPricePointZ = z.object({
	id: RailPassengerPricePointIdZ,
	passenger_ids: z.array(RailPassengerIdZ),
	price_subject_to_change: z.boolean(),
	price: CurrencyObjectZ,
})

export const RailCarriageLayoutItemTypeZ = z.object({
	/** Example: An individual seat */
	description: z.string(),
	is_overlappable: z.boolean(),
	is_reservable: z.boolean(),
	name: RailCarriageLayoutItemTypeNameZ,
})

export const RailCarriageLayoutItemZ = z.object({
	availability: RailSeatAvailabilityZ,
	available_passenger_price_points: z.array(RailPassengerPricePointIdZ),
	column: z.number(),
	/** Degrees: 0, 90, 180, 270 */
	direction: z.number(),
	id: RailCarriageLayoutItemIdZ,
	layout_item_type: RailCarriageLayoutItemTypeZ,
	length: z.number(),
	/**
	 * Human visible ID of this item like a seat number
	 * Example: 18
	 */
	position: z.string().nullable(),
	row: z.number(),
	sub_layout_item_types: z.array(MOCK),
	width: z.number(),
})

export const RailCarriageLayoutDeckZ = z.object({
	carriage_layout_items: z.array(RailCarriageLayoutItemZ),
	columns: z.number(),
	features: z.array(MOCK),
	name: z.string(),
	order: z.number(),
	rows: z.number(),
	total_available_seats: z.number(),
})

export const RailCarriageLayoutZ = z.object({
	decks: z.array(RailCarriageLayoutDeckZ),
	layout_type: RailCarriageLayoutTypeZ,
	name: z.string(),
	order: z.number(),
	total_available_seats: z.number(),
})

export const RailCarriageGroupZ = z.object({
	carriage_layouts: z.array(RailCarriageLayoutZ),
	order: z.number(),
})

export const RailSeatMapZ = z.object({
	carriage_groups: z.array(RailCarriageGroupZ),
	/** Example: TGV32953 */
	model: z.string(),
	passenger_ids: z.array(RailPassengerIdZ),
	passenger_price_points: z.array(RailPassengerPricePointZ),
	seat_preference_id: RailSeatPreferenceIdZ,
	section_id: RailSectionIdZ,
	total_carriages: z.number(),
	transport_type: RailTransportTypeZ,
})

export type RailSeatMap = z.infer<typeof RailSeatMapZ>

export const RailGetSeatMapsResponseZ = z.object({
	seat_maps: z.array(RailSeatMapZ),
})
