import React from 'react'

import { spacing } from 'src/_vars'
import { Autocomplete } from 'src/atoms/Autocomplete'
import Button from 'src/atoms/Button/Button'
import CurrencyTextField from 'src/atoms/CurrencyTextField/CurrencyTextField'
import Drawer from 'src/atoms/Drawer/Drawer'
import { FloatingCloseButton, FloatingCloseButtonContainer, FloatingFormControlContainer } from 'src/atoms/Floating'
import { Expanded, Grid, GridItem, SizedBox } from 'src/atoms/GenericComponents/GenericComponents'
import LocationSearchOption from 'src/atoms/LocationSearchOption/LocationSearchOption'
import { SvgIcon } from 'src/atoms/SvgIcon/SvgIcon'
import WizardPage from 'src/atoms/WizardPage/WizardPage'
import { WithTranslation, withTranslation } from 'src/lib/i18n/i18n'
import { debounced, eventStopper, handleIOSInput } from 'src/lib/utils'
import LocationIcon from 'src/refactor/assets/icons/location.svg'
import styled, { padding } from 'src/styles'
import { CurrencySigns, DefaultProps, HotelPriceCapRoute, Location } from 'src/travelsuit'
import { searchHotelCities } from 'src/travelsuit/hotels'
import { emptyLocation } from 'src/travelsuit/locations'
import { Currency } from 'src/types/common'

interface IProps extends DefaultProps, WithTranslation {
	rate?: HotelPriceCapRoute
	currency?: Currency
	onSave?(rate: HotelPriceCapRoute, addAnother: boolean): void
	onClose?(e: React.MouseEvent<HTMLElement>): void
}

interface IState {
	city: Location | null
	cityMobileOpen: boolean
	maxPrice: number | null
}

const FormGrid = styled(Grid)`
	${padding.symmetric({ vertical: spacing })}
`

// TASK migrate to React.FunctionComponent OR remove this if not possible
class HotelMaxRateDialog extends React.Component<IProps, IState> {
	private locationNameKey: keyof Location = 'name'
	// public static defaultProps: Partial<IProps> = {
	// 	//
	// }

	public state: IState = {
		city: this.props.rate
			? emptyLocation({
					[this.locationNameKey]: this.props.rate.city_name,
					long: this.props.rate.long,
					lat: this.props.rate.lat,
					country: this.props.rate.country_iso,
				})
			: null,
		maxPrice: this.props.rate ? this.props.rate.max_rate : null,
		cityMobileOpen: false,
	}

	public render() {
		const { t, className, onClose, currency: policyCurrency } = this.props
		const { city, cityMobileOpen, maxPrice } = this.state

		return (
			<Drawer className={className} open onClose={onClose}>
				<WizardPage
					active
					title={t('max-rate.hotels.title', 'Add New Price Cap')}
					canContinue={this.isValid}
					onProceed={eventStopper.preventDefault(() => this.save(true))}
					proceedButtonProps={{ label: t('max-rate.table.actions.save-add', 'Save &amp; Add Another') }}
					bottomBarRemark={
						<>
							<Expanded />
							<Button onClick={eventStopper.preventDefault(() => this.save(false))} disabled={!this.isValid}>
								{t('max-rate.table.actions.save-close', 'Save &amp; Close')}
							</Button>
							<SizedBox width={15} />
						</>
					}
				>
					<FormGrid columns={['2fr', '1fr']} align="flex-end" collapse mobileProps={{ gap: 20 }} gap={10}>
						<GridItem mobileColumn="auto">
							<FloatingFormControlContainer mobileFocused={cityMobileOpen}>
								<FloatingCloseButtonContainer
									visible={cityMobileOpen}
									onClick={() => this.setState({ cityMobileOpen: false })}
								>
									<FloatingCloseButton />
								</FloatingCloseButtonContainer>
								<Autocomplete
									selected={city}
									inputProps={{
										label: t('max-rate.hotels.near', 'Staying at'),
										leading: <SvgIcon src={LocationIcon} />,
										required: true,
										onFocus: () => this.setState({ cityMobileOpen: true }, handleIOSInput),
									}}
									initialQuery={(terms: string) => this.search(terms)}
									query={(terms: string) => this.delayedSearch(terms)}
									labelFn={(location: Location) => (location ? location.name : '')}
									itemValueOf={(location: Location) => (location ? location.name : '')}
									renderOption={(location: Location) => <LocationSearchOption location={location} />}
									onChange={(loc: Location) => this.updateCity(loc)}
								/>
							</FloatingFormControlContainer>
						</GridItem>
						<GridItem>
							<CurrencyTextField
								label={t('max-rate.hotels.max-price', 'Max Price')}
								value={maxPrice !== null ? maxPrice.toString() : ''}
								required={true}
								error={maxPrice !== null && maxPrice <= 0}
								currency={policyCurrency && CurrencySigns[policyCurrency]}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									this.setState({ maxPrice: e.target.value === '' ? null : Number(e.target.value) })
								}
							/>
						</GridItem>
					</FormGrid>
				</WizardPage>
			</Drawer>
		)
	}

	private save(proceed: boolean) {
		const { onSave } = this.props
		if (onSave && this.isValid) {
			onSave(this.rate, proceed)
		}

		if (proceed) {
			this.setState({
				city: null,
				maxPrice: null,
				cityMobileOpen: false,
			})
		}
	}

	private get rate(): HotelPriceCapRoute {
		let { ...city } = this.state.city!
		city = emptyLocation(city)

		return {
			city_name: city.name,
			country_iso: city.country!,
			lat: city.lat!,
			long: city.long!,
			max_rate: this.state.maxPrice!,
		}
	}

	private get isValid() {
		return [this.state.city, this.state.maxPrice].every(Boolean)
	}

	private async search(terms: string) {
		return (await searchHotelCities(terms)).map((city) =>
			emptyLocation({
				...city,
				name: city.city_name,
				short_name: city.city_name,
				long: city.longitude,
				lat: city.latitude,
				country: city.country_iso,
			}),
		)
	}

	@debounced(400)
	private async delayedSearch(terms: string) {
		return this.search(terms)
	}

	private async updateCity(city: Location) {
		this.setState({ city, cityMobileOpen: false }, handleIOSInput)
	}
}

export default withTranslation()(HotelMaxRateDialog)
