import { ExpensesNewTypes } from 'src/redux/actions/actions.types'
import { denormalize, normalize } from 'src/redux/reducers/expenses-new.reducer'
import { Callback, VoidCallback } from 'src/travelsuit'
import { Expense, ExpenseApi, FullExpense } from 'src/travelsuit/expenses'
import { DailyAllowance, Mileage } from 'src/types/expenses'

export const Actions = {
	getExpense(id: number, callback?: VoidCallback<Expense | null>) {
		return {
			type: ExpensesNewTypes.GetExpense.REQUEST,
			payload: { id },
			callback: (expense: ExpenseApi) => callback?.(normalize(expense)),
		}
	},
	getExpenses(callback?: VoidCallback<Array<Expense>>) {
		return {
			type: ExpensesNewTypes.GetExpenses.REQUEST,
			callback: (expenses: ExpenseApi[]) => callback?.(expenses.map(normalize).filter(Boolean) as Expense[]),
		}
	},
	deleteExpense(expense: Expense, callback?: Callback, swapCallbackAndSuccessAction = false) {
		return {
			type: ExpensesNewTypes.DeleteExpense.REQUEST,
			payload: { id: expense.id },
			callback,
			swapCallbackAndSuccessAction,
		}
	},
	updateExpense(
		expense: FullExpense | Mileage | DailyAllowance,
		id: number,
		callback?: Callback,
		swapCallbackAndSuccessAction = false,
	) {
		const expenseApi = denormalize(expense)
		return {
			type: ExpensesNewTypes.UpdateExpense.REQUEST,
			payload: { id },
			body: expenseApi,
			callback,
			swapCallbackAndSuccessAction,
		}
	},
	createExpense(
		expense: FullExpense | Mileage | DailyAllowance,
		callback?: Callback,
		swapCallbackAndSuccessAction = false,
	) {
		return {
			type: ExpensesNewTypes.CreateExpense.REQUEST,
			body: denormalize(expense),
			callback,
			swapCallbackAndSuccessAction,
		}
	},
}
