import moment, { unitOfTime } from 'moment'

import { TimeObject } from 'src/lib/utils'

export function compareDates(
	dateA: moment.MomentInput,
	dateB: moment.MomentInput,
	options?: {
		precision: unitOfTime.StartOf
	},
) {
	const momentDateA = moment(dateA)
	const momentDateB = moment(dateB)
	return momentDateA.isSame(momentDateB, options?.precision)
		? 0
		: momentDateA.isAfter(momentDateB, options?.precision)
			? 1
			: -1
}

export function getTimeObjectFromString(time?: string) {
	return time ? TimeObject.fromString(time) : undefined
}

export function getTotalFromTimeObject(timeObject?: TimeObject) {
	return timeObject ? timeObject.h * 60 + timeObject.m : undefined
}

export function compareTimes(timeA: string, timeB: string) {
	const minutesDiff =
		getTotalFromTimeObject(getTimeObjectFromString(timeA)!)! - getTotalFromTimeObject(getTimeObjectFromString(timeB)!)!
	return minutesDiff === 0 ? 0 : minutesDiff > 0 ? 1 : -1
}

export function resetTimezone(date: Date) {
	return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
}
