import { IAction } from './action-helpers'
import { RolesTypes as Types } from './actions.types'

export const Actions = {
	getAllRoles(): IAction {
		return {
			type: Types.GetAllRoles.REQUEST,
		}
	},
}
