import { IAction } from 'src/redux/actions/action-helpers'
import { CountryLanguagesTypes } from 'src/redux/actions/actions.types'
import { CountryLanguage } from 'src/travelsuit'

export type ICountryLanguagesState = CountryLanguage[]

function countryLanguagesReducer(state: ICountryLanguagesState = [], action: IAction): ICountryLanguagesState {
	switch (action.type) {
		case CountryLanguagesTypes.GetCountryLanguages.SUCCESS:
			return action.payload
	}
	return state
}

export default countryLanguagesReducer
