import { z } from 'zod'

import { CountriesCode } from 'src/i18nConfig'
import { CountryName, POSCountryName, ShortDistanceUnits } from 'src/travelsuit'
import { expenseStatuses } from 'src/travelsuit/expenses'
import { CurrencyZ, ShortDistanceUnitsZ } from 'src/types/common'
import { UserZ } from 'src/types/user'

const ExpenseFileZ = z.object({
	name: z.string(),
	url: z.string().url(),
	size: z.number().positive(),
})
export type ExpenseFile = z.infer<typeof ExpenseFileZ>

const TaxAndVatRateZ = z.object({
	id: z.number(),
	country: z.object({ name: POSCountryName, code: z.nativeEnum(CountriesCode) }),
	merchant_country: z.object({ name: CountryName, code: z.union([z.nativeEnum(CountriesCode), z.literal('')]) }),
	all_countries: z.boolean(),
	rate: z.number().min(0).max(100),
	name: z.string().max(255),
})
export type TaxAndVatRate = z.infer<typeof TaxAndVatRateZ>

export const TaxAndVatRateApiZ = TaxAndVatRateZ.pick({
	id: true,
	all_countries: true,
	rate: true,
	name: true,
}).extend({
	country: POSCountryName,
	country_code: z.nativeEnum(CountriesCode),
	merchant_country_name: CountryName,
	merchant_country_code: z.nativeEnum(CountriesCode).optional(),
})
export type TaxAndVatRateApi = z.infer<typeof TaxAndVatRateApiZ>

const CustomAttendeeZ = UserZ.pick({ id: true, first_name: true, last_name: true }).extend({ company_name: z.string() })

export const ExpenseRequestAttendeeZ = z.union([UserZ.pick({ id: true }), CustomAttendeeZ])

export const ExpenseResponseAttendeeZ = z.union([
	UserZ.extend({ is_internal: z.literal(true) }),
	CustomAttendeeZ.extend({ is_internal: z.literal(false) }),
])

const UnitsOfLengthApiZ = ShortDistanceUnitsZ.transform((unit) => unit.toLowerCase() as Lowercase<ShortDistanceUnits>)

const AddressAutofillSuggestionApiZ = z.object({
	id: z.string(),
	full_address: z.string(),
})

const AddressAutofillSuggestionZ = z.object({
	action: z.object({ id: z.string() }),
	full_address: z.string(),
})

const MileageRequestApiZ = z.object({
	expense_type: z.literal('mileage'),
	expense_status: z.enum(expenseStatuses),
	date: z.string().date(),
	destination_points: z.array(AddressAutofillSuggestionApiZ),
	submitter_id: z.number(),
	receipt_url: z.string().url(),
	attendees: z.array(ExpenseRequestAttendeeZ),
	distance: z.number().min(0),
	calculated_distance: z.number().min(0),
	show_calculated_distance: z.boolean(),
	currency: CurrencyZ,
	mileage_rate_id: z.number(),
	unit_of_length: UnitsOfLengthApiZ,
	expense_report: z.string().optional(),
	report_id: z.number().optional(),
	is_travel_related: z.boolean().optional(),
	description: z.string().max(255).optional(),
})
export type MileageRequestApi = z.infer<typeof MileageRequestApiZ>

const MileageRateZ = z.object({
	id: z.number(),
	country_code: z.nativeEnum(CountriesCode),
	country: z.string(),
	name: z.string(),
	currency: CurrencyZ,
	unit_of_length: ShortDistanceUnitsZ,
	amount: z.number().min(0),
	is_default: z.boolean(),
})
export type MileageRate = z.infer<typeof MileageRateZ>

export const MileageRateApiZ = MileageRateZ.omit({ unit_of_length: true }).extend({ unit_of_length: UnitsOfLengthApiZ })
export type MileageRateApi = z.infer<typeof MileageRateApiZ>

const MileageResponseApiZ = MileageRequestApiZ.pick({
	expense_type: true,
	expense_status: true,
	date: true,
	destination_points: true,
	receipt_url: true,
	distance: true,
	calculated_distance: true,
	show_calculated_distance: true,
	currency: true,
	unit_of_length: true,
	expense_report: true,
	report_id: true,
	description: true,
}).extend({
	id: z.number(),
	submitter: UserZ,
	attendees: z.array(ExpenseResponseAttendeeZ),
	mileage_rate: MileageRateZ,
	created_dt: z.string().datetime({ offset: true }),
	is_travel_related: z.boolean(),
	report_amount: z.number(),
	amount: z.number().min(0),
})
export type MileageResponseApi = z.infer<typeof MileageResponseApiZ>

const ExpenseReportBasicZ = z.object({
	id: z.union([z.number(), z.string()]),
	name: z.string(),
	tripId: z.string().optional(),
})
export type ExpenseReportBasic = z.infer<typeof ExpenseReportBasicZ>

const IsItTravelZ = z.union([z.literal('0'), z.literal('1')]).optional()
export type IsItTravel = z.infer<typeof IsItTravelZ>

export const MileageBaseZ = MileageResponseApiZ.pick({
	distance: true,
	attendees: true,
	date: true,
	description: true,
	amount: true,
	report_amount: true,
	currency: true,
}).extend({
	type: MileageResponseApiZ.shape.expense_type,
	points: z.array(AddressAutofillSuggestionZ),
	calculatedDistance: MileageResponseApiZ.shape.calculated_distance,
	showCalculatedDistance: MileageResponseApiZ.shape.show_calculated_distance,
	receiptUrl: MileageResponseApiZ.shape.receipt_url,
	rate: MileageRateZ,
	unitOfLength: ShortDistanceUnitsZ,
	report: ExpenseReportBasicZ.optional(),
	isItTravel: IsItTravelZ,
})
export type MileageBase_ = z.infer<typeof MileageBaseZ>

export const MileageZ = MileageBaseZ.extend({
	id: MileageResponseApiZ.shape.id,
	type: MileageResponseApiZ.shape.expense_type,
	status: MileageResponseApiZ.shape.expense_status,
	submitter: MileageResponseApiZ.shape.submitter,
	created_at: z.date(),
})
export type Mileage = z.infer<typeof MileageZ>

const DailyAllowanceRateZ = z.object({
	id: z.number(),
	name: z.string(),
	currency: CurrencyZ,
	amount: z.number(),
})
export type DailyAllowanceRate = z.infer<typeof DailyAllowanceRateZ>

const DailyAllowanceItemZ = z.object({
	daily_allowance_rate: DailyAllowanceRateZ,
	number_of_days: z.number().positive(),
	amount: z.number(),
})
export type DailyAllowanceItem = z.infer<typeof DailyAllowanceItemZ>

const DailyAllowanceRateRequestZ = DailyAllowanceRateZ.pick({
	name: true,
	currency: true,
	amount: true,
}).extend({
	destination_id: z.number(),
})
export type DailyAllowanceRateRequest = z.infer<typeof DailyAllowanceRateRequestZ>

const DailyAllowanceDestinationZ = z.object({
	id: z.number(),
	country_code: z.nativeEnum(CountriesCode),
	country: z.string(),
	destination_name: z.string(),
	rates: z.array(DailyAllowanceRateZ),
})
export type DailyAllowanceDestination = z.infer<typeof DailyAllowanceDestinationZ>

const DailyAllowanceDestinationRequestZ = DailyAllowanceDestinationZ.pick({
	country_code: true,
	destination_name: true,
}).extend({
	rates: z.array(DailyAllowanceRateRequestZ),
})
export type DailyAllowanceDestinationRequest = z.infer<typeof DailyAllowanceDestinationRequestZ>

const DailyAllowanceResponseZ = z.object({
	id: z.number(),
	expense_type: z.literal('daily_allowance'),
	expense_status: z.enum(expenseStatuses),
	report_amount: z.number(),
	report_id: z.number().nullable(),
	created_dt: z.string().datetime({ offset: true }),
	description: z.string().max(255).optional(),
	receipt_url: z.string().url().optional(),
	start_time: z.string().time(),
	category: z.string(),
	file_data: z
		.object({
			size: z.number(),
			name: z.string(),
		})
		.optional(),
	is_travel_related: z.boolean(),
	expense_report: z.string().optional(),
	submitter: UserZ,
	end_date: z.string().date(),
	attendees: z.array(ExpenseResponseAttendeeZ),
	daily_allowance_rates: z.array(DailyAllowanceItemZ),
	destination: z.string(),
	destination_id: z.number(),
	start_date: z.string().date(),
	end_time: z.string().time(),
	amount: z.number(),
	currency: CurrencyZ,
})
export type DailyAllowanceResponse = z.infer<typeof DailyAllowanceResponseZ>

const DailyAllowanceRequestZ = DailyAllowanceResponseZ.pick({
	currency: true,
	receipt_url: true,
	file_data: true,
	expense_type: true,
	expense_report: true,
	expense_status: true,
	start_date: true,
	end_date: true,
	start_time: true,
	end_time: true,
	is_travel_related: true,
	description: true,
	category: true,
	amount: true,
}).extend({
	attendees: z.array(ExpenseRequestAttendeeZ),
	submitter_id: z.number(),
	destination_id: z.number(),
	daily_allowance_rates: z.array(
		z.object({
			rate_id: z.number(),
			number_of_days: z.number().positive(),
			amount: z.number(),
		}),
	),
})
export type DailyAllowanceRequest = z.infer<typeof DailyAllowanceRequestZ>

const DailyAllowanceZ = DailyAllowanceResponseZ.pick({
	id: true,
	report_amount: true,
	description: true,
	start_time: true,
	category: true,
	submitter: true,
	end_date: true,
	attendees: true,
	daily_allowance_rates: true,
	start_date: true,
	end_time: true,
	amount: true,
	currency: true,
}).extend({
	type: DailyAllowanceResponseZ.shape.expense_type,
	status: DailyAllowanceResponseZ.shape.expense_status,
	report: ExpenseReportBasicZ.optional(),
	file: ExpenseFileZ.optional(),
	isItTravel: IsItTravelZ,
	created_at: z.date(),
	destination: z.object({ name: z.string(), id: z.number() }),
})
export type DailyAllowance = z.infer<typeof DailyAllowanceZ>
