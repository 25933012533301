import { useCallback, useState } from 'react'

import { useIsMounted } from 'src/lib/react-hooks/generic-hooks'

export function useAsyncAction(initialState = false) {
	const isMounted = useIsMounted()
	const [isRequestInProgress, setIsRequestInProgress] = useState(initialState)

	const performAsyncAction = useCallback(
		async function performAsyncAction<T>(action: () => Promise<T> | T) {
			setIsRequestInProgress(true)
			try {
				return await action()
			} finally {
				if (isMounted.value) {
					setIsRequestInProgress(false)
				}
			}
		},
		[isMounted],
	)

	return {
		isRequestInProgress,
		setIsRequestInProgress,
		performAsyncAction,
	}
}
