import history from 'src/lib/history'
import { loadData } from 'src/lib/requests'
import { HTTPMethod, OrderVerificationData } from 'src/travelsuit'

export type OrderVerificationDataState = {
	worldline_validated: boolean
	order_id: string
	trip_id?: number
}

export function getOrderVerificationState() {
	const locationState = history.location.state
	if (!locationState?.order_id) {
		return
	}
	return locationState as OrderVerificationDataState
}

export async function validateOrder(orderId: string, verificationData: Partial<OrderVerificationData>) {
	return loadData({
		data: verificationData,
		resourcePath: `/fee/worldline_order/${orderId}/validate`,
		method: HTTPMethod.POST,
	})
}

export function clearOrderVerificationState() {
	history.replace({ ...history.location, state: undefined })
}
