import React from 'react'
import onClickOutside from 'react-onclickoutside'

import { addE2EAttrs } from 'src/lib/e2e-utils'
import { DefaultProps } from 'src/travelsuit'

interface IProps<T extends HTMLElement = HTMLElement> extends DefaultProps {
	handleClickOutside?(e: React.MouseEvent<T>): void
}

class GenericClickOutside<T extends HTMLElement = HTMLElement> extends React.Component<IProps> {
	public handleClickOutside(e: React.MouseEvent<T>) {
		const { handleClickOutside } = this.props
		handleClickOutside?.(e)
	}

	public render() {
		const { children, className } = this.props
		if (className) {
			return (
				<div className={className} {...addE2EAttrs(this.props)}>
					{children}
				</div>
			)
		}
		return <>{children}</>
	}
}

export default onClickOutside<IProps>(GenericClickOutside)
