import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useCompanyFeatureFlags } from 'src/app-config/featureFlags'
import StarRating from 'src/atoms/StarRating/StarRating'
import { GridProps } from 'src/css-helpers/flex'
import { Trans } from 'src/lib/i18n/i18n'
import { currency } from 'src/lib/number-utils'
import LimitedLengthSummaryList from 'src/molecules/LimitedLengthSummaryList/LimitedLengthSummaryList'
import { accommodationTypeHelpers, getAccommodationTypeLabels } from 'src/organisms/PolicySettings/HotelPolicySettings'
import styled from 'src/styles'
import { DefaultElProps, HotelPriceCapRoute, Role, SelectOption } from 'src/travelsuit'
import { PolicyRefundOptions, TravelPolicy, TravelPolicyMinimal } from 'src/travelsuit/policy'

import {
	MaxRateSummaryPrice,
	MaxRateSummaryRoute,
	MaxRateSummaryRouteSegment,
	MaxRateSummaryRow,
	PolicySettingSummary,
	PolicySettingSummaryColumn,
	PolicySettingSummaryItem,
	PolicySettingSummaryTitle,
	PolicySettingSummaryValue,
} from './PolicySettings.components'
import { getNonFlexibleRefundOption, isTravelPolicyLoaded } from './utils'

interface IProps extends DefaultElProps<'div'> {
	policy: TravelPolicy | TravelPolicyMinimal
	columns?: GridProps['columns']
	role?: Role
	/** 0 = unlimited. default 2 */
	capRoutesRowsLimit?: number
}

const HotelPolicySettingsSummaryContainer = styled(PolicySettingSummary)``

const HotelPolicySettingsSummary: React.FunctionComponent<IProps> = (props) => {
	const { className, policy, columns, role } = props
	const { t } = useTranslation()
	const companyFeatureFlags = useCompanyFeatureFlags()
	const accommodationTypeLabels = useMemo(() => getAccommodationTypeLabels(t), [t])

	if (!isTravelPolicyLoaded(policy)) {
		return null
	}

	const nonFlexibleValue = getNonFlexibleRefundOption(policy.hotel_policy_data.non_flexible_hotel_rule)

	const globalPriceCap: Record<Role, number | null> =
		policy.hotel_policy_data.hotel_max_rate__rule?.max_rates_by_role ?? {}

	const maxRatings: Record<Role, number> = policy.hotel_policy_data.max_hotel_rating?.max_stars_by_role ?? {}

	const priceCapRoutes: HotelPriceCapRoute[] = policy.hotel_policy_data.hotel_max_rate__rule.rates ?? []

	const relevantRoleOptions: SelectOption[] = [
		{ value: Role.Employee, label: <Trans i18nKey="hotel-policy-settings.role-options.employees">Employees</Trans> },
		{ value: Role.Manager, label: <Trans i18nKey="hotel-policy-settings.role-options.managers">Managers</Trans> },
		{
			value: Role.Executive,
			label: <Trans i18nKey="hotel-policy-settings.role-options.executives">Executives</Trans>,
		},
	].filter((r) => !role || role === r.value)

	const refundableOptions: SelectOption[] = [
		{
			value: PolicyRefundOptions.Never,
			label: <Trans i18nKey="hotel-policy-settings.refundable-options.not-allowed">Not Allowed</Trans>,
		},
		{
			value: PolicyRefundOptions.Always,
			label: <Trans i18nKey="hotel-policy-settings.refundable-options.always-allowed">Always Allowed</Trans>,
		},
		{
			value: PolicyRefundOptions.UpToHours,
			label: (
				<Trans
					i18nKey="hotel-policy-settings.refundable-options.allow-up-to-read-only"
					count={policy.hotel_policy_data.non_flexible_hotel_rule.permit_up_to ?? 24}
				>
					Allow up to {{ count: policy.hotel_policy_data.non_flexible_hotel_rule.permit_up_to ?? 24 }} hour before
					check-in
				</Trans>
			),
		},
	]

	const getPriceCap = (role: Role) => globalPriceCap[role] ?? ''
	const getMaxRating = (role: Role) => maxRatings[role] ?? 5

	return (
		<HotelPolicySettingsSummaryContainer className={className} columns={columns}>
			<PolicySettingSummaryColumn>
				<PolicySettingSummaryItem>
					<PolicySettingSummaryTitle e2e={'PolicySettings.Non-RefundableRoomsTitle'}>
						<Trans i18nKey="hotel-policy-settings.non-refundable-rooms">Non-Refundable Rooms</Trans>
					</PolicySettingSummaryTitle>
					<PolicySettingSummaryValue e2e={'PolicySettings.Non-RefundableRoomsStatus'}>
						{refundableOptions.find((i) => i.value === nonFlexibleValue)!.label}
					</PolicySettingSummaryValue>
				</PolicySettingSummaryItem>
				{companyFeatureFlags?.enable_non_traditional_accommodation && (
					<PolicySettingSummaryItem data-test="PolicySettings.AccommodationTypes">
						<PolicySettingSummaryTitle data-test="PolicySettings.AccommodationTypes.Title">
							{t('hotel-policy-settings.accommodation-type.title', 'Accommodation Types')}
						</PolicySettingSummaryTitle>
						<PolicySettingSummaryValue data-test="PolicySettings.AccommodationTypes.Status">
							{accommodationTypeLabels
								.filter(([type]) =>
									accommodationTypeHelpers.isAllState(policy.hotel_policy_data.accomodation_type.allowed)
										? accommodationTypeHelpers.isAllValue(type)
										: accommodationTypeHelpers.isChecked(policy.hotel_policy_data.accomodation_type.allowed, type),
								)
								.map(([_type, label]) => label)
								.join(', ')}
						</PolicySettingSummaryValue>
					</PolicySettingSummaryItem>
				)}
				<PolicySettingSummaryItem e2e={'PolicySettings.ManualHotelRating'}>
					<PolicySettingSummaryTitle e2e={'PolicySettings.ManualHotelRatingTitle'}>
						<Trans i18nKey="hotel-policy-settings.manual-hotel-rating">Manual Hotel Rating</Trans>
					</PolicySettingSummaryTitle>
					<PolicySettingSummaryValue>
						{relevantRoleOptions.map((role) => (
							<MaxRateSummaryRow e2e={`PolicySettings.ManualHotelRating.${role.value}s`} key={role.value}>
								<MaxRateSummaryRoute data-test="PolicySettings.ManualHotelRating.Role">
									<MaxRateSummaryRouteSegment>{role.label}</MaxRateSummaryRouteSegment>
								</MaxRateSummaryRoute>
								<MaxRateSummaryPrice data-test="PolicySettings.ManualHotelRating.Rating">
									<StarRating rating={getMaxRating(role.value)} variant="orange" />
								</MaxRateSummaryPrice>
							</MaxRateSummaryRow>
						))}
					</PolicySettingSummaryValue>
				</PolicySettingSummaryItem>
			</PolicySettingSummaryColumn>
			<PolicySettingSummaryColumn>
				<PolicySettingSummaryItem e2e={'PolicySettings.GlobalPriceCap'}>
					<PolicySettingSummaryTitle e2e={'PolicySettings.GlobalPriceCapTitle'}>
						<Trans i18nKey="hotel-policy-settings.global-price-cap">Global Price Cap</Trans>
					</PolicySettingSummaryTitle>
					<PolicySettingSummaryValue>
						{relevantRoleOptions.map((role) => (
							<MaxRateSummaryRow e2e={`PolicySettings.GlobalPriceCap.${role.value}s`} key={role.value}>
								<MaxRateSummaryRoute data-test={`PolicySettings.GlobalPriceCap.Title`}>
									<MaxRateSummaryRouteSegment>{role.label}</MaxRateSummaryRouteSegment>
								</MaxRateSummaryRoute>
								<MaxRateSummaryPrice data-test={`PolicySettings.GlobalPriceCap.PriceCap`}>
									{getPriceCap(role.value) ? (
										currency(getPriceCap(role.value), { removeZeroes: true, currency: policy.currency_code })
									) : (
										<Trans i18nKey="hotel-policy-settings.unlimited-price-cap">Unlimited</Trans>
									)}
								</MaxRateSummaryPrice>
							</MaxRateSummaryRow>
						))}
					</PolicySettingSummaryValue>
				</PolicySettingSummaryItem>
				<PolicySettingSummaryItem e2e={'PolicySettings.ManualPriceCap'}>
					<PolicySettingSummaryTitle e2e={'PolicySettings.ManualPriceCapTitle'}>
						<Trans i18nKey="hotel-policy-settings.manual-price-cap">Manual Price Cap</Trans>
					</PolicySettingSummaryTitle>
					<PolicySettingSummaryValue>
						<LimitedLengthSummaryList maxItems={columns === 1 ? undefined : 2}>
							{priceCapRoutes.map((route, i) => (
								<MaxRateSummaryRow key={i} data-test={`PolicySettings.ManualPriceCap.Row`}>
									<MaxRateSummaryRoute data-test="PolicySettings.ManualPriceCap.City">
										<MaxRateSummaryRouteSegment>{route.city_name}</MaxRateSummaryRouteSegment>
									</MaxRateSummaryRoute>
									<MaxRateSummaryPrice data-test="PolicySettings.ManualPriceCap.PriceCap">
										{currency(route.max_rate, { removeZeroes: true, currency: policy.currency_code })}
									</MaxRateSummaryPrice>
								</MaxRateSummaryRow>
							))}
						</LimitedLengthSummaryList>
					</PolicySettingSummaryValue>
				</PolicySettingSummaryItem>
			</PolicySettingSummaryColumn>
		</HotelPolicySettingsSummaryContainer>
	)
}

HotelPolicySettingsSummary.defaultProps = {
	capRoutesRowsLimit: 2,
}

export default HotelPolicySettingsSummary
