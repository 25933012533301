import React from 'react'
import onClickOut from 'react-onclickoutside'
import { css } from 'styled-components'

import { boxShadowHover } from 'src/_vars'
import { AttachDirection, Coords } from 'src/lib/utils'
import { white } from 'src/refactor/colors'
import styled, { AnimationNames, EnterAnim, EnterAnimProps } from 'src/styles'
import { DefaultProps } from 'src/travelsuit'

export interface IProps extends DefaultProps {
	attachTo?: AttachDirection
	attachOffset?: Partial<Coords>
	animation?: AnimationNames
	animationDelay?: number
	animationDuration?: number
	animationEasing?: React.CSSProperties['animationTimingFunction']
	background?: boolean | string
	dropShadow?: boolean | string
	onClickOut?(e: React.MouseEvent<any>): void
}

interface ContainerProps {
	shadow?: IProps['dropShadow']
	background?: IProps['background']
}

const containerStyle = css`
	position: absolute;
	border-radius: 5px;
	${(props: ContainerProps) =>
		props.shadow ? `box-shadow: ${props.shadow === true ? boxShadowHover : props.shadow};` : ''}
	${(props: ContainerProps) =>
		props.background ? `background: ${props.background === true ? white : props.background};` : ''}
`

const Container = styled.div<ContainerProps>`
	${containerStyle}
`
export const AnimatedContainer = styled(EnterAnim)<ContainerProps & EnterAnimProps>`
	${containerStyle}
`

function getOffset(attachOffset?: Partial<Coords>): Coords {
	attachOffset = attachOffset || { x: 0, y: 0 }
	attachOffset.x = attachOffset.x || 0
	attachOffset.y = attachOffset.y || 0
	return attachOffset as Coords
}

function attachDirectionStyle(attachTo?: AttachDirection, attachOffset?: Partial<Coords>): React.CSSProperties {
	attachOffset = getOffset(attachOffset)

	if (!attachTo) {
		return {
			top: attachOffset.y,
			left: attachOffset.x,
		}
	}

	return {
		top: attachTo.top ? attachOffset.y : undefined,
		bottom: attachTo.bottom ? attachOffset.y : undefined,
		right: attachTo.right ? attachOffset.x : undefined,
		left: attachTo.left ? attachOffset.x : undefined,
	}
}

function attachTransformOrigin(attachTo?: AttachDirection): React.CSSProperties {
	if (!attachTo) {
		return {}
	}

	const horiz = attachTo.left ? 'left' : attachTo.right ? 'right' : 'center'
	const vert = attachTo.top ? 'top' : attachTo.bottom ? 'bottom' : 'center'

	return {
		transformOrigin: `${horiz} ${vert}`,
	}
}

export const DEFAULT_ANIMATION_DURATION = 150

// TASK migrate to React.FunctionComponent OR remove this if not possible
class FloatingContainer extends React.Component<IProps> {
	public static defaultProps = {
		animationDuration: DEFAULT_ANIMATION_DURATION,
	}

	public handleClickOutside(e: React.MouseEvent<any>) {
		if (this.props.onClickOut) {
			this.props.onClickOut(e)
		}
	}

	public render() {
		const {
			className,
			children,
			attachTo,
			attachOffset,
			animation,
			animationDelay,
			animationDuration,
			animationEasing,
			dropShadow,
			background,
			style,
		} = this.props

		const finalStyle = { ...attachDirectionStyle(attachTo, attachOffset), ...attachTransformOrigin(attachTo), ...style }

		if (!animation) {
			return (
				<Container className={className} shadow={dropShadow} background={background} style={finalStyle}>
					{children}
				</Container>
			)
		}

		return (
			<AnimatedContainer
				className={className}
				animation={animation}
				delay={animationDelay}
				duration={animationDuration}
				easing={animationEasing}
				shadow={dropShadow}
				background={background}
				style={finalStyle}
			>
				{children}
			</AnimatedContainer>
		)
	}
}

// FloatingContainer.defaultProps = {
// 	//
// }

export default onClickOut(FloatingContainer)
