import { Airline } from 'src/travelsuit'

import { IAction } from './action-helpers'
import { AirlinesTypes as Types } from './actions.types'

export const Actions = {
	setAirlines(airlines?: Airline[]): IAction {
		return {
			type: Types.SetAirlines,
			payload: airlines,
		}
	},
}
