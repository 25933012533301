import React from 'react'

import { flightTypeByNumOfStops } from 'src/lib/flight-utils'
import { Trans, useTranslation } from 'src/lib/i18n/i18n'
import { currency } from 'src/lib/number-utils'
import { useAdminUsers } from 'src/lib/react-hooks/generic-hooks'
import LimitedLengthSummaryList from 'src/molecules/LimitedLengthSummaryList/LimitedLengthSummaryList'
import ArrowBoth from 'src/refactor/assets/icons/arrow-both.svg'
import ArrowRight from 'src/refactor/assets/icons/arrow-right.svg'
import styled, { GridProps } from 'src/styles'
import { DefaultElProps, Role, SelectOption, TripDirection } from 'src/travelsuit'
import { FlightPriceCapRoute, PolicyRefundOptions, TravelPolicy, TravelPolicyMinimal } from 'src/travelsuit/policy'

import { FlightClassSummary } from './FlightClassSummary'
import { FlightPriceCap } from './FlightPriceCap'
import {
	DirectionIcon,
	MaxRateSummaryPrice,
	MaxRateSummaryRoute,
	MaxRateSummaryRouteSegment,
	MaxRateSummaryRow,
	PolicySettingSummary,
	PolicySettingSummaryColumn,
	PolicySettingSummaryItem,
	PolicySettingSummaryTitle,
	PolicySettingSummaryValue,
} from './PolicySettings.components'
import { getNonFlexibleRefundOption, isTravelPolicyLoaded } from './utils'

interface IProps extends DefaultElProps<'div'> {
	policy: TravelPolicy | TravelPolicyMinimal
	columns?: GridProps['columns']
	role?: Role
	/** 0 = unlimited. default 2 */
	capRoutesRowsLimit?: number
}

const FlightPolicySettingsSummaryContainer = styled(PolicySettingSummary)`
	${(p) => p.theme.breakpoints.up('lg')} {
		grid-gap: ${(p) => p.theme.spacing(9)}px;
	}
`

const FlightPolicySettingsSummary: React.FunctionComponent<IProps> = (props) => {
	const { className, policy, columns, role, capRoutesRowsLimit } = props
	const users = useAdminUsers()
	const { t } = useTranslation()

	if (!isTravelPolicyLoaded(policy)) {
		return null
	}

	const nonFlexibleValue = getNonFlexibleRefundOption(policy.flight_policy_data.non_flexible_flight_rule)

	const refundableOptions: SelectOption[] = [
		{
			value: PolicyRefundOptions.Never,
			label: <Trans i18nKey="flight-policy-settings.refundable-options.not-allowed">Not Allowed</Trans>,
		},
		{
			value: PolicyRefundOptions.Always,
			label: <Trans i18nKey="flight-policy-settings.refundable-options.always-allowed">Always Allowed</Trans>,
		},
	]

	const maxPaidSeatAllow = policy.flight_policy_data.flight_seat_max_price_rule.allow ?? false
	const maxPaidSeatPriceOrig = policy.flight_policy_data.flight_seat_max_price_rule.default_max_price ?? 0
	const maxPaidSeatPrice = maxPaidSeatAllow === false ? null : maxPaidSeatPriceOrig
	const shortFlightPriceCap: number | null =
		policy.flight_policy_data.flight_max_price_rule.short_flight_default_max_price ?? null
	const shortFlightSmartPrice: number | null =
		policy.flight_policy_data.flight_max_price_rule.short_flight_percentile_deviation ?? null
	const longFlightPriceCap: number | null =
		policy.flight_policy_data.flight_max_price_rule.long_flight_default_max_price ?? null
	const longFlightSmartPrice: number | null =
		policy.flight_policy_data.flight_max_price_rule.long_flight_percentile_deviation ?? null
	const priceCapRoutes: FlightPriceCapRoute[] = policy.flight_policy_data.flight_max_price_rule.routes ?? []

	return (
		<FlightPolicySettingsSummaryContainer className={className} columns={columns}>
			<PolicySettingSummaryColumn>
				<PolicySettingSummaryItem>
					<PolicySettingSummaryTitle e2e={'PolicySettings.Non-RefundableFlightsTitle'}>
						<Trans i18nKey="flight-policy-settings.non-refundable-flights">Non-Refundable Flights</Trans>
					</PolicySettingSummaryTitle>
					<PolicySettingSummaryValue e2e={'PolicySettings.Non-RefundableFlightsValue'}>
						{refundableOptions.find((i) => i.value === nonFlexibleValue)!.label}
					</PolicySettingSummaryValue>
				</PolicySettingSummaryItem>
				<PolicySettingSummaryItem>
					<PolicySettingSummaryTitle e2e={'PolicySettings.BusinessClassPermissionsTitle'}>
						{t('flight-policy-settings.class-permissions', 'Class Permissions')}
					</PolicySettingSummaryTitle>
					<FlightClassSummary users={users} rules={policy?.flight_policy_data} role={role} />
				</PolicySettingSummaryItem>
				<PolicySettingSummaryItem>
					<PolicySettingSummaryTitle e2e={'PolicySettings.AllowedPaidSeatSelectionTitle'}>
						<Trans i18nKey="flight-policy-settings.allow-paid-seat-selection">Allow Paid Seat Selection</Trans>
					</PolicySettingSummaryTitle>
					<PolicySettingSummaryValue e2e={'PolicySettings.AllowedPaidSeatSelectionStatus'}>
						{maxPaidSeatPrice !== null ? (
							<Trans i18nKey="flight-policy-settings.paid-seat.allowed">
								Allow up to {{ price: currency(maxPaidSeatPrice, { currency: policy?.currency_code }) }}
							</Trans>
						) : (
							<Trans i18nKey="flight-policy-settings.paid-seat.not-allowed">Not Allowed</Trans>
						)}
					</PolicySettingSummaryValue>
				</PolicySettingSummaryItem>
			</PolicySettingSummaryColumn>
			<PolicySettingSummaryColumn>
				<PolicySettingSummaryItem>
					<PolicySettingSummaryTitle e2e={'PolicySettings.ShortFlightsPriceCapTitle'}>
						<Trans i18nKey="flight-policy-settings.short-flights-label">Short Flights Price Cap</Trans>
					</PolicySettingSummaryTitle>
					<FlightPriceCap
						e2e="PolicySettings.ShortFlightsPriceCapStatus"
						maxPrice={shortFlightPriceCap}
						smartPrice={shortFlightSmartPrice}
						currencyCode={policy?.currency_code}
					/>
				</PolicySettingSummaryItem>
				<PolicySettingSummaryItem>
					<PolicySettingSummaryTitle e2e={'PolicySettings.LongFlightsPriceCapTitle'}>
						<Trans i18nKey="flight-policy-settings.long-flights-label">Long Flights Price Cap</Trans>
					</PolicySettingSummaryTitle>
					<FlightPriceCap
						e2e="PolicySettings.LongFlightsPriceCapStatus"
						maxPrice={longFlightPriceCap}
						smartPrice={longFlightSmartPrice}
						currencyCode={policy?.currency_code}
					/>
				</PolicySettingSummaryItem>
				<PolicySettingSummaryItem e2e={'PolicySettings.ManualPriceCapBlock'}>
					<PolicySettingSummaryTitle e2e={'PolicySettings.ManualPriceCapTitle'}>
						<Trans i18nKey="flight-policy-settings.manual-price-cap">Manual Price Cap</Trans>
					</PolicySettingSummaryTitle>
					<PolicySettingSummaryValue e2e={'PolicySettings.ManualPriceCapStatus'}>
						<LimitedLengthSummaryList maxItems={capRoutesRowsLimit}>
							{priceCapRoutes.map((route, i) => (
								<MaxRateSummaryRow key={i} e2e="PolicySettingsSummary.Row">
									<MaxRateSummaryRoute width={90}>
										<MaxRateSummaryRouteSegment e2e="PolicySettingsSummary.Row.Departure">
											{route.departure_code}
										</MaxRateSummaryRouteSegment>
										<DirectionIcon
											src={
												flightTypeByNumOfStops(route.num_of_flights) === TripDirection.RoundTrip
													? ArrowBoth
													: ArrowRight
											}
										/>
										<MaxRateSummaryRouteSegment e2e="PolicySettingsSummary.Row.Arrival">
											{route.arrival_code}
										</MaxRateSummaryRouteSegment>
									</MaxRateSummaryRoute>
									<MaxRateSummaryPrice>
										<FlightPriceCap
											maxPrice={route.max_price}
											smartPrice={route.percentile_deviation}
											currencyCode={policy?.currency_code}
											e2e="PolicySettingsSummary.Row.PriceCap"
										/>
									</MaxRateSummaryPrice>
								</MaxRateSummaryRow>
							))}
						</LimitedLengthSummaryList>
					</PolicySettingSummaryValue>
				</PolicySettingSummaryItem>
			</PolicySettingSummaryColumn>
		</FlightPolicySettingsSummaryContainer>
	)
}

FlightPolicySettingsSummary.defaultProps = {
	capRoutesRowsLimit: 2,
}

export default FlightPolicySettingsSummary
