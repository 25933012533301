import React from 'react'

import { useTranslation } from 'src/lib/i18n/i18n'
import { AdminUsersState } from 'src/redux/reducers/admin-users.reducer'
import { Role } from 'src/travelsuit'
import { FlightPolicyRules } from 'src/travelsuit/policy'

import { FlightClassSummaryRow } from './FlightClassSummaryRow'

interface FlightClassSummaryProps {
	rules: FlightPolicyRules
	role?: Role
	users: AdminUsersState
}

export function FlightClassSummary({ rules, role, users }: FlightClassSummaryProps) {
	const { t } = useTranslation()

	return (
		<>
			<FlightClassSummaryRow
				label={t('flight-policy-settings.premium-economy', 'Premium Economy')}
				role={role}
				users={users}
				rules={rules.premium_economy_class_rule}
				e2e="PolicySettings.PremiumEconomyClassPermissionStatus"
			/>
			<FlightClassSummaryRow
				label={t('flights.cabin-class-name.business', 'Business')}
				role={role}
				users={users}
				rules={rules.business_class_rule}
				e2e="PolicySettings.BusinessClassPermissionStatus"
			/>
			<FlightClassSummaryRow
				label={t('flights.cabin-class-name.first', 'First')}
				role={role}
				users={users}
				rules={rules.first_class_rule}
				e2e="PolicySettings.FirstClassPermissionStatus"
			/>
		</>
	)
}
