import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useState } from 'react'

export interface EntityCachedQueryHook<K, E extends { [key: string]: K } | Record<string, any>> {
	/**
	 *
	 * @param key
	 * @param placeholder
	 * @returns cached entity or placeholder, triggers fetch if not found in cache
	 */
	getEntity: (key?: K, placeholder?: (key: K) => E) => E | undefined

	/**
	 * Fetches a single entity and caches it
	 * @param key
	 * @returns entity
	 */
	queryEntity: (key: K) => Promise<E | undefined>

	/**
	 * Searches for entities and caches them
	 * @param args
	 * @returns
	 */
	searchEntities: (...args: unknown[]) => Promise<E[]>
}

/**
 * Create cached look-up hook for User, Airport, Airline etc.
 * @interface EntityCachedQueryHook
 *
 * Uses `react-query` QueryClient for caching
 *
 * @param domain - e.g. 'users', 'airports', 'airlines'
 * @param keyName - e.g. 'id' (user.id), 'iata' (airport.iata), 'code' (airline.code)
 * @param queryEntity - query a single entity
 * @param searchEntities - query an entity list
 * @param mapper - map hook functions name to comprehensive domain naming
 * @returns mapped `EntityCachedQueryHook`
 */
export function createEntityCachedQueryHook<K, E extends { [keyName: string]: K } | Record<string, any>, H>(
	domain: string,
	keyName: string,
	queryEntity: (key: K) => Promise<E>,
	searchEntities: (...args: unknown[]) => Promise<E[]>,
	mapper: (hook: EntityCachedQueryHook<K, E>) => H,
) {
	return () => {
		const [version, setVersion] = useState(0)

		const client = useQueryClient()

		const queryEntityWithCache = useCallback(
			async (key: K) => {
				const queryKey = [domain, key]

				const entity = client.getQueryData<E>(queryKey)
				if (entity) {
					return entity
				}

				try {
					return await client.fetchQuery({ queryKey, queryFn: () => queryEntity(key) })
				} finally {
					setVersion((version) => version + 1)
				}
			},
			[client],
		)

		const searchEntitiesWithCache = useCallback(
			async (...args: unknown[]) => {
				const entities = await searchEntities(...args)
				let hasNew = false
				for (const entity of entities) {
					const queryKey = [domain, entity[keyName]]

					const cachedEntity = client.getQueryData<E>(queryKey)
					if (!cachedEntity) {
						client.setQueryData(queryKey, entity)
						hasNew = true
					}
				}

				if (hasNew) {
					setVersion((version) => version + 1)
				}

				return entities
			},
			[client],
		)

		const getEntityWithCache = useCallback(
			(key?: K, placeholder?: (key: K) => E): E | undefined => {
				if (!key) {
					return
				}

				const entity = client.getQueryData<E>([domain, key])
				if (entity) {
					return entity
				}

				queryEntityWithCache(key)

				return placeholder?.(key)
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[client, version, queryEntityWithCache],
		)

		return mapper({
			getEntity: getEntityWithCache,
			queryEntity: queryEntityWithCache,
			searchEntities: searchEntitiesWithCache,
		})
	}
}
