import classNames from 'classnames'
import React from 'react'
import { css as addCss, keyframes } from 'styled-components'

import { CenteredAbsolute, Flex } from 'src/atoms/GenericComponents/GenericComponents'
import { addE2EAttrs, E2E } from 'src/lib/e2e-utils'
import { graySemiTransparent, hoverBlue, linkBlue } from 'src/refactor/colors'
import { font, FontType } from 'src/refactor/fonts'
import styled, { padPx, size } from 'src/styles'
import { DefaultElProps, DefaultProps } from 'src/travelsuit'

import css from './Loader.module.css'

interface IProps extends DefaultProps {
	size?: number
	desktopSize?: number
	label?: React.ReactNode
	color?: 'primary' | 'light' | 'secondary' | 'contrast'
}

export const AnimationLabel = styled.h2`
	${font(FontType.Comfortaa, { size: 20 })}
	text-align: center;
	margin-top: -80px;

	${(p) => p.theme.breakpoints.down('md')} {
		max-width: 100vw;
		padding: 0 15px;
	}
`

export const LoaderContainer = styled.div<{ size: number; desktopSize?: number }>`
	overflow: hidden;

	${(p) => (p.size ? size(p.size) : '')}

	${(p) => p.theme.breakpoints.up('lg')} {
		${(p) => (p.desktopSize ? size(p.desktopSize) : '')}
	}
`

export const CircularLoader: React.FunctionComponent<IProps> = (props) => {
	const { className, size = 64, color, label, desktopSize, style, ...rest } = props
	const colorCls = css[color!]
	const circleCls = classNames(css.Loader, className, colorCls)

	return (
		<div className={className} {...rest}>
			<LoaderContainer className={circleCls} size={size} desktopSize={desktopSize} style={style}>
				<svg className={classNames(css.circular, css.center)} viewBox="25 25 50 50">
					<circle className={css.path} cx="50" cy="50" r={20} fill="none" strokeWidth={4} strokeMiterlimit={10} />
				</svg>
			</LoaderContainer>
			{label ? <AnimationLabel style={{ marginTop: 20 }}>{label}</AnimationLabel> : null}
		</div>
	)
}

CircularLoader.defaultProps = {
	color: 'primary',
}

export const ButtonLoader = styled(CircularLoader).attrs(() => ({ size: 20 }))`
	display: inline-block;
	margin: -5px;
`
const c1 = linkBlue
const c2 = hoverBlue

export const LineLoader = styled.div<{ height?: number }>`
	height: ${(props) => padPx(props.height)};
	background: linear-gradient(90deg, ${c1} 0%, ${c2} 33%, ${c1} 66%, ${c2} 100%);
	background-size: 400%;

	@keyframes loopGradient {
		0% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 15% 50%;
		}
	}

	animation: loopGradient 2s linear infinite;
`

LineLoader.defaultProps = {
	height: 12,
}

export const PageLoader = (props: DefaultElProps<'div'>) => (
	<CenteredAbsolute {...props}>
		<CircularLoader color="primary" />
	</CenteredAbsolute>
)

export default CircularLoader

interface ILoaderWrapper {
	position?: 'fixed'
	background?: 'transparent'
	size?: number
}

export const LoaderWrapper = styled(Flex).attrs(() => ({
	justify: 'center',
	align: 'center',
}))<ILoaderWrapper>`
	position: ${(props) => props.position ?? 'absolute'};
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: ${(props) => props.background ?? graySemiTransparent};
`

export function LoaderOverlay({ background, position, size }: ILoaderWrapper) {
	return (
		<LoaderWrapper background={background} position={position}>
			<CircularLoader size={size} />
		</LoaderWrapper>
	)
}

export function ScreenLoader() {
	return <LoaderOverlay position="fixed" background="transparent" />
}

interface IProgressBar extends DefaultProps {
	progress: number
}

const hide = keyframes`
	0% {
		opacity: 100%
	}
	100% {
		opacity: 0%;
	}
`

const hideAnimation = () => addCss`
		animation: ${hide} 1000ms linear 1s forwards;
	`

const ProgressWrapper = styled.div.attrs(addE2EAttrs)<IProgressBar & E2E>`
	background: ${hoverBlue};
	width: 100%;
	height: 5px;
	${(props) => (props.progress === 100 ? hideAnimation : '')};
`

const ProgressBar = styled.div<IProgressBar>`
	background: linear-gradient(90deg, ${c1}, ${c2} 50%, ${c1});
	width: ${(props) => props.progress}%;
	transition: width 1500ms linear;
	height: 100%;
`

export function ProgressLoader({ className, progress, e2e }: IProgressBar & E2E) {
	return (
		<ProgressWrapper className={className} progress={progress} e2e={e2e} data-progress={progress}>
			<ProgressBar progress={progress} />
		</ProgressWrapper>
	)
}
