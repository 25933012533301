import React from 'react'

import useTranslation from 'src/lib/i18n/UseTranslation'
import { Fee, SupportRequestFee } from 'src/travelsuit/fees.types'
import { fullName } from 'src/travelsuit/users.utils'

import { FeeCardWithPayment } from './FeeCardWithPayment'

interface IProps {
	fee: Fee<SupportRequestFee>
	onFeesReload: () => void
}

const SupportFeeCard: React.FC<IProps> = ({ fee, onFeesReload }) => {
	const { t } = useTranslation()

	const { request_by_user, ticket } = fee.support_request

	const { invoice_profile } = request_by_user

	return (
		<FeeCardWithPayment
			fee={fee}
			secondaryChipText={t('usage-details.fees.support-ticket', 'Support Ticket - {{ticket}}', { ticket })}
			invoiceProfile={invoice_profile}
			primaryInfo={fullName(request_by_user)}
			paymentModalTitle={t('usage-details.pay-again-modal.support-request', 'Support Request Fee')}
			canChangeDefaultCard
			onFeesReload={onFeesReload}
		/>
	)
}

export default SupportFeeCard
