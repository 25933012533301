import { currency } from 'src/lib/number-utils'
import { Currency } from 'src/types/common'

export function getPriceLabel({
	currency: priceCurrency,
	price,
	keepZeroes,
}: {
	currency: Currency
	price: number | null
	keepZeroes?: boolean
}) {
	return currency(price ?? 0, {
		removeZeroes: !keepZeroes,
		currency: priceCurrency,
		precision: 2,
	})
}
