import get from 'lodash/get'

export enum IOSEventType {
	PushToken = 'push-token',
	PushPermissionRequest = 'push-permission-request',
	PushPermissionState = 'push-permission-state',
	PushNotification = 'push-notification',
	RedirectToTrip = 'redirect-to-trip',
}

export enum IOSPushPermissionRequestResult {
	Granted = 'granted',
	Denied = 'denied',
}

export enum IOSPushPermissionAuthorizationStatus {
	NotDetermined = 'notDetermined',
	Denied = 'denied',
	Authorized = 'authorized',
	Ephemeral = 'ephemeral',
	Provisional = 'provisional',
	Unknown = 'unknown',
}

export type IOSEventDetail = {
	[IOSEventType.PushToken]: string
	[IOSEventType.PushPermissionRequest]: IOSPushPermissionRequestResult
	[IOSEventType.PushPermissionState]: IOSPushPermissionAuthorizationStatus
	[IOSEventType.PushNotification]: string | null
	[IOSEventType.RedirectToTrip]: string
}

export enum IOSMessageHandlerName {
	Print = 'print',
	PushPermissionRequest = 'push-permission-request',
	PushPermissionState = 'push-permission-state',
	PushToken = 'push-token',
	InitRedirectTo3ds = 'init-redirect-to-3ds',
}

export type IOSMessageHandlerPayload = {
	[IOSMessageHandlerName.Print]: void
	[IOSMessageHandlerName.PushToken]: void
	[IOSMessageHandlerName.PushPermissionRequest]: void
	[IOSMessageHandlerName.PushPermissionState]: void
	[IOSMessageHandlerName.InitRedirectTo3ds]: { url: string }
}

export function subscribeForIOSEvent<EventType extends keyof IOSEventDetail>(
	eventType: EventType,
	listener: (event: CustomEvent<IOSEventDetail[EventType]>) => void,
) {
	window.addEventListener(eventType, listener)
	return () => window.removeEventListener(eventType, listener)
}

export function postMessageToIOSWebView<HandlerName extends keyof IOSMessageHandlerPayload>(
	handlerName: HandlerName,
	message: IOSMessageHandlerPayload[HandlerName],
): void {
	const handler: { postMessage: (payload: string) => void } | undefined = get(
		window as unknown,
		`webkit.messageHandlers.${handlerName}`,
	)

	if (!handler) {
		return
	}

	handler.postMessage(JSON.stringify(message ?? handlerName))
}

let isIOSApp: boolean | undefined = undefined

export function isLaunchedAsIOSApp() {
	if (isIOSApp === undefined) {
		isIOSApp = !!get(window as unknown, 'webkit.messageHandlers')
	}

	return isIOSApp
}
