import React from 'react'

import { GridProps } from 'src/css-helpers/flex'
import { Trans, useTranslation } from 'src/lib/i18n/i18n'
import { currency } from 'src/lib/number-utils'
import styled from 'src/styles'
import { DefaultElProps, getRoleLabel, Role, SelectOption } from 'src/travelsuit'
import { PolicyRefundOptions, TravelPolicy, TravelPolicyMinimal } from 'src/travelsuit/policy'

import {
	MaxRateSummaryRow,
	PolicySettingSummary,
	PolicySettingSummaryColumn,
	PolicySettingSummaryItem,
	PolicySettingSummaryTitle,
	PolicySettingSummaryValue,
} from './PolicySettings.components'
import { getNonFlexibleRefundOption, isRoleEligible, isTravelPolicyLoaded } from './utils'

interface IProps extends DefaultElProps<'div'> {
	policy: TravelPolicyMinimal | TravelPolicy
	columns?: GridProps['columns']
	role?: Role
}

const CarPolicySettingsSummaryContainer = styled(PolicySettingSummary)``

const CarPolicySettingsSummary: React.FunctionComponent<IProps> = (props) => {
	const { className, policy, columns, role } = props
	const { t } = useTranslation()

	if (!isTravelPolicyLoaded(policy)) {
		return null
	}

	const nonFlexibleValue = getNonFlexibleRefundOption(policy.car_policy_data.non_flexible_car_rule)

	const globalPriceCap: number = policy.car_policy_data.car_max_price_rule?.max_price ?? 0

	const roleOptions: SelectOption[] = [
		{
			value: Role.Employee,
			label: <Trans i18nKey="car-policy-settings.role-options.employees">All Employees</Trans>,
		},
		{
			value: Role.Manager,
			label: <Trans i18nKey="car-policy-settings.role-options.managers">Managers and above</Trans>,
		},
		{
			value: Role.Executive,
			label: <Trans i18nKey="car-policy-settings.role-options.executives">Executives only</Trans>,
		},
	]

	const refundableOptions: SelectOption[] = [
		{
			value: PolicyRefundOptions.Never,
			label: <Trans i18nKey="car-policy-settings.refundable-options.not-allowed">Not Allowed</Trans>,
		},
		{
			value: PolicyRefundOptions.Always,
			label: <Trans i18nKey="car-policy-settings.refundable-options.always-allowed">Always Allowed</Trans>,
		},
		{
			value: PolicyRefundOptions.UpToHours,
			label: (
				<Trans
					i18nKey="car-policy-settings.refundable-options.allow-up-to-read-only"
					count={policy.car_policy_data.non_flexible_car_rule.permit_up_to ?? 24}
				>
					Allow up to {{ count: policy.car_policy_data.non_flexible_car_rule.permit_up_to ?? 24 }} hour before check-in
				</Trans>
			),
		},
	]

	const carRentRole = policy.car_policy_data.role_car_rent.role

	return (
		<CarPolicySettingsSummaryContainer className={className} columns={columns} data-test="PolicySettings.Container">
			<PolicySettingSummaryColumn>
				<PolicySettingSummaryItem>
					<PolicySettingSummaryTitle e2e={'PolicySettings.CarBookingPermissionTitle'}>
						<Trans i18nKey="car-policy-settings.car-booking-permissions">Car Booking Permissions</Trans>
					</PolicySettingSummaryTitle>
					<PolicySettingSummaryValue e2e={'PolicySettings.CarBookingPermissionStatus'}>
						{!role || isRoleEligible(role!, carRentRole!)
							? roleOptions.find((o) => o.value === carRentRole!)?.label ??
								t('car-policy-settings.role-not-allowed', 'Not allowed for {{ role }}', {
									role: t('car-policy-settings.role-options.any', 'anyone'),
								})
							: role
								? t('car-policy-settings.role-not-allowed', 'Not allowed for {{ role }}', {
										role: getRoleLabel({ role, t, count: 2 }),
									})
								: null}
					</PolicySettingSummaryValue>
				</PolicySettingSummaryItem>
				<PolicySettingSummaryItem>
					<PolicySettingSummaryTitle data-test={'PolicySettings.GlobalPriceCapTitle'}>
						<Trans i18nKey="car-policy-settings.global-price-cap">Global Price Cap</Trans>
					</PolicySettingSummaryTitle>
					<PolicySettingSummaryValue e2e={'PolicySettings.GlobalPriceCapStatus'}>
						<MaxRateSummaryRow>
							{globalPriceCap ? (
								currency(globalPriceCap, { removeZeroes: true, currency: policy.currency_code })
							) : (
								<Trans i18nKey="car-policy-settings.unlimited-price-cap">Unlimited</Trans>
							)}
						</MaxRateSummaryRow>
					</PolicySettingSummaryValue>
				</PolicySettingSummaryItem>
			</PolicySettingSummaryColumn>
			<PolicySettingSummaryColumn>
				<PolicySettingSummaryItem>
					<PolicySettingSummaryTitle e2e={'PolicySettings.Non-RefundableCarsTitle'}>
						<Trans i18nKey="car-policy-settings.non-refundable-cars">Non-Refundable Cars</Trans>
					</PolicySettingSummaryTitle>
					<PolicySettingSummaryValue e2e={'PolicySettings.Non-RefundableCarsStatus'}>
						{refundableOptions.find((i) => i.value === nonFlexibleValue)!.label}
					</PolicySettingSummaryValue>
				</PolicySettingSummaryItem>
			</PolicySettingSummaryColumn>
		</CarPolicySettingsSummaryContainer>
	)
}

export default CarPolicySettingsSummary
