import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import moment from 'moment'

import {
	AirlineConfirmationInfo,
	HotelBooking,
	Itinerary,
	ItineraryCarBooking,
	ItineraryFlightBooking,
	ItineraryHotelBooking,
	ItineraryRailBooking,
	Location,
	PreTripRequest,
	ReservationStatus,
	Trip,
	TripFilterSet,
	TripPurpose,
	TripPurposeOption,
	TripStatus,
	User,
	WithBookingType,
	WithCvvRequirement,
	WithItineraryBookingsManagedByUser,
} from 'src/travelsuit'
import { getFilteredSelectedSeatsParams } from 'src/travelsuit/seatmaps'
import { fullName } from 'src/travelsuit/users.utils'
import { CreditCardType } from 'src/types/creditCard'

import { minMax, minMaxItem, MultiLocaleTimeFormats } from './utils'

export const tripFilterFns: { [k in keyof TripFilterSet]: (trip: Trip, value: any) => boolean } = {
	departingFrom: (trip, filter: Date) =>
		Boolean(
			trip.products.length &&
				trip.products[0].start_time &&
				moment.parseZone(trip.products[0].start_time).isSameOrAfter(filter),
		),
	departingTo: (trip, filter: Date) =>
		Boolean(
			trip.products.length &&
				trip.products[trip.products.length - 1].start_time &&
				moment.parseZone(trip.products[trip.products.length - 1].start_time).isSameOrBefore(filter),
		),
	destinationName: (trip, locationName: string) =>
		Boolean(
			trip.products.some(
				(d) =>
					(d.to_location && d.to_location.name === locationName) ||
					(d.from_location && d.from_location.name === locationName),
			),
		),
	traveler: (trip, traveler: User) => Boolean(trip.travelers.map((t) => t.id).indexOf(traveler.id) >= 0),
	creator: (trip, creator: User) => Boolean(trip.created_by === creator.id),
	office: (trip, office: Location) =>
		Boolean(trip.travelers.some((t) => get(t, 'traveler_profile.office.id', null) === office.id)),
}

export function isItineraryHasSelections(itinerary: Itinerary | null): boolean {
	return Boolean(
		itinerary &&
			[itinerary.flight_itinerary?.summary, itinerary.hotel_itinerary?.summary, itinerary.car_itinerary?.summary].some(
				(a) => Boolean(a?.length),
			),
	)
}

export function getTripRequestDates(
	request: PreTripRequest,
	{ format = MultiLocaleTimeFormats.FullReadableDate, sep = ' - ' }: Partial<{ format: string; sep: string }> = {},
) {
	const routeDates = request.route.reduce((all, r) => [...all, r.from_date, r.to_date], []).map((d) => moment(d).unix())
	return minMax(routeDates)
		.map((d) => moment.unix(d).format(format))
		.join(sep)
}

export function getFlightsBySearchId(itinerary: Itinerary | undefined, search_id: number) {
	return itinerary?.flights_bookings.filter((e) => e.search_id === search_id) ?? []
}

export function getHotelsBySearchId(itinerary: Itinerary | undefined, search_id: number) {
	return itinerary?.hotels_bookings.filter((e) => e.search_id === search_id) ?? []
}

export function getCarsBySearchId(itinerary: Itinerary | undefined, search_id: number) {
	return itinerary?.cars_bookings.filter((e) => e.search_id === search_id) ?? []
}

export function getGroupedFlights(itinerary: Itinerary): Record<string, ItineraryFlightBooking[]> {
	return itinerary?.flights_bookings.length
		? groupBy(itinerary.flights_bookings, (e) => [e.search_id, e.cancellation_status].map(String).join(';'))
		: {}
}

function getGroupedRails(itinerary: Itinerary | undefined): Record<string, ItineraryRailBooking[]> {
	return itinerary?.rails_bookings.length
		? groupBy(itinerary.rails_bookings, (e) => [e.search_id, e.cancellation_status].map(String).join(';'))
		: {}
}

export function getGroupedHotels(itinerary: Itinerary | undefined): Record<string, ItineraryHotelBooking[]> {
	return itinerary?.hotels_bookings.length
		? groupBy(itinerary!.hotels_bookings, (e) => [e.search_id, e.cancellation_status].map(String).join(';'))
		: {}
}

export function getGroupedCars(itinerary: Itinerary | undefined): Record<string, ItineraryCarBooking[]> {
	return itinerary?.cars_bookings.length // TODO fix key
		? groupBy<ItineraryCarBooking>(itinerary!.cars_bookings, (e) =>
				[e.search_id, (e as any).cancellation_status].map(String).join(';'),
			)
		: {}
}

export function getSortedFlights(itinerary: Itinerary) {
	const flightsBySearchId = getGroupedFlights(itinerary)
	const sortedFlightKeys = Object.keys(flightsBySearchId).sort(
		(a, b) =>
			moment.parseZone(flightsBySearchId[a][0]!.flights[0].segments[0].departure_time).valueOf() -
			moment.parseZone(flightsBySearchId[b][0]!.flights[0].segments[0].departure_time).valueOf(),
	)

	return sortedFlightKeys.map((k) => flightsBySearchId[k])
}

export function getSortedRails(itinerary: Itinerary) {
	const railsBySearchId = getGroupedRails(itinerary)
	const sortedRailsKeys = Object.keys(railsBySearchId).sort(
		(a, b) =>
			moment.parseZone(railsBySearchId[a][0]?.rails[0].segments[0].departure.datetime).valueOf() -
			moment.parseZone(railsBySearchId[b][0]?.rails[0].segments[0].departure.datetime).valueOf(),
	)

	return sortedRailsKeys.map((k) => railsBySearchId[k])
}

export function getSortedHotels(itinerary: Itinerary) {
	const hotelsBySearchId = getGroupedHotels(itinerary)

	const sortedHotelKeys = Object.keys(hotelsBySearchId).sort(
		(a, b) =>
			moment.parseZone(hotelsBySearchId[a][0]!.check_in).valueOf() -
			moment.parseZone(hotelsBySearchId[b][0]!.check_in).valueOf(),
	)

	return sortedHotelKeys.map((k) => hotelsBySearchId[k])
}

export function getSortedCars(itinerary: Itinerary) {
	const carsBySearchId = getGroupedCars(itinerary)

	const sortedCarKeys = Object.keys(carsBySearchId).sort(
		(a, b) =>
			moment.parseZone(carsBySearchId[a][0]!.car?.start_time).valueOf() -
			moment.parseZone(carsBySearchId[b][0]!.car?.start_time).valueOf(),
	)

	return sortedCarKeys.map((k) => carsBySearchId[k])
}

export function isTripEditable(trip?: Trip): boolean {
	if (!trip || trip.status === TripStatus.NotApproved) {
		return false
	}
	return [TripStatus.Reverted, TripStatus.Draft, TripStatus.DraftBySupport].includes(trip.status)
}

export function getTripDatesString(trip: Trip): string {
	if (trip.products.length < 1) {
		return ''
	}

	const dates = trip.products.reduce((acc, p) => [...acc, p.start_time, p.end_time], [])

	return minMaxItem(dates, (date) => moment.parseZone(date).toDate().valueOf())
		.map((d) => moment.parseZone(d).format(MultiLocaleTimeFormats.FullReadableDateShortMonth))
		.join(' - ')
}

function seatSelectionKeyExtractor(flightBookings: ItineraryFlightBooking[]): string[] {
	return flightBookings.flatMap((flightBooking) =>
		flightBooking.flights.flatMap((flight) =>
			flight.segments.flatMap((segment) => segment.seat_selection.flatMap((selection) => selection.seat_key || '')),
		),
	)
}

export function getSelectedSeats(itinerary: Itinerary, noSelectedSeats: boolean) {
	if (noSelectedSeats) {
		return { seat_keys: [] }
	}

	return { seat_keys: seatSelectionKeyExtractor(itinerary.flights_bookings) }
}

export function getFilteredSelectedSeats({
	itinerary,
	noSelectedSeats,
	selectedFlightId,
}: getFilteredSelectedSeatsParams) {
	if (noSelectedSeats) {
		return { seat_keys: [] }
	}
	return {
		seat_keys: seatSelectionKeyExtractor(
			itinerary.flights_bookings.filter((flightBooking) => Number(flightBooking.id) === Number(selectedFlightId)),
		),
	}
}

function isBookingCvvRequirementMissing(booking: WithCvvRequirement & WithBookingType) {
	return typeof booking.cvv_required !== 'boolean' && booking.booking_type !== 'external_booking'
}

export function areCvvRequirementsMissing(itinerary: Itinerary) {
	return [itinerary.flights_bookings, itinerary.hotels_bookings].flat().some(isBookingCvvRequirementMissing)
}

function isCvvRequiredForBooking({ cvv_required }: WithCvvRequirement) {
	return !!cvv_required
}

export function isSomeServiceRequiresCvv(itinerary: WithItineraryBookingsManagedByUser) {
	const { flights_bookings, hotels_bookings, cars_bookings } = itinerary
	return [...flights_bookings, ...hotels_bookings, ...cars_bookings].some(isCvvRequiredForBooking)
}

const POSSIBLE_CARD_TYPES: CreditCardType[] = [
	CreditCardType.Visa,
	CreditCardType.MasterCard,
	CreditCardType.AmericanExpress,
	CreditCardType.AmexBTA,
	CreditCardType.AmexBTAGermany,
	CreditCardType.Diners,
	CreditCardType.AirPlus,
]

export function getUnsupportedCardTypes(itinerary: WithItineraryBookingsManagedByUser): CreditCardType[] {
	if (!itinerary.hotels_bookings.length) {
		return []
	}

	const unsupportedCardTypes = new Set<CreditCardType>(POSSIBLE_CARD_TYPES)

	itinerary.hotels_bookings.forEach((booking: HotelBooking) =>
		booking.payment_cards_accepted.forEach((cardType) => unsupportedCardTypes.delete(cardType)),
	)

	return Array.from(unsupportedCardTypes)
}

export function orderTripPurposeOptions({
	tripPurposeOptions,
	tripPurposes,
}: {
	tripPurposeOptions: TripPurposeOption[]
	tripPurposes: TripPurpose[]
}) {
	const selectedTripPurposeOptions = tripPurposeOptions.filter((p) => tripPurposes.includes(p.value))
	return [...selectedTripPurposeOptions.filter((p) => p.value !== TripPurpose.Other)]
}

export function getBookingInfoFromServiceItinerary<T>(
	itineraryService: { summary: { booking_info: T[] }[] } | undefined,
) {
	return itineraryService?.summary.flatMap((bookingSummary) => bookingSummary.booking_info) ?? []
}

export function getHotelBookingInfoFromItineraryById(itinerary?: Itinerary, withHotelId: { hotel_id?: string } = {}) {
	const hotelId = withHotelId.hotel_id
	if (!itinerary || !hotelId) {
		return undefined
	}

	return getBookingInfoFromServiceItinerary(itinerary.hotel_itinerary).find(({ hotel }) => hotel.id === hotelId)
}

export function getConfirmationDetailsFromSegments<
	T extends { travelers_ids: number[]; booking_segment_id: number },
	Y extends { confirmationNumber?: string | null; airlineConfirmationNumbers?: AirlineConfirmationInfo[] },
>(
	{ id, travelers }: { id: number | string; travelers: User[] },
	{ summary }: { summary: T[] },
	getConfirmationDetailsForTraveler: (summary: T, travelerId: number) => Y,
) {
	return summary
		.filter((details) => details.booking_segment_id === Number(id))
		.flatMap((details) =>
			details.travelers_ids.map((travelerId) => {
				const traveler = travelers.find((traveler) => traveler.id === travelerId)
				if (!traveler) {
					return null
				}

				const confirmationDetails = getConfirmationDetailsForTraveler(details, travelerId)

				return {
					id: travelerId,
					fullName: fullName(traveler),
					...confirmationDetails,
				}
			}),
		)
		.filter(
			(confirmationDetails): confirmationDetails is Y & { id: number; fullName: string; confirmationNumber: string } =>
				!!confirmationDetails,
		)
}

export function isSuccessBookingStatus({
	reservation_status,
}: {
	reservation_status?: ReservationStatus | null
}): boolean {
	return !!reservation_status && [ReservationStatus.Booked, ReservationStatus.PreBooked].includes(reservation_status)
}
