import AndorraSvgUrl from 'src/assets/country-flags/flags/ad.svg'
import UnitedArabEmiratesSvgUrl from 'src/assets/country-flags/flags/ae.svg'
import AfghanistanSvgUrl from 'src/assets/country-flags/flags/af.svg'
import AntiguaBarbudaSvgUrl from 'src/assets/country-flags/flags/ag.svg'
import AnguillaSvgUrl from 'src/assets/country-flags/flags/ai.svg'
import AlbaniaSvgUrl from 'src/assets/country-flags/flags/al.svg'
import ArmeniaSvgUrl from 'src/assets/country-flags/flags/am.svg'
import AngolaSvgUrl from 'src/assets/country-flags/flags/ao.svg'
import AntarcticaSvgUrl from 'src/assets/country-flags/flags/aq.svg'
import ArgentinaSvgUrl from 'src/assets/country-flags/flags/ar.svg'
import AmericanSamoaSvgUrl from 'src/assets/country-flags/flags/as.svg'
import AustriaSvgUrl from 'src/assets/country-flags/flags/at.svg'
import AustraliaSvgUrl from 'src/assets/country-flags/flags/au.svg'
import ArubaSvgUrl from 'src/assets/country-flags/flags/aw.svg'
import AlandIslandsSvgUrl from 'src/assets/country-flags/flags/ax.svg'
import AzerbaijanSvgUrl from 'src/assets/country-flags/flags/az.svg'
import BosniaHerzegovinaSvgUrl from 'src/assets/country-flags/flags/ba.svg'
import BarbadosSvgUrl from 'src/assets/country-flags/flags/bb.svg'
import BangladeshSvgUrl from 'src/assets/country-flags/flags/bd.svg'
import BelgiumSvgUrl from 'src/assets/country-flags/flags/be.svg'
import BurkinaFasoSvgUrl from 'src/assets/country-flags/flags/bf.svg'
import BulgariaSvgUrl from 'src/assets/country-flags/flags/bg.svg'
import BahrainSvgUrl from 'src/assets/country-flags/flags/bh.svg'
import BurundiSvgUrl from 'src/assets/country-flags/flags/bi.svg'
import BeninSvgUrl from 'src/assets/country-flags/flags/bj.svg'
import StBarthelemySvgUrl from 'src/assets/country-flags/flags/bl.svg'
import BermudaSvgUrl from 'src/assets/country-flags/flags/bm.svg'
import BruneiSvgUrl from 'src/assets/country-flags/flags/bn.svg'
import BoliviaSvgUrl from 'src/assets/country-flags/flags/bo.svg'
import CaribbeanNetherlandsSvgUrl from 'src/assets/country-flags/flags/bq.svg'
import BrazilSvgUrl from 'src/assets/country-flags/flags/br.svg'
import BahamasSvgUrl from 'src/assets/country-flags/flags/bs.svg'
import BhutanSvgUrl from 'src/assets/country-flags/flags/bt.svg'
import BouvetIslandSvgUrl from 'src/assets/country-flags/flags/bv.svg'
import BotswanaSvgUrl from 'src/assets/country-flags/flags/bw.svg'
import BelarusSvgUrl from 'src/assets/country-flags/flags/by.svg'
import BelizeSvgUrl from 'src/assets/country-flags/flags/bz.svg'
import CanadaSvgUrl from 'src/assets/country-flags/flags/ca.svg'
import CocosKeelingIslandsSvgUrl from 'src/assets/country-flags/flags/cc.svg'
import CongoKinshasaSvgUrl from 'src/assets/country-flags/flags/cd.svg'
import CentralAfricanRepublicSvgUrl from 'src/assets/country-flags/flags/cf.svg'
import CongoBrazzavilleSvgUrl from 'src/assets/country-flags/flags/cg.svg'
import SwitzerlandSvgUrl from 'src/assets/country-flags/flags/ch.svg'
import CotedIvoireSvgUrl from 'src/assets/country-flags/flags/ci.svg'
import CookIslandsSvgUrl from 'src/assets/country-flags/flags/ck.svg'
import ChileSvgUrl from 'src/assets/country-flags/flags/cl.svg'
import CameroonSvgUrl from 'src/assets/country-flags/flags/cm.svg'
import ChinaSvgUrl from 'src/assets/country-flags/flags/cn.svg'
import ColombiaSvgUrl from 'src/assets/country-flags/flags/co.svg'
import CostaRicaSvgUrl from 'src/assets/country-flags/flags/cr.svg'
import CubaSvgUrl from 'src/assets/country-flags/flags/cu.svg'
import CapeVerdeSvgUrl from 'src/assets/country-flags/flags/cv.svg'
import CuracaoSvgUrl from 'src/assets/country-flags/flags/cw.svg'
import ChristmasIslandSvgUrl from 'src/assets/country-flags/flags/cx.svg'
import CyprusSvgUrl from 'src/assets/country-flags/flags/cy.svg'
import CzechiaSvgUrl from 'src/assets/country-flags/flags/cz.svg'
import GermanySvgUrl from 'src/assets/country-flags/flags/de.svg'
import DjiboutiSvgUrl from 'src/assets/country-flags/flags/dj.svg'
import DenmarkSvgUrl from 'src/assets/country-flags/flags/dk.svg'
import DominicaSvgUrl from 'src/assets/country-flags/flags/dm.svg'
import DominicanRepublicSvgUrl from 'src/assets/country-flags/flags/do.svg'
import AlgeriaSvgUrl from 'src/assets/country-flags/flags/dz.svg'
import EcuadorSvgUrl from 'src/assets/country-flags/flags/ec.svg'
import EstoniaSvgUrl from 'src/assets/country-flags/flags/ee.svg'
import EgyptSvgUrl from 'src/assets/country-flags/flags/eg.svg'
import WesternSaharaSvgUrl from 'src/assets/country-flags/flags/eh.svg'
import EritreaSvgUrl from 'src/assets/country-flags/flags/er.svg'
import SpainSvgUrl from 'src/assets/country-flags/flags/es.svg'
import EthiopiaSvgUrl from 'src/assets/country-flags/flags/et.svg'
import FinlandSvgUrl from 'src/assets/country-flags/flags/fi.svg'
import FijiSvgUrl from 'src/assets/country-flags/flags/fj.svg'
import FalklandIslandsSvgUrl from 'src/assets/country-flags/flags/fk.svg'
import MicronesiaSvgUrl from 'src/assets/country-flags/flags/fm.svg'
import FaroeIslandsSvgUrl from 'src/assets/country-flags/flags/fo.svg'
import FranceSvgUrl from 'src/assets/country-flags/flags/fr.svg'
import GabonSvgUrl from 'src/assets/country-flags/flags/ga.svg'
import UnitedKingdomSvgUrl from 'src/assets/country-flags/flags/gb.svg'
import GrenadaSvgUrl from 'src/assets/country-flags/flags/gd.svg'
import GeorgiaSvgUrl from 'src/assets/country-flags/flags/ge.svg'
import FrenchGuianaSvgUrl from 'src/assets/country-flags/flags/gf.svg'
import GuernseySvgUrl from 'src/assets/country-flags/flags/gg.svg'
import GhanaSvgUrl from 'src/assets/country-flags/flags/gh.svg'
import GibraltarSvgUrl from 'src/assets/country-flags/flags/gi.svg'
import GreenlandSvgUrl from 'src/assets/country-flags/flags/gl.svg'
import GambiaSvgUrl from 'src/assets/country-flags/flags/gm.svg'
import GuineaSvgUrl from 'src/assets/country-flags/flags/gn.svg'
import GuadeloupeSvgUrl from 'src/assets/country-flags/flags/gp.svg'
import EquatorialGuineaSvgUrl from 'src/assets/country-flags/flags/gq.svg'
import GreeceSvgUrl from 'src/assets/country-flags/flags/gr.svg'
import SouthGeorgiaSouthSandwichIslandsSvgUrl from 'src/assets/country-flags/flags/gs.svg'
import GuatemalaSvgUrl from 'src/assets/country-flags/flags/gt.svg'
import GuamSvgUrl from 'src/assets/country-flags/flags/gu.svg'
import GuineaBissauSvgUrl from 'src/assets/country-flags/flags/gw.svg'
import GuyanaSvgUrl from 'src/assets/country-flags/flags/gy.svg'
import HongKongSARChinaSvgUrl from 'src/assets/country-flags/flags/hk.svg'
import HeardMcDonaldIslandsSvgUrl from 'src/assets/country-flags/flags/hm.svg'
import HondurasSvgUrl from 'src/assets/country-flags/flags/hn.svg'
import CroatiaSvgUrl from 'src/assets/country-flags/flags/hr.svg'
import HaitiSvgUrl from 'src/assets/country-flags/flags/ht.svg'
import HungarySvgUrl from 'src/assets/country-flags/flags/hu.svg'
import IndonesiaSvgUrl from 'src/assets/country-flags/flags/id.svg'
import IrelandSvgUrl from 'src/assets/country-flags/flags/ie.svg'
import IsraelSvgUrl from 'src/assets/country-flags/flags/il.svg'
import IsleofManSvgUrl from 'src/assets/country-flags/flags/im.svg'
import IndiaSvgUrl from 'src/assets/country-flags/flags/in.svg'
import BritishIndianOceanTerritorySvgUrl from 'src/assets/country-flags/flags/io.svg'
import IraqSvgUrl from 'src/assets/country-flags/flags/iq.svg'
import IranSvgUrl from 'src/assets/country-flags/flags/ir.svg'
import IcelandSvgUrl from 'src/assets/country-flags/flags/is.svg'
import ItalySvgUrl from 'src/assets/country-flags/flags/it.svg'
import JerseySvgUrl from 'src/assets/country-flags/flags/je.svg'
import JamaicaSvgUrl from 'src/assets/country-flags/flags/jm.svg'
import JordanSvgUrl from 'src/assets/country-flags/flags/jo.svg'
import JapanSvgUrl from 'src/assets/country-flags/flags/jp.svg'
import KenyaSvgUrl from 'src/assets/country-flags/flags/ke.svg'
import KyrgyzstanSvgUrl from 'src/assets/country-flags/flags/kg.svg'
import CambodiaSvgUrl from 'src/assets/country-flags/flags/kh.svg'
import KiribatiSvgUrl from 'src/assets/country-flags/flags/ki.svg'
import ComorosSvgUrl from 'src/assets/country-flags/flags/km.svg'
import StKittsNevisSvgUrl from 'src/assets/country-flags/flags/kn.svg'
import NorthKoreaSvgUrl from 'src/assets/country-flags/flags/kp.svg'
import SouthKoreaSvgUrl from 'src/assets/country-flags/flags/kr.svg'
import KuwaitSvgUrl from 'src/assets/country-flags/flags/kw.svg'
import CaymanIslandsSvgUrl from 'src/assets/country-flags/flags/ky.svg'
import KazakhstanSvgUrl from 'src/assets/country-flags/flags/kz.svg'
import LaosSvgUrl from 'src/assets/country-flags/flags/la.svg'
import LebanonSvgUrl from 'src/assets/country-flags/flags/lb.svg'
import StLuciaSvgUrl from 'src/assets/country-flags/flags/lc.svg'
import LiechtensteinSvgUrl from 'src/assets/country-flags/flags/li.svg'
import SriLankaSvgUrl from 'src/assets/country-flags/flags/lk.svg'
import LiberiaSvgUrl from 'src/assets/country-flags/flags/lr.svg'
import LesothoSvgUrl from 'src/assets/country-flags/flags/ls.svg'
import LithuaniaSvgUrl from 'src/assets/country-flags/flags/lt.svg'
import LuxembourgSvgUrl from 'src/assets/country-flags/flags/lu.svg'
import LatviaSvgUrl from 'src/assets/country-flags/flags/lv.svg'
import LibyaSvgUrl from 'src/assets/country-flags/flags/ly.svg'
import MoroccoSvgUrl from 'src/assets/country-flags/flags/ma.svg'
import MonacoSvgUrl from 'src/assets/country-flags/flags/mc.svg'
import MoldovaSvgUrl from 'src/assets/country-flags/flags/md.svg'
import MontenegroSvgUrl from 'src/assets/country-flags/flags/me.svg'
import StMartinSvgUrl from 'src/assets/country-flags/flags/mf.svg'
import MadagascarSvgUrl from 'src/assets/country-flags/flags/mg.svg'
import MarshallIslandsSvgUrl from 'src/assets/country-flags/flags/mh.svg'
import NorthMacedoniaSvgUrl from 'src/assets/country-flags/flags/mk.svg'
import MaliSvgUrl from 'src/assets/country-flags/flags/ml.svg'
import MyanmarBurmaSvgUrl from 'src/assets/country-flags/flags/mm.svg'
import MongoliaSvgUrl from 'src/assets/country-flags/flags/mn.svg'
import MacaoSARChinaSvgUrl from 'src/assets/country-flags/flags/mo.svg'
import NorthernMarianaIslandsSvgUrl from 'src/assets/country-flags/flags/mp.svg'
import MartiniqueSvgUrl from 'src/assets/country-flags/flags/mq.svg'
import MauritaniaSvgUrl from 'src/assets/country-flags/flags/mr.svg'
import MontserratSvgUrl from 'src/assets/country-flags/flags/ms.svg'
import MaltaSvgUrl from 'src/assets/country-flags/flags/mt.svg'
import MauritiusSvgUrl from 'src/assets/country-flags/flags/mu.svg'
import MaldivesSvgUrl from 'src/assets/country-flags/flags/mv.svg'
import MalawiSvgUrl from 'src/assets/country-flags/flags/mw.svg'
import MexicoSvgUrl from 'src/assets/country-flags/flags/mx.svg'
import MalaysiaSvgUrl from 'src/assets/country-flags/flags/my.svg'
import MozambiqueSvgUrl from 'src/assets/country-flags/flags/mz.svg'
import NamibiaSvgUrl from 'src/assets/country-flags/flags/na.svg'
import NewCaledoniaSvgUrl from 'src/assets/country-flags/flags/nc.svg'
import NigerSvgUrl from 'src/assets/country-flags/flags/ne.svg'
import NorfolkIslandSvgUrl from 'src/assets/country-flags/flags/nf.svg'
import NigeriaSvgUrl from 'src/assets/country-flags/flags/ng.svg'
import NicaraguaSvgUrl from 'src/assets/country-flags/flags/ni.svg'
import NetherlandsSvgUrl from 'src/assets/country-flags/flags/nl.svg'
import NorwaySvgUrl from 'src/assets/country-flags/flags/no.svg'
import NepalSvgUrl from 'src/assets/country-flags/flags/np.svg'
import NauruSvgUrl from 'src/assets/country-flags/flags/nr.svg'
import NiueSvgUrl from 'src/assets/country-flags/flags/nu.svg'
import NewZealandSvgUrl from 'src/assets/country-flags/flags/nz.svg'
import OmanSvgUrl from 'src/assets/country-flags/flags/om.svg'
import PanamaSvgUrl from 'src/assets/country-flags/flags/pa.svg'
import PeruSvgUrl from 'src/assets/country-flags/flags/pe.svg'
import FrenchPolynesiaSvgUrl from 'src/assets/country-flags/flags/pf.svg'
import PapuaNewGuineaSvgUrl from 'src/assets/country-flags/flags/pg.svg'
import PhilippinesSvgUrl from 'src/assets/country-flags/flags/ph.svg'
import PakistanSvgUrl from 'src/assets/country-flags/flags/pk.svg'
import PolandSvgUrl from 'src/assets/country-flags/flags/pl.svg'
import StPierreMiquelonSvgUrl from 'src/assets/country-flags/flags/pm.svg'
import PitcairnIslandsSvgUrl from 'src/assets/country-flags/flags/pn.svg'
import PuertoRicoSvgUrl from 'src/assets/country-flags/flags/pr.svg'
import PalestinianTerritoriesSvgUrl from 'src/assets/country-flags/flags/ps.svg'
import PortugalSvgUrl from 'src/assets/country-flags/flags/pt.svg'
import PalauSvgUrl from 'src/assets/country-flags/flags/pw.svg'
import ParaguaySvgUrl from 'src/assets/country-flags/flags/py.svg'
import QatarSvgUrl from 'src/assets/country-flags/flags/qa.svg'
import ReunionSvgUrl from 'src/assets/country-flags/flags/re.svg'
import RomaniaSvgUrl from 'src/assets/country-flags/flags/ro.svg'
import SerbiaSvgUrl from 'src/assets/country-flags/flags/rs.svg'
import RussiaSvgUrl from 'src/assets/country-flags/flags/ru.svg'
import RwandaSvgUrl from 'src/assets/country-flags/flags/rw.svg'
import SaudiArabiaSvgUrl from 'src/assets/country-flags/flags/sa.svg'
import SolomonIslandsSvgUrl from 'src/assets/country-flags/flags/sb.svg'
import SeychellesSvgUrl from 'src/assets/country-flags/flags/sc.svg'
import SudanSvgUrl from 'src/assets/country-flags/flags/sd.svg'
import SwedenSvgUrl from 'src/assets/country-flags/flags/se.svg'
import SingaporeSvgUrl from 'src/assets/country-flags/flags/sg.svg'
import StHelenaSvgUrl from 'src/assets/country-flags/flags/sh.svg'
import SloveniaSvgUrl from 'src/assets/country-flags/flags/si.svg'
import SvalbardJanMayenSvgUrl from 'src/assets/country-flags/flags/sj.svg'
import SlovakiaSvgUrl from 'src/assets/country-flags/flags/sk.svg'
import SierraLeoneSvgUrl from 'src/assets/country-flags/flags/sl.svg'
import SanMarinoSvgUrl from 'src/assets/country-flags/flags/sm.svg'
import SenegalSvgUrl from 'src/assets/country-flags/flags/sn.svg'
import SomaliaSvgUrl from 'src/assets/country-flags/flags/so.svg'
import SurinameSvgUrl from 'src/assets/country-flags/flags/sr.svg'
import SouthSudanSvgUrl from 'src/assets/country-flags/flags/ss.svg'
import SaoTomePrincipeSvgUrl from 'src/assets/country-flags/flags/st.svg'
import ElSalvadorSvgUrl from 'src/assets/country-flags/flags/sv.svg'
import SintMaartenSvgUrl from 'src/assets/country-flags/flags/sx.svg'
import SyriaSvgUrl from 'src/assets/country-flags/flags/sy.svg'
import EswatiniSvgUrl from 'src/assets/country-flags/flags/sz.svg'
import TurksCaicosIslandsSvgUrl from 'src/assets/country-flags/flags/tc.svg'
import ChadSvgUrl from 'src/assets/country-flags/flags/td.svg'
import FrenchSouthernTerritoriesSvgUrl from 'src/assets/country-flags/flags/tf.svg'
import TogoSvgUrl from 'src/assets/country-flags/flags/tg.svg'
import ThailandSvgUrl from 'src/assets/country-flags/flags/th.svg'
import TajikistanSvgUrl from 'src/assets/country-flags/flags/tj.svg'
import TokelauSvgUrl from 'src/assets/country-flags/flags/tk.svg'
import TimorLesteSvgUrl from 'src/assets/country-flags/flags/tl.svg'
import TurkmenistanSvgUrl from 'src/assets/country-flags/flags/tm.svg'
import TunisiaSvgUrl from 'src/assets/country-flags/flags/tn.svg'
import TongaSvgUrl from 'src/assets/country-flags/flags/to.svg'
import TurkeySvgUrl from 'src/assets/country-flags/flags/tr.svg'
import TrinidadTobagoSvgUrl from 'src/assets/country-flags/flags/tt.svg'
import TuvaluSvgUrl from 'src/assets/country-flags/flags/tv.svg'
import TaiwanSvgUrl from 'src/assets/country-flags/flags/tw.svg'
import TanzaniaSvgUrl from 'src/assets/country-flags/flags/tz.svg'
import UkraineSvgUrl from 'src/assets/country-flags/flags/ua.svg'
import UgandaSvgUrl from 'src/assets/country-flags/flags/ug.svg'
import USOutlyingIslandsSvgUrl from 'src/assets/country-flags/flags/um.svg'
import UnitedStatesSvgUrl from 'src/assets/country-flags/flags/us.svg'
import UruguaySvgUrl from 'src/assets/country-flags/flags/uy.svg'
import UzbekistanSvgUrl from 'src/assets/country-flags/flags/uz.svg'
import VaticanCitySvgUrl from 'src/assets/country-flags/flags/va.svg'
import StVincentGrenadinesSvgUrl from 'src/assets/country-flags/flags/vc.svg'
import VenezuelaSvgUrl from 'src/assets/country-flags/flags/ve.svg'
import BritishVirginIslandsSvgUrl from 'src/assets/country-flags/flags/vg.svg'
import USVirginIslandsSvgUrl from 'src/assets/country-flags/flags/vi.svg'
import VietnamSvgUrl from 'src/assets/country-flags/flags/vn.svg'
import VanuatuSvgUrl from 'src/assets/country-flags/flags/vu.svg'
import WallisFutunaSvgUrl from 'src/assets/country-flags/flags/wf.svg'
import SamoaSvgUrl from 'src/assets/country-flags/flags/ws.svg'
import YemenSvgUrl from 'src/assets/country-flags/flags/ye.svg'
import MayotteSvgUrl from 'src/assets/country-flags/flags/yt.svg'
import SouthAfricaSvgUrl from 'src/assets/country-flags/flags/za.svg'
import ZambiaSvgUrl from 'src/assets/country-flags/flags/zm.svg'
import ZimbabweSvgUrl from 'src/assets/country-flags/flags/zw.svg'
import { CountriesCode } from 'src/i18nConfig'

export const countryFlagsUrl: Record<CountriesCode, string> = {
	AF: AfghanistanSvgUrl,
	AX: AlandIslandsSvgUrl,
	AL: AlbaniaSvgUrl,
	DZ: AlgeriaSvgUrl,
	AS: AmericanSamoaSvgUrl,
	AD: AndorraSvgUrl,
	AO: AngolaSvgUrl,
	AI: AnguillaSvgUrl,
	AQ: AntarcticaSvgUrl,
	AG: AntiguaBarbudaSvgUrl,
	AR: ArgentinaSvgUrl,
	AM: ArmeniaSvgUrl,
	AW: ArubaSvgUrl,
	AU: AustraliaSvgUrl,
	AT: AustriaSvgUrl,
	AZ: AzerbaijanSvgUrl,
	BS: BahamasSvgUrl,
	BH: BahrainSvgUrl,
	BD: BangladeshSvgUrl,
	BB: BarbadosSvgUrl,
	BY: BelarusSvgUrl,
	BE: BelgiumSvgUrl,
	BZ: BelizeSvgUrl,
	BJ: BeninSvgUrl,
	BM: BermudaSvgUrl,
	BT: BhutanSvgUrl,
	BO: BoliviaSvgUrl,
	BQ: CaribbeanNetherlandsSvgUrl,
	BA: BosniaHerzegovinaSvgUrl,
	BW: BotswanaSvgUrl,
	BV: BouvetIslandSvgUrl,
	BR: BrazilSvgUrl,
	IO: BritishIndianOceanTerritorySvgUrl,
	BN: BruneiSvgUrl,
	BG: BulgariaSvgUrl,
	BF: BurkinaFasoSvgUrl,
	BI: BurundiSvgUrl,
	CV: CapeVerdeSvgUrl,
	KH: CambodiaSvgUrl,
	CM: CameroonSvgUrl,
	CA: CanadaSvgUrl,
	KY: CaymanIslandsSvgUrl,
	CF: CentralAfricanRepublicSvgUrl,
	TD: ChadSvgUrl,
	CL: ChileSvgUrl,
	CN: ChinaSvgUrl,
	CX: ChristmasIslandSvgUrl,
	CC: CocosKeelingIslandsSvgUrl,
	CO: ColombiaSvgUrl,
	KM: ComorosSvgUrl,
	CD: CongoKinshasaSvgUrl,
	CG: CongoBrazzavilleSvgUrl,
	CK: CookIslandsSvgUrl,
	CR: CostaRicaSvgUrl,
	CI: CotedIvoireSvgUrl,
	HR: CroatiaSvgUrl,
	CU: CubaSvgUrl,
	CW: CuracaoSvgUrl,
	CY: CyprusSvgUrl,
	CZ: CzechiaSvgUrl,
	DK: DenmarkSvgUrl,
	DJ: DjiboutiSvgUrl,
	DM: DominicaSvgUrl,
	DO: DominicanRepublicSvgUrl,
	EC: EcuadorSvgUrl,
	EG: EgyptSvgUrl,
	SV: ElSalvadorSvgUrl,
	GQ: EquatorialGuineaSvgUrl,
	ER: EritreaSvgUrl,
	EE: EstoniaSvgUrl,
	ET: EthiopiaSvgUrl,
	FK: FalklandIslandsSvgUrl,
	FO: FaroeIslandsSvgUrl,
	FJ: FijiSvgUrl,
	FI: FinlandSvgUrl,
	FR: FranceSvgUrl,
	GF: FrenchGuianaSvgUrl,
	PF: FrenchPolynesiaSvgUrl,
	TF: FrenchSouthernTerritoriesSvgUrl,
	GA: GabonSvgUrl,
	GM: GambiaSvgUrl,
	GE: GeorgiaSvgUrl,
	DE: GermanySvgUrl,
	GH: GhanaSvgUrl,
	GI: GibraltarSvgUrl,
	GR: GreeceSvgUrl,
	GL: GreenlandSvgUrl,
	GD: GrenadaSvgUrl,
	GP: GuadeloupeSvgUrl,
	GU: GuamSvgUrl,
	GT: GuatemalaSvgUrl,
	GG: GuernseySvgUrl,
	GN: GuineaSvgUrl,
	GW: GuineaBissauSvgUrl,
	GY: GuyanaSvgUrl,
	HT: HaitiSvgUrl,
	HM: HeardMcDonaldIslandsSvgUrl,
	VA: VaticanCitySvgUrl,
	HN: HondurasSvgUrl,
	HK: HongKongSARChinaSvgUrl,
	HU: HungarySvgUrl,
	IS: IcelandSvgUrl,
	IN: IndiaSvgUrl,
	ID: IndonesiaSvgUrl,
	IR: IranSvgUrl,
	IQ: IraqSvgUrl,
	IE: IrelandSvgUrl,
	IM: IsleofManSvgUrl,
	IL: IsraelSvgUrl,
	IT: ItalySvgUrl,
	JM: JamaicaSvgUrl,
	JP: JapanSvgUrl,
	JE: JerseySvgUrl,
	JO: JordanSvgUrl,
	KZ: KazakhstanSvgUrl,
	KE: KenyaSvgUrl,
	KI: KiribatiSvgUrl,
	KP: NorthKoreaSvgUrl,
	KR: SouthKoreaSvgUrl,
	KW: KuwaitSvgUrl,
	KG: KyrgyzstanSvgUrl,
	LA: LaosSvgUrl,
	LV: LatviaSvgUrl,
	LB: LebanonSvgUrl,
	LS: LesothoSvgUrl,
	LR: LiberiaSvgUrl,
	LY: LibyaSvgUrl,
	LI: LiechtensteinSvgUrl,
	LT: LithuaniaSvgUrl,
	LU: LuxembourgSvgUrl,
	MO: MacaoSARChinaSvgUrl,
	MK: NorthMacedoniaSvgUrl,
	MG: MadagascarSvgUrl,
	MW: MalawiSvgUrl,
	MY: MalaysiaSvgUrl,
	MV: MaldivesSvgUrl,
	ML: MaliSvgUrl,
	MT: MaltaSvgUrl,
	MH: MarshallIslandsSvgUrl,
	MQ: MartiniqueSvgUrl,
	MR: MauritaniaSvgUrl,
	MU: MauritiusSvgUrl,
	YT: MayotteSvgUrl,
	MX: MexicoSvgUrl,
	FM: MicronesiaSvgUrl,
	MD: MoldovaSvgUrl,
	MC: MonacoSvgUrl,
	MN: MongoliaSvgUrl,
	ME: MontenegroSvgUrl,
	MS: MontserratSvgUrl,
	MA: MoroccoSvgUrl,
	MZ: MozambiqueSvgUrl,
	MM: MyanmarBurmaSvgUrl,
	NA: NamibiaSvgUrl,
	NR: NauruSvgUrl,
	NP: NepalSvgUrl,
	NL: NetherlandsSvgUrl,
	NC: NewCaledoniaSvgUrl,
	NZ: NewZealandSvgUrl,
	NI: NicaraguaSvgUrl,
	NE: NigerSvgUrl,
	NG: NigeriaSvgUrl,
	NU: NiueSvgUrl,
	NF: NorfolkIslandSvgUrl,
	MP: NorthernMarianaIslandsSvgUrl,
	NO: NorwaySvgUrl,
	OM: OmanSvgUrl,
	PK: PakistanSvgUrl,
	PW: PalauSvgUrl,
	PS: PalestinianTerritoriesSvgUrl,
	PA: PanamaSvgUrl,
	PG: PapuaNewGuineaSvgUrl,
	PY: ParaguaySvgUrl,
	PE: PeruSvgUrl,
	PH: PhilippinesSvgUrl,
	PN: PitcairnIslandsSvgUrl,
	PL: PolandSvgUrl,
	PT: PortugalSvgUrl,
	PR: PuertoRicoSvgUrl,
	QA: QatarSvgUrl,
	RE: ReunionSvgUrl,
	RO: RomaniaSvgUrl,
	RU: RussiaSvgUrl,
	RW: RwandaSvgUrl,
	BL: StBarthelemySvgUrl,
	SH: StHelenaSvgUrl,
	KN: StKittsNevisSvgUrl,
	LC: StLuciaSvgUrl,
	MF: StMartinSvgUrl,
	PM: StPierreMiquelonSvgUrl,
	VC: StVincentGrenadinesSvgUrl,
	WS: SamoaSvgUrl,
	SM: SanMarinoSvgUrl,
	ST: SaoTomePrincipeSvgUrl,
	SA: SaudiArabiaSvgUrl,
	SN: SenegalSvgUrl,
	RS: SerbiaSvgUrl,
	SC: SeychellesSvgUrl,
	SL: SierraLeoneSvgUrl,
	SG: SingaporeSvgUrl,
	SX: SintMaartenSvgUrl,
	SK: SlovakiaSvgUrl,
	SI: SloveniaSvgUrl,
	SB: SolomonIslandsSvgUrl,
	SO: SomaliaSvgUrl,
	ZA: SouthAfricaSvgUrl,
	GS: SouthGeorgiaSouthSandwichIslandsSvgUrl,
	SS: SouthSudanSvgUrl,
	ES: SpainSvgUrl,
	LK: SriLankaSvgUrl,
	SD: SudanSvgUrl,
	SR: SurinameSvgUrl,
	SJ: SvalbardJanMayenSvgUrl,
	SZ: EswatiniSvgUrl,
	SE: SwedenSvgUrl,
	CH: SwitzerlandSvgUrl,
	SY: SyriaSvgUrl,
	TW: TaiwanSvgUrl,
	TJ: TajikistanSvgUrl,
	TZ: TanzaniaSvgUrl,
	TH: ThailandSvgUrl,
	TL: TimorLesteSvgUrl,
	TG: TogoSvgUrl,
	TK: TokelauSvgUrl,
	TO: TongaSvgUrl,
	TT: TrinidadTobagoSvgUrl,
	TN: TunisiaSvgUrl,
	TR: TurkeySvgUrl,
	TM: TurkmenistanSvgUrl,
	TC: TurksCaicosIslandsSvgUrl,
	TV: TuvaluSvgUrl,
	UG: UgandaSvgUrl,
	UA: UkraineSvgUrl,
	AE: UnitedArabEmiratesSvgUrl,
	GB: UnitedKingdomSvgUrl,
	UM: USOutlyingIslandsSvgUrl,
	US: UnitedStatesSvgUrl,
	UY: UruguaySvgUrl,
	UZ: UzbekistanSvgUrl,
	VU: VanuatuSvgUrl,
	VE: VenezuelaSvgUrl,
	VN: VietnamSvgUrl,
	VG: BritishVirginIslandsSvgUrl,
	VI: USVirginIslandsSvgUrl,
	WF: WallisFutunaSvgUrl,
	EH: WesternSaharaSvgUrl,
	YE: YemenSvgUrl,
	ZM: ZambiaSvgUrl,
	ZW: ZimbabweSvgUrl,
}

// TODO: change value after importing default flag url
export const DEFAULT_COUNTRY_FLAG = AfghanistanSvgUrl
