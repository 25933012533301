import { createContext, useContext } from 'react'

export const ZIndexContext = createContext<number | undefined>(undefined)

export function useZIndexContext({
	overrideValue,
	defaultValue,
}: Partial<{ overrideValue: number; defaultValue: number }> = {}) {
	const zIndexFromContext = useContext(ZIndexContext)

	return overrideValue ?? (zIndexFromContext ? zIndexFromContext + 1 : zIndexFromContext) ?? defaultValue
}
