import { makeStyles } from '@material-ui/core'
import React from 'react'

import { boxShadowFloat } from 'src/_vars'

import GenericClickOutside from '../GenericClickOutside/GenericClickOutside'
import { SupportSummaryContent } from './SupportSummaryContent'

interface SupportSummaryProps {
	onClickOutside?(e: React.MouseEvent<any>): void
	onClickItem?(e: React.MouseEvent<any>): void
}

const useSummaryStyles = makeStyles(() => ({
	root: {
		overflowY: 'auto',
		height: 'auto',
		maxHeight: '500px',
		width: '280px',
		position: 'absolute',
		top: 'calc(100% + 5px)',
		right: '215px',
		transformOrigin: 'top right',
		boxShadow: boxShadowFloat,
	},
}))

export const SupportSummary: React.FunctionComponent<SupportSummaryProps> = (props) => {
	const { onClickOutside, onClickItem } = props

	function handleClickOutside(e: React.MouseEvent<any>) {
		if (onClickOutside) {
			onClickOutside(e)
		}
	}

	const styles = useSummaryStyles()

	return (
		<GenericClickOutside handleClickOutside={handleClickOutside}>
			<SupportSummaryContent className={styles.root} onClickOutside={onClickOutside} onClickItem={onClickItem} />
		</GenericClickOutside>
	)
}
