import React from 'react'

import useTranslation from 'src/lib/i18n/UseTranslation'
import Page from 'src/pages/Page/Page'
import FeesList from 'src/pages/UsageDetails/FeesList/FeesList'
import { IProps } from 'src/pages/UsageDetails/FeesList/FeesList'
import EmptyState from 'src/refactor/molecules/EmptyState/EmptyState'

const TravelerProfileUnpaidFee: React.FunctionComponent<IProps> = ({ fees, isLoading, onFeesReload }) => {
	const { t } = useTranslation()
	const hasNoUnpaidFees = !isLoading && (!fees || fees.length === 0)

	return (
		<Page>
			{hasNoUnpaidFees ? (
				<EmptyState
					variant="paperplane"
					title={t('app-router.travel-profile.no-unpaid-fee', 'There are no unpaid fee')}
				/>
			) : (
				<FeesList fees={fees} isLoading={isLoading} onFeesReload={onFeesReload} />
			)}
		</Page>
	)
}

export default TravelerProfileUnpaidFee
