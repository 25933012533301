import { AxiosError } from 'axios'

import { SearchData, VoidCallback } from 'src/travelsuit'

import { IAction } from './action-helpers'
import { RecentSearchesTypes as Types } from './actions.types'

export const Actions = {
	getRecentSearches(
		numOfRecentSearches = 5,
		callback?: VoidCallback<SearchData[]>,
		errCallback?: VoidCallback<AxiosError>,
	): IAction {
		return {
			type: Types.GetRecentSearches.REQUEST,
			params: { num_of_searches: numOfRecentSearches, timezone_offset: new Date().getTimezoneOffset() },
			callback,
			errCallback,
		}
	},
}
