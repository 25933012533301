import { EssentialTravelInformation } from 'src/travelsuit'

import { IAction } from '../actions/action-helpers'
import { EssentialTravelInformationTypes } from '../actions/actions.types'

export type EssentialTravelInformationState = EssentialTravelInformation[]

export function essentialTravelInformationReducer(
	state: EssentialTravelInformationState = [],
	action: IAction,
): EssentialTravelInformationState {
	switch (action.type) {
		case EssentialTravelInformationTypes.GetEssentialTravelInformation.SUCCESS:
			return action.payload
	}
	return state
}
