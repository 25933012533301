import { Callback } from 'src/travelsuit'

import { IAction } from './action-helpers'
import { CovidInfoTypes } from './actions.types'

export const Actions = {
	getCovidInfo(callback?: Callback, errCallback?: Callback): IAction {
		return {
			type: CovidInfoTypes.GetCovidInfo.REQUEST,
			callback,
			errCallback,
		}
	},
}
