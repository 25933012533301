import { ApplicationState } from '../stores'
import { IAction } from './action-helpers'
import { RootTypes as Types } from './actions.types'

export const Actions = {
	clearStore(initialState: Partial<ApplicationState>): IAction {
		return {
			type: Types.ClearStore,
			payload: initialState,
		}
	},
}
