import { upsertInto } from 'src/lib/array-utils'
import { PreTripRequest } from 'src/travelsuit'

import { IAction } from '../actions/action-helpers'
import { TripRequestsTypes } from '../actions/actions.types'

export type TripRequestsState = PreTripRequest[]

const emptyTripRequestsState: TripRequestsState = []

function tripRequestsReducer(state: TripRequestsState = emptyTripRequestsState, action: IAction): TripRequestsState {
	switch (action.type) {
		case TripRequestsTypes.GetTripRequests.SUCCESS:
			return action.payload ?? []
		case TripRequestsTypes.SubmitTripRequest.SUCCESS:
			return upsertInto(state, action.payload as PreTripRequest, (r) => r.id)
		case TripRequestsTypes.DeleteTripRequest.SUCCESS:
			return state.filter((tripRequest) => tripRequest.id !== action.requestPayload?.id)
	}
	return state
}

export default tripRequestsReducer
