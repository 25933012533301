import { IAction } from 'src/redux/actions/action-helpers'
import { CovidInfoTypes } from 'src/redux/actions/actions.types'

export interface ICovidInfoState {
	covid_information_hub_link: string
}

const emptyCovidInfoState: ICovidInfoState = { covid_information_hub_link: '' }

function covidInfoReducer(state: ICovidInfoState = emptyCovidInfoState, action: IAction): ICovidInfoState {
	switch (action.type) {
		case CovidInfoTypes.GetCovidInfo.SUCCESS:
			return action.payload
	}
	return state
}

export default covidInfoReducer
