import { IAction } from '../actions/action-helpers'

export type LoadingState = object

function loadingReducer(state: LoadingState = {}, action: IAction): LoadingState {
	const { type } = action
	const matches = /(.*)_(REQUEST|SUCCESS|FAILED)/.exec(type)

	if (!matches) {
		return state
	}

	const [, requestName, requestState] = matches
	return {
		...state,
		[requestName]: requestState === 'REQUEST',
	}
}

export const createLoadingSelector = (actions: string[]) => (state: LoadingState) => {
	//@ts-expect-error - todo fix type
	return actions.some((action) => state[action])
}

export const createIsDoneSelector = (actions: string[]) => (state: LoadingState) => {
	// @ts-expect-error todo if you see this please remove this comment and fix the type error, thank you :)
	return actions.every((action) => Object.keys(state).includes(action) && !state[action])
}

export default loadingReducer
