import classNames from 'classnames'

import { bgMaterialAlt, fgPrimary } from 'src/_vars'
import { Tooltip } from 'src/atoms/Tooltip'
import { backgroundGray, brightTurquoise, hoverBlue } from 'src/refactor/colors'
import styled, { css } from 'src/styles'

export enum OptionItemSelectionVariant {
	Background = 'background',
	FontColor = 'fontColor',
}

const selectedStateStyles = {
	[OptionItemSelectionVariant.Background]: () => css`
		background: ${hoverBlue};
	`,
	[OptionItemSelectionVariant.FontColor]: () => css`
		color: ${brightTurquoise};
	`,
}

export const OptionItem = styled(Tooltip).attrs((props) => ({
	className: classNames(props.className, '--option'),
	role: 'option',
}))<{ selected: boolean; disabled: boolean; selectionVariant?: OptionItemSelectionVariant }>`
	padding: ${(p) => p.theme.spacing(3)}px ${(p) => p.theme.spacing(4)}px;
	color: ${fgPrimary};
	line-height: 1.3;

	cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};

	${(p) => (p.selected ? selectedStateStyles[p.selectionVariant ?? OptionItemSelectionVariant.Background]() : '')}

	opacity: ${(p) => (p.disabled ? 0.5 : '')};

	&:not(:last-child) {
		border-bottom: 1px solid ${backgroundGray};
	}

	&:focus {
		outline: none;
		background-color: ${bgMaterialAlt};
	}

	&:hover {
		background-color: ${(p) => (p.disabled ? '' : hoverBlue)};
	}
`
