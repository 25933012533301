import { forceArray } from 'src/lib/array-utils'
import { getStore } from 'src/redux/stores'
import {
	Callback,
	CarBookingStatuses,
	CarResult,
	EmptyVoidCallback,
	FlightBookingStatuses,
	FlightResult,
	HotelSelectDetails,
	Itinerary,
	PriceChangesData,
	ReservationStatus,
	RoomRate,
	VoidCallback,
	WithOriginalBookingSegmentId,
} from 'src/travelsuit'
import { prepareCarJson } from 'src/travelsuit/cars'
import { CarRate } from 'src/travelsuit/cars.type'
import { prepareFlightJson } from 'src/travelsuit/flights'
import { prepareRoomJson } from 'src/travelsuit/hotels'

import { IAction } from './action-helpers'
import { ItineraryTypes as Types } from './actions.types'

const hotelBookingTimeouts: Record<any, number> = {}

export type ConfirmPriceChangePayload = Record<number, number[]>

export type BookFlightResult = {
	bookingSegments: FlightResult[]
	priceChanges: PriceChangesData | undefined
}

export type BookRailResult = FlightResult[]

export const Actions = {
	getItinerary(tripId: number, callback?: VoidCallback<Itinerary>, errCallback?: EmptyVoidCallback): IAction {
		return {
			type: Types.GetItinerary.REQUEST,
			payload: { tripId },
			callback,
			errCallback,
		}
	},
	bookHotelRoom(
		tripId: number,
		searchId: number,
		hotelId: string,
		details: HotelSelectDetails,
		callback?: Callback,
		errCallback?: Callback,
	) {
		return {
			type: Types.BookHotelRoom.REQUEST,
			payload: { tripId, searchId, hotelId, details },
			body: prepareRoomJson(hotelId, details, searchId),
			callback(response: RoomRate[]) {
				if (response.length) {
					getStore().dispatch(
						Actions.checkHotelBookingStatus(
							tripId,
							response.map((r) => r.id),
							callback,
							errCallback,
						),
					)
				}
			},
			errCallback,
		}
	},
	checkHotelBookingStatus(tripId: number, bookingSegmentsIds: number[], callback?: Callback, errCallback?: Callback) {
		return {
			type: Types.CheckHotelBooking.REQUEST,
			payload: { tripId, bookingSegmentsIds },
			body: { booking_segments_ids: bookingSegmentsIds },
			callback(response: { status: ReservationStatus }) {
				if (response.status === ReservationStatus.Submitted) {
					hotelBookingTimeouts[tripId] = window.setTimeout(
						() =>
							getStore().dispatch(Actions.checkHotelBookingStatus(tripId, bookingSegmentsIds, callback, errCallback)),
						2000,
					)
				} else if (response.status === ReservationStatus.BookFailed) {
					if (errCallback) {
						errCallback(response)
					}
				} else if (response.status === ReservationStatus.Booked) {
					if (callback) {
						callback(response)
					}
				}
			},
			errCallback,
		}
	},
	cancelHotelBooking(
		tripId: number,
		payload: { reason?: string | null; booking_segments_ids: number[] },
		callback?: VoidCallback,
		errCallback?: EmptyVoidCallback,
	): IAction {
		return {
			type: Types.CancelHotelBooking.REQUEST,
			payload: { tripId, hotelBookingsIds: payload?.booking_segments_ids },
			body: payload,
			callback,
			errCallback,
		}
	},
	bookFlight(
		tripId: number,
		flight: FlightResult & Partial<WithOriginalBookingSegmentId>,
		searchId: number,
		callback?: VoidCallback<BookFlightResult>,
		errCallback?: EmptyVoidCallback,
	) {
		return {
			type: Types.BookFlight.REQUEST,
			payload: { tripId, flight, searchId },
			body: prepareFlightJson(flight, searchId),
			callback: (results: FlightResult[]) => {
				if (results.length) {
					checkFlightBookingStatus(
						tripId,
						results.map((r) => r.id),
						(priceChanges) =>
							callback?.({
								bookingSegments: results,
								priceChanges,
							}),
						errCallback,
					)
				} else {
					errCallback?.()
				}
			},
			errCallback,
		}
	},
	bookFlightNew(
		tripId: number,
		flight: FlightResult & Partial<WithOriginalBookingSegmentId>,
		searchId: number,
		callback?: VoidCallback<BookFlightResult>,
		errCallback?: EmptyVoidCallback,
	) {
		return {
			type: Types.BookFlightNew.REQUEST,
			payload: { tripId, flight, searchId },
			body: prepareFlightJson(flight, searchId),
			callback: (results: FlightResult[]) => {
				if (results.length) {
					checkFlightBookingStatus(
						tripId,
						results.map((r) => r.id),
						(priceChanges) =>
							callback?.({
								bookingSegments: results,
								priceChanges,
							}),
						errCallback,
					)
				} else {
					errCallback?.()
				}
			},
			errCallback,
		}
	},
	bookRail(
		tripId: number,
		searchId: number,
		journeyIdentifiers: {
			rail_identifier: string
			alternative_identifier: string
		}[],
		callback?: VoidCallback<BookRailResult>,
		errCallback?: EmptyVoidCallback,
	) {
		return {
			type: Types.BookRail.REQUEST,
			payload: { tripId, searchId },
			body: {
				search_id: searchId,
				journey_identifiers: journeyIdentifiers,
			},
			callback,
			errCallback,
		}
	},
	checkFlightsBookingStatus(
		tripId: number,
		bookingSegmentsIds: number[],
		callback: (result: { booking_status: FlightBookingStatuses; price_changes?: PriceChangesData }) => void,
		errCallback: EmptyVoidCallback,
	) {
		return {
			type: Types.CheckFlightBooking.REQUEST,
			payload: { tripId, bookingSegmentsIds },
			body: { booking_segments_ids: bookingSegmentsIds },
			callback,
			errCallback,
		}
	},
	cancelFlightBooking(
		tripId: number,
		flightBookingSegmentToTravelersIds: (string | number)[],
		{ reason, save_residual_value }: { reason?: string | null; save_residual_value?: boolean | null } = {},
		callback?: VoidCallback,
		errCallback?: EmptyVoidCallback,
	): IAction {
		return {
			type: Types.CancelFlightBooking.REQUEST,
			payload: { tripId },
			body: { booking_segment_to_travelers_ids: flightBookingSegmentToTravelersIds, reason, save_residual_value },
			callback,
			errCallback,
		}
	},
	cancelTrainBooking(
		tripId: number,
		booking_segments_ids: number[],
		callback?: VoidCallback,
		errCallback?: EmptyVoidCallback,
	): IAction {
		return {
			type: Types.CancelTrainBooking.REQUEST,
			payload: { tripId },
			body: { booking_segments_ids },
			callback,
			errCallback,
		}
	},
	clearItineraryChanges(tripId: number) {
		return {
			type: Types.ClearItineraryChanges,
			payload: { tripId },
		}
	},
	addFlightLuggage(tripId: number, flightBookingsIds: number[], callback?: Callback, errCallback?: Callback) {
		return {
			type: Types.AddFlightLuggage.REQUEST,
			payload: { tripId, flightBookingsIds },
			callback,
			errCallback,
			body: { booking_segments_ids: flightBookingsIds },
		}
	},
	removeFlightLuggage(tripId: number, flightBookingsIds: number[], callback?: Callback, errCallback?: Callback) {
		return {
			type: Types.RemoveFlightLuggage.REQUEST,
			payload: { tripId },
			callback,
			errCallback,
			body: { booking_segments_ids: flightBookingsIds },
		}
	},
	bookCar(
		tripId: number,
		car: CarResult,
		rate: CarRate,
		searchId: number,
		additionalEquip?: string[],
		callback?: VoidCallback<{ status: CarBookingStatuses }>,
		errCallback?: EmptyVoidCallback,
	) {
		return {
			type: Types.BookCar.REQUEST,
			payload: { tripId, car, searchId },
			body: prepareCarJson(car, rate, additionalEquip, searchId),
			callback: (_results: CarResult | CarResult[]) => {
				const results = forceArray(_results)
				if (results.length) {
					checkCarBookingStatus(tripId, results[0], callback, errCallback)
				} else {
					errCallback?.()
				}
			},
			errCallback,
		}
	},
	checkCarsBookingStatus(
		tripId: number,
		car: CarResult,
		callback?: VoidCallback<{ status: CarBookingStatuses }>,
		errCallback?: EmptyVoidCallback,
	) {
		return {
			type: Types.CheckCarBooking.REQUEST,
			payload: { tripId, bookingSegmentsIds: [car.id!] },
			body: { booking_segments_ids: [car.id!] },
			callback,
			errCallback,
		}
	},
	cancelCarBooking(
		tripId: number,
		payload: { reason?: string | null; booking_segments_ids: number[] },
		callback?: VoidCallback,
		errCallback?: EmptyVoidCallback,
	): IAction {
		return {
			type: Types.CancelCarBooking.REQUEST,
			payload: { tripId, carBookingIds: payload.booking_segments_ids },
			body: payload,
			callback,
			errCallback,
		}
	},

	confirmPriceChange(
		tripId: number,
		confirmPriceChangePayload: ConfirmPriceChangePayload,
		callback?: VoidCallback,
		errCallback?: EmptyVoidCallback,
	) {
		return {
			type: Types.ConfirmPriceChange.REQUEST,
			payload: { tripId },
			body: { booking_segments: confirmPriceChangePayload },
			callback,
			errCallback,
		}
	},

	refusePriceChange(tripId: number, callback?: VoidCallback, errCallback?: EmptyVoidCallback) {
		return {
			type: Types.RefusePriceChange.REQUEST,
			payload: { tripId },
			callback,
			errCallback,
		}
	},
}

const MAX_TRY_COUNT = 20
const flightBookingTimeouts: Record<any, number> = {}
const carBookingTimeouts: Record<any, number> = {}

async function checkFlightBookingStatus(
	tripId: number,
	bookingSegmentToTravelersIds: number[],
	callback?: (priceChanges?: PriceChangesData) => void,
	errCallback?: EmptyVoidCallback,
	tryCount: number = MAX_TRY_COUNT,
) {
	if (tryCount <= 0) {
		getStore().dispatch(Actions.cancelFlightBooking(tripId, bookingSegmentToTravelersIds))
		return errCallback?.()
	}

	getStore().dispatch(
		Actions.checkFlightsBookingStatus(
			tripId,
			bookingSegmentToTravelersIds,
			(result: { booking_status: FlightBookingStatuses; price_changes?: PriceChangesData }) => {
				if (result.booking_status === FlightBookingStatuses.Booked) {
					return callback?.(result.price_changes)
				}
				if (result.booking_status === FlightBookingStatuses.Failed) {
					return errCallback?.()
				}

				flightBookingTimeouts[tripId] = window.setTimeout(
					() => checkFlightBookingStatus(tripId, bookingSegmentToTravelersIds, callback, errCallback, tryCount - 1),
					2000,
				)
			},
			() => errCallback?.(),
		),
	)
}

async function checkCarBookingStatus(
	tripId: number,
	car: CarResult,
	callback?: VoidCallback<{ status: CarBookingStatuses }>,
	errCallback?: EmptyVoidCallback,
	tryCount: number = MAX_TRY_COUNT,
) {
	if (tryCount <= 0) {
		getStore().dispatch(Actions.cancelCarBooking(tripId, { booking_segments_ids: [Number(car.id)] }))
		return errCallback?.()
	}

	getStore().dispatch(
		Actions.checkCarsBookingStatus(
			tripId,
			car,
			(result: { status: CarBookingStatuses }) => {
				if (result.status === CarBookingStatuses.Booked) {
					return callback?.(result)
				}
				if (result.status === CarBookingStatuses.Failed) {
					return errCallback?.()
				}

				carBookingTimeouts[tripId] = window.setTimeout(
					() => checkCarBookingStatus(tripId, car, callback, errCallback, tryCount - 1),
					2000,
				)
			},
			() => errCallback?.(),
		),
	)
}

export function cancelFlightsBookingChecks(tripId?: number) {
	if (!tripId) {
		for (const id of Object.keys(flightBookingTimeouts)) {
			if (flightBookingTimeouts[id]) {
				clearTimeout(flightBookingTimeouts[id])
			}
			delete flightBookingTimeouts[id]
		}
		return
	}

	if (flightBookingTimeouts[tripId]) {
		clearTimeout(flightBookingTimeouts[tripId])
	}
}

export function cancelCarBookingChecks(tripId?: number) {
	if (!tripId) {
		for (const id of Object.keys(carBookingTimeouts)) {
			if (carBookingTimeouts[id]) {
				clearTimeout(carBookingTimeouts[id])
			}
			delete carBookingTimeouts[id]
		}
		return
	}

	if (carBookingTimeouts[tripId]) {
		clearTimeout(carBookingTimeouts[tripId])
	}
}

export function cancelHotelBookingChecks(tripId?: number) {
	if (!tripId) {
		for (const id of Object.keys(hotelBookingTimeouts)) {
			if (hotelBookingTimeouts[id]) {
				clearTimeout(hotelBookingTimeouts[id])
			}
			delete hotelBookingTimeouts[id]
		}
		return
	}

	if (hotelBookingTimeouts[tripId]) {
		clearTimeout(hotelBookingTimeouts[tripId])
	}
}
