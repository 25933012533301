import { CompanyTypeModal as TSCompanyTypeModal } from 'src/travelsuit'

import { IAction } from '../actions/action-helpers'
import { CompanyTypeModal } from '../actions/actions.types'

export type CompanyTypeModalState = TSCompanyTypeModal | null

function companyTypeModal(state: CompanyTypeModalState = { showModal: false }, action: IAction): CompanyTypeModalState {
	switch (action.type) {
		case CompanyTypeModal.ShowMyCompanyModal:
			return { ...action.payload }
		case CompanyTypeModal.HideMyCompanyModal:
			return { ...action.payload }
	}
	return state
}

export default companyTypeModal
