import { Callback } from 'src/travelsuit'

import { IAction } from './action-helpers'
import { HotelsInfoTypes as Types } from './actions.types'

export const Actions = {
	getHotelInfo(hotelId: string, callback?: Callback, errCallback?: Callback): IAction {
		return {
			type: Types.GetHotelInfo.REQUEST,
			payload: { hotelId },
			callback,
			errCallback,
		}
	},
}
