import React from 'react'

import { lightMidGray, midGray } from 'src/_vars'
import { useTranslation } from 'src/lib/i18n/i18n'
import { currency, getCurrencySign } from 'src/lib/number-utils'
import { triggerInputChangeEvent } from 'src/lib/utils'
import TrashIcon from 'src/refactor/assets/actions/delete.svg'
import styled, { padPx } from 'src/styles'
import { DefaultElProps } from 'src/travelsuit'
import { Currency } from 'src/types/common'

import { LinkButton } from '../Button/Button'
import GenericClickOutside from '../GenericClickOutside/GenericClickOutside'
import { Flex } from '../GenericComponents/GenericComponents'
import SvgIcon from '../SvgIcon/SvgIcon'
import TextField from '../TextField/TextField'
import Tooltip from '../Tooltip/Tooltip'

interface IProps extends Omit<DefaultElProps<'input'>, 'ref'> {
	width?: number | string
	currency?: Currency
}

const ControlIcon = styled(SvgIcon)`
	transition: all 150ms ease-in-out;
	color: ${midGray};
	margin-left: 1ex;
	cursor: pointer;
	vertical-align: middle;

	&:hover {
		opacity: 0.5 !important;
	}
`

const EditablePriceInputContainer = styled(Flex).attrs({ align: 'center' })`
	margin: -5px 0;

	&:hover ${ControlIcon} {
		opacity: 1;
	}
`

const CurrencySymbol = styled.span`
	color: ${lightMidGray};
	position: absolute;
	left: -20px;
	top: 50%;
	transform: translateY(-50%);
`
interface CurrencyProps {
	width: number | string
	visible: boolean
	currency: Currency
}

const CurrencyInput = styled(TextField).attrs<CurrencyProps>((props) => ({
	type: 'number',
	leading: props.visible && <CurrencySymbol>{getCurrencySign(props.currency)}</CurrencySymbol>,
	style: { width: props.width ? padPx(props.width) : undefined },
}))<CurrencyProps>`
	${(props) => (!props.visible ? `display: none !important;` : '')}
	& input {
		text-align: right;
		height: 27px;
		padding: 0;
	}
`

const EditablePriceInput: React.FunctionComponent<IProps> = (props) => {
	const { className, value, width, currency: curr, disabled, ...rest } = props
	const { t } = useTranslation()
	const [editing, setEditing] = React.useState(false)
	const inputRef = React.useRef<HTMLInputElement | null>(null)

	function resetInput() {
		if (inputRef.current) {
			triggerInputChangeEvent(inputRef.current, '')
		}
	}

	return (
		<GenericClickOutside handleClickOutside={() => setEditing(false)}>
			<EditablePriceInputContainer className={className}>
				<CurrencyInput
					e2e="CurrencyInput"
					width={width}
					value={value}
					currency={curr}
					onBlur={() => setEditing(false)}
					inputRef={inputRef}
					visible={editing}
					disabled={disabled}
					{...(rest as any)}
				/>
				{editing ? (
					<Tooltip title={t('editable-price-input.clear', 'Clear')}>
						<ControlIcon e2e="Icon.ClearPrice" src={TrashIcon} onClick={() => resetInput()} />
					</Tooltip>
				) : null}
				{!editing ? (
					<Tooltip title={!disabled ? t('editable-price-input.click-to-edit', 'Click to edit') : undefined}>
						<LinkButton e2e="Price.Unlimited" onClick={() => setEditing(true)} disabled={disabled}>
							{value
								? currency(value as string | number, { removeZeroes: true, currency: curr })
								: t('editable-price-input.unlimited', 'Unlimited')}
						</LinkButton>
					</Tooltip>
				) : null}
			</EditablePriceInputContainer>
		</GenericClickOutside>
	)
}

EditablePriceInput.defaultProps = {
	width: 80,
}

export default EditablePriceInput
