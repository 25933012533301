import { Dialog } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import { black70 } from 'src/_vars'
import CrossIcon from 'src/assets/cross-thick.svg'
import { Flex } from 'src/atoms/GenericComponents/GenericComponents'
import SvgIcon from 'src/atoms/SvgIcon/SvgIcon'
import { useZIndexContext, ZIndexContext } from 'src/common/z-index'
import ArrowRight from 'src/refactor/assets/icons/arrow-right.svg'
import { disabledGray, white } from 'src/refactor/colors'
import { flex } from 'src/styles'

const ArrowContainer = styled(Flex).attrs(() => ({
	align: 'center',
	justify: 'left',
}))`
	height: 100%;
`

const ArrowLeftIcon = styled(SvgIcon).attrs(() => ({
	size: 16,
	src: ArrowRight,
}))`
	color: ${disabledGray};
	transform: rotate(180deg);
`

const TransparentDialog = styled(Dialog)`
	& .MuiBackdrop-root {
		background: none;
	}

	& .MuiPaper-root {
		background: ${black70};
	}
`

const TransparentDialogContainer = styled(Flex).attrs(() => ({
	direction: 'column',
	align: 'stretch',
}))<{ fullHeight?: boolean }>`
	height: 100%;

	justify-content: ${({ fullHeight }) => (fullHeight ? '' : 'flex-end')};
`

const CloseButtonContainer = styled.div<{ alignRight?: boolean }>`
	min-height: 44px;
	padding: 0 15px;

	${({ alignRight }) => alignRight && flex({ align: 'center', justify: 'right' })}
`

const MobileDialogContent = styled.div<{ fullHeight?: boolean }>`
	background: ${white};
	border-radius: ${(p) => p.theme.spacing(2)}px ${(p) => p.theme.spacing(2)}px 0 0;

	flex: ${(p) => (p.fullHeight ? '1 1' : '')};

	${flex({ direction: 'column' })}

	padding-top: ${(p) => p.theme.spacing(4)}px;

	overflow: hidden;
`

const CloseButton = styled(SvgIcon).attrs(() => ({ src: CrossIcon }))`
	color: ${white};
`

export interface MobileDialogProps {
	open?: boolean
	onClose?(e?: React.MouseEvent<any>): void
	shouldUseArrowCloseButton?: boolean
	fullHeight?: boolean
	onOpen?(): void
	className?: string
	disableRestoreFocus?: boolean
	e2e?: string
}

export const MobileDialogView: React.FC<MobileDialogProps> = ({
	open = true,
	onClose,
	children,
	shouldUseArrowCloseButton,
	fullHeight = true,
	onOpen,
	className,
	disableRestoreFocus,
	e2e = 'MobileDialogView',
}) => {
	const zIndex = useZIndexContext()

	return (
		<TransparentDialog
			disableRestoreFocus={disableRestoreFocus}
			style={zIndex ? { zIndex } : undefined}
			open={open}
			fullScreen
			TransitionProps={{
				onEntering: () => {
					onOpen?.()
				},
			}}
		>
			<TransparentDialogContainer data-test={e2e}>
				<CloseButtonContainer alignRight={!shouldUseArrowCloseButton}>
					{shouldUseArrowCloseButton ? (
						<ArrowContainer>
							<ArrowLeftIcon onClick={onClose} data-test={`${e2e}.Close`} />
						</ArrowContainer>
					) : (
						<CloseButton onClick={onClose} data-test={`${e2e}.Close`} />
					)}
				</CloseButtonContainer>
				<MobileDialogContent fullHeight={fullHeight} className={className} data-test={`${e2e}.Content`}>
					<ZIndexContext.Provider value={zIndex}>{children}</ZIndexContext.Provider>
				</MobileDialogContent>
			</TransparentDialogContainer>
		</TransparentDialog>
	)
}
