import React from 'react'
import { useTranslation } from 'react-i18next'

import { useLoadingSelector, useStoreValue, useUser } from 'src/lib/react-hooks/generic-hooks'
import { useLoadPackagesAndBillingSettings } from 'src/pages/CompanyOnboarding/CreateCompanyInvoices/steps/PackagesAndBilling/useLoadPackagesAndBillingSettings'
import { MyUserRolesTypes } from 'src/redux/actions'
import { UserRoleNames } from 'src/travelsuit'
import { hasUserRole, isBookerForOthers } from 'src/travelsuit/my-user-roles'

import { useUserTracking } from './useUserTracking'

export function useIdentifyUser() {
	const user = useUser()
	const company = useStoreValue(({ myCompany }) => myCompany)
	const { data: paymentSettings, isLoading } = useLoadPackagesAndBillingSettings({}, { refresh: !!user })
	const {
		i18n: { language },
	} = useTranslation()

	const userTrackingClient = useUserTracking()

	const isLoadingRoles = useLoadingSelector(MyUserRolesTypes.GetMyUserRoles)

	const isIdentificationDataReady = !!user && !!company && !isLoading && !isLoadingRoles

	React.useEffect(() => {
		if (!userTrackingClient || !isIdentificationDataReady) {
			return
		}

		const userId = user.id
		const userInvoiceProfile = user.invoice_profile
		const invoiceProfileId = userInvoiceProfile?.id

		const billingPackage = paymentSettings.find(
			({ invoice_profile }) => invoice_profile?.country?.id === userInvoiceProfile?.country?.id,
		)?.package

		const packageName = billingPackage?.name
		const featureScope = billingPackage?.feature_scope

		const companyId = company?.id
		const companyGpoId = company?.gpo?.id

		userTrackingClient.identify(userId!, {
			language,
			admin: hasUserRole(UserRoleNames.Admin),
			booker: isBookerForOthers(),
			featureScope,
			packageName,
			companyId,
			companyGpoId,
			invoiceProfileId,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userTrackingClient, isIdentificationDataReady])
}
