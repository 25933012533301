import { VoidCallback } from 'src/travelsuit'

import { IAction } from './action-helpers'
import { IndustriesTypes } from './actions.types'

export const Actions = {
	getIndustries(callback?: VoidCallback<string[]>, errCallback?: VoidCallback): IAction {
		return {
			type: IndustriesTypes.GetIndustries.REQUEST,
			callback,
			errCallback,
		}
	},
}
