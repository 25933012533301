export function insertSeparator<T, Y>(elements: (T | Y)[] | undefined | null, separator: Y) {
	if (!elements) {
		return []
	}

	const copy = elements.concat()

	for (let i = elements.length; i > 1; i--) {
		copy.splice(i - 1, 0, separator)
	}

	return copy
}
