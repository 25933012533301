import {
	RailSearchExtendPageRequest,
	RailSearchExtendPageRequestZ,
	RailSearchSetInwardRequest,
	RailSearchSetInwardRequestZ,
} from 'src/types/rails'

import { getGGClient, RequestOverrides } from './GetGoingClient'

export async function setRailSearchInward(
	searchId: number,
	payload: RailSearchSetInwardRequest,
	overrides?: RequestOverrides,
) {
	return getGGClient()
		.request('POST', `rails/search/${searchId}/search_inward`, {
			requestSchema: RailSearchSetInwardRequestZ,
			data: payload,

			...overrides,
		})
		.then((r) => r.data)
}

export async function extendRailSearchPage(
	searchId: number,
	payload: RailSearchExtendPageRequest,
	overrides?: RequestOverrides,
) {
	return getGGClient()
		.request('POST', `rails/search/${searchId}/paginate`, {
			requestSchema: RailSearchExtendPageRequestZ,
			data: payload,
			...overrides,
		})
		.then((r) => r.data)
}
