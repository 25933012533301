import { AbortRequestSignal, getAbortRequestSignal } from './getAbortRequestSignal'
import { WithAbortRequestConfig } from './types'

export interface AbortableRequestResponse<T> {
	data: T
}

export class AbortableRequest<T, Y> {
	private signal: AbortRequestSignal | null = null

	constructor(private loadData: (args: T & WithAbortRequestConfig) => Promise<AbortableRequestResponse<Y>>) {}

	public abort() {
		if (this.signal) {
			this.signal.cancel()
		}
		this.reset()
	}

	private reset() {
		this.signal = null
	}

	public async performRequest(args: T): Promise<AbortableRequestResponse<Y>> {
		this.abort()

		this.signal = getAbortRequestSignal()

		let result: AbortableRequestResponse<Y> | undefined = undefined
		try {
			result = await this.loadData({
				...args,
				getAbortRequestConfig: this.signal.getAbortRequestConfig,
			})
		} finally {
			this.reset()
		}

		return result
	}
}
