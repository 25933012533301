import React from 'react'

import useTranslation from 'src/lib/i18n/UseTranslation'
import { Fee, TripFee } from 'src/travelsuit/fees.types'
import { fullName } from 'src/travelsuit/users.utils'

import { FeeCardWithPayment } from './FeeCardWithPayment'

interface IProps {
	fee: Fee<TripFee>
	onFeesReload: () => void
}

const TripFeeCard: React.FC<IProps> = ({ fee, onFeesReload }) => {
	const { t } = useTranslation()

	const {
		trip: { id: tripId, name },
		user,
	} = fee[fee.service_type]

	return (
		<FeeCardWithPayment
			fee={fee}
			secondaryChipText={t('usage-details.fees.trip-id', 'Trip ID - {{tripId}}', { tripId })}
			primaryInfo={name || ''}
			invoiceProfile={user.invoice_profile}
			travelerName={fullName(user)}
			paymentModalTitle={t('usage-details.pay-again-modal.trip', 'Trip Fee')}
			onFeesReload={onFeesReload}
		/>
	)
}

export default TripFeeCard
