import { upsertInto } from 'src/lib/array-utils'
import { Company, CompanyPreference } from 'src/travelsuit'

import { IAction } from '../actions/action-helpers'
import { AuthTypes, MyCompanyTypes } from '../actions/actions.types'

export type MyCompanyState = Company | null

function myCompanyReducer(state: MyCompanyState = null, action: IAction): MyCompanyState {
	let preferences: CompanyPreference[]
	switch (action.type) {
		case MyCompanyTypes.GetMyCompany.SUCCESS:
		case MyCompanyTypes.UpdateMyCompanyLogo.SUCCESS:
		case MyCompanyTypes.UpdateMyCompanyDetails.SUCCESS:
		case MyCompanyTypes.SaveBmisNumberInMyCompany.SUCCESS:
		case MyCompanyTypes.AcceptTermsOfServices.SUCCESS:
			return { ...action.payload }
		case MyCompanyTypes.DeleteBmisNumberFromCompany.SUCCESS:
			return state && { ...state, bmis: null }
		case MyCompanyTypes.UpdateMyCompanyPrefernces.SUCCESS:
			preferences = upsertInto<CompanyPreference>(
				state?.preferences ?? [],
				action.payload.preferences,
				(p) => p.preference_type,
			)
			return { ...state!, preferences }
		case AuthTypes.LogoutDone:
			return null
	}
	return state
}

export default myCompanyReducer
