export function padUnit(v: any, unit: string): string {
	return typeof v === 'number' ? v + unit : v
}

export function padPx(v: any) {
	return padUnit(v, 'px')
}

interface PadMultiConfig {
	defaultUnit: string
	map: (n: any, i: number) => number | string
}

export function padMulti(list: any, config: Partial<PadMultiConfig> = {}): string {
	list = Array.isArray(list) ? list : [list]
	return (list as any[]).map((n, i) => padUnit(config.map ? config.map(n, i) : n, config.defaultUnit ?? 'px')).join(' ')
}

export function cssExpr(expr: string | number, operator: string, value: number | string | null, unit = 'px') {
	if (value === null) {
		return padUnit(expr, unit)
	}

	if (typeof expr === 'string') {
		return `calc(${expr} ${operator} ${padUnit(value, unit)})`
	}

	if (typeof value === 'number') {
		switch (operator) {
			case '*':
				return padUnit(expr * value, unit)
			case '/':
				return padUnit(expr / value, unit)
			case '+':
				return padUnit(expr + value, unit)
			case '-':
				return padUnit(expr - value, unit)
			default:
				break
		}
	}

	return `calc(${padUnit(expr, unit)} ${operator} ${padUnit(value, unit)})`
}

export function cssAdd(expr: string | number, value: number | string | null, unit?: string) {
	return cssExpr(expr, '+', value, unit)
}
export function cssReduce(expr: string | number, value: number | string | null, unit?: string) {
	return cssExpr(expr, '-', value, unit)
}
export function cssDivide(expr: string | number, value: number | string | null, unit?: string) {
	return cssExpr(expr, '/', value, unit)
}
export function cssMultiply(expr: string | number, value: number | string | null, unit?: string) {
	return cssExpr(expr, '*', value, unit)
}
