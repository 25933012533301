import { TFunction } from 'i18next'
import { defaultsDeep, isNumber, uniq, uniqBy } from 'lodash'

import { flightUniqueKey } from 'src/lib/flight-utils'
import { mapGetOrSet } from 'src/lib/map'
import { getCabinClassName, getCabinClassNameTranslations, isNotNil } from 'src/lib/utils'
import { CabinClassName, getCabinClassLabel, ItineraryFlightBooking, MinimalSegment, Segment } from 'src/travelsuit'
import { FlightOptionKey, FlightUniqueKey } from 'src/types/flights'

import { Amenities, BookingOption, FlightGroup, FlightOption, FlightSliceSegments } from './types'

export function bookingSegmentToOption(
	bookingSegment: ItineraryFlightBooking,
	referenceBookingOption: BookingOption,
): BookingOption {
	const segmentCopy = structuredClone(bookingSegment) as ItineraryFlightBooking
	const optionCopy = structuredClone(referenceBookingOption) as BookingOption
	// The type is broken
	const flightOptionKeys: FlightOptionKey[] = segmentCopy.flight_option_keys as any
	const result: BookingOption = defaultsDeep(segmentCopy, optionCopy)
	result.fare_components.forEach((fc) => {
		fc.fare_basis_code = 'BOOKING_SEGMENT'
	})
	optionCopy.flight_option_keys![0].flight_option_keys = flightOptionKeys
	result.flight_option_keys = optionCopy.flight_option_keys
	// IDs are different between the DB and the search
	referenceBookingOption.flights.forEach((fo, foi) => {
		result.flights[foi].flight_option_key = flightOptionKeys[foi]
		fo.segments.forEach((fos, fosi) => {
			result.flights[foi].segments[fosi].id = fos.id
			const fareComponent = result.fare_components.find((fc) => fc.segment_ids.includes(fos.segment_id_ref))
			if (fareComponent) {
				// fare_basis just should be unique
				fareComponent.fare_basis_code += `:${fos.segment_id_ref}`
				fareComponent.price_class_code = null
				fareComponent.price_class_description = null
				fareComponent.price_class_name = bookingSegment.flights[foi].segments[fosi].cabin_commercial_name ?? null
			}
		})
	})
	return result
}

export function getAggregatedFlightPolicy(bookingOptions: BookingOption[]) {
	return {
		in_policy: bookingOptions.some((bo) => bo.in_policy),
		policy_deviations: uniqBy(
			bookingOptions.flatMap((bo) => bo.policy_deviations),
			(pd) => pd.name,
		),
	}
}

export function getFlightOptionByStep(bookingOption: BookingOption, stepIndex: number): FlightOption {
	return bookingOption.flights[stepIndex]
}

export function getFareComponents<FC extends { segment_ids: string[] }>(
	bookingOption: { fare_components: FC[] },
	flightOption: { segments: { segment_id_ref?: string }[] },
) {
	return bookingOption.fare_components.filter((fc) =>
		fc.segment_ids.some((sid) => flightOption.segments.some((s) => s.segment_id_ref === sid)),
	)
}

export function getFlightAmenities(flightOption: FlightSliceSegments): Amenities {
	return Object.fromEntries(
		flightOption.segments
			.flatMap(
				(segment) =>
					segment.amenities?.map((amenity) => {
						return amenity.exists && amenity.amenity ? amenity.amenity : undefined
					}) ?? [],
			)
			.filter(isNotNil)
			.map((a) => [a, true]),
	)
}

export function getFlightSliceCabinName(
	t: TFunction,
	flightOption?: { segments: { cabin?: CabinClassName | null; cabin_commercial_name?: string | null }[] },
): string {
	return uniq((flightOption?.segments ?? []).map((s) => s.cabin && getCabinClassLabel({ cabinClassName: s.cabin, t })))
		.filter(isNotNil)
		.join(', ')
}

export function getFlightSliceEmissions(flightSlice: FlightSliceSegments): number {
	return (
		flightSlice.segments.reduce(
			(acc, seg) => (seg.emissions && isNumber(acc) ? acc + seg.emissions : undefined),
			0 as number | undefined,
		) ?? 0
	)
}

export function getFlightSliceFareFamily(
	fareComponents?: { price_class_name: string | null }[],
	flightOption?: { segments: { cabin?: CabinClassName | null; cabin_commercial_name?: string | null }[] },
): string {
	const fareComponentsPriceClass = (fareComponents ?? []).map((fc) => fc.price_class_name || '').join(',')
	const segmentsPriceClass = (flightOption?.segments ?? []).map((s) => s.cabin_commercial_name).join(',')
	const cabinType = (flightOption?.segments ?? []).map((s) => s.cabin || '').join(',')

	return `${fareComponentsPriceClass || segmentsPriceClass}:${cabinType}`.toLowerCase()
}

export function getFlightSliceFareTitle(
	t: TFunction,
	fareComponents?: { price_class_name: string | null }[],
	flightOption?: { segments: { cabin?: CabinClassName | null; cabin_commercial_name?: string | null }[] },
): string {
	const fareComponentsPriceClass = uniq(fareComponents?.map((fc) => fc.price_class_name))
		.filter(isNotNil)
		.join(', ')
	const segmentsPriceClass = uniq(flightOption?.segments.map((s) => s.cabin_commercial_name))
		.filter(isNotNil)
		.join(', ')
	return fareComponentsPriceClass || segmentsPriceClass
}

export function getFlightSegmentFareTitle(t: TFunction, segment: MinimalSegment | Segment): number | undefined {
	// @ts-expect-error todo if you see this please remove this comment and fix the type error, thank you :)
	return segment.cabin_commercial_name ?? getCabinClassNameTranslations(t)[getCabinClassName(segment.cabin)]
}

export function getFlightProductEmissions(flightProduct: { flights: FlightSliceSegments[] }): number | undefined {
	return flightProduct.flights.reduce(
		(acc, fl) => {
			const sliceEmissions = getFlightSliceEmissions(fl)
			return sliceEmissions && isNumber(acc) ? acc + sliceEmissions : undefined
		},
		0 as number | undefined,
	)
}

export function getFlightFullFareBasis(
	fareComponents: { fare_basis_code: string | null; segment_ids: string[] }[],
	segmentIds: string[],
): string {
	return segmentIds.map((sid) => fareComponents.find((fc) => fc.segment_ids.includes(sid))!).join(':')
}

export function getFlightSliceFareBasis(
	fareComponents?: {
		airline_code: string
		fare_basis_code: string
	}[],
): string {
	return fareComponents?.map((fc) => `${fc.airline_code}:${fc.fare_basis_code}`).join(',') ?? ''
}

export function groupBookingOptionsByFlight(bookingOptions?: BookingOption[]) {
	return (bookingOptions ?? []).reduce((flightGroupMap, bookingOption: BookingOption) => {
		for (const flightOption of bookingOption.flights) {
			const flightKey = flightUniqueKey(flightOption)

			const flightGroup = mapGetOrSet(flightGroupMap, flightKey, (key) => ({
				bookingOptions: [],
				exampleFlightOption: flightOption,
				flightUniqueKey: key,
				emissionsByFlightIdentifier: new Map(),
				emissionsByFlightOptionKey: new Map(),
				fareComponentsByFlightOptionKey: new Map(),
				fareFamilyByFlightOptionKey: new Map(),
			}))

			flightGroup.bookingOptions.push(bookingOption)

			const fareComponents = getFareComponents(bookingOption, flightOption)

			flightGroup.emissionsByFlightIdentifier.set(
				bookingOption.flight_identifier,
				getFlightProductEmissions(bookingOption),
			)
			flightGroup.emissionsByFlightOptionKey.set(flightOption.flight_option_key, getFlightSliceEmissions(flightOption))
			flightGroup.fareComponentsByFlightOptionKey.set(flightOption.flight_option_key, fareComponents)
			flightGroup.fareFamilyByFlightOptionKey.set(
				flightOption.flight_option_key,
				getFlightSliceFareFamily(fareComponents, flightOption),
			)
		}

		return flightGroupMap
	}, new Map<FlightUniqueKey, FlightGroup>())
}
