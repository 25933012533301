export function downloadFileByLink(fileUrl: string, filename = '') {
	const linkElement = document.createElement('a')
	linkElement.download = filename
	linkElement.style.display = 'none'
	linkElement.href = fileUrl

	const linkParent = document.body
	linkElement.addEventListener('click', () => {
		linkParent.removeChild(linkElement)
	})
	linkParent.appendChild(linkElement)

	linkElement.click()
}
