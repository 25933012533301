import React from 'react'
import { connect } from 'react-redux'

import { ApplicationState } from 'src/redux/stores'
import { DefaultProps, PermissionDefs, UserPermissionsTypes } from 'src/travelsuit'

interface IProps extends DefaultProps {
	permissions: UserPermissionsTypes[] | Partial<PermissionDefs>
	userPermissions?: UserPermissionsTypes[]
}

const isDef = (def: Partial<PermissionDefs> | UserPermissionsTypes[]): def is Partial<PermissionDefs> => {
	return !(def instanceof Array)
}

const asDef = (def: Partial<PermissionDefs> | UserPermissionsTypes[]): PermissionDefs => {
	const fixedDef: PermissionDefs = !isDef(def)
		? { every: [], oneOf: def, either: false }
		: { every: def.every || [], oneOf: def.oneOf || [], either: def.either || false }

	return fixedDef
}

const permissionChecker = (
	def: Partial<PermissionDefs> | UserPermissionsTypes[],
	userPermissions: UserPermissionsTypes[] = [],
) => {
	const fixedDef = asDef(def)
	const every = !fixedDef.every.length || fixedDef.every.every((d) => userPermissions.includes(d))
	const oneOf = !fixedDef.oneOf.length || fixedDef.oneOf.some((d) => userPermissions.includes(d))
	return fixedDef.either ? every || oneOf : every && oneOf
}

const CheckPermission: React.FunctionComponent<IProps> = (props) => {
	const { permissions, children, userPermissions } = props

	return permissionChecker(permissions, userPermissions) ? <React.Fragment>{children}</React.Fragment> : null
}

const mapStateToProps = ({ userPermissions }: ApplicationState) => ({
	userPermissions,
})

export default connect(mapStateToProps)(CheckPermission)
