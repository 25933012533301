import React from 'react'

import { Font } from 'src/atoms/GenericComponents'
import { Stack } from 'src/atoms/System'
import { MobileDialogTitle } from 'src/molecules/MobileDIalog/MobileDialogTitle'
import { secondaryBlack } from 'src/refactor/colors'
import styled from 'src/styles'

export const MobileOptionsContainer = styled.div<{ fullHeight?: boolean }>`
	max-height: 100%;
	overflow-y: auto;
	flex: 1 1 auto;

	&::-webkit-scrollbar {
		display: none;
	}

	${({ fullHeight }) => fullHeight && `flex-basis: 0;`}
`

interface MobileDropdownContentProps {
	title?: React.ReactNode
	subtitle?: React.ReactNode
	tooltip?: string
	fullHeight?: boolean
	className?: string
	horizontalPadding?: boolean
}

export function MobileDropdownContent({
	title,
	subtitle,
	fullHeight,
	children,
	tooltip,
	className,
	horizontalPadding,
}: React.PropsWithChildren<MobileDropdownContentProps>) {
	return (
		<>
			{title && (
				<MobileDialogTitle title={tooltip} as="div" horizontalPadding={horizontalPadding}>
					<Stack gap={1}>
						<div>{title}</div>
						{subtitle ? (
							<Font size={12} weight={700} color={secondaryBlack}>
								{subtitle}
							</Font>
						) : null}
					</Stack>
				</MobileDialogTitle>
			)}
			<MobileOptionsContainer fullHeight={fullHeight ?? true} className={className}>
				{children}
			</MobileOptionsContainer>
		</>
	)
}
