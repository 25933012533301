import { TFunction } from 'src/lib/i18n/i18n'

import { SegmentToTraveler } from '.'

export interface SupportRequest {
	request_type: SupportRequestType
	status: SupportRequestStatus
	created_by: number
	created_dt: Date
	last_update: Date
	booking_segment_ids: number[]
	ticket_no: string
	booking_segment_to_travelers: SegmentToTraveler[]
}

export enum SupportRequestType {
	/**
	 * @deprecated
	 */
	FlightsChangeFlight = 'flights_change_flight',

	/**
	 * @deprecated
	 */
	FlightsOther = 'flights_other',

	FlightsCancelBooking = 'flights_cancel_booking',
	FlightsCancelBookingAndSaveResidualValueAsCredits = 'flights_save_residual_value',

	/**
	 * @deprecated
	 */
	HotelsChangeDates = 'hotels_change_dates',

	HotelsCancelBooking = 'hotels_cancel_booking',
	HotelsOther = 'hotels_other',

	CarsCancelBooking = 'cars_cancel_booking',
	CarsOther = 'cars_other',
	CarsBooking = 'cars_booking',

	TripCancel = 'trip_cancel',

	TrainsManage = 'trains_manage',
}

export type CancelSupportRequest =
	| SupportRequestType.FlightsCancelBooking
	| SupportRequestType.HotelsCancelBooking
	| SupportRequestType.CarsCancelBooking
	| SupportRequestType.TripCancel
	| SupportRequestType.FlightsCancelBookingAndSaveResidualValueAsCredits

export type OtherSupportRequest = SupportRequestType.HotelsOther | SupportRequestType.CarsOther

export const getSupportRequestTypeLabelTranslations = (t: TFunction) => {
	const supportRequestTypeLabelMap: Record<SupportRequestType, string> = {
		[SupportRequestType.FlightsChangeFlight]: t(
			'manage-bookings.request-type-labels.flights-change-flight',
			'Request a flight change',
		),
		[SupportRequestType.FlightsCancelBooking]: t(
			'manage-bookings.request-type-labels.flights-cancel-booking',
			'Get Refund',
		),
		[SupportRequestType.FlightsCancelBookingAndSaveResidualValueAsCredits]: t(
			'manage-bookings.request-type-labels.flights-save-residual-value',
			'Save residual value as credits for future flights',
		),
		[SupportRequestType.FlightsOther]: t('manage-bookings.request-type-labels.flights-other', 'Other'),

		[SupportRequestType.HotelsChangeDates]: t(
			'manage-bookings.request-type-labels.hotels-change-dates',
			'Request to change dates',
		),
		[SupportRequestType.HotelsCancelBooking]: t(
			'manage-bookings.request-type-labels.hotels-cancel-booking',
			'Request to cancel booking',
		),
		[SupportRequestType.HotelsOther]: t('manage-bookings.request-type-labels.hotels-other', 'Other'),

		[SupportRequestType.CarsCancelBooking]: t(
			'manage-bookings.request-type-labels.cars-cancel-booking',
			'Request to cancel booking',
		),
		[SupportRequestType.CarsBooking]: t('manage-bookings.request-type-labels.cars-booking', 'Request to book car'),
		[SupportRequestType.CarsOther]: t('manage-bookings.request-type-labels.cars-other', 'Other'),

		[SupportRequestType.TripCancel]: t('manage-bookings.request-type-labels.trip-cancel', 'Cancel Trip'),
		[SupportRequestType.TrainsManage]: t('manage-bookings.request-type-labels.train-manage', 'Manage Train'),
	}

	return supportRequestTypeLabelMap
}

export function getSupportRequestTypeLabel(type: SupportRequestType, t: TFunction) {
	return getSupportRequestTypeLabelTranslations(t)[type]
}

export function getSupportRequestLabel(supportRequest: SupportRequest, t: TFunction) {
	return getSupportRequestTypeLabel(supportRequest.request_type, t)
}

export enum SupportRequestStatus {
	Created = 'created',
	Fulfilled = 'fulfilled',
	Declined = 'declined',
}
