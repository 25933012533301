import { removeFrom, upsertInto } from 'src/lib/array-utils'
import { HomePageData, PreTripRequest } from 'src/travelsuit'

import { IAction } from '../actions/action-helpers'
import { HomePageTypes, TripRequestsTypes } from '../actions/actions.types'

export type HomePageState = HomePageData | null

const emptyHomePageState: HomePageState = null

function homePageReducer(state: HomePageState = emptyHomePageState, action: IAction): HomePageState {
	switch (action.type) {
		case HomePageTypes.GetHomePage.SUCCESS:
			return action.payload ?? state ?? emptyHomePageState
		case TripRequestsTypes.ManageTripRequest.SUCCESS:
			return {
				...state!,
				waiting_to_approve: removeFrom(state?.waiting_to_approve, action.payload as PreTripRequest, (r) => r.id),
				pre_trip_requests: upsertInto(state?.pre_trip_requests, action.payload as PreTripRequest, (r) => r.id),
			}
		case TripRequestsTypes.SubmitTripRequest.SUCCESS:
			return {
				...state!,
				pre_trip_requests: upsertInto(state?.pre_trip_requests, action.payload as PreTripRequest, (r) => r.id),
			}
		case TripRequestsTypes.DeleteTripRequest.SUCCESS:
			return {
				...state!,
				pre_trip_requests: state!.pre_trip_requests.filter(
					(tripRequest) => tripRequest.id !== action.requestPayload?.id,
				),
			}
	}
	return state
}

export default homePageReducer
