import React from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'

import Tab from 'src/atoms/Tab/Tab'
import Tabs from 'src/atoms/Tabs/Tabs'
import useTranslation from 'src/lib/i18n/UseTranslation'
import { isLaunchedAsStandaloneApp } from 'src/lib/mobile-app'
import { routeFor, Routes } from 'src/lib/route-utils'
import { RestrictedMobileAppRoute } from 'src/molecules/RestrictedMobileAppContent/RestrictedMobileAppRoute'
import { TravelProfilePageTitle } from 'src/organisms/EditTraveler/TravelProfilePageTitle'
import { EditTraveler, TravelerUnusedTickets } from 'src/pages/App/lazyRoutes'
import { useLoadFees } from 'src/pages/UsageDetails/useLoadFees'
import styled from 'src/styles'

import TravelerProfileUnpaidFee from './TravelerProfileUnpaidFee'

const StyledTabs = styled(Tabs)`
	margin-top: 40px;
`

function TravelerProfileRoutes(props: RouteComponentProps<{ id: `${number}` }>) {
	const { location, match } = props
	const userId = Number(match.params.id)
	const { t } = useTranslation()

	const isLaunchedAsMobileApp = isLaunchedAsStandaloneApp()

	const { isLoading, fees, loadFees } = useLoadFees({
		resourcePath: `users/${userId}/unpaid_fee`,
		shouldSkipLoading: isLaunchedAsMobileApp,
	})

	const editProfilePath = routeFor(Routes.EditTravelerProfile, { id: userId })
	const unusedTicketsPath = routeFor(Routes.TravelerProfileUnusedTickets, { id: userId })
	const unpaidFeePath = routeFor(Routes.TravelerProfileUnpaidFee, { id: userId })
	const currentPath = location.pathname
	const shouldShowFeesTab = (fees && fees.length > 0) || currentPath === unpaidFeePath

	return (
		<>
			<TravelProfilePageTitle userId={userId} />
			<StyledTabs e2eForTabStrips="AppRouter.TravelProfileSubmenu" active={currentPath}>
				<Tab tabKey={editProfilePath} label={t('app-router.travel-profile.profile', 'Profile')}>
					<Route
						path={editProfilePath}
						render={(editProfileProps) => <EditTraveler {...editProfileProps} key={userId} id={userId} />}
					/>
				</Tab>
				<Tab tabKey={unusedTicketsPath} label={t('app-router.travel-profile.unused-tickets', 'Unused tickets')}>
					<Route path={unusedTicketsPath} render={() => <TravelerUnusedTickets userId={userId} />} />
				</Tab>
				{shouldShowFeesTab && !isLaunchedAsMobileApp ? (
					<Tab tabKey={unpaidFeePath} label={t('app-router.travel-profile.unpaid-fee', 'Unpaid Fee')}>
						<Route
							path={unpaidFeePath}
							render={() => <TravelerProfileUnpaidFee fees={fees} isLoading={isLoading} onFeesReload={loadFees} />}
						/>
					</Tab>
				) : null}
			</StyledTabs>
			<RestrictedMobileAppRoute path={unpaidFeePath} render={() => <></>} />
		</>
	)
}

export default TravelerProfileRoutes
