import GermanyFlagSvgUrl from 'src/assets/country-flags/germany.svg'
import GreatBritainFlagSvgUrl from 'src/assets/country-flags/great_britain.svg'
import USAFlagSvgUrl from 'src/assets/country-flags/USA.svg'
import { CountriesCode } from 'src/i18nConfig'

export const countryFlag = {
	svgUrl: {
		[CountriesCode.GB]: GreatBritainFlagSvgUrl,
		[CountriesCode.US]: USAFlagSvgUrl,
		[CountriesCode.DE]: GermanyFlagSvgUrl,
	},
}
