import { useCallback } from 'react'
import { z } from 'zod'

import { captureAndLogError } from 'src/lib/error'
import { useAbortableRequest } from 'src/lib/react-hooks/useAbortableRequest'
import { useAsyncAction } from 'src/lib/react-hooks/useAsyncAction'
import { loadData, LoadDataArgs } from 'src/lib/requests/loadData'

export function useFetchData<T, Z extends z.ZodType<T> = z.ZodType<T>>({
	isInitialLoading,
	responseSchema,
}: { isInitialLoading?: boolean; responseSchema?: Z } = {}) {
	const { performRequest } = useAbortableRequest<{}, z.infer<Z>>(loadData)
	const { isRequestInProgress, performAsyncAction } = useAsyncAction(isInitialLoading)

	const fetchData = useCallback(
		(args: LoadDataArgs) =>
			performAsyncAction(() =>
				performRequest(args).then((r) => {
					responseSchema?.parseAsync(r.data).catch(captureAndLogError)
					return r
				}),
			),
		[performAsyncAction, performRequest, responseSchema],
	)

	return {
		isLoading: isRequestInProgress,
		fetchData,
	}
}
