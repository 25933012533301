import { captureEvent, captureException, Severity } from '@sentry/react'

import { appConfig } from 'src/app-config/appConfig'
import { DeployEnv, ENV } from 'src/lib/env'

export const shouldLogCapturedEvents = ENV.IS_DEBUG || appConfig.DEPLOY_ENV !== DeployEnv.production

export function severityToConsoleLevel(severity?: Severity) {
	if (severity === Severity.Critical || severity === Severity.Error || severity === Severity.Fatal) {
		return 'error'
	} else if (severity === Severity.Warning) {
		return 'warn'
	} else if (severity === Severity.Info) {
		return 'info'
	} else {
		return 'log'
	}
}

export const captureAndLogError: typeof captureException = (error, captureContext) => {
	if (shouldLogCapturedEvents) {
		const level = severityToConsoleLevel(
			(captureContext && 'level' in captureContext && captureContext.level) || Severity.Error,
		)
		console[level](error, captureContext) // eslint-disable-line no-console
	}

	if (appConfig.SENTRY_ENABLED) {
		return captureException(error, captureContext)
	}

	return Date.now().toString()
}

export const captureAndLogEvent: typeof captureEvent = (event) => {
	if (shouldLogCapturedEvents) {
		console.warn(event)
	}

	if (appConfig.SENTRY_ENABLED) {
		return captureEvent(event)
	}

	return Date.now().toString()
}
