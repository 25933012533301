import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { ApplicationState } from 'src/redux/stores'

import { SessionTimeoutModal } from './SessionTimeoutModal'

interface IProps {}

export const ShowSessionTimeoutModal: FC<IProps> = () => {
	const [show, setShow] = useState(false)
	const auth = useSelector((store: ApplicationState) => store.auth)
	const needShowModal = auth.get('showModal')

	useEffect(() => {
		setShow(false)

		if (needShowModal) {
			setShow(() => true)
		}
	}, [needShowModal])

	return <>{show && <SessionTimeoutModal onClose={() => setShow(false)} />}</>
}
