import { createLazyComponentWithSuspense } from 'src/lib/createLazyComponentWithSuspense'

export const BookConsultant = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "BookConsultant" */ 'src/pages/BookConsultant/BookConsultant'),
)

export const ReportSettings = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "ReportSettings" */ 'src/pages/ReportSettings/ReportSettings'),
)

export const CorporateDiscountsAndRewardPrograms = createLazyComponentWithSuspense(
	() =>
		import(
			/* webpackChunkName: "CorporateDiscountsAndRewardPrograms" */ 'src/pages/CorporateDiscountsAndRewardPrograms'
		),
)

export const HomePage = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "HomePage" */ 'src/pages/HomePage/HomePage'),
)

export const Safety = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "Safety" */ 'src/pages/Safety/Safety'),
)

export const GeneralSettings = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "GeneralSettings" */ 'src/pages/GeneralSettings/GeneralSettings'),
)

export const ManageTravelers = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "ManageTravelers" */ 'src/pages/ManageTravelers/ManageTravelers'),
)

export const ManageDepartments = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "ManageDepartments" */ 'src/pages/ManageDepartments/ManageDepartments'),
)

export const ManageLocations = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "ManageLocations" */ 'src/pages/ManageLocations/ManageLocations'),
)

export const ManagePolicy = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "ManagePolicy" */ 'src/pages/ManagePolicy/ManagePolicy'),
)

export const ManageExpenses = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "ManageExpenses" */ 'src/pages/ManageExpenses/ManageExpenses'),
)

export const NewExpense = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "NewExpense" */ 'src/pages/NewExpense/NewExpense'),
)

export const EditExpense = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "EditStandardExpense" */ 'src/pages/EditExpense/EditStandardExpense'),
)
export const NewMileage = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "NewMileage" */ 'src/pages/NewMileage/NewMileage'),
)

export const EditMileage = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "EditMileage" */ 'src/pages/NewMileage/EditMileage'),
)

export const EditStandardExpenseInReport = createLazyComponentWithSuspense(
	() =>
		import(/* webpackChunkName: "EditStandardExpenseInReport" */ 'src/pages/EditExpense/EditStandardExpenseInReport'),
)

export const EditMileageInReport = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "EditMileageInReport" */ 'src/pages/EditExpense/EditMileageInReport'),
)
export const EditDailyAllowanceInReport = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "EditDailyAllowanceInReport" */ 'src/pages/EditExpense/EditDailyAllowanceInReport'),
)

export const NewDailyAllowance = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "NewDailyAllowance" */ 'src/pages/NewDailyAllowance/NewDailyAllowance'),
)

export const EditDailyAllowance = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "EditDailyAllowance" */ 'src/pages/EditDailyAllowance/EditDailyAllowance'),
)

export const ReportsRouter = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "ReportsRouter" */ 'src/pages/ReportsRouter/ReportsRouter'),
)

export const Itinerary = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "Itinerary" */ 'src/pages/Itinerary/Itinerary'),
)

export const HotelResultPage = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "HotelResultPage" */ 'src/pages/HotelResultPage/HotelResultPage'),
)

export const TripBuilder = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "TripBuilder" */ 'src/pages/TripBuilder/TripBuilder'),
)

export const TripProduct = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "TripProduct" */ 'src/pages/TripBuilder/TripProduct'),
)

export const TripsList = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "Trips" */ 'src/pages/Trips/Trips'),
)

export const ExpenseReports = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "ExpenseReports" */ 'src/pages/ExpenseReports/ExpenseReports'),
)

export const ExpenseReportsSubmitted = createLazyComponentWithSuspense(
	() =>
		import(
			/* webpackChunkName: "ExpenseReportsSubmitted" */ 'src/pages/ExpenseReportsSubmitted/ExpenseReportsSubmitted'
		),
)

export const NewExpenseReport = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "NewExpenseReport" */ 'src/pages/ExpenseReports/NewExpenseReport'),
)

export const EditExpenseReport = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "EditExpenseReport" */ 'src/pages/ExpenseReports/EditExpenseReport'),
)

export const SelectExpenses = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "SelectExpenses" */ 'src/pages/ExpenseReports/SelectExpenses'),
)

export const EditTraveler = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "EditTraveler" */ 'src/organisms/EditTraveler/EditTraveler'),
)

export const TravelerUnusedTickets = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "TravelerUnusedTickets" */ 'src/pages/TravelerProfileUnusedTickets'),
)

export const ChangePlan = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "ChangePlan" */ 'src/pages/ChangePlan/ChangePlan'),
)

export const ActivateInvoiceProfile = createLazyComponentWithSuspense(
	() =>
		import(/* webpackChunkName: "ActivateInvoiceProfile" */ 'src/pages/ActivateInvoiceProfile/ActivateInvoiceProfile'),
)

export const CreateInvoiceProfileWizard = createLazyComponentWithSuspense(
	() =>
		import(
			/* webpackChunkName: "CreateInvoiceProfileWizard" */ 'src/pages/CreateInvoiceProfile/CreateInvoiceProfileWizard'
		),
)

export const InvoiceProfiles = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "InvoiceProfiles" */ 'src/pages/InvoiceProfiles/InvoiceProfiles'),
)

export const CurrentPlan = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "CurrentPlan" */ 'src/pages/CurrentPlan/CurrentPlan'),
)

export const UsageDetails = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "UsageDetails" */ 'src/pages/UsageDetails/UsageDetails'),
)

export const CreateCompanyInvoices = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "CreateCompanyInvoices" */ 'src/pages/CompanyOnboarding/CreateCompanyInvoices'),
)

export const ConfirmCompanyPage = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "ConfirmCompany" */ 'src/pages/CompanyOnboarding/ConfirmCompany'),
)

export const CallbackVerification = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "CallbackVerification" */ 'src/pages/CallbackVerification'),
)

export const CreditCardCallbackVerification = createLazyComponentWithSuspense(
	() =>
		import(
			/* webpackChunkName: "CreditCardCallbackVerification" */ 'src/pages/CreditCardCallbackVerification/CreditCardCallbackVerification'
		),
)

export const PasswordChangeResult = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "PasswordChangeResult" */ 'src/pages/PasswordChangeResult/PasswordChangeResult'),
)

export const Support = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "Support" */ 'src/pages/Support/Support'),
)
