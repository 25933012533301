import { objDiff } from 'src/lib/utils'
import { Callback, Group } from 'src/travelsuit'

import { getStoreState } from '../stores'
import { IAction } from './action-helpers'
import { DepartmentsTypes as Types } from './actions.types'

export const Actions = {
	getDepartment(id: number, callback?: Callback): IAction {
		return {
			type: Types.GetDepartment.REQUEST,
			payload: { id },
			callback,
		}
	},
	getAllDepartments(): IAction {
		return {
			type: Types.GetAllDepartments.REQUEST,
		}
	},
	updateDepartment(department: Group, callback?: Callback): IAction {
		const originalDepartment = getStoreState().departments.get(department.id)
		const changes = objDiff(originalDepartment, department)
		delete changes.approver
		delete changes.users_count

		return {
			type: Types.UpdateDepartment.REQUEST,
			payload: { ...changes, id: department.id },
			requestPayload: department,
			callback,
		}
	},
	createDepartment({ approver, users_count, ...rest }: Group, callback?: Callback): IAction {
		return {
			type: Types.CreateDepartment.REQUEST,
			payload: { ...rest },
			callback,
		}
	},
	deleteDepartment(id: number, callback?: Callback): IAction {
		return {
			type: Types.DeleteDepartment.REQUEST,
			payload: { id },
			callback,
		}
	},
}
