import React from 'react'

import { isLaunchedAsStandaloneApp } from 'src/lib/mobile-app'

interface RestrictedMobileAppContentProps {
	fallback?: React.ReactNode
}

export function RestrictedMobileAppContent({
	children,
	fallback = null,
}: React.PropsWithChildren<RestrictedMobileAppContentProps>) {
	return <>{isLaunchedAsStandaloneApp() ? fallback : children}</>
}
