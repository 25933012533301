import React, { useCallback, useEffect, useLayoutEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'

import { entityGenerator, noop } from 'src/lib/utils'
import { IAction, isRequestTypeAction, LiveUserLocationsActions, RequestTypeMapping } from 'src/redux/actions'
import { createLoadingSelector } from 'src/redux/reducers/loading.reducer'
import { ApplicationState } from 'src/redux/stores'
import { ArrayOr, AuthUser, EmptyVoidCallback, TravelProfile, User } from 'src/travelsuit'
import { CompanyPreferenceType } from 'src/types/company'

import { forceArray } from '../array-utils'

export function useStoreValue<T>(converter: (state: ApplicationState) => T = noop as any): T {
	return useSelector(converter)
}

export function selectCurrentUser({ auth }: ApplicationState) {
	return auth.get('internalUser') as User
}

export function selectAuthUser({ auth }: ApplicationState) {
	return auth.get('user') as AuthUser
}

export function useUser() {
	return useStoreValue(selectCurrentUser)
}

export function useAuthUser() {
	return useStoreValue(selectAuthUser)
}

export function useAdminUsers() {
	return useStoreValue(({ adminUsers }) => adminUsers)
}

export function useTravelProfile() {
	const user = useUser()
	const { id } = user ?? {}
	return useStoreValue(({ travelers }) => travelers.get(id!) as TravelProfile)
}

export function usePreference(key: CompanyPreferenceType) {
	return useStoreValue(({ myCompany }) => myCompany?.preferences.find((p) => p.preference_type === key))
}

const _WINDOW_FOCUS_INTERVALS: Record<string, number | undefined> = {}

export function useWindowFocusAwareInterval(
	key: keyof typeof _WINDOW_FOCUS_INTERVALS,
	ms: number,
	cb: EmptyVoidCallback,
) {
	const clearInterval = () => {
		window.clearInterval(_WINDOW_FOCUS_INTERVALS[key])
		delete _WINDOW_FOCUS_INTERVALS[key]
	}

	const createInterval = () => {
		clearInterval()
		cb()
		_WINDOW_FOCUS_INTERVALS[key] = window.setInterval(cb, ms)
	}

	const createListeners = () => {
		window.addEventListener('focus', createInterval)
		window.addEventListener('blur', clearInterval)
	}

	const clearListeners = () => {
		window.removeEventListener('focus', createInterval)
		window.removeEventListener('blur', clearInterval)
	}

	const clearAll = () => {
		clearListeners()
		clearInterval()
	}

	const createAll = () => {
		createInterval()
		createListeners()
	}

	React.useEffect(() => {
		clearAll()
		createAll()
		return clearAll
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return clearAll
}

export function useLoadingSelector(
	key: string | Pick<RequestTypeMapping, 'ORIGINAL'>,
	...keys: Array<string | Pick<RequestTypeMapping, 'ORIGINAL'>>
) {
	const stringKeys: string[] = [key, ...keys].map((key) => (isRequestTypeAction(key) ? key.ORIGINAL : (key as string)))
	return useSelector((state: ApplicationState) => createLoadingSelector(stringKeys)(state.loading))
}

export function useLoadingSelectorOnce(
	key: string | Pick<RequestTypeMapping, 'ORIGINAL'>,
	...keys: Array<string | Pick<RequestTypeMapping, 'ORIGINAL'>>
) {
	const loading = useLoadingSelector(key, ...keys)
	const [afterLoad, setAfterLoad] = React.useState(false)

	React.useEffect(() => {
		if (!loading) {
			setAfterLoad(true)
		}
	}, [loading])

	return !afterLoad
}

export function useLiveLocationMap() {
	const userLocations = useStoreValue((state) => state.liveUserLocations.userLocations)
	const dispatch = useDispatch()

	React.useEffect(() => {
		dispatch(LiveUserLocationsActions.getLiveUserLocations())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return userLocations
}

export function useWindowSize() {
	const [size, setSize] = React.useState({ width: window.outerWidth, height: window.outerHeight })

	React.useEffect(() => {
		function _wrapped(this: Window) {
			setSize({ width: this.outerWidth, height: this.outerHeight })
		}
		window.addEventListener('resize', _wrapped)
		return () => window.removeEventListener('resize', _wrapped)
	}, [size.width, size.height])

	return size
}

/** Keeps a reference of the value at call time. This is useful when you want to access previous values inside
 * an effect hook.
 *
 * Example:
 * ```typescript
 * const counter = React.useState(0)
 * const previousCounter = usePreviousValue(counter)
 *
 * React.useEffect(() => console.info({ counter, previousCounter }), [counter])
 * ```
 */
export function usePreviousValue<T = any>(value: T): T {
	const ref = React.useRef<T>(value)
	React.useEffect(() => {
		ref.current = value
	}, [value])

	return ref.current!
}

export interface APIHookOptions {
	refresh: boolean
	onLoad?: EmptyVoidCallback
	onError?: EmptyVoidCallback
}

const withDefaultAPIHookOptions = entityGenerator<APIHookOptions>({
	refresh: false,
})

export interface APIHookCreatorConfig<O, T> {
	storeMapper(state: ApplicationState, params: O, options: APIHookOptions): T
	loadingSelectors: ArrayOr<string | RequestTypeMapping>
	loadAction(params: O, options: APIHookOptions): IAction
	mapParamsToHookDeps?(params: O): unknown[]
}

interface LoadOnceAPIHookCreatorConfig<O, T> extends APIHookCreatorConfig<O, T> {
	isEmpty(state: T): boolean
}

interface PollAPIHookCreatorConfig<O, T> extends APIHookCreatorConfig<O, T> {
	pollInterval?: number
}

type APIHookOptionsFunction<O, T> = (
	params: O,
	options?: Partial<APIHookOptions>,
) => { dispatchLoadAction: () => void; loading: boolean; options: APIHookOptions; data: T }

type APIHookFunction<O, T> = (params: O, options?: Partial<APIHookOptions>, dependencies?: unknown[]) => T

type PollAPIHookFunction<O, T> = (
	params: O,
	shouldContinuePolling: (value: T) => boolean,
	options?: Partial<APIHookOptions>,
) => T

function createAPIOptionsHook<O, T>(config: APIHookCreatorConfig<O, T>): APIHookOptionsFunction<O, T> {
	return (params, optionsOverride) => {
		const options = withDefaultAPIHookOptions(optionsOverride)
		const data = useStoreValue((state) => config.storeMapper(state, params, options))
		const selectors = forceArray(config.loadingSelectors)
		const loading = useLoadingSelector(selectors[0], ...selectors.slice(1))
		const dispatch = useDispatch()

		const dispatchLoadAction = () => dispatch(config.loadAction(params, options))

		return {
			options,
			dispatchLoadAction,
			loading,
			data,
		}
	}
}

export function createAPIHook<O, T>(config: APIHookCreatorConfig<O, T>): APIHookFunction<O, T> {
	const useAPIOptions = createAPIOptionsHook(config)

	return (params, optionsOverride, dependencies = []) => {
		const { dispatchLoadAction, loading, options, data } = useAPIOptions(params, optionsOverride)

		React.useEffect(() => {
			if (options.refresh && !loading) {
				dispatchLoadAction()
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [options.refresh, ...(config.mapParamsToHookDeps?.(params) ?? []), ...dependencies])

		return data
	}
}

export function createApiHookWithLoadingState<O, T>(
	config: APIHookCreatorConfig<O, T>,
): (params: O, options?: Partial<APIHookOptions>) => { data: T; isLoading: boolean } {
	const useApiHook = createAPIHook(config)

	const [loadingSelector, ...restSelectors] = forceArray(config.loadingSelectors)

	return function useAPIHookWithLoadingState(params: O, optionsOverride?: Partial<APIHookOptions>) {
		const loading = useLoadingSelector(loadingSelector, ...restSelectors)
		const [shouldRefresh, setShouldRefresh] = React.useState(optionsOverride?.refresh !== false && !loading)

		const data = useApiHook(params, {
			refresh: shouldRefresh,
			onLoad: () => {
				setShouldRefresh(false)
				optionsOverride?.onLoad?.()
			},
			onError: () => {
				setShouldRefresh(false)
				optionsOverride?.onError?.()
			},
		})

		return {
			data,
			isLoading: loading || shouldRefresh,
		}
	}
}

export function createLoadOnceAPIHook<O, T>(config: LoadOnceAPIHookCreatorConfig<O, T>): APIHookFunction<O, T> {
	const useAPIOptions = createAPIOptionsHook(config)

	return (params, optionsOverride) => {
		const { dispatchLoadAction, loading, data } = useAPIOptions(params, optionsOverride)
		const isDataEpmty = config.isEmpty(data)

		React.useEffect(() => {
			if (isDataEpmty && !loading) {
				dispatchLoadAction()
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [isDataEpmty])

		return data
	}
}

export function createPollAPIHook<O, T>(config: PollAPIHookCreatorConfig<O, T>): PollAPIHookFunction<O, T> {
	const useAPIOptions = createAPIOptionsHook(config)

	return (params, shouldContinuePolling, optionsOverride) => {
		const { dispatchLoadAction, loading, data } = useAPIOptions(params, optionsOverride)

		React.useEffect(() => {
			if ((data && !shouldContinuePolling(data)) || loading) {
				return
			}

			const timeoutId = setTimeout(dispatchLoadAction, config.pollInterval)

			return () => {
				clearTimeout(timeoutId)
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [loading, data, shouldContinuePolling])

		return data
	}
}

export function useComplexState<T>(initial: T): [T, (val: Partial<T>) => void] {
	const [state, setState] = useReducer(
		(prevState, updatedProperty) => ({
			...prevState,
			...updatedProperty,
		}),
		initial,
	)

	return [state, setState]
}

/**
 * Allow to inherit and re-use the ref coming from props and useImperativeHandle
 * is an overkill. Like when both the parent component and the current component
 * want to have a ref just the be able to call `.focus()`
 *
 * Example:
 * ```ts
 * function TextField({ inputRef: propsRef, ...rest }) {
 *   const inputRef = useCommonRef(propsRef)
 *
 *   useEffect(() => {
 *     inputRef.current?.focus()
 *   })
 *
 *   return <input ref={inputRef} type="text" {...rest} />
 * }
 * ```
 */
export function useSynchronizedRef<T>(
	refToSync: React.RefObject<T> | undefined,
	initialValue: T | null = null,
): React.RefObject<T> {
	const myRef = useRef<T>(initialValue)

	useLayoutEffect(() => {
		const updateRef = () => {
			if (refToSync) {
				;(refToSync as React.MutableRefObject<T | null>).current = myRef.current
			}
		}
		updateRef()
		return updateRef
	})

	return myRef
}

export type UseOnClickOutsideOptions = {
	/** Listen for clicks outside of this container */
	containerRef: React.RefObject<HTMLElement>
	onClickOutside: () => void
	/** Set this to `false` when your popup is closed to save on DOM listeners */
	shouldListen?: boolean
}

/** Replaces the `onClickOut` HoC from `react-onclickoutside` for functional components */
export function useOnClickOutside({ containerRef, onClickOutside, shouldListen }: UseOnClickOutsideOptions) {
	useEffect(() => {
		if (!containerRef.current || shouldListen === false) {
			return
		}

		const listener = (evt: MouseEvent | TouchEvent) => {
			if (evt.target && containerRef.current && !evt.composedPath().includes(containerRef.current)) {
				onClickOutside()
			}
		}

		document.addEventListener('click', listener)
		document.addEventListener('touchstart', listener)

		return () => {
			document.removeEventListener('click', listener)
			document.removeEventListener('touchstart', listener)
		}
	}, [containerRef, onClickOutside, shouldListen])
}

/**
 * When you want to use a mutable container for its speed,
 * but still need to update the UI after some of its mutations.
 * ```tsx
 * const [expandedMap, forceUpdate] = useMutableValue<Map<string, boolean>(new Map())
 * const toggleRow = (rowId: string) => {
 *   expandedMap.set(rowId, !expandedMap.get(rowId))
 *   forceUpdate()
 *   // or alternative
 *   forceUpdate((em) => em.set(rowId, !em.get(rowId))
 * }
 * return <Row expanded={expandedMap.get(rowId)} onClick={() => toggleRow(rowId)} />
 * ```
 */
export function useMutableValue<T>(initialValue: T) {
	const ref = useRef(initialValue)
	const [_version, setVersion] = useState(0)
	const forceUpdate = useCallback((mutation?: (value: T) => void) => {
		mutation?.(ref.current)
		setVersion((v) => (v < Number.MAX_SAFE_INTEGER ? v + 1 : 0))
	}, [])
	return [ref.current, forceUpdate] as const
}

type CB<A = unknown> = (value?: A) => any

/**
 * In the newer type-fest there array helpers to cut off resCb and errCb from Parameters,
 * but we can't import is due to the TS requirements. So, for now it would be manual p1, p2...
 */
export interface AsyncDispatch {
	<R>(action: (resCb?: CB<R>, errCb?: CB) => any): Promise<R>
	<P1, R>(action: (p1: P1, resCb?: CB<R>, errCb?: CB) => any, p1: P1): Promise<R>
	<P1, P2, R>(action: (p1: P1, p2: P2, resCb?: CB<R>, errCb?: CB) => any, p1: P1, p2: P2): Promise<R>
	<P1, P2, P3, R>(
		action: (p1: P1, p2: P2, p3: P3, resCb?: CB<R>, errCb?: CB) => any,
		p1: P1,
		p2: P2,
		p3: P3,
	): Promise<R>
	<P1, P2, P3, P4, R>(
		action: (p1: P1, p2: P2, p3: P3, p4: P4, resCb?: CB<R>, errCb?: CB) => any,
		p1: P1,
		p2: P2,
		p3: P3,
		p4: P4,
	): Promise<R>
}

export function promisifyDispatch(dispatch: Dispatch): AsyncDispatch {
	return <A extends (...args: any[]) => any>(action: A, ...args: Parameters<A>) =>
		new Promise((resolve, reject) => {
			args.push(resolve, reject)
			dispatch(action(...args))
		})
}

export function useAsyncDispatch() {
	const dispatch = useDispatch()
	return useMemo(() => promisifyDispatch(dispatch), [dispatch])
}

/** The return value is stable */
export function useIsMounted() {
	const ref = useRef({ value: true })
	useEffect(
		() => () => {
			ref.current.value = false
		},
		[],
	)
	return ref.current
}
