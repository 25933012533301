import React from 'react'

import styled from 'src/styles'

import { dialogViewHorizontalPadding } from './dialogViewHorizontalPadding'
import { MobileDialogView } from './MobileDialogView'

export const StyledMobileDialogView = styled(MobileDialogView)<{ horizontalPadding?: boolean }>`
	${(p) => (p.horizontalPadding ? dialogViewHorizontalPadding() : '')}

	padding-bottom: ${(p) => p.theme.spacing(3)}px;

	overflow-y: auto;
`

export interface MobileDialogProps {
	open?: boolean
	onClose?(e?: React.MouseEvent<any>): void
	shouldUseArrowCloseButton?: boolean
	fullHeight?: boolean
	onOpen?(): void
	horizontalPadding?: boolean
}

export const MobileDialog: React.FC<MobileDialogProps> = ({
	open = true,
	children,
	fullHeight = true,
	horizontalPadding = true,
	...rest
}) => {
	return (
		<>
			{open ? (
				<StyledMobileDialogView open={open} fullHeight={fullHeight} horizontalPadding={horizontalPadding} {...rest}>
					{children}
				</StyledMobileDialogView>
			) : (
				children
			)}
		</>
	)
}
