import { CreditCardInfo, InvoiceProfile, VoidCallback } from 'src/travelsuit'

import { IAction } from './action-helpers'
import { CompanyInvoiceProfilesTypes } from './actions.types'

const convertInvoiceProfileToApiModel = ({
	country,
	language,
	trade_register_number,
	...restProfile
}: Partial<InvoiceProfile>) => ({
	...restProfile,
	language_id: language?.id,
	country_id: country?.id,
	trade_register_number: trade_register_number || null,
})

export const Actions = {
	getCompanyInvoiceProfiles(callback?: VoidCallback<InvoiceProfile[]>, errCallback?: VoidCallback<never>): IAction {
		return {
			type: CompanyInvoiceProfilesTypes.GetCompanyInvoiceProfiles.REQUEST,
			callback,
			errCallback,
		}
	},
	getCompanyInvoiceProfile(
		id: number,
		callback?: VoidCallback<InvoiceProfile>,
		errCallback?: VoidCallback<never>,
	): IAction {
		return {
			type: CompanyInvoiceProfilesTypes.GetCompanyInvoiceProfile.REQUEST,
			payload: { id },
			callback,
			errCallback,
		}
	},
	createCompanyInvoiceProfile(
		profile: Partial<InvoiceProfile>,
		callback?: VoidCallback<InvoiceProfile>,
		errCallback?: VoidCallback<never>,
	): IAction {
		return {
			type: CompanyInvoiceProfilesTypes.CreateCompanyInvoiceProfile.REQUEST,
			payload: convertInvoiceProfileToApiModel(profile),
			callback,
			errCallback,
		}
	},
	updateCompanyInvoiceProfile(
		profile: InvoiceProfile,
		callback?: VoidCallback<InvoiceProfile>,
		errCallback?: VoidCallback<never>,
	): IAction {
		return {
			type: CompanyInvoiceProfilesTypes.UpdateCompanyInvoiceProfile.REQUEST,
			payload: convertInvoiceProfileToApiModel(profile),
			callback,
			errCallback,
		}
	},
	deleteCompanyInvoiceProfile(
		id: number,
		callback?: VoidCallback<InvoiceProfile>,
		errCallback?: VoidCallback<never>,
	): IAction {
		return {
			type: CompanyInvoiceProfilesTypes.DeleteCompanyInvoiceProfile.REQUEST,
			payload: { id },
			callback,
			errCallback,
		}
	},

	confirmCompanyInvoiceProfile(
		id: number,
		callback?: VoidCallback<InvoiceProfile>,
		errCallback?: VoidCallback<never>,
	): IAction {
		return {
			type: CompanyInvoiceProfilesTypes.ConfirmCompanyInvoiceProfile.REQUEST,
			payload: { id },
			callback,
			errCallback,
		}
	},

	acceptTermsAndConditionsForInvoiceProfile(
		id: number,
		callback?: VoidCallback<InvoiceProfile>,
		errCallback?: VoidCallback<never>,
	): IAction {
		return {
			type: CompanyInvoiceProfilesTypes.AcceptTermsAndConditionsForInvoiceProfile.REQUEST,
			payload: { id },
			callback,
			errCallback,
		}
	},

	addCompanyCreditCard(invoiceProfileId: number, creditCard: CreditCardInfo): IAction {
		return {
			type: CompanyInvoiceProfilesTypes.AddCompanyCreditCard,
			payload: { invoiceProfileId, creditCard },
		}
	},

	updateCompanyCreditCard(invoiceProfileId: number, creditCard: CreditCardInfo): IAction {
		return {
			type: CompanyInvoiceProfilesTypes.UpdateCompanyCreditCard,
			payload: { invoiceProfileId, creditCard },
		}
	},

	deleteCompanyCreditCard(invoiceProfileId: number, creditCardId: number): IAction {
		return {
			type: CompanyInvoiceProfilesTypes.DeleteCompanyCreditCard,
			payload: { invoiceProfileId, creditCardId },
		}
	},
}
