import { TFunction } from 'src/lib/i18n/i18n'
import { PaymentFeeType, ServiceFeeType } from 'src/travelsuit/fees.types'

export const getServiceTypesOptions = (t: TFunction) => [
	{ value: ServiceFeeType.Trip, label: t('usage-details.service-fee-type.trip', 'Trip') },
	{
		value: ServiceFeeType.SupportRequest,
		label: t('usage-details.service-fee-type.support_request', 'Support Request'),
	},
	{ value: ServiceFeeType.AnnualPayment, label: t('usage-details.service-fee-type.annual_payment', 'Annual Payment') },
	{
		value: ServiceFeeType.MonthlyPayment,
		label: t('usage-details.service-fee-type.monthly_payment', 'Monthly Payment'),
	},
	{ value: ServiceFeeType.ProfiledUser, label: t('usage-details.service-fee-type.profiled_user', 'Profiled user') },
	{ value: ServiceFeeType.ActiveUser, label: t('usage-details.service-fee-type.active_user', 'Active User') },
]

export const getPaymentFeeTypeOptions = (t: TFunction, unpaidFees?: boolean) => {
	const defaultOptions = [
		{ value: PaymentFeeType.WithFee, label: t('usage-details.payment-fee-type.with-fee', 'With fee') },
		{ value: PaymentFeeType.WhithoutFee, label: t('usage-details.payment-fee-type.without-fee', 'Without fee') },
	]
	const optionsWithUnpaid = [
		...defaultOptions,
		{ value: PaymentFeeType.UnpaidFees, label: t('usage-details.payment-fee-type.unpaid-fee', 'Unpaid fee') },
	]
	return unpaidFees ? optionsWithUnpaid : defaultOptions
}

export const allValuesSelected = (obj: Record<string, boolean>, optionsLength: number) =>
	Object.values(obj).length === optionsLength && Object.values(obj).every((val) => val)

export const isFilterApplied = (filter: Record<string, boolean>, optionsLength: number, selectedValues: string[]) =>
	!allValuesSelected(filter, optionsLength) && !!selectedValues.length

export const setAllValues = (v: boolean, options: Record<'id', number>[]) =>
	options.reduce((result, cur) => ({ ...result, [cur.id]: v }), {} as Record<number, boolean>)

export const getFilteredOptions = (obj: Record<string | number, boolean>) => {
	const selected = Object.keys(obj).filter((key) => obj[key])
	const isAllSelected = Object.keys(obj).length === selected.length
	return isAllSelected ? [] : selected
}
