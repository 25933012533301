import classNames from 'classnames'
import React from 'react'
import ReactDOM from 'react-dom'

import CrossIcon from 'src/assets/cross.svg'
import { TabletOrAbove } from 'src/atoms/GenericComponents/GenericComponents'
import SvgIcon, { CloseIcon } from 'src/atoms/SvgIcon/SvgIcon'
import { ZIndexContext } from 'src/common/z-index'
import { passTestId } from 'src/lib/e2e-utils'
import { blockScroll, eventStopper } from 'src/lib/utils'

import {
	BackColumn,
	BottomModalButtonsContainer,
	BottomModalContainer,
	BottomModalControls,
	BottomModalSeparator,
	ButtonsContainer,
	CloseButton,
	DesktopTitle,
	MobileHeaderRow,
	ModalBase,
	ModalBox,
	ModalContent,
	ModalControlRow,
	ModalOverlay,
	ModalRoot,
	TitleColumn,
} from './Modal.components'
import { IPropsModal, widthVariantToPx } from './Modal.types'
import { DOMTreePosition } from './Modal.types'

export { DOMTreePosition }

export interface IProps extends IPropsModal {
	hideBottomSeparator?: boolean
	containerClassName?: string
	closeOnOverlayClick?: boolean
}

const CompanyTypeAndColors = {
	success: 'menuItem',
	warning: 'yellow',
	alert: 'menuItemError',
	menuItemError: 'transparent',
}

CloseButton.defaultProps = {
	...CloseButton.defaultProps,
	rounded: '50%',
}

const ModalContext = React.createContext<HTMLElement | null>(null)

// TASK migrate to React.FunctionComponent OR remove this if not possible
class Modal extends React.Component<IProps> {
	public static defaultProps: Partial<IProps> = {
		open: true,
		contentComponent: ModalBox,
		variant: 'success',
		width: 'sm',
		domTreePosition: DOMTreePosition.root,
		closeOnOverlayClick: true,
	}

	public static contextType = ModalContext

	context!: React.ContextType<typeof Modal.contextType>

	private contentRef = React.createRef<HTMLDivElement>()

	public componentDidMount() {
		if (this.props.open && this.contentRef.current) {
			blockScroll(this.contentRef.current, true)
		}
	}

	public componentDidUpdate(prevProps: IProps) {
		if (this.props.open !== undefined && this.props.open !== prevProps.open && this.contentRef.current) {
			blockScroll(this.contentRef.current, this.props.open)
		}
	}

	public componentWillUnmount() {
		if (this.contentRef.current) {
			blockScroll(this.contentRef.current, false)
		}
	}

	public renderButton(el: React.ReactElement<any>) {
		const cls = classNames(el.props.className, { 'control-button': !el.props.hasOwnProperty('color') })
		return React.cloneElement(el, { className: cls })
	}

	public render() {
		const {
			className,
			children,
			onClose,
			open,
			controlButtons,
			contentComponent,
			variant,
			contentClassName,
			controlRowComponent,
			title,
			mobileTitle,
			width: propWidth = 'sm',
			domTreePosition,
			renderInElement,
			zIndex = 9999999,
			hideBottomSeparator,
			containerClassName,
			closeOnOverlayClick,
			bottomBarRemark,
		} = this.props
		// @ts-expect-error todo if you see this please remove this comment and fix the type error, thank you :)
		const width = typeof propWidth === 'string' ? widthVariantToPx[propWidth] : propWidth
		const boxProps = { width, variant }

		const output = (
			<ModalRoot className={className} open={open} zIndex={zIndex} data-test={passTestId(this.props)}>
				<ModalOverlay open={Boolean(open)} onClick={(e) => closeOnOverlayClick && onClose?.(e)} />
				<ModalBase data-test="Modal.Block" open={Boolean(open)} maxWidth={width} className={containerClassName}>
					<ModalControlRow as={controlRowComponent} maxWidth={width} variant={variant} open={open!}>
						{title ? <DesktopTitle data-test="Modal.Title">{title}</DesktopTitle> : null}
						<TabletOrAbove inline>
							{onClose && (
								<CloseButton
									data-test="Modal.Button.Close"
									onClick={(e) => onClose?.(e)}
									// @ts-expect-error todo if you see this please remove this comment and fix the type error, thank you :)
									color={variant ? CompanyTypeAndColors[variant] : 'transparent'}
								>
									<SvgIcon src={CrossIcon} />
								</CloseButton>
							)}
						</TabletOrAbove>
						<MobileHeaderRow>
							<TitleColumn>{mobileTitle || title}</TitleColumn>
							<BackColumn>
								<a onClick={eventStopper.preventDefault((e) => onClose?.(e))}>
									<CloseIcon size={24} />
								</a>
							</BackColumn>
						</MobileHeaderRow>
					</ModalControlRow>
					<ModalContent
						className={contentClassName}
						onTouchStart={(e) => e.preventDefault()}
						ref={this.contentRef as any}
						maxWidth={width}
					>
						<ModalBox className="--scrollAuto" as={contentComponent} {...boxProps}>
							<ZIndexContext.Provider value={zIndex}>{children}</ZIndexContext.Provider>
						</ModalBox>
						{controlButtons?.length ? (
							<BottomModalContainer>
								{hideBottomSeparator ? null : <BottomModalSeparator />}
								<BottomModalControls>
									{bottomBarRemark}
									<BottomModalButtonsContainer>
										<ButtonsContainer>{controlButtons}</ButtonsContainer>
									</BottomModalButtonsContainer>
								</BottomModalControls>
							</BottomModalContainer>
						) : null}
					</ModalContent>
				</ModalBase>
			</ModalRoot>
		)

		// renderInElement overrides domTreePosition, so it should stay above it
		if (renderInElement) {
			return ReactDOM.createPortal(output, renderInElement)
		}

		if (domTreePosition === DOMTreePosition.root && this.context !== null) {
			return ReactDOM.createPortal(output, this.context as HTMLElement)
		}

		return output
	}
}

interface ModalContextProviderState {
	element: HTMLElement | null
}

// TASK migrate to React.FunctionComponent OR remove this if not possible
export class ModalContextProvider extends React.Component<{}, ModalContextProviderState> {
	public state: ModalContextProviderState = {
		element: null,
	}

	public render() {
		const { element } = this.state
		return (
			<ModalContext.Provider value={element}>
				{this.props.children}
				<div
					id="modal-dom-root"
					ref={(ref) => {
						if (ref && ref !== element) {
							this.setState({ element: ref })
						}
					}}
				/>
			</ModalContext.Provider>
		)
	}
}

export default Modal
