import { replaceEntityById } from 'src/lib/entity/replaceEntityById'
import { PaymentInfo } from 'src/travelsuit'

import { IAction } from '../actions/action-helpers'
import { PackagesAndBillingSettingsTypes } from '../actions/actions.types'

export type PackagesAndBillingSettingsState = PaymentInfo[]

export function packagesAndBillingSettingsReducer(
	state: PackagesAndBillingSettingsState = [],
	action: IAction,
): PackagesAndBillingSettingsState {
	switch (action.type) {
		case PackagesAndBillingSettingsTypes.GetPackagesAndBillingSettings.SUCCESS:
			return action.payload
		case PackagesAndBillingSettingsTypes.UpdatePaymentInfo.SUCCESS:
			return replaceEntityById(state, action.payload)
		case PackagesAndBillingSettingsTypes.CreatePackagesAndBillingSettings.SUCCESS:
		case PackagesAndBillingSettingsTypes.UpdatePackagesAndBillingSettings.SUCCESS:
			return action.payload
	}
	return state
}
