import { LiveUserLocation, UserTrackingQS } from 'src/travelsuit'

import { IAction } from '../actions/action-helpers'
import { LiveUserLocationsTypes, UserTrackingTypes } from '../actions/actions.types'

export interface LiveUserLocationsState {
	userLocations: LiveUserLocation[]
}

const emptyLiveUserLocationsState: LiveUserLocationsState = {
	userLocations: [],
}

function updateLocation(location: LiveUserLocation, locations: LiveUserLocation[]): LiveUserLocation[] {
	const idx = locations.findIndex((loc) => loc.traveler.id === location.traveler.id)
	if (idx > -1) {
		const loc: LiveUserLocation = { ...locations[idx], ...location }
		const copy = [...locations]
		copy.splice(idx, 1, loc)
		return copy
	}
	return [...locations, location]
}

function liveUserLocationsReducer(
	state: LiveUserLocationsState = emptyLiveUserLocationsState,
	action: IAction,
): LiveUserLocationsState {
	let userLocation: LiveUserLocation
	let userTrackingPayload: UserTrackingQS

	switch (action.type) {
		case LiveUserLocationsTypes.GetLiveUserLocations.SUCCESS:
			return {
				...state,
				userLocations: action.payload.traveler_locations,
			}
		case UserTrackingTypes.UserTracking.REQUEST:
			userTrackingPayload = action.payload
			userLocation = state.userLocations.find((loc) => loc.user_tracking_id === userTrackingPayload.user_tracking_id)!

			if (!userTrackingPayload.user_tracking_id || !userLocation) {
				return state
			}

			return {
				...state,
				userLocations: updateLocation(
					{
						...userLocation,
						verified: userTrackingPayload.status === 'confirmed',
					},
					state.userLocations,
				),
			}
	}
	return state
}

export default liveUserLocationsReducer
