import React from 'react'
import { useTranslation } from 'react-i18next'

import ErrorBannerMdSrc from 'src/assets/error-banner-md.svg'
import ErrorBannerXsSrc from 'src/assets/error-banner-xs.svg'
import ErrorBannerSrc from 'src/assets/error-banner.svg'
import { Button } from 'src/atoms/Button'
import { Font } from 'src/atoms/GenericComponents'
import { Routes } from 'src/lib/route-utils'
import { ErrorPageBase } from 'src/molecules/ErrorPageBase'
import { backgroundGray, secondaryBlack } from 'src/refactor/colors'
import styled, { FontType } from 'src/styles'

interface GeneralErrorPageProps {
	errorId: string | null
}

const StyledErrorPage = styled(ErrorPageBase)`
	overflow-x: hidden;
	min-height: 100vh;
	background-color: ${backgroundGray};
`

const StyledButton = styled(Button)`
	${(p) => p.theme.breakpoints.down('sm')} {
		width: 100%;
	}
`

export function GeneralErrorPage({ errorId }: GeneralErrorPageProps) {
	const { t } = useTranslation()

	const reload = () => window.location.reload()

	return (
		<StyledErrorPage
			title={t('error-banner.title', 'Something went wrong')}
			subtitle={t('error-banner.subtitle', 'Sorry for the inconvenience. Please try again')}
			buttons={[
				<StyledButton
					key="goToHome"
					onClick={() => {
						window.location.href = Routes.Home
					}}
					color="blueOutline"
					minWidth={160}
				>
					{t('error-banner.go-to-home', 'Home Page')}
				</StyledButton>,
				<StyledButton key="reload" onClick={reload} color="bluePrimary" minWidth={160}>
					{t('error-banner.refresh', 'Refresh')}
				</StyledButton>,
			]}
			pageInfoContent={
				errorId ? (
					<Font as="div" weight={700} size={12} color={secondaryBlack} family={FontType.Comfortaa}>
						{t('error-banner.error-id', 'Error ID: {{errorId}}', { errorId })}
					</Font>
				) : null
			}
			pictureProps={{
				defaultSrc: ErrorBannerSrc,
				srcSets: { xs: ErrorBannerXsSrc, md: ErrorBannerMdSrc },
				width: '100%',
			}}
		/>
	)
}
