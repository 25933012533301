import { IOSMessageHandlerName, isLaunchedAsIOSApp, postMessageToIOSWebView } from './ios'
import { openExternalUrl } from './openExternalUrl'

export function redirectTo3DSVerification(redirectUrl: string) {
	if (isLaunchedAsIOSApp()) {
		postMessageToIOSWebView(IOSMessageHandlerName.InitRedirectTo3ds, {
			url: redirectUrl,
		})
	} else {
		openExternalUrl(redirectUrl, '_self')
	}
}
