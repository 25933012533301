import { BillingPackage } from 'src/travelsuit'
import { GetBillingPackagesResponseZ } from 'src/types/billing'

import { getGGClient, RequestOverrides } from './GetGoingClient'

export async function getBillingPackages(overrides?: RequestOverrides) {
	return getGGClient()
		.request('GET', `packages`, {
			responseSchema: GetBillingPackagesResponseZ,
			...overrides,
		})
		.then((r) => r.data as BillingPackage[])
}
