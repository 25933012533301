import { ImmutableMap } from 'src/lib/immutable/ImmutableMap'
import { TripGap } from 'src/travelsuit'

import { IAction } from '../actions/action-helpers'
import { TripGapsTypes } from '../actions/actions.types'

export type TripIdsToTripGaps = ImmutableMap<number, TripGap[]>

interface TStoreDef {
	tripGapForSearch: TripGap | null
}

export type TripGapsState = ImmutableMap<keyof TStoreDef, any>

function tripGapsReducer(state: TripGapsState = new ImmutableMap(), action: IAction): TripGapsState {
	switch (action.type) {
		case TripGapsTypes.SetTripGapForSearch:
			return state.set('tripGapForSearch', action.payload.tripGap)
		case TripGapsTypes.GetTripGaps.SUCCESS:
		case TripGapsTypes.ResetTripGaps:
			return new ImmutableMap()
	}
	return state
}

export default tripGapsReducer
