import React from 'react'

import { Flex } from 'src/atoms/GenericComponents/GenericComponents'
import LoadingContainer from 'src/atoms/LoadingContainer/LoadingContainer'
import { ActiveUserFeeCard } from 'src/molecules/FeeCards/ActiveUserFeeCard'
import SubscriptionFeeCard from 'src/molecules/FeeCards/SubscriptionFeeCard'
import SupportFeeCard from 'src/molecules/FeeCards/SupportFeeCard'
import TripFeeCard from 'src/molecules/FeeCards/TripFeeCard'
import UserFeeCard from 'src/molecules/FeeCards/UserFeeCard'
import EmptyState from 'src/refactor/molecules/EmptyState/EmptyState'
import styled from 'src/styles'
import { Fee, FeesData } from 'src/travelsuit/fees.types'
import { ServiceFeeType } from 'src/travelsuit/fees.types'

import { useUsageDetailsTranslations } from '../UsageDetails.translations'
import FeesEmptyState from './FeesEmptyState'

const FeeCards = {
	[ServiceFeeType.Trip]: TripFeeCard,
	[ServiceFeeType.ActiveUser]: ActiveUserFeeCard,
	[ServiceFeeType.ProfiledUser]: UserFeeCard,
	[ServiceFeeType.SupportRequest]: SupportFeeCard,
	[ServiceFeeType.AnnualPayment]: SubscriptionFeeCard,
	[ServiceFeeType.MonthlyPayment]: SubscriptionFeeCard,
}

const FeesCount = styled.p`
	margin-bottom: 10px;
	padding: 0;
	font-size: 16px;
	line-height: 22px;
`

export interface IProps {
	fees?: Fee<FeesData>[]
	isLoading: boolean
	onResetFilters?: () => void
	onFeesReload: () => void
}

const FeesList: React.FC<IProps> = ({ fees, isLoading, onResetFilters, onFeesReload }) => {
	const { translation } = useUsageDetailsTranslations()

	return (
		<LoadingContainer loading={isLoading}>
			{fees ? (
				!fees.length && onResetFilters ? (
					<FeesEmptyState resetFilters={onResetFilters} />
				) : (
					<>
						{<FeesCount>{translation.feesCount({ count: fees.length })}</FeesCount>}
						<Flex direction="column" align="stretch" gap={20}>
							{fees.map((fee) => {
								const Card = FeeCards[fee.service_type] as React.FC<{ fee: Fee<FeesData>; onFeesReload: () => void }>
								return <Card key={fee[fee.service_type].id} fee={fee} onFeesReload={onFeesReload} />
							})}
						</Flex>
					</>
				)
			) : (
				<EmptyState
					variant="paperplane"
					title={translation.feesNotFoundTitle}
					subtitle={translation.feesNotFoundSubtitle}
				/>
			)}
		</LoadingContainer>
	)
}

export default FeesList
