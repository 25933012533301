import { SegmentWithUser } from 'src/organisms/SupportRequest/types'
import { SegmentToTraveler, VoidCallback } from 'src/travelsuit'
import { OtherSupportRequest, SupportRequest, SupportRequestType } from 'src/travelsuit/support-request'

import { IAction } from './action-helpers'
import { TripSupportRequestsTypes as Types } from './actions.types'

const createSupportRequestPayloadBase = (segments: SegmentWithUser[]): SupportRequestBase => {
	const segmentsWithIds: SegmentToTraveler[] = []

	segments.forEach((seg) => {
		segmentsWithIds.push({
			user_id: seg.user.id!,
			booking_segment_id: Number(seg.bookingSegment.id!),
		})
	})

	return {
		booking_segment_to_travelers: segmentsWithIds,
	}
}

const createSupportRequestPayloadOther = (segments: SegmentWithUser[], details: string, type: OtherSupportRequest) => {
	const payload: SupportRequestOther = {
		...createSupportRequestPayloadBase(segments),
		request_data: {
			request_type: type,
			data: {
				message: details,
			},
		},
	}

	return payload
}

const createSupportTrainsManageRequest = (
	segments: SegmentWithUser[],
	message?: string,
): SupportRequestTrainsManage => ({
	...createSupportRequestPayloadBase(segments),
	request_data: {
		request_type: SupportRequestType.TrainsManage,
		data: {
			message,
		},
	},
})

export const Actions = {
	createOtherSupportRequest(
		tripId: number,
		segments: SegmentWithUser[],
		details: string,
		type: OtherSupportRequest,
		callback?: VoidCallback<SupportRequest>,
		errCallback?: VoidCallback<never>,
	): IAction {
		const payload = createSupportRequestPayloadOther(segments, details, type)

		return {
			type: Types.CreateSupportRequest.REQUEST,
			payload: { tripId, ...payload },
			callback,
			errCallback,
		}
	},

	createTrainsManageSupportRequest(
		tripId: number,
		segments: SegmentWithUser[],
		message?: string,
		callback?: VoidCallback<never>,
		errCallback?: VoidCallback<never>,
	): IAction {
		return {
			type: Types.CreateSupportRequest.REQUEST,
			payload: { tripId, ...createSupportTrainsManageRequest(segments, message) },
			callback,
			errCallback,
		}
	},
}

export default Actions

/* Support requests interface for backend below */
interface SupportRequestBase {
	booking_segment_to_travelers: SegmentToTraveler[]
}

interface SupportRequestOther extends SupportRequestBase {
	request_data: {
		request_type: SupportRequestType
		data: {
			message: string
		}
	}
}

interface SupportRequestTrainsManage extends SupportRequestBase {
	request_data: {
		request_type: SupportRequestType
		data: {
			message?: string
		}
	}
}
