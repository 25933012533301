import { clearLocalStorageValue, getLocalStorageValue, setLocalStorageValue } from 'src/lib/localStorage'

const VERIFICATION_REDIRECT_DATA_KEY = '3dsVerificationResult'

export interface VerificationRedirectData<TData = never> {
	returnToUrl: string
	state?: object
	data: TData
}

export function getVerificationRedirectData<T = never>() {
	return getLocalStorageValue<VerificationRedirectData<T>>(VERIFICATION_REDIRECT_DATA_KEY)
}

export function setVerificationRedirectData<TData>(data: VerificationRedirectData<TData | undefined>) {
	setLocalStorageValue(VERIFICATION_REDIRECT_DATA_KEY, data)
}

export function clearVerificationRedirectData() {
	clearLocalStorageValue(VERIFICATION_REDIRECT_DATA_KEY)
}
