import React, { cloneElement, FC, ReactElement, useState } from 'react'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper'

import { PopperArrow, PopperContainer } from 'src/atoms/Tooltip/PopperTooltip.components'
import { filterEmptyValues } from 'src/lib/array-utils'

interface IProps {
	targetElement: ReactElement
	placement?: 'top' | 'left' | 'right' | 'bottom'
	className?: string
	dark?: boolean
	windowPadding?: number
}

export const PopperTooltip: FC<IProps> = ({
	targetElement,
	children,
	placement = 'top',
	className,
	dark,
	windowPadding,
}) => {
	const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null)
	const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
	const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null)
	const [visible, setVisible] = useState(false)
	const onMouseOver = () => {
		if (!visible) {
			setVisible(true)
		}
	}
	const onMouseOut = () => setVisible(false)

	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		modifiers: filterEmptyValues([
			{ name: 'arrow', options: { element: arrowElement } },
			windowPadding
				? {
						name: 'preventOverflow',
						options: {
							altAxis: true,
							padding: windowPadding,
						},
					}
				: null,
		]),
		placement,
	})
	const targetElementWithRef = cloneElement(targetElement, {
		ref: setReferenceElement,
		onMouseOver,
		onMouseOut,
	})

	return (
		<>
			{targetElementWithRef}
			{ReactDOM.createPortal(
				<>
					{visible && children && (
						<PopperContainer
							ref={setPopperElement}
							style={styles.popper}
							{...attributes.popper}
							className={className}
							dark={dark}
						>
							{children}
							<PopperArrow ref={setArrowElement} style={styles.arrow} />
						</PopperContainer>
					)}
				</>,
				document.querySelector('#popperContainer')!,
			)}
		</>
	)
}
