import {
	CompanyFlightProgram,
	CompanyFlightProgramCreationFormData,
	CompanyFlightProgramUpdateFormData,
	VoidCallback,
} from 'src/travelsuit'

import { IAction } from './action-helpers'
import { CompanyFlightProgramsTypes } from './actions.types'

export const Actions = {
	getCompanyFlightPrograms(
		callback?: VoidCallback<CompanyFlightProgram[]>,
		errCallback?: VoidCallback<never>,
	): IAction {
		return {
			type: CompanyFlightProgramsTypes.GetCompanyFlightPrograms.REQUEST,
			callback,
			errCallback,
		}
	},
	createCompanyFlightProgram(
		formData: CompanyFlightProgramCreationFormData,
		callback?: VoidCallback<CompanyFlightProgram>,
		errCallback?: VoidCallback<never>,
	): IAction {
		return {
			type: CompanyFlightProgramsTypes.CreateCompanyFlightProgram.REQUEST,
			body: formData,
			callback,
			errCallback,
		}
	},
	updateCompanyFlightProgram(
		formData: CompanyFlightProgramUpdateFormData,
		callback?: VoidCallback<CompanyFlightProgram>,
		errCallback?: VoidCallback<never>,
	): IAction {
		return {
			type: CompanyFlightProgramsTypes.UpdateCompanyFlightProgram.REQUEST,
			body: formData,
			callback,
			errCallback,
		}
	},
	deleteCompanyFlightProgram(id: number, callback?: VoidCallback<never>, errCallback?: VoidCallback<never>): IAction {
		return {
			type: CompanyFlightProgramsTypes.DeleteCompanyFlightProgram.REQUEST,
			payload: { id },
			callback,
			errCallback,
		}
	},
}
