import { z } from 'zod'

import { AbsoluteURLZ, CurrencyZ, ISODateStringZ, OriginalAmountZ, TimezoneZ } from 'src/types/common'
import { BookingOptionUTAZ } from 'src/types/flights/uta'
import { GetSearchResultsResponseZ } from 'src/types/search'
import { UserIdZ } from 'src/types/user'

import {
	AirlineZ,
	FlightCabinClassZ,
	FlightCarrierCodeZ,
	FlightFareGroupKeyZ,
	FlightIdentifierZ,
	FlightOptionKeyZ,
	FlightProviderZ,
	FlightSearchSegmentIdZ,
	FlightSearchSliceIdZ,
	FlightSegmentIdZ,
	FlightSegmentRefIdZ,
	FlightSliceIdZ,
	IATACodeZ,
	MissingFrequentFlyerInformationZ,
} from './common'

export const FlightSearchAirportZ = z.strictObject({
	code: IATACodeZ,
	/** Example: Berlin, Germany */
	location: z.string(),
	/** Example: Brandenburg */
	name: z.string().nullable(),
	timezone: TimezoneZ,
	/**
	 * A sum up of its fields.
	 * Example: Berlin, Germany (BER-Brandenburg)
	 */
	title: z.string(),
})

export type FlightSearchAirport = z.infer<typeof FlightSearchAirportZ>

export const FlightAmenityZ = z.strictObject({
	/** The ID of this amenity. Example: fresh_food */
	amenity: z.string(),
	/** Example: Light meal (fee) */
	display_text: z.string(),
	exists: z.boolean(),
})

export type FlightAmenity = z.infer<typeof FlightAmenityZ>

export const FlightBaggageAllowanceZ = z.strictObject({
	max_pieces: z.number().nullable(),
	max_weight: z.number().nullable(),
	weight_unit: z.string().nullable(),
})

export type FlightBaggageAllowance = z.infer<typeof FlightBaggageAllowanceZ>

export const FlightCarrierZ = z.strictObject({
	baggage_policy_url: AbsoluteURLZ.nullish(),
	code: FlightCarrierCodeZ,
	conditions_of_carriage_url: AbsoluteURLZ.nullish(),
	flight_number_suffix: z.string().nullable(),
	flight_number: z.string().nullable(),
	name: z.string(),
})

export type FlightCarrier = z.infer<typeof FlightCarrierZ>

export const FlightDiscountZ = z.strictObject({
	airline_iata: z.unknown(),
	code: z.string(),
	is_bcd_nego_fare: z.boolean(),
	is_company_discount: z.boolean(),
	is_exclusive: z.boolean(),
	label: z.string().nullable(),
})

export type FlightDiscount = z.infer<typeof FlightDiscountZ>

export const FlightDurationZ = z.strictObject({
	hours: z.number(),
	minutes: z.number(),
})

export type FlightDuration = z.infer<typeof FlightDurationZ>

export const FlightFareComponentZ = z.strictObject({
	airline_code: z.string(),
	contract_codes: z.unknown(),
	discounts: z.array(FlightDiscountZ),
	fare_basis_code: z.string(),
	is_bcd_negotiated: z.boolean(),
	is_company_negotiated: z.boolean(),
	is_negotiated: z.boolean(),
	price_class_code: z.string().nullable(),
	price_class_description: z.string().nullable(),
	price_class_name: z.string().nullable(),
	/** Actually a RefID instead of the main segment ID */
	segment_ids: z.array(FlightSegmentRefIdZ),
	ticket_designator_code: z.string().nullable(),
})

export type FlightFareComponent = z.infer<typeof FlightFareComponentZ>

export const FlightDepartureArrivalZ = z.strictObject({
	airport: FlightSearchAirportZ,
	datetime: ISODateStringZ,
	/** ID of the terminal. Example: F */
	terminal: z.string().nullable(),
})

export type FlightDepartureArrival = z.infer<typeof FlightDepartureArrivalZ>

export const FlightPenaltyFeeZ = z.strictObject({
	/** R means yes */
	allowed: z.union([z.literal('Y'), z.literal('N'), z.literal('R')]),
	/** @todo ID of the application. Example: PRIOR_DEP */
	application: z.string(),
	guaranteed: z.string().nullable(),
	max_amount: z.number().nullable(),
	max_unit: z.string().nullable(),
	min_amount: z.number().nullable(),
	min_unit: z.string().nullable(),
	original_max_amount: z.number().nullable(),
	original_min_amount: z.number().nullable(),
	original_total: z.number().nullable(),
	penalty_type: z.string().nullable(),
	text: z.string().nullable(),
	total: z.number().nullable(),
})

export type FlightPenaltyFee = z.infer<typeof FlightPenaltyFeeZ>

export const FlightPenaltiesZ = z.strictObject({
	cancellation_fee_applies: z.boolean().nullable(),
	cancellation_fees: z.array(FlightPenaltyFeeZ).nullable(),
	carrier_fee: z.boolean().nullable(),
	change_itinerary_fee_applies: z.boolean().nullable(),
	change_itinerary_fees: z.array(FlightPenaltyFeeZ).nullable(),
	currency: CurrencyZ.nullable(),
	deposit_refundable: z.boolean().nullable(),
	deposit_required: z.boolean().nullable(),
	failure_to_confirm_fee_applies: z.boolean().nullable(),
	failure_to_confirm_fees: z.array(FlightPenaltyFeeZ).nullable(),
	original_currency: z.string().nullable(),
	ticket_changeable: z.boolean().nullable(),
	ticket_refundable: z.boolean().nullable(),
	ticket_replacement_fee_applies: z.boolean().nullable(),
	ticket_replacement_fees: z.array(FlightPenaltyFeeZ).nullable(),
})

export type FlightPenalties = z.infer<typeof FlightPenaltiesZ>

export const FlightPolicyDeviationZ = z.strictObject({
	flight_id: z.array(FlightSliceIdZ.nullable()).nullish(),
	name: z.string(),
	res_id: z.string().nullish(),
	search_result_id: z.array(z.string()).nullish(),
	segment_id: z.array(FlightSegmentIdZ.nullable()).nullish(),
	traveler_ids: z.array(UserIdZ),
})

export type FlightPolicyDeviation = z.infer<typeof FlightPolicyDeviationZ>

export const FlightSearchSliceSegmentZ = z.strictObject({
	/** Example: Airbus A320-200 (Sharklets) */
	airplane: z.string().nullable(),
	airport_change: z.boolean(),
	amenities: z.array(FlightAmenityZ).nullable(),
	arrival: FlightDepartureArrivalZ,
	baggage_allowance: FlightBaggageAllowanceZ,
	cabin: FlightCabinClassZ,
	/** Booking segment only */
	cabin_commercial_name: z.string().optional(),
	carrier: FlightCarrierZ,
	class_of_service: z.string(),
	departure: FlightDepartureArrivalZ,
	duration: FlightDurationZ,
	/** In grams of CO2e */
	emissions: z.number().nullable(),
	/**
	 * Provides a stable key of the segment not connected to the fare (and its amenities),
	 * would be the same between different fare groups.
	 * Formula: hash ( carrier, departure, arrival )
	 * */
	id: FlightSearchSegmentIdZ,
	married_segment: z.unknown(),
	operating_carrier: FlightCarrierZ,
	/** A link to the fare components */
	segment_id_ref: FlightSegmentRefIdZ,
	sell_indicator: z.unknown(),
	stops: z.array(z.unknown()).nullable(),
})

export type FlightSearchSliceSegment = z.infer<typeof FlightSearchSliceSegmentZ>

export const FlightSearchSliceZ = z.strictObject({
	duration: FlightDurationZ,
	flight_option_key: FlightOptionKeyZ,
	/**
	 * Provides a stable key of the slice not connected to the fare,
	 * would be the same between different fare groups.
	 * Formula: concat ( segment.0.key )
	 */
	id: FlightSearchSliceIdZ,
	seats: z.number().nullable(),
	segments: z.array(FlightSearchSliceSegmentZ),
})

export type FlightSearchSlice = z.infer<typeof FlightSearchSliceZ>

export const FlightBookingOptionZ = z.strictObject({
	__original_total_price: OriginalAmountZ,
	baggage_allowance: FlightBaggageAllowanceZ.nullable(),
	currency: CurrencyZ,
	fare_components: z.array(FlightFareComponentZ),
	fare_group_key: FlightFareGroupKeyZ,
	fare_group_keys: z.array(z.strictObject({ traveler_id: UserIdZ, fare_group_key: FlightFareGroupKeyZ })),
	/**
	 * A stable key of the path + its fare. Similar to the fare_group_key, but doesn't change between requests.
	 * Differs to the `key` in slices and segments as it is linked to the fare.
	 * Formula: concat ( provider, concat ( fare_components.0.fare_basis_code ), concat ( flight.0.key ) )
	 * */
	flight_identifier: FlightIdentifierZ,
	flight_option_keys: z.array(
		z.strictObject({
			traveler_id: UserIdZ,
			flight_option_keys: z.array(FlightOptionKeyZ),
		}),
	),
	/** Flight slices */
	flights: z.array(FlightSearchSliceZ),
	freetext: z.unknown(),
	hold_luggage_price: z.number().optional(),
	in_policy: z.boolean(),
	is_active: z.boolean(),
	is_ndc: z.boolean(),
	is_negotiated: z.boolean(),
	/** A custom field used on the search page to track upsells */
	isUpsell: z.boolean().optional(),
	missing_ffn_information: MissingFrequentFlyerInformationZ,
	original_flight_identifier: FlightIdentifierZ.nullish(),
	penalties: FlightPenaltiesZ.nullable(),
	policy_deviations: z.array(FlightPolicyDeviationZ),
	provider: FlightProviderZ,
	recommendation_score: z.number(),
	/**
	 * @deprecated
	 * Has a different value to the booking segment.
	 * Use flight_identifier instead.
	 */
	res_id: z.unknown(),
	session_ids: z.unknown().optional(),
	ticket_by_date: z.string().nullable(),
	ticket_refundable: z.boolean().nullable(),
	total_price: z.number(),
	travel_safe_rating: z.number(),
	/** An array of UTA objects for each slice */
	uta: BookingOptionUTAZ,
	validating_carrier_codes: z.array(FlightCarrierCodeZ),
})

export type FlightBookingOption = z.infer<typeof FlightBookingOptionZ>

export const FlightSearchResultZ = z.strictObject({
	airlines: z.array(AirlineZ),
	last_seen_result_index: z.number(),
	max_result_index: z.number(),
	results: z.array(FlightBookingOptionZ),
	session_ids: z.unknown(),
})

export type FlightSearchResult = z.infer<typeof FlightSearchResultZ>

export const GetFlightSearchResultsResponseZ = z.strictObject({
	...GetSearchResultsResponseZ.shape,
	flight_results: FlightSearchResultZ.nullish(),
})
