import { ImmutableMap } from 'src/lib/immutable/ImmutableMap'
import {
	Itinerary,
	ItineraryCarBooking,
	ItineraryChanges,
	ItineraryHotelBooking,
	PriceChange,
	TripNote,
	WithBookingSegmentToTravelers,
} from 'src/travelsuit'
import { BookingSegmentToTravelerUpdates, TripBookingStatusResponse } from 'src/travelsuit/trips'

import { IAction, removeSeatSelectionFromStore } from '../actions/action-helpers'
import { ExpensesTypes, ItineraryTypes, SeatmapTypes, TripTypes } from '../actions/actions.types'

export type ItineraryState = ImmutableMap<number, ItineraryData>

export interface ItineraryData {
	itinerary: Itinerary
	itineraryChanges?: ItineraryChanges
}

function processLoadedItinerary(state: ItineraryState, itinerary: Itinerary) {
	const id = itinerary.trip_id
	const itineraryData = state.get(id)
	const itineraryChanges = itineraryData?.itinerary
		? calculateItineraryPricesIncrease(itineraryData.itinerary, itinerary)
		: undefined

	const newState: ItineraryData = { itinerary }
	if (itineraryChanges) {
		newState.itineraryChanges = itineraryChanges
	}
	return state.set(id, newState)
}

function updateBookingSegmentToTravelers<T extends WithBookingSegmentToTravelers>(
	bookings: T[],
	updates: BookingSegmentToTravelerUpdates[],
) {
	return bookings.map((booking) => {
		const updatedBookingSegmentsToTravelers = booking.booking_segment_to_travelers.map((bookingSegmentToTraveler) => {
			const bookingSegmentToTravelerUpdates: BookingSegmentToTravelerUpdates | undefined = updates.find(
				({ id }: BookingSegmentToTravelerUpdates) => id === bookingSegmentToTraveler.id,
			)

			if (!bookingSegmentToTravelerUpdates) {
				return bookingSegmentToTraveler
			}

			return {
				...bookingSegmentToTraveler,
				...bookingSegmentToTravelerUpdates,
				cloned_booking_errors: !bookingSegmentToTraveler.cloned_booking_errors?.length
					? bookingSegmentToTravelerUpdates.booking_errors
					: bookingSegmentToTraveler.cloned_booking_errors,
			}
		})

		return {
			...booking,
			booking_segment_to_travelers: updatedBookingSegmentsToTravelers,
		}
	})
}

function applyBookingStatusUpdates(itinerary: Itinerary, updates: TripBookingStatusResponse): Itinerary {
	const bookingSegmentToTravelerUpdates = updates.booking_segment_to_travelers
	return {
		...itinerary,
		flights_bookings: updateBookingSegmentToTravelers(itinerary.flights_bookings, bookingSegmentToTravelerUpdates),
		hotels_bookings: updateBookingSegmentToTravelers(itinerary.hotels_bookings, bookingSegmentToTravelerUpdates),
		cars_bookings: updateBookingSegmentToTravelers(itinerary.cars_bookings, bookingSegmentToTravelerUpdates),
		trip_fees: itinerary.trip_fees
			? itinerary.trip_fees.map((tripFee) => {
					const tripFeeUpdates = updates.trip_fees.find(({ user }) => tripFee.user.id === user.id)
					if (!tripFeeUpdates) {
						return tripFee
					}

					return {
						...tripFee,
						status: tripFeeUpdates.status,
					}
				})
			: undefined,
	}
}

function itineraryReducer(state: ItineraryState = new ImmutableMap(), action: IAction): ItineraryState {
	switch (action.type) {
		// Itinerary
		case ItineraryTypes.GetItinerary.SUCCESS:
			return processLoadedItinerary(state, action.payload)

		case ItineraryTypes.ClearItineraryChanges:
			return state.update(action.payload.tripId, ({ itinerary }) => ({ itinerary }))

		case SeatmapTypes.DeleteSeats.SUCCESS:
			return state.update(action.requestParams.trip_id, ({ itinerary }) => {
				return {
					// TO-DO make removeSeatSelectionFromStore as pure function
					itinerary: removeSeatSelectionFromStore({ ...itinerary }, action.requestPayload.seat_keys),
				}
			})
		// Expenses
		case ExpensesTypes.AddExpense.SUCCESS:
			return state.update(action.payload.trip_id, ({ itinerary }) => ({
				itinerary: {
					...itinerary,
					expenses: [...itinerary.expenses, action.payload],
				},
			}))

		case ExpensesTypes.UpdateExpense.SUCCESS:
			return state.update(action.payload.trip_id, (data) => {
				const { itinerary } = data
				const { expenses } = itinerary
				const originalIdx = expenses.findIndex((e) => e.id === action.payload.id)
				if (originalIdx === -1) {
					return data
				}
				expenses[originalIdx] = { ...expenses[originalIdx], ...action.payload }

				const newExpenses = [...expenses]
				return {
					itinerary: {
						...itinerary,
						expenses: newExpenses,
					},
				}
			})

		case ExpensesTypes.RemoveExpense.SUCCESS:
			return state.update(action.requestPayload.trip_id, ({ itinerary }) => {
				const { expenses } = itinerary

				const newExpenses = expenses.filter((e) => e.id !== action.requestPayload.id)
				return {
					itinerary: {
						...itinerary,
						expenses: newExpenses,
					},
				}
			})
		case ItineraryTypes.CancelHotelBooking.SUCCESS:
			return state.update(action.requestPayload.tripId, ({ itinerary }) => {
				return {
					itinerary: {
						...itinerary,
						hotels_bookings: itinerary.hotels_bookings.filter(
							(b) => !action.requestPayload.hotelBookingsIds.includes(b.id),
						),
					},
				}
			})
		case TripTypes.GetBookingStatus.SUCCESS:
			return state.update(action.requestPayload.tripId, ({ itinerary }) => {
				return {
					itinerary: applyBookingStatusUpdates(itinerary, action.payload),
				}
			})
		/* Trip Notes */
		case TripTypes.AddNote.SUCCESS:
			return state.update(action.requestPayload.tripId, ({ itinerary }) => {
				return {
					itinerary: {
						...itinerary,
						trip_notes: [action.payload, ...itinerary.trip_notes!],
					},
				}
			})
		case TripTypes.EditNote.SUCCESS:
			return state.update(action.requestPayload.tripId, ({ itinerary }) => {
				const note = action.payload as TripNote
				const trip_notes = itinerary.trip_notes || []

				const index = trip_notes.findIndex(({ id }) => id === note.id)
				if (~index) {
					trip_notes.splice(index, 1, note)
				} else {
					trip_notes.unshift(note)
				}

				return {
					itinerary: {
						...itinerary,
						trip_notes,
					},
				}
			})
		case TripTypes.RemoveNote.SUCCESS:
			return state.update(action.requestPayload.tripId, ({ itinerary }) => {
				return {
					itinerary: {
						...itinerary,
						trip_notes: itinerary.trip_notes?.filter((note) => action.requestPayload.noteId !== note.id),
					},
				}
			})

		case ItineraryTypes.CancelTrainBooking.SUCCESS:
			return state.update(action.requestPayload.tripId, ({ itinerary }) => {
				return {
					itinerary: {
						...itinerary,
					},
				}
			})
	}
	return state
}

function calculateItineraryPricesIncrease(oldItinerary: Itinerary, newItinerary: Itinerary) {
	const hotels: PriceChange[] = []
	const cars: PriceChange[] = []

	oldItinerary.hotels_bookings
		.filter(({ res_id }) => !!res_id)
		.forEach((hr: ItineraryHotelBooking) => {
			const newHotelRoom = newItinerary.hotels_bookings.find((nhr: ItineraryHotelBooking) => nhr.res_id === hr.res_id)
			if (newHotelRoom && Number(newHotelRoom.total_price) > Number(hr.total_price)) {
				hotels.push({ oldPrice: Number(hr.total_price), newPrice: Number(newHotelRoom.total_price), res_id: hr.res_id })
			}
		})

	oldItinerary.cars_bookings
		.filter(({ res_id }) => !!res_id)
		.forEach((cb: ItineraryCarBooking) => {
			const newCar = newItinerary.cars_bookings.find((ncb: ItineraryCarBooking) => ncb.res_id === cb.res_id)
			if (newCar && Number(newCar.total_price) > Number(cb.total_price)) {
				cars.push({ oldPrice: Number(cb.total_price), newPrice: Number(newCar.total_price), res_id: cb.res_id })
			}
		})

	if (!hotels.length && !cars.length) {
		return null
	}

	return { hotels, cars }
}

export default itineraryReducer
