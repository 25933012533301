import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'

import UserListItem from 'src/atoms/UserListItem/UserListItem'
import { User } from 'src/travelsuit'
import { fullName } from 'src/travelsuit/users'
import { UserStatus } from 'src/types/user'

import Autocomplete, { AutocompleteProps } from './Autocomplete'

export type ApproverAutocompleteProps = Omit<
	AutocompleteProps,
	'itemValueOf' | 'labelFn' | 'disabledCondition' | 'onChange' | 'query' | 'renderOption' | 'sortAlphabetically'
> & {
	onChange?(selected: User): void
	query(search: string): Promise<User[]> | User[]
}

export const ApproverAutocomplete = (props: ApproverAutocompleteProps) => {
	const { inputProps, ...rest } = props
	const { t } = useTranslation()
	const label = t('policy-approver-selector.add-an-approver', '+ Add an approver')

	return (
		<Autocomplete
			disabledCondition={(u: User) => !!getApproverDisabledHint(t, u)}
			inputProps={{
				label,
				...inputProps,
			}}
			itemValueOf={(u: User) => u?.id ?? -1}
			labelFn={fullName}
			renderOption={(u: User) => <UserListItem user={u} infoHint={getApproverDisabledHint(t, u)} />}
			sortAlphabetically
			{...rest}
		/>
	)
}

export function getApproverDisabledHint(t: TFunction, user: User) {
	if (user.status !== UserStatus.Registered || user.deactivate_at) {
		return t(
			'policy-approver-selector.must-have-an-active-permanent-profile',
			'An approver must have a registered permanent profile',
		)
	}
	return undefined
}
