import lottie from 'lottie-web/build/player/lottie_light.min.js'
import React from 'react'

import styled, { mediaQuery, size } from 'src/styles'
import { DefaultElProps } from 'src/travelsuit'

export interface IProps extends DefaultElProps<'div'> {
	animation: object
	width?: number | string
	height?: number | string
	mobileProps?: {
		width?: number | string
		height?: number | string
	}
}

const SvgAnimationContainer = styled.div<Omit<IProps, 'animation'>>`
	${(props) => (props.width || props.height ? size(props.width || 'auto', props.height || 'auto') : '')}
	${(props) =>
		props.mobileProps
			? props.width || props.height
				? mediaQuery.mobileOnly`${size(props.width || 'auto', props.height || 'auto')}`
				: ''
			: ''}
`

// TASK migrate to React.FunctionComponent OR remove this if not possible
class SvgAnimation extends React.Component<IProps> {
	private ref: HTMLElement

	public render() {
		const { className, ...rest } = this.props

		return <SvgAnimationContainer className={className} {...rest} ref={(ref) => this.handleRef(ref)} />
	}

	private handleRef(ref: HTMLElement | null) {
		if (!ref || this.ref === ref) {
			return
		}
		this.ref = ref
		this.startAnimation(ref)
	}

	private startAnimation(ref: HTMLElement) {
		const { animation } = this.props
		lottie.loadAnimation({
			container: ref,
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: animation,
		})
	}
}

export default SvgAnimation
