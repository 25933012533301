import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from 'src/atoms/Button/Button'
import Modal from 'src/atoms/Modal/Modal'
import { Timer } from 'src/atoms/Timer/Timer'
import { requestProviderLogout } from 'src/lib/auth'
import { getMillisecondsToExpire, setTimerLogout } from 'src/lib/auth'
import { useTranslation } from 'src/lib/i18n/i18n'
import { AuthActions } from 'src/redux/actions'
import { ApplicationState } from 'src/redux/stores'

import { Bolder, ClockIcon, Container, Text } from './SessionTimeoutModal.components'

interface IProps {
	onClose: () => void
}

export const SessionTimeoutModal: FC<IProps> = ({ onClose }) => {
	const auth = useSelector((store: ApplicationState) => store.auth)
	const sessionInfo = auth.get('sessionInfo')
	const getSeconds = useCallback(() => {
		return Math.floor(getMillisecondsToExpire(sessionInfo?.expires_at) / 1000)
	}, [sessionInfo?.expires_at])
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const onConfirm = () => {
		dispatch(AuthActions.updateSession())
		dispatch(AuthActions.hideModal())
		onClose()
	}

	const onLogout = () => {
		requestProviderLogout()
		onClose()
	}

	const onCancel = () => {
		setTimerLogout()
		onClose()
	}

	return (
		<Modal
			title={t('session-timeout-modal.title', 'Session Timeout')}
			variant="warning"
			onClose={onClose}
			controlButtons={[
				<Button key="cancel" color="blueOutline" onClick={onLogout}>
					{t('session-timeout-modal.btn-cancel', 'Log Off')}
				</Button>,
				<Button key="ok" color="bluePrimary" onClick={onConfirm}>
					{t('session-timeout-modal.btn-ok', 'Continue Session')}
				</Button>,
			]}
			renderInElement={document.querySelector('#popperContainer')! as HTMLElement}
			zIndex={200000}
		>
			<Container>
				<Text>
					<ClockIcon />
					{t('session-timeout-modal.text', 'Your session is about to expire. The remaining time')}{' '}
					<Bolder>
						<Timer getSeconds={getSeconds} onFinishTimer={onCancel} />
					</Bolder>
				</Text>
				<Text>{t('session-timeout-modal.confirmation', 'Do you want to continue?')}</Text>
			</Container>
		</Modal>
	)
}
