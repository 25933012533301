export const enrichWithBrowserData = (body: Record<string, unknown>) => {
	const browser_data = {
		javaScriptEnabled: true,
		colorDepth: screen.colorDepth,
		screenHeight: screen.height,
		screenWidth: screen.width,
		innerHeight: window.innerHeight,
		innerWidth: window.innerWidth,
	}
	return {
		...body,
		browser_data,
		timezoneOffsetUtcMinutes: new Date().getTimezoneOffset(),
	}
}
