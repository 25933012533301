import { IAction, TaxAndVatRateTypes } from 'src/redux/actions'
import { TaxAndVatRate, TaxAndVatRateApi } from 'src/types/expenses'

export type TaxAndVatRatesState = Array<TaxAndVatRate>

function taxAndVatRatesReducer(state: TaxAndVatRatesState = [], action: IAction) {
	switch (action.type) {
		case TaxAndVatRateTypes.GetRates.SUCCESS:
			return sort(action.payload).map(normalize)
		default:
			return state
	}
}

export default taxAndVatRatesReducer

export function normalize(taxAndVatRate: TaxAndVatRateApi): TaxAndVatRate {
	return {
		id: taxAndVatRate.id,
		country: { name: taxAndVatRate.country, code: taxAndVatRate.country_code },
		merchant_country: {
			name: taxAndVatRate.all_countries ? 'All Countries' : taxAndVatRate.merchant_country_name,
			code: taxAndVatRate.all_countries ? '' : taxAndVatRate.merchant_country_code ?? '',
		},
		all_countries: taxAndVatRate.all_countries,
		rate: taxAndVatRate.rate,
		name: taxAndVatRate.name,
	}
}

export function denormalize(taxAndVatRate: TaxAndVatRate): TaxAndVatRateApi {
	const all_countries = taxAndVatRate.merchant_country.code === ''

	return {
		id: taxAndVatRate.id,
		country: taxAndVatRate.country.name,
		country_code: taxAndVatRate.country.code,
		merchant_country_name: taxAndVatRate.merchant_country.name,
		merchant_country_code: taxAndVatRate.merchant_country.code || undefined,
		all_countries,
		rate: +taxAndVatRate.rate,
		name: taxAndVatRate.name,
	}
}

function sort(taxAndVatRates: TaxAndVatRateApi[]): TaxAndVatRateApi[] {
	const sorted = [...taxAndVatRates]

	const compareFns: ((a: TaxAndVatRateApi, b: TaxAndVatRateApi) => number)[] = [
		(a, b) => a.country.localeCompare(b.country),
		(a, b) => +a.all_countries - +b.all_countries,
		(a, b) => a.merchant_country_name.localeCompare(b.merchant_country_name),
		(a, b) => b.rate - a.rate,
		(a, b) => a.name.localeCompare(b.name),
	]

	sorted.sort((a, b) => {
		const _compareFns = [...compareFns]
		let difference = 0
		while (_compareFns.length) {
			difference = _compareFns.shift()!(a, b)
			if (difference !== 0) {
				return difference
			}
		}
		return difference
	})

	return sorted
}
