import { removeFromArrayByIndex } from './removeFromArrayByIndex'

export function toggleValueInArray<T>(values: T[], valueToToggle: T): T[] {
	const indexOfValueToToggle = values.indexOf(valueToToggle)

	if (indexOfValueToToggle === -1) {
		return [...values, valueToToggle]
	}

	return removeFromArrayByIndex(values, indexOfValueToToggle)
}
