import { VoidCallback } from 'src/travelsuit'

import { IAction } from './action-helpers'
import { HotelsRatesTypes as Types } from './actions.types'

export const Actions = {
	getHotelRates(searchId: number, hotelId: string, callback?: VoidCallback, errCallback?: VoidCallback): IAction {
		return {
			type: Types.GetHotelRates.REQUEST,
			payload: { searchId, hotelId },
			params: { search_id: searchId },
			callback,
			errCallback,
		}
	},
}
