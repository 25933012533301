import { SearchData, SearchRequestWithTrain, SearchResult } from 'src/travelsuit'
import {
	GetSearchParamsResponseZ,
	GetSearchResultsResponseZ,
	SearchId,
	SearchJobStatus,
	StartSearchRequestZ,
	StartSearchResponseZ,
} from 'src/types/search'

import { getGGClient, RequestOverrides } from './GetGoingClient'

export async function getSearchParams(searchId: SearchId, overrides?: RequestOverrides) {
	return getGGClient()
		.request('GET', `search/${searchId}`, {
			responseSchema: GetSearchParamsResponseZ,
			...overrides,
		})
		.then((r) => r.data as unknown as SearchData)
}

export async function getSearchResults(searchId: SearchId, overrides?: RequestOverrides) {
	return getGGClient().pollJobResult(`search/${searchId}/results`, {
		responseSchema: GetSearchResultsResponseZ,
		mapResult: ({ data }, poll) =>
			data.status === SearchJobStatus.Success || data.status === SearchJobStatus.Failed
				? (data as unknown as SearchResult)
				: poll,
		...overrides,
	})
}

export async function startSearch(params: SearchRequestWithTrain, overrides?: RequestOverrides) {
	return getGGClient()
		.request('POST', 'search', {
			requestSchema: StartSearchRequestZ,
			responseSchema: StartSearchResponseZ,
			data: params,
			...overrides,
		})
		.then((r) => r.data)
}
