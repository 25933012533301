import React from 'react'

import useTranslation from 'src/lib/i18n/UseTranslation'
import { Fee, PackagePaymentFee } from 'src/travelsuit/fees.types'

import BaseFeeCard from './BaseFeeCard'

interface IProps {
	fee: Fee<PackagePaymentFee>
	onFeesReload: () => void
}

const SubscriptionFeeCard: React.FC<IProps> = ({ fee }) => {
	const { t } = useTranslation()
	const {
		payment_info: { invoice_profile, package: billingPackage, country_pos },
	} = fee[fee.service_type]

	const packageName = billingPackage.name
	const country = country_pos.country

	return (
		<BaseFeeCard
			fee={fee}
			country={country}
			secondaryChipText={t('usage-details.fees.package', 'Package - {{name}}', { packageName })}
			primaryInfo={invoice_profile?.display_name || ''}
		/>
	)
}

export default SubscriptionFeeCard
