import uniqWith from 'lodash/uniqWith'
import moment from 'moment'

import { getPlaceDetails, searchAddress } from 'src/lib/places-api'
import { loadData } from 'src/lib/requests'
import { WithAbortRequestConfig } from 'src/lib/requests'
import { entityGenerator } from 'src/lib/utils'
import { CountriesActions } from 'src/redux/actions'
import { getStore, getStoreState } from 'src/redux/stores'
import { searchAirports } from 'src/travelsuit/airports'
import { searchHotelsByName } from 'src/travelsuit/hotels'
import { Locales } from 'src/types/locale'

import {
	Airport,
	Country,
	FlightDirection,
	HotelSearchResult,
	HTTPMethod,
	Location,
	LocationId,
	LocationTypes,
	ProductType,
	ResultSegment,
	TripDirection,
	User,
} from './index'
import { City, filterEmptyCities, searchSites, Station } from './sites'

export interface OfficeLocation extends Partial<Location> {
	main_approver?: Omit<User, 'traveler_profile'> | null
}

export const emptyLocation = entityGenerator<Location>({
	id: null,
	name: '',
	address: '',
	location_type: '' as LocationTypes,
	lat: null,
	long: null,
	location_data: null,
	description: null,
	main_contact_name: null,
	main_contact_email: null,
	main_contact_phone: null,
	main_approver: null,
	state_code: null,
})
export const emptyOfficeLocation = entityGenerator<Partial<OfficeLocation>>({
	name: '',
	address: '',
	location_type: '' as LocationTypes,
	lat: null,
	long: null,
	location_data: null,
})

export const emptyCountry = entityGenerator<Country>({
	calling_code: '',
	name: '',
	iso: '',
})

export const BERLIN_TRAINSTATION = emptyLocation({
	id: 731188,
	name: 'Stresow (S), Berlin',
	description: 'Berlin Train station',
	location_type: LocationTypes.Rail,
})

export const FRANKFURT_TRAINSTATION = emptyLocation({
	id: 278118,
	name: 'Bahnhof, Frankfurt (Oder)',
	description: 'Frankfurt Train station',
	location_type: LocationTypes.Rail,
})

interface OfficeJson {
	id: LocationId | null
	name: string
	address: string
	created_dt?: string
	office_type: LocationTypes
	country_code?: string
	city_name?: string
	state_name?: string
	description: string | null
	contact_name: string | null
	contact_email: string | null
	contact_phone: string | null
	office_approver?: User | null
	office_approver_id?: number | null
	latitude: number | null
	longitude: number | null
	group_id?: number
	has_policy?: boolean
	users_count?: number
}

export function prepareLocationJson(location: Location): OfficeJson {
	return {
		id: location.id,
		office_type: location.location_type,
		created_dt: location.created_dt,
		name: location.name,
		address: location.address,
		description: location.description,
		contact_email: location.main_contact_email,
		contact_phone: location.main_contact_phone,
		contact_name: location.main_contact_name,
		office_approver_id: location.main_approver?.id,
		country_code: location.country_code,
		city_name: location.city_name,
		state_name: location.state_name,
		longitude: location.long,
		latitude: location.lat,
		group_id: location.group_id,
		has_policy: location.has_policy,
	}
}

export function parseOfficeJson(officeJson: OfficeJson): Location {
	return {
		id: officeJson.id,
		name: officeJson.name,
		created_dt: officeJson.created_dt,
		address: officeJson.address,
		location_type: officeJson.office_type,
		location_data: null,
		country_code: officeJson.country_code,
		city_name: officeJson.city_name,
		state_name: officeJson.state_name,
		description: officeJson.description,
		main_contact_name: officeJson.contact_name,
		main_contact_email: officeJson.contact_email,
		main_contact_phone: officeJson.contact_phone,
		main_approver: officeJson.office_approver || null,
		lat: officeJson.latitude,
		long: officeJson.longitude,
		group_id: officeJson.group_id,
		has_policy: officeJson.has_policy,
		users_count: officeJson.users_count,
		state_code: null,
	}
}

let gettingCountries: Promise<Country[]> | null = null

export async function getCountries(): Promise<Country[]> {
	const cached = getStoreState().countries
	if (cached.cached && cached.countries.every((c) => c.calling_code !== null)) {
		return cached.countries
	}

	if (gettingCountries) {
		return gettingCountries
	}

	return (gettingCountries = new Promise((resolve, reject) => {
		getStore().dispatch(
			CountriesActions.getCountries(
				(countries) => {
					gettingCountries = null
					resolve(countries)
				},
				(err) => {
					gettingCountries = null
					reject(err)
				},
			),
		)
	}))
}

export async function searchCountries(search: string) {
	search = search.toLocaleLowerCase().trim()
	const countries: Country[] = await getCountries()
	return countries.filter(({ name }) => new RegExp(`\\b${search}`).test(name!.toLocaleLowerCase())).slice(0, 20)
}

export async function searchLocations(
	search: string,
	filters?: { office_type: LocationTypes },
	shouldSortByLastCreated = false,
) {
	const { data: rawResults } = await loadData<OfficeJson[]>({
		resourcePath: 'offices',
		params: { search: search.toLocaleLowerCase().trim(), ...filters },
	})

	const results = rawResults?.map((office: OfficeJson) => parseOfficeJson(office))

	if (!results?.length) {
		return []
	}

	const locations = removeDuplicateAirports(results)

	if (shouldSortByLastCreated) {
		return (locations || []).sort((a, b) => (moment(a.created_dt).isSameOrBefore(moment(b.created_dt)) ? 1 : -1))
	}
	return locations
}

function removeDuplicateAirports(locations: Location[]): Location[] {
	const nonDuplicateAirports = uniqWith(locations, (arrLoc, othLoc) => {
		const isNonAirport =
			arrLoc.location_type !== LocationTypes.Airport || othLoc.location_type !== LocationTypes.Airport
		if (isNonAirport) {
			return false
		}

		return arrLoc.airport_name === othLoc.airport_name
	})

	return nonDuplicateAirports
}

function autocompletePredictionsToLocations(predictions: google.maps.places.AutocompletePrediction[]): Location[] {
	return predictions.map(
		(prediction) =>
			({
				id: null,
				name: prediction.description,
				address: prediction.description,
				location_data: prediction,
				location_type: LocationTypes.Other,
			}) as Location,
	)
}

type LoadCitiesArgs = WithAbortRequestConfig & {
	terms: string
}

async function loadCities({ terms, getAbortRequestConfig }: LoadCitiesArgs) {
	const cities = await searchSites({ search: terms, getAbortRequestConfig })
	return filterEmptyCities(cities)
}

async function loadLocationsByCities({
	terms,
	locationTypesToTransform = [LocationTypes.City, LocationTypes.Airport, LocationTypes.Rail, LocationTypes.Office],
	getAbortRequestConfig,
}: {
	locationTypesToTransform?: LocationTypes[]
} & LoadCitiesArgs) {
	const nonEmptyCities = await loadCities({ terms, getAbortRequestConfig })
	return transformIntoLocations(locationTypesToTransform, nonEmptyCities)
}

export async function searchHotelAddresses({
	terms,
	getAbortRequestConfig,
	sessionToken,
}: {
	sessionToken: google.maps.places.AutocompleteSessionToken
} & LoadCitiesArgs) {
	terms = terms.trim()

	const [locations, hotels, addresses] = await Promise.all([
		loadLocationsByCities({
			terms,
			getAbortRequestConfig,
			locationTypesToTransform: [LocationTypes.Office],
		}),
		searchHotelsByName(terms, { include_hotels: true }).then(hotelsToLocations),
		searchAddress(terms, sessionToken).then(autocompletePredictionsToLocations),
	])

	return {
		data: [...locations, ...hotels, ...addresses],
	}
}

export async function searchCarAddresses({ terms, getAbortRequestConfig }: LoadCitiesArgs) {
	return {
		data: await loadLocationsByCities({
			terms: terms.trim(),
			getAbortRequestConfig,
		}),
	}
}

export async function searchFlightAddresses({ terms, getAbortRequestConfig }: LoadCitiesArgs) {
	const cities = await loadCities({ terms: terms.trim(), getAbortRequestConfig })

	return { data: cities }
}

const LOCATION_CONVERTERS: Partial<Record<LocationTypes, (cities: City[]) => Location[]>> = {
	[LocationTypes.Airport]: airportsToLocations,
	[LocationTypes.City]: citiesToLocation,
	[LocationTypes.Office]: officeToLocations,
	[LocationTypes.Rail]: trainsToLocations,
}

export function transformIntoLocations(locationTypes: LocationTypes[], cities: City[]): Location[] {
	return Object.keys(LOCATION_CONVERTERS)
		.filter((locationType: LocationTypes) => locationTypes.includes(locationType))
		.flatMap((locationType: LocationTypes) =>
			LOCATION_CONVERTERS[locationType] ? LOCATION_CONVERTERS[locationType](cities) : [],
		)
}

function citiesToLocation(cities: City[]): Location[] {
	return cities.map((city) => {
		return emptyLocation({
			id: city.id,
			lat: city.latitude,
			location_type: LocationTypes.City,
			long: city.longitude,
			name: city.city,
			country_name: city.country,
			short_name: city.iata_code,
			state_code: city.state_code,
			iata_code: city.iata_code,
		})
	})
}

function countriesToLocation(country: Country[]): Location[] {
	return country.map((country) => {
		return emptyLocation({
			location_type: LocationTypes.Country,
			name: country.name,
			short_name: country.iso,
		})
	})
}

function airportsToLocations(cities: City[]): Location[] {
	return cities.flatMap((city) =>
		city.airports.map((airport) => {
			return emptyLocation({
				id: airport.id,
				location_type: LocationTypes.Airport,
				name: airport.name,
				short_name: airport.code,
				address: airport.name,
				lat: airport.latitude,
				long: airport.longitude,
				airport_name: airport.code,
				country: airport.country_iso,
				city_code: city.iata_code,
				code: airport.code,
			})
		}),
	)
}

function officeToLocations(cities: City[]): Location[] {
	return cities.flatMap((city) =>
		city.offices.map((office) => {
			return emptyLocation({
				name: office.name,
				address: office.address,
				location_type: LocationTypes.Office,
				visibility: 'public',
				id: office.id as any,
				//TODO: Office ids are generated string with symbols
				// As a temporary solution it's safe passthrough them as numbers but
				// later we should move from intermediate Location transformation
			})
		}),
	)
}

export function railStationToLocation(station: Station) {
	return emptyLocation({
		id: station.id,
		name: station.name_local,
		location_type: LocationTypes.Rail,
		external_id: station.external_id,
	})
}

function trainsToLocations(cities: City[]): Location[] {
	return cities.flatMap((city) => city.stations.map(railStationToLocation))
}

function hotelsToLocations(hotels: HotelSearchResult[]): Location[] {
	return hotels.map((hotel) =>
		emptyLocation({
			id: null,
			name: hotel.label,
			address: hotel.label,
			location_data: hotel,
			location_type: hotel.location_type,
			lat: hotel.latitude,
			long: hotel.longitude,
		}),
	)
}

export async function searchAddresses(
	terms: string,
	autocompleteSessionKey: google.maps.places.AutocompleteSessionToken,
	type: ProductType | null,
) {
	terms = terms.trim()

	if (!terms.length) {
		return await searchLocations(terms, undefined, true)
	}

	const addresses = searchAddress(terms, autocompleteSessionKey)
	const airports = [ProductType.Flights, ProductType.Cars, ProductType.Rails].includes(type!)
		? searchAirports(terms)
		: Promise.resolve([])
	const hotels = [ProductType.Hotels, null].includes(type as ProductType | null)
		? searchHotelsByName(terms, { include_hotels: type === ProductType.Hotels })
		: Promise.resolve([])
	const locations = await searchLocations(terms, undefined, true)
	const [hotelsRes, addressRes, airportRes] = await Promise.all([
		hotels,
		addresses.then((res) =>
			res.filter(
				(a) => !locations.find((l) => l.name === a.description || `${l.name}, ${l.country_name}` === a.description),
			),
		),
		airports,
	])

	const airportsLocations = _createAirportsLocations(airportRes, locations)

	let locs = [
		...locations,
		...hotelsRes
			.filter((h) => !locations.find((l) => l.name === h.label || `${l.name}, ${l.country_name}` === h.label))
			.map((h) =>
				emptyLocation({
					id: null,
					name: h.label,
					address: h.label,
					location_data: h,
					location_type: h.location_type,
					lat: h.latitude,
					long: h.longitude,
				}),
			),
		...airportsLocations,
	]

	if (!locs.length || type !== ProductType.Flights) {
		locs = [...locs, ...autocompletePredictionsToLocations(addressRes)]
	}

	if (type === ProductType.Flights || type === ProductType.Rails) {
		locs = [...locs, BERLIN_TRAINSTATION, FRANKFURT_TRAINSTATION]
	}

	return locs
}

function _createAirportsLocations(airports: Airport[], userPrivateLocations: Location[]): Location[] {
	const mapCityCodeToAirports = airports.reduce((map, a) => {
		return map.set(a.city_code || '', (map.get(a.city_code || '') || []).concat([a]))
	}, new Map<string, Airport[]>())

	return Array.from(mapCityCodeToAirports.keys()).reduce((airportsLocations, code) => {
		const relevantAirports = mapCityCodeToAirports.get(code) || []

		return airportsLocations.concat(
			relevantAirports.reduce((airportsLocation, a) => {
				const name = a.location_type === LocationTypes.Airport ? `${a.name} (${a.iata})` : a.name
				const savedLocation = userPrivateLocations.find(
					(l) =>
						(l.name === name && l.location_type && l.location_type === a.location_type) ||
						// Check the we won't have any duplicate airports by comparing iata
						(l.location_type === LocationTypes.Airport &&
							a.location_type === LocationTypes.Airport &&
							l.airport_name &&
							a.location_data?.iata &&
							l.airport_name === a.location_data.iata),
				)
				if (!savedLocation) {
					airportsLocation.push({
						id: null,
						name,
						short_name: a.location_data?.iata,
						address: a.name,
						location_data: a.location_data,
						location_type: a.location_type,
						lat: a.lat,
						long: a.long,
						airport_name: a.location_data?.iata,
						country: a.country_iso,
						country_name: a.country_name,
					} as Location)
				}
				return airportsLocation
			}, new Array<Location>()),
		)
	}, new Array<Location>())
}

export async function searchAirportLocations(terms: string): Promise<Location[]> {
	const airportsAndCities = transformIntoLocations(
		[LocationTypes.Airport, LocationTypes.City],
		(await searchSites({ search: terms })).filter((c) => c.iata_code),
	)
	const countries = terms.trim() ? countriesToLocation(await searchCountries(terms)) : []

	return [...airportsAndCities, ...countries]
}

export async function addLocation({ id, ...location }: Location) {
	const { data } = await loadData<Location>({ resourcePath: 'locations', data: location, method: HTTPMethod.POST })
	return data
}

export function getLocationsAutoCompleteSegments(
	productType: ProductType,
	flightType?: FlightDirection,
): ResultSegment[] {
	type SegmentType = 'companyLocs' | 'cities' | 'airportsOrCities' | 'airports' | 'hotels' | 'addresses' | 'trains'

	const segments: Record<SegmentType, ResultSegment> = {
		companyLocs: {
			key: 'company_locs',
			label: 'locations.autocomplete-segments.company-locations',
			filterFn: (result) => Boolean(result.id && result.visibility === 'public'),
		},
		cities: {
			key: 'cities',
			label: 'locations.autocomplete-segments.cities',
			filterFn: (result) => result.location_type === LocationTypes.City,
		},
		airportsOrCities: {
			key: 'airports_or_cities',
			label: 'locations.autocomplete-segments.airports',
			filterFn: (result) =>
				result.location_type === LocationTypes.Airport || result.location_type === LocationTypes.City,
		},
		airports: {
			key: 'airports',
			label: 'locations.autocomplete-segments.airports',
			filterFn: (result) => result.location_type === LocationTypes.Airport,
		},
		hotels: {
			key: 'hotels',
			label: 'locations.autocomplete-segments.hotels',
			filterFn: (result) => result.location_type === LocationTypes.Hotel,
		},
		addresses: {
			key: 'addresss',
			label: 'locations.autocomplete-segments.addresses',
			filterFn: (result) =>
				result.location_type === LocationTypes.Other && (!result.id || result.visibility === 'private'),
		},
		trains: {
			key: 'trains',
			label: 'TrainStation',
			filterFn: (result) => result.location_type === LocationTypes.Rail,
		},
	}

	switch (productType) {
		case ProductType.Hotels:
			return [segments.cities, segments.hotels, segments.companyLocs, segments.addresses]
		case ProductType.Cars:
			return [segments.airports, segments.cities, segments.companyLocs, segments.addresses]
		case ProductType.Flights:
			return flightType === TripDirection.MultiDestination
				? [segments.airportsOrCities, segments.companyLocs, segments.addresses]
				: [segments.airportsOrCities, segments.companyLocs, segments.addresses, segments.trains]
		default:
			return [] // Unsupported
	}
}

export async function enrichLocationDetails(
	loc: Location,
	autocompleteSessionKey: google.maps.places.AutocompleteSessionToken,
): Promise<Location> {
	if (loc.location_data && 'place_id' in loc.location_data && loc.location_data.place_id) {
		const data = await getPlaceDetails(loc.location_data.place_id, autocompleteSessionKey)
		loc.location_data = data
		if (data?.geometry?.location) {
			loc.lat = data.geometry.location.lat()
			loc.long = data.geometry.location.lng()
		}
	}
	return loc
}

export function getAdditionalLocationData(loc: Location) {
	if (loc.location_data) {
		return loc
	}

	const { country_name, ...rest } = loc
	return {
		...rest,
		address: loc.name || '',
		location_data: {
			label: loc.name || '',
			latitude: loc.lat || 0,
			longitude: loc.long || 0,
		},
	}
}

export const PrivacyPolicyLinkByLanguage: Record<Locales, string> = {
	[Locales.enUS]: 'https://www.bcdtravel.com/privacy-policy/',
	[Locales.enGB]: 'https://www.bcdtravel.com/privacy-policy/',
	[Locales.deDE]: 'https://www.bcdtravel.com/privacy-policy/#privacy_policy_de',
}
