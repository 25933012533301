import { IAction } from 'src/redux/actions/action-helpers'
import { IndustriesTypes } from 'src/redux/actions/actions.types'

export type IndustriesState = string[]

export function industriesReducer(state: IndustriesState = [], action: IAction): IndustriesState {
	switch (action.type) {
		case IndustriesTypes.GetIndustries.SUCCESS:
			return action.payload.industries
	}
	return state
}
