import { z } from 'zod'

import { LocationZ, TripDirectionZ } from '../trip'
import { UserZ } from '../user'

const RailsType = z.literal('rails')

export const PageDirectionZ = z.enum(['next', 'previous'])

export type PageDirection = z.infer<typeof PageDirectionZ>

// Requests

export const RailSearchRequestRouteZ = z.object({
	departure_date: z.string().datetime(),
	from_location: z.string(),
	from_location_type: z.enum(['rail']),
	to_location: z.string(),
	to_location_type: z.enum(['rail']),
	connecting_locations: z
		.object({
			location_type: z.string(),
			location_id: z.string(),
		})
		.array(),
})

export type RailSearchRequestRoute = z.infer<typeof RailSearchRequestRouteZ>

export const RailSearchRequestDetailsZ = z.object({
	rails_type: TripDirectionZ,
	routes: RailSearchRequestRouteZ.array().min(1),
	card_id: z.string().optional(),
})

export type RailSearchRequestDetails = z.infer<typeof RailSearchRequestDetailsZ>

export const RailSearchRequestZ = z.object({
	type: RailsType,
	details: RailSearchRequestDetailsZ,
	travelers_ids: z.number().int().array(),
	trip_id: z.number().int(),
})

export type RailSearchRequest = z.infer<typeof RailSearchRequestZ>

export const RailSearchSetInwardRequestZ = z.object({
	search_id: z.string(),
	outward_journey_id: z.string(),
	outward_alternative_ids: z.string().array(),
})

export type RailSearchSetInwardRequest = z.infer<typeof RailSearchSetInwardRequestZ>

export const RailSearchExtendPageRequestZ = z.object({
	search_id: z.string(),
	type: z.enum(['outward', 'inward']),
	page_direction: z.enum(['next', 'previous']),
	inward_search_id: z.string().optional(),
})

export type RailSearchExtendPageRequest = z.infer<typeof RailSearchExtendPageRequestZ>

// Responses

export const RailSearchRouteZ = z.object({
	departure_date: z.string().datetime(),
	from_location: LocationZ.nullable(),
	to_location: LocationZ.nullable(),
	connecting_locations: z
		.object({
			location_type: z.string(),
			location: LocationZ,
		})
		.array(),
})

export type RailSearchRoute = z.infer<typeof RailSearchRouteZ>

export const RailSearchDetailsZ = z.object({
	rails_type: TripDirectionZ,
	routes: RailSearchRouteZ.array().min(1),
	card_id: z.string().optional(),
	search_id: z.string().optional(),
	inward_search_id: z.string().optional(),
	page_direction: PageDirectionZ.optional(),
})

export type RailSearchDetails = z.infer<typeof RailSearchDetailsZ>

export const RailSearchResponseZ = z.object({
	type: RailsType,
	details: RailSearchDetailsZ,
	travelers: UserZ.array().min(1),
	trip_id: z.number().int(),
})

export type RailSearchResponse = z.infer<typeof RailSearchResponseZ>

export function mapRailSearchRequestRoute(route: RailSearchRoute): RailSearchRequestRoute {
	return {
		departure_date: route.departure_date,
		from_location: route.from_location!.external_id!,
		from_location_type: 'rail',
		to_location: route.to_location!.external_id!,
		to_location_type: 'rail',
		connecting_locations: route.connecting_locations.map((locationDetails) => ({
			location_type: locationDetails.location_type,
			location_id: locationDetails.location.external_id!,
		})),
	}
}
